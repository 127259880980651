import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid
} from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import { socketConnect } from 'socket.io-react';
import Button from 'components/CustomButtons/Button';
import ActiveCheckout from './Active';
import CheckoutList from './List';
class TabMobileCheckout extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      current:'list'
    };
    
  }
  changeTab(slug) {
    this.setState({current:slug});
  };
  render() {
    const {current } = this.state;
    const tabs = {
      // 'view': { title: 'Active Checkout', slug: 'view'},
      // 'list': { title: 'Checkout List', slug: 'list'},
      // 'log': { title: 'Log', slug: 'log'},
      
    }
    const { userData,dataArray } = this.props;
    const props = { userData: userData || {}, user_id: +userData.user_id,dataArray: dataArray || {} };
    const buttons = Object.keys(tabs).map((key, idx) => {
      const { title, slug} = tabs[key];
      return <Button key={idx} color={(slug === current) ? 'blue' : 'white'} onClick={() => this.changeTab(slug)}>{title}</Button>;
    });
    let tabsData;
    // console.log(tabsData);
    if (current=='view') {      
      tabsData = <ActiveCheckout {...props}/>;    
    } else {     
       tabsData = <CheckoutList {...props} />; 
    }
    return (
      <div  className='progress scrollable-h'>
        <Grid container>
      <div className='tabsCheckout'>
        <ItemGrid md={12}>
          {buttons}
        </ItemGrid>
      </div>
      <div className='afterTabsCheckout'>
        <div className={'full-w'}>
          {tabsData}
        </div>
      </div>
    </Grid>
    </div>
    );
  }
}

TabMobileCheckout.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object
};
const props = state => ({
  dataArray: state.dataArray
});

export default connect(props, null)(socketConnect(TabMobileCheckout));
