import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomInput from './CustomInput';

class ItselfInput extends Component {
  constructor(props) {
    super(props);
    const { value } = props.inputProps || {};
    this.state = {
      value: value || ''
    };
  }

  componentWillReceiveProps(next) {
    const { value: oldValue } = this.state;
    const { value } = next.inputProps || {};
    if (oldValue !== value && this.ItselfInputRef) {
      this.setState({ value });
    }
  }

  render() {
    const { value } = this.state;
    const { inputProps: old } = this.props;
    const onChange = e => this.setState({ value: e.target.value });
    const inputProps = { ...old, onChange, ...(value ? { value } : {}) };
    return (
      <div ref={el => (this.ItselfInputRef = el)}>
        <CustomInput {...{ ...this.props, inputProps }} />
      </div>
    );
  }
}

ItselfInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default ItselfInput;
