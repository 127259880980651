import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';
import { Select2 } from "select2-react-component";
import { Close } from '@material-ui/icons/index';

require('moment-weekday-calc');

class SidesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sides: [],
      thetype: null,
    };
  }

  onChange = (type, value) => {
    this.setState({
      [type]: value
    });
  }

  render() {
    const { sides, thetype } = this.state;
    const { save, productId, date, sidesList, usersides, products, delSides } = this.props;

    var types = [
      '': 'Select Side Type',
      'alternative' : 'Alternative',
      'addons' : 'Additional',
    ];
    var sideshow = [];
    if (typeof(usersides[date]) != 'undefined') {
      Object.keys(usersides[date]).map( (pr_id, idx) => {
        if (pr_id == productId) {
          Object.keys(usersides[date][pr_id]).map( (el, idn) => {
          var side = usersides[date][pr_id][el];
            if (typeof(products[side.side_id]) != 'undefined') {
              var fat = products[side.side_id].fat/1000;
              var carb = products[side.side_id].carb/1000;
              var protein = products[side.side_id].protein/1000;
              var calories = products[side.side_id].calories/1000;
              var name = products[side.side_id].title;
              sideshow.push(
                <Grid container >
                  <Grid item md={4}>
                    {name} #{side.side_id}
                  </Grid>
                  <Grid item md={1}>
                    Fat: {fat}
                  </Grid>
                  <Grid item md={1}>
                    Protein: {protein}
                  </Grid>
                  <Grid item md={1}>
                    Carbs: {carb}
                  </Grid>
                  <Grid item md={1}>
                    Calories: {calories}
                  </Grid>
                  <Grid item md={2}>
                    {side.type}
                  </Grid>
                  <Grid item md={2}>
                    <Button size='small' color='white' onClick={(e) => {delSides(side.id)}}><Close color='error' /></Button>
                  </Grid>
                </Grid>
              );
            }
          })
        }
      });
    }

    return (
      <div>
        <div className='edit-modal-inner edit-sides-modal' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Edit Sides</Text>
            </Grid>
            <Grid item md={12}> 
                  {sidesList && <Select2 
                    placeholder='Side'
                    data={sidesList} 
                    multiple={true}
                    value={sides || ''}
                    update={ 
                      (value) => this.onChange('sides', value)
                    }>
                  </Select2>}

                  <SelectInput 
                labelText='Sides type'
                items={types}
                value={thetype || ''}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => this.onChange('thetype', e.target.value)
                }} 
                />
            </Grid> 
          </Grid>
          <div className='modal-button-handler right'>
            <Button color='darkBlue' onClick={() => save(productId, sides, date, thetype)}>Save</Button>
          </div>
        </div>
        <h3 className="list-subhead">Customer's optional sides</h3>
        <span>
          Alternative - in use instead of default sides <br/>
          Addons - additional sides (default or alternative + addons [purchased sides are under this list]) <br/>
        </span><br/><br/>
        <div className="sides-text">
          <div className="underline">
          <Grid container >
            {sideshow}
          </Grid>
          </div>
        </div>
      </div>
    );
  }
}

SidesModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default SidesModal;