import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { browserHistory, Link } from 'react-router';
import { AddAlert } from '@material-ui/icons/index';
import { connect } from 'react-redux';
import { getArrays } from 'utils';
import moment from 'moment';
import RegularCard from 'components/Cards/RegularCard';
import ItemGrid from 'components/Grid/ItemGrid';
import Snackbar from 'components/Snackbar/Snackbar';

class CheckoutDetails extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      id,
      checkoutMealPlan: {},
      checkoutPlanParams: {},
      note: false,
      message: '',
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getCheckoutDetails(id);
  }

  showNotification(message) {
    if (this.CheckoutDetailsRef) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  listener = ({ data, type }) => {
    if (this.CheckoutDetailsRef) {
      switch (type) {
        case 'getOk':
          this.setState(data);
          break;
        case 'getErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('mobile_checkout', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('mobile_checkout', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['typeList', 'productsFull'].filter(el => !(el in dataArray));
    console.log(types);
    types.length && getArrays(socket, types);
  }

  getCheckoutDetails = checkoutId => this.props.socket.emit('mobile_checkout', { type: 'get', data: { checkoutId } });

  planRenderer = checkoutMealPlan => {
    const { dataArray } = this.props;
    const { typeList, productsFull } = dataArray;
    const renderTypes = ({ cooked, packed, delivered, total, ...types }) => {
      return typeList && productsFull ? Object.keys(types || {}).map((type, idx) => {
        const dish = types[type];
        return (
          <div key={idx}>
            <span>{typeList[type].title} - </span>
            <Link to={`/admin/menu/edit/${dish}`} onlyActiveOnIndex><span>{(productsFull[dish] || { title: 'Not found' }).title}</span></Link>
          </div>
        );
      }) : [];
    };
    return Object.keys(checkoutMealPlan).map((stamp, idx) => {
      const curPlan = checkoutMealPlan[stamp];
      const date = moment.unix(stamp);
      console.log(stamp);
      console.log(date);
      return (
        <ItemGrid xs={12} sm={12} md={6} key={idx}>
          <RegularCard
            cardTitle={date.format('DD MMM YYYY')}
            cardSubtitle=''
            content={<div>{renderTypes(curPlan)}</div>}
          />
        </ItemGrid>
      );
    });
  };

  render() {
    const { checkoutMealPlan, message, note, closeNote } = this.state;
    return (
      <div ref={el => (this.CheckoutDetailsRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={'Checkout Details'}
              cardSubtitle='View details'
              content={
                <div>
                  <Grid container>
                    {this.planRenderer(checkoutMealPlan)}
                  </Grid>
                </div>
              }
            />
          </ItemGrid>
        </Grid>
        <Snackbar
          place='tc'
          color='info'
          icon={AddAlert}
          message={message}
          open={note}
          closeNotification={closeNote}
          close
        />
      </div>
    );
  }
}

CheckoutDetails.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired, 
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(CheckoutDetails));
