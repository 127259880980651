import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Tabs,
  Tab
} from 'material-ui';

import tasksCardStyle from 'assets/jss/material-dashboard-react/tasksCardStyle';

class CustomTabs extends Component {
  state = {
    value: false
  };

  handleChange = (event, value) => {
    this.props.onChangeTab(value);
    this.setState({ value });
  };

  render() {
    const { customClasses, classes, data, headerColor, defaultTab } = this.props;
    const { value } = this.state;
    const active = value === false ? (defaultTab === undefined ? Object.keys(data)[0] : defaultTab) : value;
    const tabs = Object.keys(data).map(key => {
      const tabInfo = data[key] || {};
      return (
        <Tab
          classes={{
            wrapper: classes.tabWrapper,
            labelIcon: classes.labelIcon,
            label: classes.label,
            textColorInheritSelected: classes.textColorInheritSelected,
          }}
          icon={tabInfo.icon}
          label={tabInfo['label']}
          key={key}
          value={key}
        />
      );
    });
    const headerColorClass = headerColor ? classes[`${headerColor}Background`] : '';
    return (
      <Card className={classes.card}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            title: classes.cardTitle,
            content: classes.cardHeaderContent
          }}
          action={
            <Tabs
              classes={{
                flexContainer: classes.tabsContainer + ' ' + customClasses,
                indicator: classes.displayNone
              }}
              value={value || defaultTab}
              onChange={this.handleChange}
              textColor='inherit'
            >
              {tabs}
            </Tabs>
          }
          className={headerColorClass}
        />
        <CardContent>
          <Typography component='div'>
            {data[active].renderTabContent()}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.any,
  headerColor: PropTypes.oneOf(['orange', 'green', 'darkGreen', 'red', 'blue', 'purple', 'darkBlue']),
  data: PropTypes.object.isRequired,
  onChangeTab: PropTypes.func,
  defaultTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

CustomTabs.defaultProps = {
  onChangeTab: () => {}
};

export default withStyles(tasksCardStyle)(CustomTabs);
