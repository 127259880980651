export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

const initialState = {
  place: 'tc',
  color: 'info',
  icon: undefined,
  message: '',
  open: false
};

export function setNotify(obj) {
  return {
    type    : SET_NOTIFICATION,
    payload : obj
  };
}

export function clearNotify() {
  return {
    type    : CLEAR_NOTIFICATION
  };
}

export default function notifyReducer(state = initialState, action) {
  if (action.type === SET_NOTIFICATION) {
    return { ...initialState, ...action.payload, open: true };
  }
  if (action.type === CLEAR_NOTIFICATION) {
    return { ...state, open: false, message: '' };
  }
  return state;
}
