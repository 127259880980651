import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/SelectInput';
import moment from 'moment';
import Calendar from 'components/Calendar';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day').unix();
    //const fromval = moment().subtract(30, 'days').calendar();
    this.state = {
      periodFrom: null,
      periodTo: today,
      status:'',
      errors : {},
    }
  }

  doFilter() {
    // this.props.socket.emit('order', { type: 'getNotifications', data: { dateFrom: this.state.periodFrom, dateTo: this.state.periodTo } });
    this.props.doFilter({ dateFrom: this.state.periodFrom, dateTo: this.state.periodTo,status:this.state.status });
  }
 
  render() {
    const {periodFrom, periodTo, errors} = this.state;
    const { thetype } = this.props;
    const onChangeStaus = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      console.log(value);
      this.setState({ status:value });
    };
    return (
      <div className='fix-on-filters'>

          {!thetype&&<Fragment> <Calendar
            date={periodFrom}
            name={'periodFrom'}
            title={'From date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodFrom: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />

          <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'To date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
         
          <Button color='danger' size='large' onClick={() => {
              this.setState({
                periodFrom: null,
                periodTo: null,
              });
          }}>Clear</Button>
          <Button color='darkBlue' size='large' onClick={ () => {this.doFilter()}}>Apply</Button>
        </Fragment>}
        {thetype=='birthday' && <Fragment>
         <Calendar
            date={periodFrom}
            name={'periodFrom'}
            title={'Month'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodFrom: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
            view={'year'}
            maxDetail={'year'}
          />
          <SelectInput
            labelText='Status'
            items={{'active':'Active','inactive':'In Active'}}
            value={this.state.status}
            error={'status' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'status',
              onChange:onChangeStaus
            }}
          />
          <Button color='danger' size='large' onClick={() => {
              this.setState({
                periodFrom: null,
                status: '',
              });
          }}>Clear</Button>
          <Button color='darkBlue' size='large' onClick={ () => {this.doFilter()}}>Apply</Button>
          </Fragment>}
      </div>
    );
  }
}

export default Filters;