import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import DateTime from 'components/DateTime';

const containerStyle = {
  position: 'absolute',
  left: 'calc(50% - 150px)',
  top: '300px',
  width: '300px'
};

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return <Fragment>
      <div style={containerStyle}>
        <DateTime
          onChange={() => {}}
          changeOnClickOverMonth={false}
        />
      </div>
    </Fragment>;
  }
}

Test.propTypes = {
};

Test.defaultProps = {
};

const mapStateToProps = state => ({
});

export default socketConnect(connect(mapStateToProps, null)(Test));
