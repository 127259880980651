function Exception(message) {
  return message;
}

function intersect() {
  if (arguments.length > 1) {
    const targetArr = arguments[0];
    if (!Array.isArray(targetArr)) {
      throw Exception('Invalid argument');
    }
    let other = [];
    for (let i = 1; i < arguments.length; i++) {
      if (Array.isArray(arguments[i])) {
        other = [...other, ...arguments[i]];
      } else {
        throw Exception('Invalid argument');
      }
    }
    return targetArr.filter(el => other.includes(el));
  } else {
    throw Exception('Invalid number of arguments');
  }
}

function unsetErrors(errors, names) {
  if (names.length === 1) {
    delete errors[names[0]];
  } else if (names.length > 1) {
    errors = { ...errors, [names[0]]: unsetErrors(errors[names[0]], names.splice(1)) };
  }
  return errors;
}

function getArrays(socket, type) {
  if (socket.emit && type.length) {
    socket.emit('get_arrays', {
      type: 'get',
      data: { type }
    });
  }
}

function iterator(start = 0) {
  let i = start;
  return function () {
    return i++;
  };
}

function getFormData(refs) {
  return Object.keys(refs).reduce((acc, cur) => {
    const ref = refs[cur];
    if (ref && ref.state) {
      const { value } = ref.state;
      return { ...acc, [cur]: value };
    } else {
      return acc;
    }
  }, {});
}

function clone(instance) {
  const cloned = Array.isArray(instance) ? [...instance] : (typeof instance === 'object' ? { ...instance } : instance);
  for (const key in instance) {
    if (typeof instance[key] === 'object') {
      instance[key] = clone(instance[key]);
    }
  }
  return cloned;
}

function parseDates(datesString = '', delimiter = ';') {
  const dates = datesString.split(delimiter);
  const currentYear = (new Date()).getUTCFullYear();
  const full = /^\d{4}-(02-(0[1-9]|[12]\d)|((01|03|05|07|08|10|12)-(0[1-9]|[12]\d|3[01]))|(04|06|09|11)-(0[1-9]|[12\d]|30))$/i;
  const short = /^(02-(0[1-9]|[12]\d)|((01|03|05|07|08|10|12)-(0[1-9]|[12]\d|3[01]))|(04|06|09|11)-(0[1-9]|[12\d]|30))$/i;
  return dates.reduce((acc, date) => {
    if (date.match(short)) {
      return [...acc, `${currentYear}-${date}`];
    } else if (date.match(full)) {
      return [...acc, date];
    } else {
      return acc;
    }
  }, []);
}

function utcUnix(date, micro = false) {
  return +new Date(date) / (micro ? 1 : 1000);
}
function getExcludeWeek (exclude_week_day){
  const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return (exclude_week_day!=undefined && exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
}
function parseHtmlEntities(str) {
  return str.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
      var num = parseInt(numStr, 10); // read num as normal number
      return String.fromCharCode(num);
  });
}
export { intersect, unsetErrors, getArrays, iterator, getFormData, clone, parseDates, utcUnix, getExcludeWeek, parseHtmlEntities };
