import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip/es/Tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

class ToolTip extends Component {
  render() {
    const { tooltipText, children, tooltipDirection, trigger, hide, onVisibleChange } = this.props;
    const props = hide ? { visible: false } : {};
    return (
      <Tooltip
        placement={tooltipDirection}
        onVisibleChange={onVisibleChange}
        trigger={trigger}
        destroyTooltipOnHide
        mouseLeaveDelay={0}
        overlay={tooltipText}
        arrowContent={<div className='rc-tooltip-arrow-inner' />}
        overlayStyle={{ zIndex: 9999 }}
        {...props}
      >
        {children}
      </Tooltip>
    );
  }
}

ToolTip.propTypes = {
  tooltipText: PropTypes.node.isRequired,
  children: PropTypes.node,
  tooltipDirection: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  trigger: PropTypes.array, // 'click', 'hover', 'focus',
  hide: PropTypes.bool,
  onVisibleChange: PropTypes.func
};

ToolTip.defaultProps = {
  tooltipDirection: 'right',
  children: null,
  hide: false,
  onVisibleChange: () => {},
  trigger: ['hover', 'focus']
};

export default ToolTip;
