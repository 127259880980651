import React from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import ChartistGraph from 'react-chartist';
import moment from 'moment';
import {
  AssignmentTurnedIn,
  Store,
  InfoOutline,
  DateRange,
  LocalOffer,
  Update,
  AccessTime,
  Info,
  People,
  Person,
} from '@material-ui/icons';
import { withStyles, Grid } from 'material-ui';
import StatsCard from 'components/Cards/StatsCard';
import ChartCard from 'components/Cards/ChartCard';
import RegularCard from 'components/Cards/RegularCard';
import Table from 'components/Table/Table';
import TableModal from 'components/Table/TableModal';
import ItemGrid from 'components/Grid/ItemGrid';
import { emailsSubscriptionChart } from 'variables/charts';
import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: {},
      dailySales: [],
      feedbacks: [],
      updated: moment()
    };
    props.socket.emit('statistic', { type: 'get', data: { fbCount: 5 } });
  }

  componentWillMount() {
    this.interval = setInterval(() => this.forceUpdate(() => {}), 1000 * 60);
    this.props.socket.on('statistic', this.listener);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.props.socket.removeListener('statistic', this.listener);
  }

  listener = ({ type, status, data }) => {
    if (this.DashboardRef) {
      if (status === 'ok') {
        if (type === 'get') {
          this.setState({ ...data, updated: moment() });
        }
      } else if (status === 'err') {
        console.error(type, data);
      }
    }
  };

  performDailySales = sales => {
    let max = 0;
    const endDate = moment().utc().startOf('day');
    const date = endDate.clone().subtract(7, 'd');
    const labels = [];
    const dates = [];
    while (date.isBefore(endDate)) {
      const stamp = date.unix();
      labels.push({
        toString() {
          const date = moment.unix(stamp);
          return `<span title="${date.format('DD MMM, ')}">${date.format('ddd')}</span>`;
        }
      });
      const sum = ~~((sales.find(({ date }) => +date === +stamp) || { sum: 0 }).sum / 100);
      dates.push(sum);
      if (sum > max) {
        max = sum;
      }
      date.add(1, 'd');
    }
    return {
      data: {
        labels,
        series: [dates]
      },
      options: {
        axisX: {
          showGrid: false
        },
        low: 0,
        high: max + 100,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0
        }
      },
      animation: {
        draw: function (data) {
          if (data.type === 'bar') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * 80,
                dur: 500,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
          }
        }
      }
    };
  };

  render() {
    const { statistics, feedbacks, dailySales, updated } = this.state;
    const { deliveries, revenue, issues, customers, managers, nutritionists, managers_customers, nutritionists_customers, nutrilist, managelist, emirates, manager_details, nutritionist_details, lifetimeemirates ,activecustomers  } = statistics;
    const { total, delivered } = deliveries || {};
    const fbTableData = feedbacks.map(({ user, rating, feedback }, idx) => [`${idx + 1}`, user, rating, feedback]);
    
    var nutriTable = [];
    var managerTable = [];
    var emirateTableData = [];
    var emi_header = [];
    var lifeEmirateTableData = [];
    var lifeEmiHeader = [];
    var manager_ids = [];
    var nutritionist_ids = [];

    if (typeof(manager_details) != 'undefined') {
      Object.keys(manager_details).map(function(key, index) {
        manager_ids.push(key);
        managerTable.push(Object.values(manager_details[key]))
      });
    }

    if (typeof(nutritionist_details) != 'undefined') {
      Object.keys(nutritionist_details).map(function(key, index) {
        nutritionist_ids.push(key);
        nutriTable.push(Object.values(nutritionist_details[key]))
      });
    }
    if (typeof(lifetimeemirates) != 'undefined') {
      lifeEmiHeader = Object.keys(lifetimeemirates);
      lifeEmirateTableData.push(Object.values(lifetimeemirates));
    }

    if (typeof(emirates) != 'undefined') {
      emi_header = Object.keys(emirates);
      emirateTableData.push(Object.values(emirates));
    }

    const dailySalesData = this.performDailySales(dailySales);
    return (
      <div ref={el => (this.DashboardRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={AssignmentTurnedIn}
              iconColor='orange'
              title='Today deliveries'
              description={`${delivered || 0}/${total || 0}`}
              statIcon={Info}
              statIconColor='info'
              cardIconColor='#FB8423'
              statLink={{ text: 'More info...', href: '/logistic' }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={Store}
              iconColor='green'
              title='Revenue'
              description={`AED ${((revenue || 0) / 100).toFixed(0)}`}
              statIcon={DateRange}
              statText='Today'
              cardIconColor='#19C065'
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={People}
              iconColor='blue'
              title='Total Customers'
              description={customers || 0}
              statIcon={LocalOffer}
              // statLink={{ text: 'Go to Issues', href: '/admin/issues' }}
              statText='Just Updated'
              cardIconColor='#1459C7'
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={People}
              iconColor='green'
              title='Active customers'
              description={activecustomers || 0}
              statIcon={Update}
              statText='Just Updated'
              cardIconColor='#19C065'
            />
          </ItemGrid>
        </Grid>

        <Grid container>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={Person}
              iconColor='red'
              title='Active Managers'
              description={managers || 0}
              statIcon={Info}
              cardIconColor='#ED3C59'
              
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={People}
              iconColor='red'
              title='Managed Customers'
              cardIconColor='#ED3C59'
              description={managers_customers || 0}
              statIcon={Info}
              
            />
          </ItemGrid>
           <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={Person}
              iconColor='orange'
              title='Active Nutritionists'
              description={nutritionists || 0}
              statIcon={Info}
              cardIconColor='#FB8423'
              
            />
          </ItemGrid>
           <ItemGrid xs={12} sm={6} md={3}>
            <StatsCard
              icon={People}
              iconColor='orange'
              title='Customers with Nutritionist'
              cardIconColor='#FB8423'
              description={nutritionists_customers || 0}
              statIcon={Info}
             
            />
          </ItemGrid>
        </Grid>
        <Grid container>
         <ItemGrid xs={12} sm={3} md={3}>
            <RegularCard
              headerColor='orange'
              cardTitle='Nutritionist Customer List'
              cardSubtitle=''
              content={
                <TableModal
                  tableHeaderColor='warning'
                  cardIconColor='#FB8423'
                  list={nutrilist}
                  ids={nutritionist_ids}
                  tableHead={['Nutritionist', 'Customers', 'Amount (AED)']}
                  tableData={nutriTable}
                  header={"Customers of selected Nutritionist"}
                />
              }
            />
          </ItemGrid>

           <ItemGrid xs={12} sm={3} md={3}>
            <RegularCard
              headerColor='red'
              cardIconColor='#ED3C59'
              cardTitle='Manager Customer List'
              cardSubtitle=''
              content={
                <TableModal
                  tableHeaderColor='warning'
                  list={managelist}
                  ids={manager_ids}
                  tableHead={['Manager', 'Customers', 'Amount (AED)']}
                  tableData={managerTable}
                  header={"Customers of selected Manager"}
                />
              }
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={6} md={6}>
            <RegularCard
              headerColor='green'
              cardIconColor='#19C065'
              cardTitle='Customers By Emirates'
              cardSubtitle=''
              IshaveSub={true}
              subcardTitle='Lifetime Customers by Emirates'
              subcontent={
                <Table
                  tableHeaderColor='warning'
                  tableHead={lifeEmiHeader}
                  tableData={lifeEmirateTableData}
                />
              }
              content={
                <Table
                  tableHeaderColor='warning'
                  tableHead={emi_header}
                  tableData={emirateTableData}
                />
              }
            />
          </ItemGrid>
        </Grid>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={6}>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
                <ChartCard
                  chart={
                    <ChartistGraph
                      className='ct-chart'
                      data={dailySalesData.data}
                      type='Bar'
                      options={dailySalesData.options}
                      listener={dailySalesData.animation}
                    />
                  }
                  chartColor='green'
                  title='Daily Sales'
                  text={<span>Last week sales rate</span>}
                  statIcon={AccessTime}
                  statText={`updated ${updated.fromNow()}`}
                />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
                <ChartCard
                  chart={
                    <ChartistGraph
                      className='ct-chart'
                      data={emailsSubscriptionChart.data}
                      type='Bar'
                      options={emailsSubscriptionChart.options}
                      responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                      listener={emailsSubscriptionChart.animation}
                    />
                  }
                  chartColor='orange'
                  cardIconColor='#FB8423'
                  title='Email Subscriptions'
                  text='Last Campaign Performance'
                  statIcon={AccessTime}
                  statText='campaign sent 2 days ago'
                />
              </ItemGrid>
            </Grid>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={6}>
            <RegularCard
              headerColor='blue'
              cardTitle='Last Feedbacks'
              cardSubtitle={`New feedbacks on ${moment().format('Do MMM, YYYY')}`}
              content={
                <Table
                  tableHeaderColor='warning'
                  tableHead={['ID', 'Customer', 'Rating', 'Feedback']}
                  tableData={fbTableData}
                />
              }
            />
          </ItemGrid>
        </Grid>
      </div>
    );
  }
}

Dashboard.propTypes = {
  socket: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default socketConnect(withStyles(dashboardStyle)(Dashboard));
