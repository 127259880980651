import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'components/SelectInput';
import Calendar from 'components/Calendar';
import Button from 'components/CustomButtons/Button';
import moment from 'moment/moment';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(1, 'd').unix(),
      slots: []
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today
    };
  }

  render() {
    const { filters, today } = this.state;
    const { slotsList, errors, applyFilters, usedSlots } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value } });
    };
    const { periodFrom, periodTo, slots } = filters;
    const deliveriesList = { '': 'All', ...(slotsList || []).reduce((acc, { id, name }) => (usedSlots.includes(+id) ? { ...acc, [+id]: name } : acc), {}) };
    return (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
          <Calendar
            date={periodFrom || today}
            name={'periodFrom'}
            title={'From date'}
            minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              onChange(undefined, date.isSameOrAfter(today) ? date.unix() : today.unix(), name);
              if (date.isAfter(moment.unix(periodTo))) {
                setTimeout(() => onChange({}, date.unix(), 'periodTo'), 10);
              }
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
          <Calendar
            date={periodTo || today}
            name={'periodTo'}
            title={'To date'}
            minDate={moment.unix(periodTo || today).utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              const from = filters.periodFrom ? moment.unix(filters.periodFrom) : today;
              onChange(undefined, date.isSameOrAfter(today) && date.isAfter(from) ? date.unix() : today.unix(), name);
            }}
            controlled
            error={'periodTo' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
          <SelectInput
            labelText='Filter by slot'
            items={deliveriesList || {}}
            value={slots || []}
            error={'slots' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'slots',
              onChange
            }}
          />
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters(this.defFilters);
              this.setState({ filters: this.defFilters });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  slotsList: PropTypes.array.isRequired,
  usedSlots: PropTypes.array.isRequired,
  applyFilters: PropTypes.func.isRequired,
  errors: PropTypes.object
};

Filters.defaultProps = {
  offset: 0,
  errors: {}
};

export default Filters;
