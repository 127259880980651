import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import Calendar from 'components/Calendar';
import Button from 'components/CustomButtons/Button'; 
import moment from 'moment';

class PaymentAnalysis extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            paymentAnalysis: [],
            periodTo:null,
            periodFrom:null,
            errors:[]
        }
        this.getPaymentAnalysis();
    }
    componentWillMount() { 
        this.props.socket.on('finances', this.listener);
    }
    
    componentWillUnmount() {
     this.props.socket.removeListener('finances', this.listener);
    }
    
    listener = ({ type, data }) => {
    
        switch (type) {
            case 'paymentAnalysisOk':
            this.setState({
                paymentAnalysis: data,
            })
            break;
            default:
            console.log(data.message);
            break;
        }
    
    }
    getPaymentAnalysis = () => this.props.socket.emit('finances', { type: 'paymentAnalysis', data: {} });
    exportDaycsv() {
        const { periodTo } = this.state;
        window.open(
          'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/payment-analysis?date='+periodTo,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
      render() {
        const { periodTo, periodFrom,errors ,paymentAnalysis } = this.state;
        var rows = [];
        paymentAnalysis&&Object.keys(paymentAnalysis).map( (year, idx) => {
    
           Object.keys(paymentAnalysis[year]).reverse().map( (month, idm) => {
            let mtotal=0,mbagtotal=0;
            rows.push(<tr className="days-left-line">
            <td colspan="5">{moment().month(parseInt(month)-1).format("MMM")}, {year}</td>
          </tr>);
            Object.keys(paymentAnalysis[year][month]).map( (day, idm) => {

            Object.keys(paymentAnalysis[year][month][day]).map( (userId, idm) => {
               const { price,bag_price,paymentMethod, username } = paymentAnalysis[year][month][day][userId];
               mtotal+=parseInt(price);
               mbagtotal+=parseFloat(bag_price);
            rows.push(<tr>
            <td >{moment().month(parseInt(month)-1).format("MMM")} {day} {year}</td>
            <td >{username}</td>
            <td >{(price/100).toFixed(2)} AED</td>
            <td >{bag_price} AED</td>
            <td >{paymentMethod}</td>

          </tr>);
           });

          });
          rows.push(<tr className="row-border-top">
                <td colspan="2" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
                <td><b>Total Price</b></td>
                <td className="green-text"> <b>Total Bag Price</b></td>
                <td></td>
        </tr>)
        rows.push(<tr className="row-border-top">
        <td colspan="2" align="left"></td>
        <td>{(mtotal/100).toFixed(2)} AED</td>
        <td className="green-text"> {mbagtotal} AED</td>
        <td></td>
</tr>)
          });

        });

        return <div>   
            <div className=' finances'>
      
        <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'Select month to export'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            view={'year'}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
         
          <Button color='success' size='large' onClick={ () => {this.exportDaycsv()}}>Export CSV</Button>
        </div>
            <CustomCard marginOnePx>
        <div>
          <table className="Tabled fintable">
            <thead className="roundedHeader financetbl stickyheader">
              <tr id="header-row">
                <th scope="col">Paid Date</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Plan Amount</th>
                <th scope="col">Bag Deposit</th>
                <th scope="col">Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </CustomCard></div>;
      }
}
PaymentAnalysis.propTypes = {
    socket: PropTypes.object,
    setExport: PropTypes.func
  };
export default socketConnect(PaymentAnalysis);
  