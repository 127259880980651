import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Filters from './filters';
import Button from 'components/CustomButtons/Button';
import { ReasonModal, AddModal, ReasonPayModal } from '../Customers/Details/Modals';
import { LeadModal, CartModal } from './Modals';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import { getArrays, parseDates } from 'utils';
import { browserHistory } from 'react-router';
// import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory, History } from 'history';
import moment from 'moment/moment';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [], 
      renewals: false,
      thetype: false,
      mealPlan: false,
      pendings: false,
      pauses: false,
      errors: {},
      bags: false,
      leads: [],
      birthdays: {},
      cart: {},
      midplanfeedback: {},
    };
    this.getNotifications();
    //this.getRenewals();
    //this.getPendings();
    //this.getPauseNotify();
    //this.getBags();
    //this.getLeads();
  }

  orderListener = ({ type, data }) => {
      switch (type) {
        case 'getNotificationsOk':
          this.setState({
            'notifications' : data.notifications,
          });
        break;
        case 'getLeadsOk':
          this.setState({
            'leads' : data,
          });
        break;
        case 'getBirthdayListOk':
          this.setState({
            birthdays: data.res,
          });
          //console.log(data);
        break;
        case 'sentBirthdayOk':
          this.getBirthdayList();
        break;
        case 'setFollowLeadOk':
        case 'setConvertLeadOk':
        case 'setLeadOk':
          this.getLeads();
        break;
        case 'acceptPauseManagerOk':
        case 'acceptPauseOk':
        case 'acceptHoldManagerOk':
        case 'acceptHoldOk':
          this.getPauseNotify();
        break;
        case 'getOk':
          this.setState(data);
          const {mealPlan, userData} = this.state;
          if (mealPlan && userData) {
            this.showModal('renew');
          }
        break;
        case 'deletePauseRowOk':
        case 'afterPauseSetOk':
        case 'hidePauseNotificationOk':
          this.getPauseNotify();
        break;
        case 'setOk':
          this.setState({
            mealPlan: null,
            userData: null,
          });
          this.getRenewals();
          this.props.clearModal();
          this.props.stop();
        break;
        case 'setRenewOk':
          this.getRenewals();
        break;
        case 'getPendingsOk':
          this.setState({
            'pendings':data,
          });
        break;
        case 'getCartListOk':
          this.setState({
            'cart':data,
          });
        break;
        case 'removeFromCartOk':
        case 'cartPurchasedOk':
          this.getCart();
          this.props.clearModal();
        break;
        case 'getBagsOk':
          this.setState({
            'bags':data,
          });
        break;
        case 'getRenewalsOk':
          this.setState({
            'renewals' : data,
          });
        break;
        case 'getPauseNotifyOk':
          this.setState({
            'pauses' : data,
          });
        break;
        case 'bagCollectOk':
        case 'bagRefundOk':
          this.getBags();
        break;
        case 'setPayStatusOk':
          this.getPendings();
        break;
        case 'acceptNotificationsOk':
        case 'managedNotificationsOk':
          this.getNotifications();
          this.getRenewals();
          this.getPendings();
          this.getPauseNotify();
        break;
        case 'midplanfeedbackOk':
          this.setState({
            'midplanfeedback':data,
          });
        break;
        case 'setOrderNotificationOk':
          const { midplanfeedback } = this.state;
          if(data.orderId!=undefined){
            const { notification,datas,orderCounts } = midplanfeedback;
            let notify = {...notification,...data.notification};
            this.setState({
              midplanfeedback:{datas:datas,orderCounts,notification:notify},
            });
          }
         
        break;
      }
  };

  componentWillMount() {
    this.props.socket.on('order', this.orderListener);
    this.props.socket.on('profile', this.orderListener);

    const { dataArray, socket } = this.props;
    const types = ['planList', 'typeList', 'dietList', 'slotsList', 'productsFull', 'products', 'priceList', 'commonVouchers', 'couponTypes', 'paymentMethods'].filter(el => !(el in dataArray));
    getArrays(socket, types);
    //clearDefaultDishes();
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.orderListener);
    this.props.socket.removeListener('profile', this.orderListener);
  }

  getBirthdayList = (data) => {
    this.props.socket.emit('order', { type: 'getBirthdayList', data: data });
  };

  sentWish(user_id) {
    this.props.socket.emit('order', { type: 'sentBirthday', data: { user_id: user_id } });
  }

  getNotifications = () => {
    this.props.socket.emit('order', { type: 'getNotifications', data: { dateFrom: null, dateTo: null } });
  };

  getRenewals = () => {
    this.props.socket.emit('order', { type: 'getRenewals', data: { dateFrom: null, dateTo: null } });
  };

  getPendings = () => {
    this.props.socket.emit('order', { type: 'getPendings', data: { dateFrom: null, dateTo: null } });
  };

  getCart = () => {
    this.props.socket.emit('order', { type: 'getCartList', data: { dateFrom: null, dateTo: null } });
  };

  getBags = () => {
    this.props.socket.emit('order', { type: 'getBags', data: { dateFrom: null, dateTo: null } });
  };

  bagCollect = (status, order_id, sheduled) => {
    this.props.socket.emit('order', { type: 'bagCollect', data: { status: status, orderId: order_id, sheduled: sheduled } });
  };

  bagRefund = (status, order_id) => {
    this.props.socket.emit('order', { type: 'bagRefund', data: { status: status, orderId: order_id } });
  };

  getPauseNotify = () => {
    this.props.socket.emit('order', { type: 'getPauseNotify', data: { dateFrom: null, dateTo: null } });
  }

  acceptNotify = (id) => {
    this.props.socket.emit('order', { type: 'acceptNotifications', data: { notifyId: id} });
  }

  acceptManager = (id, type=false, is_pendings=false) => {
    if (type) {
      this.props.socket.emit('order', { type: 'managedNotifications', data: { notifyId: id, thetype: type, pendings: is_pendings} });
    } else {
      this.props.socket.emit('order', { type: 'managedNotifications', data: { notifyId: id} });
    }
  }

  acceptPauseManager = (id) => {
    this.props.socket.emit('order', { type: 'acceptPauseManager', data: {pauseId: id} });
  }

  acceptPause = (id) => {
    this.props.socket.emit('order', { type: 'acceptPause', data: {pauseId: id} });
  }

  acceptHoldManager = (id) => {
    this.props.socket.emit('order', { type: 'acceptHoldManager', data: {orderId: id} });
  }

  acceptHold = (id) => {
    this.props.socket.emit('order', { type: 'acceptHold', data: {orderId: id} });
  }
  getMidPlanFeedback(){
    this.props.socket.emit('order', { type: 'midplanfeedback', data: {} });
  }
  setOrderNotification = (orderId,section,action) =>{
    this.props.socket.emit('order', { type: 'setOrderNotification', data: {orderId,section,action} });
  }
  setRenew(order_id, stat, reason = null, comment = null) {
    const {socket} = this.props;
    const data = {orderId: order_id, stat: stat, 'reason': reason, 'comment': comment};
    socket.emit('order', { type: 'setRenew', data });
    this.props.clearModal();
  }

  setLead(name, email, phone, type, quality, notes, signed) {
    const {socket} = this.props;
    const data = {name: name, email: email, phone: phone, type: type, quality: quality, notes: notes, signed: signed};
    socket.emit('order', { type: 'setLead', data });
    this.props.clearModal();
  }

  editLead(name, email, phone, type, quality, notes, signed, leadId) {
    const {socket} = this.props;
    const data = {name: name, email: email, phone: phone, type: type, quality: quality, notes: notes, signed: signed, leadId: leadId};
    console.log(data);
    socket.emit('order', { type: 'setLead', data });
    this.props.clearModal();
  }

  followUp(type, lead_id) {
    const {socket} = this.props;
    const data = {typeId: type, leadId: lead_id};
    socket.emit('order', { type: 'setFollowLead', data });
  }
  hidePauseRow(pause_id,order_id) {
    const {socket} = this.props;
    const data ={pauseId:pause_id,orderId:order_id};
    socket.emit('order', { type: 'hidePauseNotification', data });
  }
  deletePauseRow(order_id,start_date) {
    const {socket} = this.props;
    var data = {orderId: order_id,startDate:(start_date<=moment().unix())?start_date:moment().unix()};
    socket.emit('order', { type: 'afterPauseSet', data });
    // socket.emit('order', { type: 'deletePauseRow', data });
  }

  converted(type, lead_id) {
    const {socket} = this.props;
    const data = {typeId: type, leadId: lead_id};
    socket.emit('order', { type: 'setConvertLead', data });
  }

  getPlan(orderId, is_future=0) {
    console.log('get plan query');
    this.props.socket.emit('order', { type: 'get', data: { orderId: orderId, isFuture: is_future } });
  }

  getLeads() {
    this.props.socket.emit('order', { type: 'getLeads', data: {} });
  }

  getUserData = (userId) => {
    console.log('get user query');
    userId && this.props.socket.emit('profile', {
      type: 'get',
      data: {
        userId
      }
    });
  };

  cleanUpStates(user_id, order_id) {
    this.setState({
      mealPlan: null,
      userData: null,
    });
    this.getPlan(order_id);
    this.getUserData(user_id);                    
  }

    savePlan = (dataObj, _type, build = 'newPlan', bagPrice=false) => {
    _type !== 'pause' && this.props.spin();
    const {userData} = this.state;
    const { mealPlan: detailsData, planParams, skipped } = dataObj;
    const { id: orderId, deliveryTime, cutlery } = planParams;
    const clearData = data => {
      return Object.keys(data).reduce((acc, date) => {
        const { cooked, packed, delivered, total, ...rest } = data[date];
        return { ...acc, [date]: rest };
      }, {});
    };
    //this.props.stop();
    switch (_type) {
      case 'edit':
        this.props.socket.emit('order', {
          type: 'modify',
          data: {
            orderId,
            deliverySlot: deliveryTime,
            cutlery,
            detailsData: clearData(detailsData),
            skipped
          }
        });
        break;
      case 'add':
        const { userData } = this.state;
        const {planParams} = dataObj;
        const { mealPlan, diet, deliverySlot, price, payed, voucher_field, coupon_id } = planParams;
        const orderData = {
          user_id: userData.user_id,
          [(voucher_field || 'coupon_id')]: +coupon_id || null,
          paymentMethod: planParams.paymentMethod,
          mealPlan_id: +mealPlan,
          price,
          cutlery,
          diet_id: +diet,
          shipping: 0,
          payed: +!!payed
        };
        const details = Object.keys(detailsData).reduce((data, unix) => {
          const date = detailsData[unix];
          const datePlan = Object.keys(date).reduce((acc, cur) => [...acc, {
            date: +unix,
            'mealType_id': +cur,
            'dish_id': +date[cur]
          }], []);
          return [...data, ...datePlan];
        }, []);
        this.props.socket.emit('order', {
          type: 'set',
          data: {
            orderData,
            detailsData: details,
            deliverySlot,
            build,
            bagPrice: bagPrice,
          }
        });
        break;
      /*case 'pause':
        //this.setState({ mealPlan: detailsData, planParams });
        this.setState({ mealPlan: detailsData });
        setTimeout(() => this.showModal('edit'), 200);
        break;*/
    }
  };

  getCustomPlans = (diet_id, mealTypes) => {
    this.props.socket.emit('custom_plan', { type: 'get', data: { diet_id, mealTypes } });
    this.props.socket.emit('default_dishes', { type: 'get', data: { dietId: diet_id, types: mealTypes } });
  };

  setPaidStatus = (order_id, reason = null, user_id) => {
    const {socket} = this.props;
    const data = {orderId: order_id, 'reason': reason, 'userId': user_id};
    socket.emit('order', { type: 'setPayStatus', data });
    this.props.clearModal();
  };

  showModal = (type, extraData, is_future=false) => {
      const { setModal, holidays, remainDeliveries } = this.props;
      var {dataArray} = this.props;
      const { mealPlan, planParams, userData, errors } = this.state;
      const {products, typeList} = dataArray;
      const props = {
        width: '55%',
        height: '56vh',
        display: true
      };
      
      const modalProps = {
            build: 'newPlan',
            save: this.savePlan,
            errors,
            userData,
            dataArray,
      };

      switch (type) {
        case 'addlead':
          props.content = <LeadModal save={(name, email, phone, type, quality, notes, signed) => {
            this.setLead(name, email, phone, type, quality, notes, signed);
            this.setState({showModal: false});
          }} />;
          props.width = '640px';
          props.height = '480px';
        break;
        case 'cartview':
          props.content = <CartModal cart={extraData} products={products} typeList={typeList} socket={this.props.socket}/>;
          props.width = '640px';
          props.height = '480px';
        break;
        case 'editlead':
          props.content = <LeadModal thelead={extraData} save={(name, email, phone, type, quality, notes, signed, leadId) => {
            this.editLead(name, email, phone, type, quality, notes, signed, leadId);
            this.setState({showModal: false});
          }} />;
          props.width = '640px';
          props.height = '480px';
        break;
        case 'renew':
          console.log('modal step');
          modalProps.build = 'renew';
          modalProps.minDate = +Math.max(...Object.keys(mealPlan || {})) + 3600 * 24;
          modalProps.prevPlanParams = planParams || {};
          props.content = <AddModal
            {...modalProps}
            getCustomPlans={this.getCustomPlans}
          />;
          props.width = '75%';
          props.height = '60vh';
        break;
        case 'reason':
          props.content = <ReasonModal orderId={extraData} save={(order_id, reason, comment) => {
            this.setRenew(order_id, 0, reason, comment);
            this.setState({showModal: false});
          }} />;
          props.width = '640px';
          props.height = '480px';
        break;
        case 'paystatus':
          props.content = <ReasonPayModal dataArray={dataArray} orderId={extraData.order_id} save={ (order_id, reason) => {
            this.setPaidStatus(order_id, reason, extraData.user_id);
            this.setState({showModal: false});
          }} />;
          props.width = '640px';
          props.height = '480px';
        break;
      }
      
      props.content && setModal(props);
      
    };
  doFilter = (data)=>{
      const { thetype } = this.state;
      if(thetype){
          if(thetype=='birthday'){
            this.getBirthdayList(data);
          }
      }else{
        this.props.socket.emit('order', { type: 'getNotifications', data: data });
      }
  }  
  render() {    
    const {notifications, thetype, renewals, pendings, pauses, cart} = this.state;
    const {dataArray} = this.props;
    const {planList} = dataArray;
    //console.log(reasons);
    
    var rows = [];
    var req = {
      1: 'Customer Changes',
      2: 'Customer Requests',
      3: 'Customer Feedback',
      4: 'Upcoming Renewals',
      5: 'Pending Payment',
      6: 'Customer Leads',
      7: 'Login logs',
      8: 'Skip Delivery/Pause',
    }

    const reasonList = [
      '',
      'Traveling',
      'Didn’t like the food',
      'No much options',
      'Delivery Timings',
      'Not my macros',
      'Expensive',
      'No Discounts',
      'Others',
      'No response / reply after contacting',
      'No Feedback ',
      'Undecided, will contact later',
    ];
    
    switch (thetype) {
      case 'birthday':
        const {birthdays} = this.state;
        var monthes = {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December'
        }
        Object.keys(birthdays).map( (month, idx) => {
          rows.push(
                      <tr className="days-left-line">
                        <td colSpan="6">{monthes[month]}</td>
                      </tr>
          )
          Object.keys(birthdays[month]).map( (day, idl) => {
            Object.keys(birthdays[month][day]).map( (data, idm) => {
              
                  var customer = birthdays[month][day][data];
                  rows.push( 
                          <tr>
                            <td>{idm+1}</td>
                            <td> 
                            <a className="pointer" target='_blank' href={"/admin/customers/details/"+customer.user_id}> {customer.name} #{customer.user_id}</a>
                            </td>
                            <td>{customer.birth_date}</td>
                            <td>{customer.amount}</td>
                            <td>{customer.price} AED</td>
                            <td>
                              {customer.sent && 'Birthday wish sent'}
                              {!customer.sent && <Button color='success' size='medium' onClick={() => {
                                this.sentWish(customer.user_id);
                              }}>Send Birthday Wish
                              </Button>}
                            </td>
                          </tr>
                  );

            });  
          });
        });
      break;
      case 'pauses':
      Object.keys(pauses).map( (ptype, idn) => {
        if (ptype == 'indefinite') {
            rows.push(
                <tr className="days-left-line">
                  <td colSpan="7">Paused (indefinite time)</td>
                </tr>
            );
        } else if (ptype == 'hold') {
            rows.push(
                <tr className="days-left-line">
                  <td colSpan="7">On Hold, but not paused</td>
                </tr>
            );
        } else {
            rows.push(
                <tr className="days-left-line">
                  <td colSpan="7">Paused (with end date)</td>
                </tr>
            );
        }
        Object.keys(pauses[ptype]).map( (el, idx) => {
          var row = pauses[ptype][el];
                if (row.order_id == 1246) {
                  console.log(row);
                }
                var results = null;
                if (row.completed_by_name) {
                  results = (
                    <div>
                      Reviewed by {row.completed_by_name} at {row.completed_date}
                    </div>
                  )
                } else {
                  results = (
                    <div> 
                      <Button color='success' size='small' onClick={() => {
                        if (ptype == 'hold') {
                          this.acceptHold(row.order_id);
                        } else {
                          this.acceptPause(row.pause_id);
                        }
                      }}>Reviewed by team</Button>
                    </div>
                  ) 
                }
 
                var resultsManager = null;
                var deletdisabled =true;
                if (row.confirmed_by_name) {
                  resultsManager = (
                    <div>
                      Confirmed by {row.confirmed_by_name} at {row.confirmed_date}
                    </div>
                  )
                  deletdisabled=false;
                } else {
                  resultsManager = (
                    <div> 
                      <Button color='danger' size='small' onClick={() => {
                        if (ptype == 'hold') {
                          this.acceptHoldManager(row.order_id);
                        } else {
                          this.acceptPauseManager(row.pause_id);
                        }
                      }}>Confirmed by Manager</Button>
                    </div>
                  )
                }

          rows.push(
            <tr>
              <td>{idx + 1}</td>
              <td><a className="pointer" target='_blank' href={"/admin/customers/details/"+row.user_id}> {row.name} #{row.user_id}</a></td>
              <td>Mealplan: {planList[row.mealplan_id].title || ''} <br/> order #{row.order_id}</td>
              {ptype != 'hold' && <td>{row.start_date} - {row.end_date}</td>}
              {ptype == 'hold' && <td>on hold</td>}
              <td>{results}</td>
              <td>{resultsManager}</td>
              <td>
                  <Button color='primary' disabled={deletdisabled} size='small' onClick={() => {
                      this.hidePauseRow(row.pause_id,row.order_id);
                      // this.deletePauseRow(row.order_id,row.start_date);
                  }}>Delete</Button>
              </td>
            </tr>
          );
        });
      });
      break;
      case 'pending':
        if(pendings){
          Object.keys(pendings).map( (stat, idn) => {
            if (stat == 'done') {
                    rows.push(
                        <tr className="days-left-line">
                          <td colSpan="9">Done</td>
                        </tr>
                    );
            } else if (stat == 'not_paid') {
                    rows.push(
                        <tr className="days-left-line">
                          <td colSpan="9">Not Paid</td>
                        </tr>
                    );
            }
  
            Object.keys(pendings[stat]).map( (el, idx) => {
            var row = pendings[stat][el];
            var paytext = <span className="red-text">Not Paid</span>
            if (row.paymentMethod && row.paymentMethod != 'not_paid') {
              paytext = <span className="green-text">Paid by {row.paymentMethod}</span>
            }
  
                var maresults = null;
                if (row.confirmed_by_name) {
                  maresults = (
                      <div>
                        confirmed by {row.confirmed_by_name} at {row.confirmed_date}
                      </div>
                    )
                } else {
                    maresults = (
                      <div> 
                        <Button color='danger' size='small' onClick={() => {
                          this.acceptManager(row.order_id, 'orders', true);
                        }}>Confirmed by Accounts</Button>
                      </div>
                    )
                }
  
                  var results = null;
                  if (row.completed_by_name) {
                    results = (
                      <div>
                        Reviewed by {row.completed_by_name} at {row.completed_date}
                      </div>
                    )
                  } else {
                    results = (
                      <div> 
                        { (!row.paymentMethod || row.paymentMethod == 'not_paid') && <Button color='success' size='small' onClick={() => {
                          this.showModal('paystatus', { order_id: row.order_id, user_id: row.user_id })
                        }}>Set Paid</Button>}
                      </div>
                    )
                  }
  
  
            rows.push(
              <tr>
                <td>{parseInt(el) + 1}</td>
                <td> <a className="pointer" target='_blank' href={"/admin/customers/details/"+row.user_id}> {row.name} #{row.user_id}</a></td>
                <td>Mealplan: {planList[row.mealplan_id].title || ''} <br/> order #{row.order_id} <br/> Status: {row.status}</td>
                <td>{row.start_date} - {row.end_date}</td>
                <td>{row.daydif}</td>
                <td>{row.price} AED</td>
                <td>{paytext}</td>
                <td>{results}</td>
                <td>{maresults}</td>
              </tr>
            );
          });
          })
        }else{
          rows.push(
            <tr>
              <td colSpan={9}>No Pending Payments</td></tr>);
        }
      break;
      case 'renewals':
      if (renewals) {
       
        Object.keys(renewals).map((date, idx) => {
              if (date == 'done_renewed') {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="7">Done (Renewed)</td>
                      </tr>
                  );
              } else if (date == 'done_not_renewed') {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="7">Done (Not Renewed)</td>
                      </tr>
                  );
              } else if (date == '0') {
                rows.push(
                  <tr className="days-left-line">
                    <td colSpan="7">No Action Taken</td>
                  </tr>
              );
              } else {

                  var num = parseInt((date - moment().unix())/24/3600) +1;
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="7">{num} days left</td>
                      </tr>
                  );
              }
          Object.keys(renewals[date]).map((els, ids) => {
                var renewed = <span className="blue-text">Check with customer</span>;
                var is_checked = false;
                if (renewals[date][els].renew_reason || renewals[date][els].renew_comment) {
                  renewed = <span className="red-text">Not Renewed</span>;
                  is_checked = true;
                }
                var is_renewed = false;

                if (renewals[date][els].renew_status == '1') {
                  renewed = <span className="green-text">Renewed</span>;
                  is_renewed = true;
                  is_checked = true;
                } 

                rows.push(
                  <tr className={ is_renewed ? 'renew-line' : is_checked ? 'notrenew-line' : 'checked-line'}>
                    <td>{ids+1}</td>
                    <td><a className="pointer" target='_blank' href={"/admin/customers/details/"+renewals[date][els].user_id}> {renewals[date][els].name} #{renewals[date][els].user_id}</a></td>
                    <td>{renewals[date][els].end_date}</td>
                    <td>{renewals[date][els].mealinfo}</td>
                    <td>{renewed}
                    {!is_renewed && renewals[date][els].renew_comment && <div><br/><span>{reasonList[renewals[date][els].renew_comment]}</span></div> }
                    {!is_renewed && renewals[date][els].renew_reason && <div><br/><span>{renewals[date][els].renew_reason}</span></div> }
                    </td>
                    <td>
                      {!renewals[date][els].setBy && !is_renewed && <div><br/><Button color='success' size='small' onClick={() => {
                        this.cleanUpStates(renewals[date][els].user_id, renewals[date][els].order_id);
                      }}>Renew</Button></div>}
                      {!renewals[date][els].setBy && !is_renewed && !renewals[date][els].renew_reason && <div><br/><Button color='danger' size='small' onClick={() => {
                        this.showModal('reason', renewals[date][els].order_id)
                      }}>Not Renew</Button></div>}

                      {renewals[date][els].setBy && <span>Reviewed by {renewals[date][els].completed_by_name} at {renewals[date][els].completed_date}</span>}
                      
                    </td>
                    <td>
                      {renewals[date][els].confirmedBy && <span>Confirmed by {renewals[date][els].confirmed_by_name} at {renewals[date][els].confirmed_date}</span>}
                      {!renewals[date][els].confirmedBy && <div> 
                        <Button color='danger' size='small' onClick={() => {
                          this.acceptManager(renewals[date][els].order_id, 'orders');
                        }}>Confirmed by Manager</Button>
                      </div>}
                    </td>
                  </tr>
                )
          });
        });
      }
      break;

      case 'leads':
          const {leads} = this.state;

          const leadType = [
            '',
            'Meal plan', 
            'Juice plan', 
            'Online orders', 
            'Groceries', 
            'Catering', 
            'Desserts', 
            'Snackboxes',
          ];

          const leadQuality = [
            '',
            'Genuine', 
            'Will Call Back', 
            'Bad Lead',
          ];

          const signedList = [
            '',
            'Ara',
            'Rawda',
            'Rayana',
            'Justine',
            'Sarath',
            'Edward',
            'John',
            'Sarath',
            'Paolo',
            'Lany',
            'Bayan',
          ];


          console.log(leads);

          Object.keys(leads).map( (stat, idn) => {
          if (stat == 'done') {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="12">Completed Leads</td>
                      </tr>
                  );
          } else {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="12">Ongoing Leads</td>
                      </tr>
                  );
          }
          Object.keys(leads[stat]).map( (year, ida) => {
            Object.keys(leads[stat][year]).map( (month, idm) => {
              rows.push(
                      <tr className="days-left-line green-bg">
                        <td colSpan="12">{month} {year}</td>
                      </tr>
              );
              Object.keys(leads[stat][year][month]).map( (el, idx) => {

                var thelead = leads[stat][year][month][el];
                var follow = (
                  <div className="lead-border">
                      {thelead.follow_type && 
                        <div className="green-text lead-small">
                          {thelead.follow_type} level follow <br/> [{thelead.follow_date}]
                        </div>
                      }

                        <Button color='primary' size='small' onClick={() => {
                            this.followUp(1, thelead.id);
                        }}>1st f-w Up</Button>

                        <Button color='warning' size='small' onClick={() => {
                            this.followUp(2, thelead.id);
                        }}>2nd f-w Up</Button>

                        <Button color='gray' size='small' onClick={() => {
                            this.followUp(3, thelead.id);
                        }}>3rd f-w Up</Button>
                  </div>
                )

                var convert = (
                  <div>
                    <Button color='success' size='small' onClick={() => {
                        this.converted(true, thelead.id);
                    }}>Converted</Button>

                    <Button color='danger' size='small' onClick={() => {
                        this.converted(false, thelead.id);
                    }}>Not Converted</Button> 
                  </div>
                )

                if (thelead.convert_type === 1) {
                    convert = (
                      <div className="green-text lead-small">
                        Converted <br/> [{thelead.convert_date}]
                      </div>
                    )
                }

                if (thelead.convert_type === 0) {
                    convert = (
                      <div className="red-text lead-small">
                        Not Converted <br/> [{thelead.convert_date}]
                      </div>
                    )
                }

                var signedcolor = '';
                if (thelead.signed) {
                  signedcolor = 'color-text-'+thelead.signed;
                }
                rows.push(
                  <tr className="lead-tr">
                    <td>{idx+1}</td>
                    <td>{thelead.date || ''}</td>
                    <td>{thelead.name || ''}</td>
                    <td>{thelead.email || ''}</td>
                    <td>{thelead.phone || ''}</td>
                    <td>{thelead.type && leadType[thelead.type]}</td>
                    <td>{thelead.quality && leadQuality[thelead.quality]}</td>
                    <td>{thelead.signed && <span className={signedcolor}>{signedList[thelead.signed]}</span>}</td>
                    <td>
                        {follow}
                    </td>
                    <td>
                        {convert}        
                    </td>
                    <td className="lead-small">{thelead.notes}</td>
                    <td className="lead-small">
                        <Button color='primary' size='small' onClick={() => {
                          this.showModal('editlead', thelead);
                        }}>Edit</Button> 
                    </td>
                  </tr>
                );
              });
            });
          });
        });
      break;

      case 'cart':
        const {cart} = this.state;
        const {dataArray} = this.props;
        const {typeList, products} = dataArray;

        if (typeof(cart) != 'undefined') {
          Object.keys(cart).map( (el, idx) => {
            if (typeof(cart[el][0]) != 'undefined') {
              var cartrow = cart[el][0];
              rows.push(
                    <tr id="{idx}">
                      <td>#</td>
                      <td><a className="pointer" target='_blank' href={"/admin/customers/details/"+cartrow.user_id}> {cartrow.name} #{cartrow.user_id}</a></td>
                      <td>
                        <Button color='primary' size='small' onClick={() => {
                            this.showModal('cartview', cart[el]);
                        }}>view cart</Button>
                      </td>
                    </tr>
              )
            }
          });
        }
      break;

      case 'bags':
         const {bags} = this.state;

         Object.keys(bags).map( (stat, idn) => {
          if (stat == 'done') {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="9">Done</td>
                      </tr>
                  );
          } else if (stat == 'not_paid') {
                  rows.push(
                      <tr className="days-left-line">
                        <td colSpan="9">Bag Actions Required</td>
                      </tr>
                  );
          }

          Object.keys(bags[stat]).map( (el, idx) => {
          var row = bags[stat][el];
          var paytext = <span className="red-text">Not Paid</span>
          if (row.paymentMethod && row.paymentMethod != 'not_paid') {
            paytext = <span className="green-text">Paid by {row.paymentMethod}</span>
          }

              var maresults = null;
              if (row.completed_by_name) {
                  maresults = (
                    <div>
                      Bag collected status: {row.is_bag_collected ? <span className="green-text">collected</span> : <span className="red-text">not collected</span>} <br/>
                      Set by {row.completed_by_name} at {row.completed_date}
                    </div>
                  )
              } else {
                  maresults = (
                    <div> 
                      <Button color='warning' size='small' onClick={() => {
                        this.bagCollect(false, row.order_id, false);
                      }}>Not required, as renewed</Button>
                      <Button color='success' size='small' onClick={() => {
                        this.bagCollect(true, row.order_id, false);
                      }}>Set collected</Button>
                    </div>
                  )
              }

                var results = null;
                if (row.confirmed_by_name) {
                  results = (
                    <div>
                      Bag refund status: {row.is_bag_refunded ? <span className="green-text">refunded</span> : <span className="red-text">not refunded</span>} <br/>
                      Set by {row.confirmed_by_name} at {row.confirmed_date}
                    </div>
                  )
                } else {
                  results = (
                    <div> 
                      { <Button color='success' size='small' onClick={() => {
                        this.bagRefund(true, row.order_id);
                      }}>Refund {row.bag_price} AED Bag Deposit</Button>}
                      { <Button color='danger' size='small' onClick={() => {
                        this.bagRefund(false, row.order_id);
                      }}>Decline {row.bag_price} AED Bag Deposit</Button>}
                    </div>
                  )
                }


          rows.push(
            <tr>
              <td>{parseInt(el) + 1}</td>
              <td><a className="pointer" target='_blank' href={"/admin/customers/details/"+row.user_id}> {row.name} #{row.user_id}</a></td>
              <td>Mealplan: {planList[row.mealplan_id].title || ''} <br/> order #{row.order_id} <br/> Status: {row.status}</td>
              <td>{row.start_date} - {row.end_date}</td>
              <td>{row.daydif}</td>
              <td>
                {!row.is_sheduled ? <Button color='primary' size='small' onClick={() => {
                        this.bagCollect(false, row.order_id, true);
                      }}>Sheduled for collection</Button> : <span className="purple-text">The bag is sheduled</span>}

                
                {maresults}
              </td>
              <td>{results}</td>
            </tr>
          );
        });
        });

      break;
      case 'midplanfeedback':
        const { midplanfeedback } = this.state;
        const { notification,datas,orderCounts } = midplanfeedback;
          midplanfeedback && datas && Object.keys(datas).map((el,idx)=>{
            // if(notification[datas[el].order_id]==undefined || notification[datas[el].order_id]['notrequire']==undefined){
              let notify =(notification[datas[el].order_id]!=undefined)?notification[datas[el].order_id]:'';
              rows.push(
                <tr key={idx}>
                  <td> <a className="pointer" target='_blank' href={"/admin/customers/details/"+datas[el].user_id}> {datas[el].name} #{datas[el].user_id}</a></td>
                  <td>{moment.unix(datas[el].start_date).format('DD.MM.YYYY')}</td>
                  <td>{moment.unix(datas[el].end_date).format('DD.MM.YYYY')}</td>
                  <td>{(orderCounts[datas[el].user_id]!=undefined)?orderCounts[datas[el].user_id].totalcount:1}</td>
                  <td><strong>{datas[el].consumeday}</strong></td>
                  <td>{datas[el].acount-datas[el].consumeday}</td>
                  <td>{!notify && <Button color='success' size='small' onClick={() => {
                            this.setOrderNotification(datas[el].order_id,'midplanfeedback','sendmessage');
                        
                        }}>Message Sent</Button>}
                     {notify && notify['sendmessage']!=undefined&& 'Send By '+notify['sendmessage']['confirmby']+' at '+moment.unix(notify['sendmessage']['created_at']).format('Do MMM YYYY - LT')}
                   
                     {!notify && <Button color='success' size='small' onClick={() => {
                          if(notify!=undefined && notify['notrequire']==undefined){
                            this.setOrderNotification(datas[el].order_id,'midplanfeedback','notrequire');
                          }
                        }}>Not Required</Button>}
                        {notify && notify['notrequire']!=undefined&& 'Not Required Set By '+notify['notrequire']['confirmby']+' at '+moment.unix(notify['notrequire']['created_at']).format('Do MMM YYYY - LT')}
                        </td>
                </tr>
              )
            // }
           });
      break;  
      default:
          Object.keys(notifications).map((el, idx) => {
            
            if (!thetype || (thetype && (notifications[el].type == thetype)) ) {

                 var results = null;
                 if (notifications[el].is_completed === '1') {
                  //user info:
                  results = (
                    <div>
                      Reviewed by {notifications[el].completed_by_name} at {notifications[el].completed_date}
                    </div>
                  )
                 } else {
                  //buttons:
                  results = (
                    <div> 
                      <Button color='success' size='small' onClick={() => {
                        this.acceptNotify(notifications[el].id);
                      }}>Reviewed by team</Button>
                    </div>
                  )
                 }

                 var maresults = null;
                 if (notifications[el].is_managed === '1') {
                  //user info:
                  maresults = (
                    <div>
                      confirmed by {notifications[el].managed_by_name} at {notifications[el].managed_date}
                    </div>
                  )
                 } else {
                  //buttons:
                  maresults = (
                    <div> 
                      <Button color='danger' size='small' onClick={() => {
                        this.acceptManager(notifications[el].id);
                      }}>Confirmed by Manager</Button>
                    </div>
                  )
                 }
                 rows.push(
                  <tr >
                    <td>{idx+1}</td>
                    <td>{notifications[el].date}</td>
                    <td> <a className="pointer" target='_blank' href={"/admin/customers/details/"+notifications[el].user_id}> {notifications[el].name} #{notifications[el].user_id}</a></td>
                    <td>{notifications[el].action}</td>
                    <td>{req[notifications[el].type] || null}</td>
                    <td>{results}</td>
                    <td>{maresults}</td>
                  </tr>
                )
            }
           
          });
        break;
    }

    return (
      <Grid container>
        <Grid item md={12} className="whiteArea">
            <h1>Action Center</h1>
            <Filters socket={this.props.socket} doFilter={this.doFilter} thetype={thetype} />
            <Button color={!thetype ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: false,    
              });
            }}>All</Button>

            <Button color={thetype == 2 ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 2,    
              });
            }}>Customer Requests</Button>

            <Button color={thetype == 1 ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 1,    
              });
            }}>Customer Changes</Button>

            <Button color={thetype == 3 ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 3,    
              });
            }}>Customer Feedback</Button>

            <Button color={thetype == 'renewals' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'renewals',    
              });
              this.getRenewals();
            }}>Upcoming Renewals</Button>

            <Button color={thetype == 'pending' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'pending',    
              });
              this.getPendings();
            }}>Pending Payment</Button>
            <Button color={thetype == 'midplanfeedback' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'midplanfeedback',    
              });
              this.getMidPlanFeedback()
            }}>Mid-Plan Feedback</Button>
            <Button color={thetype == 'pauses' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'pauses',    
              });
              this.getPauseNotify();
            }}>Hold / Pause List</Button>

            <Button color={thetype == 'leads' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'leads',    
              });
              this.getLeads();
            }}>Customer Leads</Button>

            <Button color={thetype == 7 ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 7,    
              });
            }}>Login logs</Button>

             <Button color={thetype == 'bags' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'bags',    
              });
              this.getBags();
            }}>Bag actions</Button>

             <Button color={(thetype == 'birthday') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'birthday',    
              });
              this.getBirthdayList();
            }}>Birthday List</Button>

             <Button color={(thetype == 'cart') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'cart',    
              });
              this.getCart();
            }}>Cart List</Button>
             <Button color={thetype == 8 ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 8,    
              });
            }}>Skip Delivery/Pause</Button>

            { (thetype == 'renewals') && 
            <div>
            <p>The filter dates can't be in use here. You have the statistics of renewals until today.</p>
            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Last date of meal plan</th>
                  <th scope="col">Mealplan Info</th>
                  <th scope="col">Renewed / not renewed</th>
                  <th scope="col">Team Review</th> 
                  <th scope="col">Manager Confirmation</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }

            {thetype == 'birthday' && <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Customer name</th>
                  <th scope="col">Birthday date</th>
                  <th scope="col">No of Meal Plan Subscriptions</th>
                  <th scope="col">Total Spend</th>
                  <th scope="col">Birthday Wish Sent</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}


            { (thetype == 'pending') && 
            <div>
            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Mealplan</th>
                  <th scope="col">Mealplan Dates</th>
                  <th scope="col">No of days done since start of plan</th>
                  <th scope="col">Amount to pay</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Team Review</th> 
                  <th scope="col">Manager Confirmation</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }

            { (thetype == 'cart') && 
            <div>
            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Cart</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }


            { (thetype == 'leads') && 
            <div>

            <Button color='primary' size='medium' onClick={() => {
              this.showModal('addlead');
            }}>Add Leads</Button>

            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">date</th>
                  <th scope="col">name</th>
                  <th scope="col">email</th>
                  <th scope="col">phone</th>
                  <th scope="col">lead type</th>
                  <th scope="col">lead quality</th>
                  <th scope="col">Assigned to</th>
                  <th scope="col">follow up</th>
                  <th scope="col">converted</th>
                  <th scope="col">notes</th>
                  <th scope="col">edit</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }

            { (thetype == 'bags') && 
            <div>
            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Mealplan</th>
                  <th scope="col">Mealplan Dates</th>
                  <th scope="col">Last-day of meal plan</th>
                  <th scope="col">Team Review</th> 
                  <th scope="col">Refunded</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }


            { (thetype == 'pauses') && 
            <div>
            <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Mealplan Dates</th>
                  <th scope="col">Pause / On Hold Info</th>
                  <th scope="col">Team Review</th> 
                  <th scope="col">Manager Confirmation</th>
                  <th scope="col">Delete</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }

            { (thetype != 'renewals') && (thetype != 'pending') && (thetype != 'cart') && (thetype != 'birthday') && (thetype != 'leads') && (thetype != 'pauses') && (thetype != 'bags') && thetype != 'midplanfeedback' && <div>
              {!thetype && <p>The List here can't keep the renewals tab's data because of another table structure.</p>}
              <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">User</th>
                  <th scope="col">Updates</th>
                  <th scope="col">Type</th>
                  <th scope="col">Team Review</th> 
                  <th scope="col">Manager Confirmation</th> 
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table></div> }
            { (thetype == 'midplanfeedback') && 
            <div>
            <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">Customer Name</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">No of plans subscribed</th>
                  <th scope="col">No of days consumed</th>
                  <th scope="col">No of days Left</th>
                  <th scope="col">Team review</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            </div> }
        </Grid>
      </Grid>
    );
  }
}

Notifications.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
}; 

const props = state => ({
  dataArray: state.dataArray,
  pinsList: Object.keys(state.pins.customers),
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  addPin: (group, id) => dispatch(addPin(group, id)),
  removePin: (group, id) => dispatch(removePin(group, id)),
  clearNotify: () => dispatch(clearNotify()),
  clearModal: () => dispatch(clearModal()),
  setNotify: props => dispatch(setNotify(props)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions, null, { withRefs: true })(Notifications));