import React, { Component } from 'react';
import {
  Grid
} from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import { Link } from 'react-router';
import { getCatalogs, groups } from 'variables/menu';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import CustomCard from 'components/Cards/CustomCard';

class Catalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      cats: getCatalogs()
    };
  }

  componentDidMount() {
    this.CatalogsRef && setTimeout(() => this.setState({ xyz: true }), 100);
  }

  render() {
    const { xyz, cats } = this.state;
    const itemsGroups = cats ? cats.reduce((acc, cat, idx) => {
      const { path, title, group } = cat;
      const item = (
        <ItemGrid md={2} key={idx}>
          <Link to={path} className='settings'>
            <Button color='darkBlue'>
              {title}
            </Button>
          </Link>
        </ItemGrid>
      );
      return { ...acc, [group]: [...(acc[group] || []), item] };
    }) : {};
    const catsList = Object.keys(groups).map((el, idx) => {
      const title = groups[el];
      return <Grid key={idx} container>
        <CustomCard marginOnePx>
          <Text style={{ fontSize: '20px', color: '#242424' }}>{title}</Text>
          <Grid container>{itemsGroups[el]}</Grid>
        </CustomCard>
      </Grid>;
    });
    return <div ref={el => (this.CatalogsRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
      <Grid container>
        {catsList}
      </Grid>
    </div>;
  }
}

export default Catalogs;
