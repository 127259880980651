import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'rc-time-picker/assets/index.css';
import './style.css';
import moment from 'moment';
import ToolTip from '../ToolTip';
import Input from '../CustomInput/CustomInput';

const cbStyle = {
  display: 'block',
  position: 'absolute',
  opacity: 0
};

class InputCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      value: moment(),
      calendarValue: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value ? moment.unix(nextProps.value).utc().locale(this.props.locale).format('L') : '',
        calendarValue: nextProps.value ? moment.unix(nextProps.value) : moment().utc().locale(this.props.locale).startOf('day')
      });
    }
  }

  componentWillMount() {
    const { value, locale } = this.props;
    moment().locale(locale);
    this.setState({
      value: value ? moment.unix(value).locale(locale).utc().format('L') : '',
      calendarValue: value ? moment.unix(value).utc() : moment().utc().startOf('day')
    });
    if (this.props.showCalendarForSure) {
      this.setState({ showCalendar: true });
    }
  }

  updateDate = e => {
    if (!this.props.showCalendarForSure) {
      this.setState({ value: e.locale(this.props.locale).format('L'), showCalendar: false });
      this.props.toggle(false);
    }
    this.props.onChange(e.locale(this.props.locale).startOf('day').unix());
    this.InputCalendarRef.blur();
  };

  toggleCalendar = () => {
    const { showCalendar } = this.state;
    const { disable } = this.props;
    if (!disable || showCalendar) {
      if (showCalendar) {
        this.InputCalendarRef.blur();
      } else {
        this.props.toggle(showCalendar);
        this.setState({ showCalendar: !showCalendar });
      }
    }
  };

  onBlur = () => {
    if (!this.onCal) {
      this.props.toggle(false);
      this.setState({ showCalendar: false });
    }
  };

  render() {
    const {
      className, name, error, classError, placeholder, classNameCalendar, tooltipProps, dateValidator, onNavigateForward, onNavigateBack,
      locale, viewMode, disable, renderDay, renderMonth, renderYear, defaultValue, showCalendarForSure, onViewModeChange
    } = this.props;
    const { showCalendar, value } = this.state;
    return (
      <div
        className={`${className} ${!showCalendarForSure ? 'absolute-cal' : ''} ${error ? classError : ''}`}
        tabIndex='0'
        onBlur={this.onBlur}
        ref={el => (this.InputCalendarRef = el)}
      >
        {!showCalendarForSure &&
        <ToolTip {...tooltipProps}>
          <div onClick={this.toggleCalendar}>
            <input type='checkbox' style={cbStyle} />
            <Input
              labelText={placeholder}
              formControlProps={{
                fullWidth: true
              }}
              error={error}
              inputProps={{
                value,
                name,
                type: 'text',
                disabled: disable
              }}
            />
          </div>
        </ToolTip>
        }
        {showCalendar &&
          <div className='cal-holder' onMouseEnter={() => (this.onCal = true)} onMouseLeave={() => (this.onCal = false)}>
            <Datetime
              locale={locale}
              className={classNameCalendar}
              onChange={this.updateDate}
              viewMode={viewMode}
              input={false}
              value={value}
              timeFormat={false}
              utc
              isValidDate={dateValidator}
              renderDay={renderDay}
              renderMonth={renderMonth}
              renderYear={renderYear}
              defaultValue={defaultValue}
              onViewModeChange={onViewModeChange}
              onNavigateBack={onNavigateBack}
              onNavigateForward={onNavigateForward}
            />
          </div>
        }
      </div>
    );
  }
}

InputCalendar.propTypes = {
  placeholder: PropTypes.string,
  viewMode: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  classNameCalendar: PropTypes.string,
  classError: PropTypes.string,
  name: PropTypes.string,
  locale: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  toggle: PropTypes.func,
  disable: PropTypes.bool,
  error: PropTypes.bool,
  renderDay: PropTypes.func,
  onNavigateForward: PropTypes.func,
  onNavigateBack: PropTypes.func,
  renderMonth: PropTypes.func,
  renderYear: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  showCalendarForSure: PropTypes.bool,
  onViewModeChange: PropTypes.func,
  tooltipProps: PropTypes.object,
  dateValidator: PropTypes.func
};

InputCalendar.defaultProps = {
  before: false,
  fullYear: false,
  any: false,
  disable: false,
  viewMode: 'days',
  placeholder: '',
  className: 'search-profile-main-cal form-input calendar-input pointer',
  classNameCalendar: '',
  classError: 'has-error',
  name: '',
  onChange: () => {},
  onViewModeChange: () => true,
  onNavigateBack: () => true,
  onNavigateForward: () => true,
  toggle: () => {},
  error: false,
  renderDay: (props, currentDate, selectedDate) => {
    return <td {...props}>{ currentDate.date() }</td>;
  },
  renderMonth: (props, month, year, selectedDate) => {
    return <td {...props}>{ moment().month(month).format('MMM') }</td>;
  },
  renderYear: (props, year, selectedDate) => {
    return <td {...props}>{ year }</td>;
  },
  defaultValue: moment().startOf('day'),
  showCalendarForSure: false,
  dateValidator: () => true,
  tooltipProps: {
    tooltipText: '',
    tooltipDirection: 'top',
    trigger: []
  }
};

export default InputCalendar;
