import React from 'react';
import ReactDOM from 'react-dom';
import createStore from './store/createStore';
import './styles/main.css';
import 'assets/css/material-dashboard-react.css?v=1.2.0';

// Store Initialization
// ------------------------------------
const stores = createStore(window.__INITIAL_STATE__);

const store = stores.store;
const persistor = stores.persistor;

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root');

(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
  }
})();

let render = () => {
  const App = require('./components/App').default;
  let routes = require('./routes/index').default(store);
  ReactDOM.render(
    <App store={store} persistor={persistor} routes={routes} />,
    MOUNT_NODE
  );
};

// Development Tools
// ------------------------------------
if (__DEV__) {
  if (module.hot) {
    const renderApp = render;
    const renderError = (error) => {
      const RedBox = require('redbox-react').default;
      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    render = () => {
      try {
        renderApp();
      } catch (e) {
        console.error(e);
        renderError(e);
      }
    };

    // Setup hot module replacement
    module.hot.accept([
      './components/App',
      './routes/index'
    ], () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      })
    );
  }
}

// Let's Go!
// ------------------------------------
if (!__TEST__) render();
