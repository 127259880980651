import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removePin, clearGroupPins } from 'store/pins';
import Button from 'components/CustomButtons/Button';
import { Remove, Flag, Close } from '@material-ui/icons/index';
import './assets/style.css';

class Pins extends Component {
  constructor(props) {
    super(props);
    const { active } = props;
    this.state = {
      active
    };
  }

  removeOnePin = (e, id) => {
    e.stopPropagation();
    const { removePin, group } = this.props;
    removePin(group, id);
  };

  removeAllPins = () => {
    const { clearGroupPins, group } = this.props;
    clearGroupPins(group);
  };

  componentWillReceiveProps(next) {
    if (this.PinsRef) {
      if (Object.keys(this.props.pinsStorage[this.props.group]).length !== Object.keys(next.pinsStorage[this.props.group]).length) {
        this.setState({ pinsList: next.pinsStorage[this.props.group] });
      }
    }
  }

  render() {
    const { pinsStorage, clickPin, group, title } = this.props;
    const { active } = this.state;
    const pinsList = pinsStorage[group] || {};
    const pinsNeeded = Object.keys(pinsList).length === 0 && pinsList.constructor === Object;
    let list = pinsList ? Object.keys(pinsList).reduce((list, key) => {
      const pin = pinsList[key];
      return [
        ...list,
        (
          <Button
            color={+key === +active ? 'darkBlue' : 'white'}
            size='small'
            style={{ marginRight: '5px', padding: '2px 0' }}
            key={key}
            onClick={e => clickPin(e, +key)}
          >
            <Flag />
            <span className='item-title'>{pin.title}</span>
            <div className='pin-inner' onClick={e => this.removeOnePin(e, key)}>
              <Close />
            </div>
          </Button>
        )
      ];
    }, []) : [];
    return (
      <div className='my-pins'>
        <div className='pins-outher ' ref={el => (this.PinsRef = el)}>
          {!pinsNeeded && title && <span>{title}</span>}
          <div className='pins-inner scrollable-v'>
            {list}
            {!pinsNeeded && <span className='remove' onClick={this.removeAllPins} ><Remove /></span>}
          </div>
        </div>
      </div>
    );
  }
}

Pins.propTypes = {
  pinsStorage: PropTypes.object.isRequired,
  removePin: PropTypes.func.isRequired,
  clickPin: PropTypes.func.isRequired,
  clearGroupPins: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  group: PropTypes.string.isRequired,
  title: PropTypes.string
};

const mapStateToProps = state => ({
  pinsStorage: state.pins
});

const bindAction = dispatch => ({
  removePin: (group, id) => dispatch(removePin(group, id)),
  clearGroupPins: group => dispatch(clearGroupPins(group))
});

export default connect(mapStateToProps, bindAction, null, { withRef: true })(Pins);
