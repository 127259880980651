import React, { Component } from 'react';

class Unauthorized extends Component {
  render() {
    return (<div style={{}} className='not-found'>
       <p>NO ACCESS!</p>
      <p>You do not have access to these sections.</p>
    </div>);
  }
}

export default Unauthorized;
