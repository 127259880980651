import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import { Link } from 'react-router';
import Button from 'components/CustomButtons/Button';
import { setModal, clearModal } from 'store/modal';
import { getArrays, iterator } from 'utils';
import printJS from 'print-js';

const tabs = [
  { title: 'All', slug: 'all' },
  { title: 'Can be packed', slug: 'available' }
];

class Packaging extends Component {
  constructor(props) {
    super(props);
    this.hygiens = {};
    this.cutleries = {};
  }

  DishInfo = ({ dishInfo, needsCutlery, dataArray, idx }) => {
    const { dishPacks, cutleryList, hygieneList } = dataArray;
    const { title, nickname, pack, cutlery, hygiene } = dishInfo;
    hygiene && (hygiene in this.hygiens ? this.hygiens[hygiene]++ : this.hygiens[hygiene] = 1);
    cutlery && (cutlery in this.cutleries ? this.cutleries[cutlery]++ : this.cutleries[cutlery] = 1);
    const leftCounter = iterator();
    const rightCounter = iterator();
    const leftRows = [
      <p className='item-group' key={`l_${leftCounter()}_${idx}`}>
        <span className='item-name'>{nickname || title}</span>
      </p>
    ];
    let rightRows = [
      <p className='item' key={`r_${rightCounter()}_${idx}`}>
        <span className='item-name'>{dishPacks[pack]}</span>
      </p>
    ];
    if (needsCutlery) {
      cutlery && rightRows.push(<p className='item' key={`r_${rightCounter()}_${idx}`}>
        <span className='item-name'>{cutleryList[cutlery]}</span>
      </p>);
      hygiene && rightRows.push(<p className='item' key={`r_${rightCounter()}_${idx}`}>
        <span className='item-name'>{hygieneList[hygiene]}</span>
      </p>);
    }
    while (leftRows.length < rightRows.length) {
      leftRows.push(<p className='item-group' key={`l_${leftCounter()}_${idx}`} />);
    }
    return <div className='package' key={idx}>
      <div className='list'>
        <div className='head'>
          <p>Dish</p>
          <p>Pack list</p>
        </div>
        <div className='body'>
          {rightRows.map((el, idx) => <div className='imems-row' key={idx}>
            {leftRows[idx]}
            {el}
          </div>)}
        </div>
      </div>
    </div>;
  };

  Menu = ({ menu, needsCutlery, dataArray }) => {
    const { productsFull } = dataArray;
    this.hygiens = {};
    this.cutleries = {};
    return Object.values(menu).map((typeInfo, idx) => {
      const { dish } = typeInfo;
      return <this.DishInfo {...{ dishInfo: productsFull[dish], needsCutlery, dataArray, idx }} key={idx} />;
    });
  };

  showPrintModal = (userInfo) => {
    const { menu, needsCutlery, username } = userInfo;
    const { setModal, dataArray } = this.props;
    const printConfig = {
      printable: 'printable-content',
      type: 'html',
      scanStyles: true,
      targetStyles: ['*']
    };
    const { hygiens, cutleries } = this;
    setModal({
      content: <div className={'pack-print'}>
        <div id='printable-content'>
          <div className='pack-list'>
            <p className='list-title'>Pack list for: <span>{username}</span></p>
            <div className='head head-print'><p>Dish</p><p>Pack</p></div>
            <this.Menu {...{ menu, needsCutlery, dataArray }} />
            <hr />
            <this.TotalInfo {...{ hygiens, cutleries, needsCutlery, dataArray }} />
          </div>
        </div>
        <Button size={'large'} color={'darkBlue'} pullRight onClick={() => printJS(printConfig)}>Print</Button>
      </div>
    });
  };

  TotalInfo = ({ hygiens, cutleries, needsCutlery, dataArray }) => {
    const { cutleryList, hygieneList } = dataArray;
    const getInfo = (info, dict) => Object.keys(info).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${dict[cur]}${info[cur] > 1 ? ` x ${info[cur]}` : ''}`, 'not included');
    return <div className='package general'>
      <div className='list'>
        <div className='head'>
          <p>General</p>
          <p>Pack list</p>
        </div>
        <div className='body'>
          <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>General packaging</span>
            </p>
            <p className='item'>
              <span className='item-name'>Bag</span>
            </p>
          </div>
          {!!needsCutlery && <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>Cutlery</span>
            </p>
            <p className='item'>
              <span className='item-name'>{getInfo(cutleries, cutleryList)}</span>
            </p>
          </div>}
          <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>Hygiene products</span>
            </p>
            <p className='item'>
              <span className='item-name'>{getInfo(hygiens, hygieneList)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>;
  };

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['deliveryTime', 'typeList', 'dishPacks', 'cutleryList', 'hygieneList', 'transportTypes', 'productsFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    socket.emit('packaging', { type: 'get', data: { types: this.types, special: true } });
  }

  render() {
    const { routes, userType } = this.props;
    const curPath = routes.reverse()[0].path;
    const buttons = tabs.map((tab, idx) => {
      const { title, slug } = tab;
      return <Link to={`/${userType}/packaging/${slug}`} key={idx} onlyActiveOnIndex>
        <Button color={(slug === curPath) ? 'darkBlue' : 'white'}>{title}</Button>
      </Link>;
    });
    const children = this.props.children && Children.map(
      this.props.children,
      child => cloneElement(child, {
        showPrintModal: this.showPrintModal
      })
    );
    return <Grid container>
      <div className='tabsCustom'>
        <ItemGrid md={12}>
          {buttons}
        </ItemGrid>
      </div>
      <div className='afterTabsCustom'>
        <ItemGrid md={12}>
          {children}
        </ItemGrid>
      </div>
    </Grid>;
  }
}

Packaging.propTypes = {
  socket: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired
};

const props = state => ({
  userType: (state.user || {}).type || '',
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal())
});

export default socketConnect(connect(props, actions)(Packaging));
