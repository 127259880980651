import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import { Table, TableBody, TableCell, TableRow } from 'material-ui';
import TableHead from 'material-ui/Table/TableHead';
import moment from 'moment';
import Filters from './Filters';
import Text from 'components/Typography/Text';
import Button from 'components/CustomButtons/Button';
 
class Accounting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      xyz: false,
      orderBy: 'default',
      orderDir: '+',
      status: 'all',
      reason: 'all'
    };
    this.getList();
  }

  componentWillMount() {
    this.props.socket.on('finances', this.financesListener);
    this.props.socket.on('export', this.exportListener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('finances', this.financesListener);
    this.props.socket.removeListener('export', this.exportListener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 50);
    this.props.setExport({ type: 'accounting' });
  }

  financesListener = ({ type, status, data }) => {
    if (this.AccountingRef) {
      if (status === 'ok') {
        if (['listAccounting', 'payment'].includes(type)) {
          this.setState(data);
        }
      } else {
        console.error(data);
      }
    }
  };

  exportListener = ({ type, status, data }) => {
    if (this.AccountingRef) {
      if (status === 'ok') {
        if (type === 'orderInvoice') {
          window.open(data.file);
        }
      } else {
        console.error(data);
      }
    }
  };

  getList = () => this.props.socket.emit('finances', { type: 'listAccounting', data: {} });

  sort = (a, b) => {
    const results = { true: 1, false: -1 };
    const { orderBy, orderDir } = this.state;
    if (orderBy === 'default') {
      return 0;
    }
    const getItemData = (item, prefix) => {
      const { username, debit, credit, payment_date } = item;
      const prop = name => `${prefix}${name}`;
      return {
        [prop('username')]: username,
        [prop('debit')]: debit,
        [prop('bagPrice')]: bag_price,
        [prop('credit')]: credit,
        [prop('date')]: payment_date
      };
    };
    const obj = { ...getItemData(a, 'a_'), ...getItemData(b, 'b_') };
    return results[orderDir === '+' ? obj[`a_${orderBy}`] > obj[`b_${orderBy}`] : obj[`b_${orderBy}`] > obj[`a_${orderBy}`]] || 0;
  };

  applyFilters = ({ order, nameOrPhone, status, reason }) => {
    const orderArr = [...order];
    const orderDir = orderArr.splice(-1).join('');
    const orderBy = orderArr.join('');
    const { orderDir: oldOrderDir, orderBy: oldOrderBy, nameOrPhone: oldNameOrPhone, status: oldStatus, reason: oldReason } = this.state;
    let newState = {};
    if (orderDir !== oldOrderDir || orderBy !== oldOrderBy) {
      newState = { orderBy, orderDir };
    }
    if (nameOrPhone !== oldNameOrPhone) {
      newState.nameOrPhone = nameOrPhone;
    }
    if (status !== oldStatus) {
      newState.status = status;
    }
    if (reason !== oldReason) {
      newState.reason = reason;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  };

  exportInvoice = (order_id, reason) => this.props.socket.emit('export', { type: 'orderInvoice', data: { order_id, reason } });

  markAsPayed = (id, reason, status) => this.props.socket.emit('finances', { type: 'payment', data: { [reason]: id, reason, status } });

  renderContent = (list = []) => {
    const price = (value, mul = 0.01) => isNaN(+value) ? 0 : +value * mul;
    const reasons = { sales: 'Sales', refund: 'Refund' };
    const totals = {
      debit: { done: 0, pending: 0 },
      credit: { done: 0, pending: 0 },
      bag: {done: 0, pending: 0},
      update(type, status, value) {
        this[type][status] += price(value, 1);
      }
    };
    const { nameOrPhone, status, reason: reasonFilter } = this.state;
    const filtered = list.filter(({ username, phone, payed, reason }) => {
      const lower = (nameOrPhone || '').toLowerCase();
      const matchByNameOrPhone = nameOrPhone ? (username || '').toLowerCase().includes(lower) || (phone || '').toLowerCase().includes(lower) : true;
      const matchByStatus = status === 'all' ? true : +status === +payed;
      const matchByReason = reasonFilter === 'all' ? true : reasonFilter === reason;
      return matchByNameOrPhone && matchByStatus && matchByReason;
    });
    const rows = filtered.sort(this.sort).map((item, idx) => {
      const { id, username, phone, debit, credit, payment_date, payed, reason, bag_price } = item;
      totals.update('debit', payed ? 'done' : 'pending', debit);
      totals.update('credit', payed ? 'done' : 'pending', credit);
      totals.update('bag', payed ? 'done' : 'pending', bag_price);
      return <TableRow key={idx}>
        <TableCell>{id}</TableCell>
        <TableCell>
          <Text inline={false} bold>{`${idx + 1}. ${username}`}</Text>
          <Text inline={false} style={{ fontSize: 'smaller' }}>{phone}</Text>
        </TableCell>
        <TableCell>{price(debit)}</TableCell>
        <TableCell>{bag_price}</TableCell>
        <TableCell>{price(credit)}</TableCell>
        <TableCell>{payed ? 'Done' : 'Pending'}</TableCell>
        <TableCell>{payed ? moment.unix(payment_date).format('DD MMM, YYYY') : '-'}</TableCell>
        <TableCell>{reasons[reason]}</TableCell>
        <TableCell>
          <Button size={'small'} color={'success'} onClick={() => this.exportInvoice(id, reason)}>Export the invoice</Button>
          <Button size={'small'} color={'darkBlue'} onClick={() => this.markAsPayed(id, reason, !payed)}>Mark as {['not ', ''][+!payed]}payed</Button>
        </TableCell>
      </TableRow>;
    }); 
    return <Table>
      <TableHead className='primaryTableHeader roundedHeader not-align-right'>
        <TableRow className='totals-row'>
          <TableCell colSpan={3} className={'performed'}>
            <Text color={'darkBlue'} bold>Performed</Text>
            <Text color={'success'} bold>{`Debit: ${totals.debit.done / 100}`}</Text>
            <Text color={'success'} bold>{`Bag Price: ${totals.bag.done}`} </Text>
            <Text color={'warning'} bold>{`Credit: ${totals.credit.done / 100}`}</Text>
          </TableCell>
          <TableCell colSpan={5} className={'pending'}>
            <Text bold>Pending</Text>
            <Text color={'success'} bold>{`Debit: ${totals.debit.pending / 100}`}</Text>
            <Text color={'success'} bold>{`Bag Price: ${totals.bag.pending}`} </Text>
            <Text color={'warning'} bold>{`Credit: ${totals.credit.pending / 100}`}</Text>
          </TableCell>
        </TableRow>
        <TableRow id='header-row'>
          <TableCell>Order ID</TableCell>
          <TableCell>Client</TableCell>
          <TableCell>Debit, AED</TableCell>
          <TableCell>Bag Deposit, AED</TableCell>
          <TableCell>Credit, AED</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Reason</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
      </TableBody>
    </Table>;
  };

  render() {
    const { xyz, list } = this.state;
    console.log(list);
    return (
      <div ref={el => (this.AccountingRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
        <div className='filtesHolder finances'>
          <Filters applyFilters={this.applyFilters} />
        </div>
        <CustomCard marginOnePx>
          <div>
            {this.renderContent(list)}
          </div>
        </CustomCard>
      </div>
    );
  }
}

Accounting.propTypes = {
  socket: PropTypes.object,
  setExport: PropTypes.func.isRequired
};

export default socketConnect(Accounting);
