import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

class PieChart extends Component {
  render() {
    const { dataSets, labels, legend } = this.props;
    const datasets = Object.keys(dataSets).map((label) => {
      const { data, backgroundColor, hoverBackgroundColor } = dataSets[label];
      return {
        data,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor
      };
    });
    const data = {
      labels,
      datasets
    };
    const options = {
      legend
    };
    return (
      <Doughnut data={data} options={options} />
    );
  }
}

PieChart.propTypes = {
  dataSets: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  legend: PropTypes.shape({
    display: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
    labels: PropTypes.shape({
      padding: PropTypes.number,
      fontSize: PropTypes.number
    })
  })
};

PieChart.defaultProps = {
  legend: {
    display: true,
    position: 'top',
    labels: {
      padding: 10,
      fontSize: 12
    }
  }
};

export default PieChart;
