import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';

class TabFeedback extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      totalRating: false,
      dishesRating: []
    };
    this.getReviews(user_id); 
  }

  getReviews = user_id => this.props.socket.emit('rating', { type: 'getUserReviews', data: { user_id } });

  listener = action => {
    const { type, data } = action;
    if (type === 'getUserReviewsOk') {
      this.setState(data);
      console.log(this.state);
    } else if (type === 'getUserReviews') {
      console.err(data.message);
    }
  };

  componentWillMount() {
    this.props.socket.on('rating', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('rating', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.TabFeedbackRef && next.user_id !== this.props.user_id) {
      this.getReviews(next.user_id);
    }
  }

  render() {
    const { totalRating, dishesRating } = this.state;
    const { products } = this.props;
    var feedbacks = [];
    
    const getRating = (rating) => Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
    
    if (totalRating) {
      totalRating.map( (el, idx) => {
        feedbacks.push(
            <div className='review general'> 
            <div className='review-block'>
              <div className='head-line'>
                <p className='title'>General review</p>
                <p className='date'>{moment.unix(el.date).format('DD MMM, YYYY')}</p>
                <div className='stars'>
                  <p className='rating'>Overall Rating <br/> {getRating(+el.rating)}</p>
                  <p className='rating'>Food <br/> {getRating(+el.rating2)}</p>
                  <p className='rating'>Service <br/> {getRating(+el.rating3)}</p>
                  <p className='rating'>Delivery <br/> {getRating(+el.rating4)}</p>
                </div>
              </div>
              <div className='review-text'>{el.feedback}</div>
            </div>
            </div>)
      });
    }

    return (
      <div className='feedback-holder scrollable-h' ref={el => (this.TabFeedbackRef = el)}>
        
        {feedbacks}

        {!!(dishesRating && dishesRating.length && products) && dishesRating.map((el, idx) => {
          const { dish_id, rating, feedback, date } = el;
          const { title, image } = products[dish_id] || {};
          return (
            <div className='review' key={idx}>
              <div className='photo' style={{ backgroundImage: `url(${image})` }} />
              <div className='review-block'>
                <div className='head-line'>
                  <p className='title'>{title}</p>
                  <p className='date'>{moment.unix(date).format('DD MMM, YYYY')}</p>
                  <div className='stars'><p className='rating'>{getRating(+rating)}</p></div>
                </div>
                <div className='review-text'>{feedback}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

TabFeedback.propTypes = {
  socket: PropTypes.object,
  products: PropTypes.object,
  user_id: PropTypes.number.isRequired
};

const props = state => ({
  products: state.dataArray.products
});

export default socketConnect(connect(props, null)(TabFeedback));
