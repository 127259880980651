import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Close } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';

class Parts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: props.components || {},
      order: (props.components && Object.keys(props.components)) || []
    };
  }

  renderComponentFields = (id) => {
    const { products, dishPacks, cutleryList, hygieneList } = this.props;
    const { components, order: oldOrder } = this.state;
    const list = Object.keys(products).reduce((acc, cur) => {
      const comp = products[cur];
      return (comp['dishType'] !== 'composite' && (!components[cur] || cur === id)) ? { ...acc, [cur]: comp.title } : acc;
    }, {});
    const onChange = val => {
      let comps = { ...components };
      let order = [...oldOrder];
      if (id && id in comps) {
        comps = { ...comps, [val]: comps[id] };
        delete comps[id];
        order[order.indexOf(id)] = val;
      } else {
        comps = { ...comps, [val]: {} };
        order = [...order, val];
      }
      this.setState({ components: comps, order });
    };
    const { pack_id, cutlery_id, hygiene_id } = components[id] || {};
    const propChange = (name, value) => {
      id && this.setState({ components: { ...components, [id]: { ...components[id], [name]: value } } });
    };
    const remove = () => {
      if (id in components) {
        let comps = { ...components };
        delete comps[id];
        this.setState({ components: comps, order: oldOrder.filter(el => el !== id) });
      }
    };
    return (
      <ItemGrid md={12} key={id} container>
        <ItemGrid md={4}>
          <Select
            placeholder={'Component'}
            list={list}
            value={id || ''}
            name={'components'}
            onChange={(name, value) => onChange(value)}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <Select
            placeholder={'Package'}
            list={dishPacks}
            value={pack_id || ''}
            name={'pack_id'}
            onChange={propChange}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <Select
            placeholder={'Cutlery'}
            list={cutleryList}
            value={cutlery_id || ''}
            name={'cutlery_id'}
            onChange={propChange}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <Select
            placeholder={'Hygiene products'}
            list={hygieneList}
            value={hygiene_id || ''}
            name={'hygiene_id'}
            onChange={propChange}
          />
        </ItemGrid>
        <ItemGrid md={2}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>
      </ItemGrid>
    );
  };

  render() {
    const { order } = this.state;
    return (
      <Grid container>
        {order.map(this.renderComponentFields)}
        {this.renderComponentFields()}
      </Grid>
    );
  }
}

Parts.propTypes = {
  ingredientListFull: PropTypes.object.isRequired,
  components: PropTypes.object,
  products: PropTypes.object.isRequired,
  dishPacks: PropTypes.object.isRequired,
  cutleryList: PropTypes.object.isRequired,
  hygieneList: PropTypes.object.isRequired
};

export default Parts;
