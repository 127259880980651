import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { clearNotify, setNotify } from 'store/notify';
import { ErrorOutline, Edit, Save, Cancel } from '@material-ui/icons';
import Grid from 'components/Grid/ItemGrid';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';

class SystemSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      value: '',
      errors: {}
    };
  }

  getSettings() {
    this.props.socket.emit('settings', { type: 'set', data: {} });
  }

  saveSettings(data) {
    this.props.socket.emit('settings', { type: 'set', data });
  }

  componentWillMount() {
    this.props.socket.on('settings', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('settings', this.listener);
  }

  listener = action => {
    const { type, status, data } = action;
    if (this.SystemSettingsRef && status === 'ok') {
      if (type === 'set') {
        this.props.setNotify({
          place: 'tc',
          color: 'success',
          icon: ErrorOutline,
          message: data.message,
          open: true
        });
        this.setState({ editing: false, value: '' });
        setTimeout(() => this.props.clearNotify(), 5000);
      }
    } else if (status === 'err') {
      const { errors } = data;
      if (errors) {
        this.setState({ errors });
      }
      console.error(action);
    }
  };

  render() {
    const { settings } = this.props;
    const { editing, value: newValue, errors } = this.state;
    const cardStyle = { padding: '10px', borderRadius: '3px' };
    return <div ref={el => (this.SystemSettingsRef = el)}>
      <Grid container md={12}>
        <Grid item md={12}>
          <CustomCard
            cardCustomStyle={cardStyle}
            marginOnePx
            backgroundColor='darkGreen'
          >
            <Grid container>
              <Grid item md={3}>Parameter</Grid>
              <Grid item md={4}>Value</Grid>
              <Grid item md={3}>Old value</Grid>
              <Grid item md={2}>Actions</Grid>
            </Grid>
          </CustomCard>
        </Grid>
        {Object.keys(settings || {}).map((param, idx) => {
          const { name, value, old_value } = settings[param];
          const clickEdit = () => {
            this.setState({ editing: param, value });
          };
          const valueContent = editing === param
            ? <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={'value' in errors}
              inputProps={{
                value: newValue,
                style: { marginTop: 0 },
                multiline: true,
                rows: 4,
                onChange: e => this.setState({ value: e.target.value })
              }}
            />
            : <div style={{ wordBreak: 'break-all' }}>{value}</div>;
          const buttons = editing === param
            ? <Fragment>
              <Button size='small' color='transparent' round style={{ margin: 0 }} onClick={() => this.setState({ editing: false, value: '' })}>
                <Cancel color='error' />
              </Button>
              <Button size='small' color='transparent' round style={{ margin: 0 }} onClick={() => this.saveSettings({ param, value: newValue })}>
                <Save color='primary' />
              </Button>
            </Fragment>
            : <Button size='small' color='transparent' round style={{ margin: 0 }} onClick={clickEdit}>
              <Edit color='action' />
            </Button>;
          return (
            <Grid key={idx} item md={12}>
              <CustomCard cardCustomStyle={cardStyle} marginOnePx>
                <Grid container>
                  <Grid item md={3}><div style={{ wordBreak: 'break-all', fontSize: '14px', lineHeight: 1 }}>{name || param}</div></Grid>
                  <Grid item md={4}>{valueContent}</Grid>
                  <Grid item md={3}><div style={{ wordBreak: 'break-all', color: '#999' }}>{old_value}</div></Grid>
                  <Grid item md={2}>{buttons}</Grid>
                </Grid>
              </CustomCard>
            </Grid>
          );
        })}
      </Grid>
    </div>;
  }
}

SystemSettings.propTypes = {
  socket: PropTypes.object,
  settings: PropTypes.object,
  setNotify: PropTypes.func,
  clearNotify: PropTypes.func
};

const props = state => ({
  settings: state.settings
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify())
});

export default socketConnect(connect(props, actions)(SystemSettings));
