import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { browserHistory } from 'react-router';
import { AddAlert, ChevronLeft } from '@material-ui/icons/index';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ItemGrid from 'components/Grid/ItemGrid';
import SelectInput from 'components/SelectInput';
import Snackbar from 'components/Snackbar/Snackbar';
import noImage from 'assets/no_img.svg';
import sendImage from 'handler/sendImage';
import Calendar from 'components/Calendar';
import Select from 'components/Select';

class ModelForm extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    const id = 'id' in params && params.id ? params.id : 'add';
    const modelName = 'modelName' in params && params.modelName ? this.formatModelName(params.modelName) : '';
    this.state = {
      id,
      modelName,
      errors: {},
      attributes: {},
      labels: {},
      image: {},
      needImageUpload: '',
      message: '',
      note: false,
      color: false,
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getFormData(modelName, id);
  }

  formatModelName = (name, delimiter = '') => {
    const capitalize = str => `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`;
    return name.split('-').reduce((acc, cur) => `${acc}${capitalize(cur)}${delimiter}`, '');
  };

  componentWillReceiveProps(next) {
    const nextModelName = this.formatModelName(next.params.modelName);
    const nextId = 'id' in next.params && next.params.id ? next.params.id : 'add';
    const { modelName, id } = this.state;
    if ((nextModelName !== modelName || id !== nextId) && this.ModelFormRef) {
      this.setState({ modelName: nextModelName, id: nextId });
      this.getFormData(nextModelName, nextId);
    }
  }

  showNotification(message, color = 'info') {
    if (this.ModelFormRef) {
      this.setState({ note : true, message, color });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  listener = ({ type, data }) => {
    if (this.ModelFormRef) {
      switch (type) {
        case 'getOk':
          this.setState(data);
          break;
        case 'setOk':
          const { needImageUpload, modelName, image } = this.state;
          const { modelName: routeModelName } = this.props.params;
          const saved = (id, message) => {
            browserHistory.push(`/${this.props.user.type}/model/${routeModelName}/edit/${id}`);
            this.showNotification(message);
          };
          if (needImageUpload) {
            this.saveImage(modelName, needImageUpload, data.id, image, () => {
              saved(data.id, data.message);
            });
          } else {
            saved(data.id, data.message);
          }
          break;
        case 'setErr':
          const { errors } = data || {};
          if (this.state.modelName === 'Ingredient' && (errors || {}).name) {
            this.showNotification(errors.name[0], 'warning');
          }
          this.setState({ errors });
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('other_tables', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('other_tables', this.listener);
  }

  getFormData = (modelName, id) => this.props.socket.emit('other_tables', { type: 'get', data: { modelName, id } });

  submit = () => {
    const { modelName, labels, image, id } = this.state;
    const data = { ...this.state.attributes };
    const attribute = Object.keys(labels).filter(label => labels[label].type === 'image')[0];
    if (attribute && Object.keys(image).length) {
      delete data['attribute'];
    }
    this.setState({ needImageUpload: attribute });
    this.sendData(modelName, data, id);
  };

  sendData = (modelName, attributes, id) => {
    this.props.socket.emit('other_tables', {
      type: 'set',
      data: {
        modelName,
        attributes,
        id
      }
    });
  };

  saveImage = (modelName, attribute, id, image, callback) => {
    const dataForm = new FormData();
    dataForm.append('access_token', this.props.user.token);
    dataForm.append('type', 'modelImage');
    dataForm.append('modelName', modelName);
    dataForm.append('id', id);
    dataForm.append('attribute', attribute);
    dataForm.append(`${modelName}[${attribute}]`, image);
    sendImage.call(this, dataForm, callback);
  };

  changeForm = (name, value) => this.setState({ attributes: { ...this.state.attributes, [name]: value } });

  renderSimpleInput = (name, value, label, error, change, itemProps = {}, type = 'text') => {
    const { min, max, multiline, rows, mul, readonly } = itemProps;
    let onChange = e => change(e.target.name, e.target.value);
    if (type === 'text' && max) {
      const maxVal = max || Infinity;
      onChange = e => {
        const { name, value } = e.target;
        change(name, value.length > maxVal ? value.substr(0, maxVal) : value);
      };
    }
    if (type === 'number' && ('max' in itemProps || 'min' in itemProps || mul)) {
      const maxVal = 'max' in itemProps ? +max : Infinity;
      const minVal = 'min' in itemProps ? min : -Infinity;
      const mulVal = +mul || 1;
      onChange = e => {
        const value = +e.target.value / mulVal;
        change(e.target.name, value < minVal ? minVal : (value > maxVal ? maxVal : value));
      };
    }
    return (<CustomInput
      labelText={label}
      formControlProps={{
        fullWidth: true
      }}
      error={error}
      inputProps={{
        value: (type === 'number' && mul ? value * mul : value) || '',
        disabled: readonly,
        multiline,
        rows,
        type,
        name,
        onChange
      }}
    />);
  };

  renderSimpleDateInput = (name, value, label, error, change, itemProps = {}) => {
    const { format } = itemProps;
    return (<Calendar
      controlled
      onChange={value => change(name, value)}
      date={+value || moment().unix()}
      name={name}
      title={label || ''}
      formControlProps={{
        fullWidth: true
      }}
      format={format}
    />);
  };
  isJsonString =(str) =>{
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
  renderSelect = (name, value, label, error, change, props = {}) => {
    const multiple = !!props.multiple;
      if(multiple&&this.isJsonString(value)){
        value = JSON.parse(value);
      }
    return (<Select
      placeholder={label || ''}
      multiple={multiple}
      list={props.items}
      value={(multiple ? value : '' + value) || (multiple ? [] : '')}
      name={name}
      disabled={props.readonly}
      onChange={change}
    />);
  };
  //
  // renderSelect = (name, value, label, error, change, props = {}) => {
  //   const onChange = e => change(name, e.target.value);
  //   const multiple = !!props.multiple;
  //   return (<SelectInput
  //     labelText={label}
  //     multiple={multiple}
  //     items={props.items}
  //     value={(multiple ? value : '' + value) || (multiple ? [] : '')}
  //     name={name}
  //     empty={props.empty || ''}
  //     formControlProps={{
  //       fullWidth: true
  //     }}
  //     inputProps={{
  //       disabled: props.readonly,
  //       onChange
  //     }}
  //   />);
  // };

  onDrop(files) {
    this.setState({ image: files[0] });
  }

  renderImagePicker = (name, value, label, error, change, props = {}) => {
    const path = props.path || '';
    const image = this.state.image.preview || (path + value) || noImage;
    return (
      <Dropzone
        onDrop={(files) => this.onDrop(files)}
        style={{
          backgroundImage: `url(${image})`,
          height: '200px',
          width: 'auto',
          maxWidth: '400px',
          position: 'relative',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto'
        }}
      >
        <div>Drop file here</div>
      </Dropzone>);
  };

  renderInput(name, labels, attributes) {
    const info = labels[name];
    const value = attributes[name];
    const errors = this.state.errors || {};
    let input = '';
    if (info.length) {
      input = this.renderSimpleInput(name, value, info, name in errors, this.changeForm);
    } else {
      const { type, label, props, middleware } = info;
      let mul = 1;
      let max = Infinity;
      if (middleware === 'formatVouchers') {
        const { formatter } = props;
        const typesProps = formatter[attributes.type] || {};
        mul = typesProps.mul || 1;
        max = typesProps.max || Infinity;
      }
      switch (type) {
        case 'text':
          input = this.renderSimpleInput(name, value, label, name in errors, this.changeForm, props);
          break;
        case 'number':
          const onChange = (_name, _value) => this.changeForm(_name, (_value > max ? max : _value) / mul);
          input = this.renderSimpleInput(name, value * mul, label, name in errors, onChange, props, 'number');
          break;
        case 'select':
          input = this.renderSelect(name, value, label, name in errors, this.changeForm, props);
          break;
        case 'switch':
          input = this.renderSelect(name, '' + value, label, name in errors, this.changeForm, { items: { 0: 'No', 1: 'Yes' } });
          break;
        case 'image':
          input = this.renderImagePicker(name, value, label, name in errors, this.changeForm, props);
          break;
        case 'date':
          input = this.renderSimpleDateInput(name, value, label, name in errors, this.changeForm, props);
          break;
      }
    }
    return input;
  }

  renderFields(attributes, labels) {
    return Object.keys(labels).map((attribute, key) => {
      if (attribute in attributes) {
        const params = labels[attribute].params || {};
        return (<ItemGrid key={key} xs={params.xs || 12} sm={params.sm || 12} md={params.md || 12}>
          {this.renderInput(attribute, labels, attributes)}
        </ItemGrid>);
      }
    });
  }

  render() {
    const { attributes, labels, message, note, closeNote, color } = this.state;
    const { customBackLink, params } = this.props;
    const { modelName } = params;
    const backLink = customBackLink || `/admin/model/${modelName}`;
    return (
      <div ref={el => (this.ModelFormRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Button color='darkBlue' size='medium' onClick={() => browserHistory.push(backLink)}><ChevronLeft /></Button>
            <RegularCard
              cardTitle={this.formatModelName((modelName=='manage-pd')?'Support Staff: Name':modelName, ' ')}
              cardSubtitle='Edit info'
              headerColor='darkBlue'
              content={
                <div>
                  <Grid container>
                    {this.renderFields(attributes, labels)}
                  </Grid>
                </div>
              }
              footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
            />
          </ItemGrid>
        </Grid>
        <Snackbar
          place='tc'
          color={color || 'info'}
          icon={AddAlert}
          message={message}
          open={note}
          closeNotification={closeNote}
          close
        />
      </div>
    );
  }
}

ModelForm.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  customBackLink: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user
});

export default socketConnect(connect(mapStateToProps, null)(ModelForm));
