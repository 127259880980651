import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';

class LogFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        sort: '-id'
      }
    };
  }

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['typeList', 'dietList', 'productsFull', 'dishLabels','sidesTypes','dietListFull'].filter(el => !(el in dataArray));
    if (types.length) {
      getArrays(socket, types);
    }
  }

//   componentWillReceiveProps(next) {
//     if (this.FilterRef && next.offset !== this.state.offset) {
//       this.setState({ offset: next.offset });
//     }
//   }

  render() {
    const { filters, offset } = this.state;
    const { dataArray, errors, applyFilters } = this.props;
    const onChange = e => {
      const { name, value } = e.target;
      this.setState({ filters: { ...filters, [name]: value }, offset: name === 'sort' ? offset : 0 });
    };
    const { dietList, typeList: originTypeList } = dataArray;
    // console.log(dietList);
    let logTypes = {
        'custom_plan': 'Main Dish List Update',
        'default_dish':'Default Dish Update',
        'custom_plan_sides':'SideDish List Update',
        'default_dish_sides': 'Default SideDish List Update'
        };
    const typeList = originTypeList && Object.keys(originTypeList).reduce((acc, cur) => ({ ...acc, [+cur]: originTypeList[+cur].title }), {});
    const rFlag = !!(dietList  && typeList);
    return rFlag ? (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
         <SelectInput
            labelText='Filter by type'
            items={{ '': 'All', ...logTypes }}
            value={filters.logtype || ''}
            error={'logtype' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '15%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'logtype',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by program'
            items={{ '': 'All', ...dietList }}
            value={filters.dietId || ''}
            error={'dietId' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '15%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'dietId',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by dish or meal type'
            items={{ '': 'All', ...typeList }}
            value={filters.typeId || ''}
            error={'typeId' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '15%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'typeId',
              onChange
            }}
          />
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters({ sort: '-id' }, offset);
              this.setState({ filters: { sort: '-id' } });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    ) : null;
  }
}

LogFilter.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  errors: PropTypes.object,
  applyFilters: PropTypes.func.isRequired
};

LogFilter.defaultProps = {
  errors: {}
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props, null)(LogFilter));
