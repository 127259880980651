import React, { Component, Children, cloneElement } from 'react';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Link } from 'react-router';
import { setBackLocation } from 'store/backLoc';

class Finances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { title: 'Sales', slug: 'sales' },
        { title: 'Vouchers', slug: 'vouchers' },
        { title: 'Refunds', slug: 'refunds' },
        { title: 'Accounting', slug: 'accounting' },
        { title: 'Payment Sales', slug: 'dailysales'},
        { title: 'Daily & Monthly Sales', slug: 'revenuesales'},
        { title: 'Carry-forward', slug: 'carry-forward'},
        { title: 'Payment Analysis', slug: 'payment-analysis'}
      ],
      type: '',
      filters: {}
    };
  }

  onClickExport = () => {
    const { filters, type } = this.state;
    this.props.socket.emit('export', { type, data: { filters } });
  };

  render() {
    const { tabs, type } = this.state;
    const { location, setBackLocation } = this.props;
    const buttons = tabs.map(({ title, slug }, idx) => {
      const to = `/admin/finances/${slug}`;
      const curPath = location.pathname.includes(to);
      return <Link to={`${to}${slug === 'vouchers' ? '/personal' : ''}`} key={idx} onlyActiveOnIndex={false}>
        <Button color={curPath ? 'darkBlue' : 'white'} onClick={() => setBackLocation('/admin')}>{title}</Button>
      </Link>;
    });
    const children = this.props.children && Children.map(
      this.props.children,
      child => cloneElement(child, {
        setExport: ex => this.setState(ex)
      })
    );
    return (
      <Grid container>
        <div className='tabsCustom'>
          <Grid item md={12}>
            {buttons}
            {['sales', 'refunds'].includes(type) && <Button color='success' size='large' pullRight onClick={this.onClickExport}>Export to excel</Button>}
          </Grid>
        </div>
        <div className='afterTabsCustom full-w'>
          <Grid item md={12}>
            {children}
          </Grid>
        </div>
      </Grid>
    );
  }
}

Finances.propTypes = {
  socket: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  setBackLocation: PropTypes.func
};

const actions = dispatch => ({
  setBackLocation: loc => dispatch(setBackLocation(loc))
});

export default socketConnect(connect(null, actions)(Finances));
