export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';

const initialState = {
  token: false,
  language: (navigator.languages && navigator.languages[1]) || navigator.language || navigator.userLanguage,
  lat: 0,
  lng: 0
};

export function userUpdate(user) {
  return {
    type    : USER_UPDATE,
    payload : user
  };
}

export function userLogin(user) {
  return {
    type    : USER_LOGIN,
    payload : user
  };
}

export function userLogout(pl) {
  return {
    type    : USER_LOGOUT,
    payload : pl
  };
}

export default function userReducer(state = initialState, action) {
  if (action.type === USER_LOGIN) {
    return action.payload;
  }
  if (action.type === USER_UPDATE) {
    return { ...state, ...action.payload };
  }
  if (action.type === USER_LOGOUT) {
    return { ...initialState, ...action.payload };
  }
  return state;
}
