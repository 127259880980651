import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

class LeadModal extends Component {
  constructor(props) {
    super(props);
    
    const {thelead} = props;
    if (typeof(thelead) != 'undefined') {
      var type = null;
      var quality = null;
      var signed = null;
      if (thelead.type) {
        type = thelead.type.toString();
      } 
      if (thelead.quality) {
        quality = thelead.quality.toString();
      }
      if (thelead.signed) {
        signed = thelead.signed.toString();
      }
      var comment = thelead.notes;
      var name = thelead.name;
      var email = thelead.email;
      var phone = thelead.phone;
      var leadId = thelead.id;

      console.log('=========');
      console.log(thelead);
      console.log('=========');
    } else {
      var type = null;
      var quality = null;
      var signed = null;
      var comment = '';
      var name = '';
      var email = '';
      var phone = '';
      var leadId = null;
    }
    this.state = {
      leadId: leadId,
      type: type,
      quality: quality,
      signed: signed,
      comment: comment,
      name: name,
      email: email,
      phone: phone,
    };
  }

  render() {
    const { type, quality, comment, name, email, phone, signed, leadId } = this.state;

    console.log(type);
    console.log(quality);
    console.log(signed);

    console.log(typeof(signed));

    const { save, orderId } = this.props;
    console.log(orderId);

    const leadType = [
      '',
      'Meal plan', 
      'Juice plan', 
      'Online orders', 
      'Groceries', 
      'Catering', 
      'Desserts', 
      'Snackboxes',
    ];

    const leadQuality = [
      '',
      'Genuine', 
      'Will Call Back', 
      'Bad Lead',
    ];

    const signedList = [
      '',
      'Ara',
      'Rawda',
      'Rayana',
      'Justine',
      'Sarath',
      'Edward',
      'John',
      'Sarath',
      'Paolo',
      'Lany',
      'Bayan',
    ];

    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Add Leads</Text>
            </Grid>
            <Grid item md={6} className='padding-modal'>
              <CustomInput
                labelText='Name'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name || '',
                  onChange: e => this.setState({ name: e.target.value.length < 64 ? e.target.value : name }),
                  multiline: false,
                  rows: 1
                }}
              />
            </Grid>
            <Grid item md={6} className='padding-modal'>
              <CustomInput
                labelText='E-mail'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: email || '',
                  onChange: e => this.setState({ email: e.target.value.length < 64 ? e.target.value : email }),
                  multiline: false,
                  rows: 1
                }}
              />
            </Grid>
            <Grid item md={6} className='padding-modal'>
              <CustomInput
                labelText='Phone'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: phone || '',
                  onChange: e => this.setState({ phone: e.target.value.length < 64 ? e.target.value : phone }),
                  multiline: false,
                }}
              />
            </Grid>
             <Grid item md={6} className='padding-modal'>
                  <SelectInput
                    labelText='Assigned to'
                    items={signedList || {}}
                    value={signed || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({signed: e.target.value})
                    }}
                  />
            </Grid>
            <Grid item md={6} className='padding-modal'>
                  <SelectInput
                    labelText='Type'
                    items={leadType || {}}
                    value={type || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({type: e.target.value})
                    }}
                  />
            </Grid>
            <Grid item md={6} className='padding-modal'>
                  <SelectInput
                    labelText='Quality'
                    items={leadQuality || {}}
                    value={quality || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({quality: e.target.value})
                    }}
                  />
            </Grid>
            <Grid item md={12} className='padding-modal'>
              <CustomInput
                labelText='Notes'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: comment || '',
                  onChange: e => this.setState({ comment: e.target.value.length < 1023 ? e.target.value : comment }),
                  multiline: true,
                  rows: 6
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(name, email, phone, type, quality, comment, signed, leadId)}>Save</Button>
        </div>
      </div>
    );
  }
}

LeadModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default LeadModal;