import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import { Table, TableBody, TableCell, TableRow, IconButton, Tooltip, withStyles } from 'material-ui';
import TableHead from 'material-ui/Table/TableHead';
import moment from 'moment';
import Filters from './Filters';
import Text from 'components/Typography/Text';
import { Done, SettingsBackupRestore } from '@material-ui/icons';
import tasksStyle from 'assets/jss/material-dashboard-react/tasksStyle.jsx';

class Refunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      xyz: false,
      orderBy: 'start',
      orderDir: '-',
      status: '0'
    };
    this.getRefunds();
  }

  componentWillMount() {
    this.props.socket.on('finances', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('finances', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 50);
    this.props.setExport({ type: 'refunds' });
  }

  listener = ({ type, status, data }) => {
    if (this.RefundsRef) {
      if (status === 'ok') {
        if (['listRefunds', 'changeRefundStatus'].includes(type)) {
          this.setState(data);
        }
      } else {
        console.error(data);
      }
    }
  };

  getRefunds = () => this.props.socket.emit('finances', { type: 'listRefunds', data: {} });

  sort = (a, b) => {
    const results = { true: 1, false: -1 };
    const { orderBy, orderDir } = this.state;
    const getItemData = (item, prefix) => {
      const { username, sum, order_info } = item;
      const { start, end } = order_info || {};
      const prop = name => `${prefix}${name}`;
      return {
        [prop('username')]: username,
        [prop('sum')]: sum,
        [prop('start')]: start,
        [prop('end')]: end
      };
    };
    const obj = { ...getItemData(a, 'a_'), ...getItemData(b, 'b_') };
    return results[orderDir === '+' ? obj[`a_${orderBy}`] > obj[`b_${orderBy}`] : obj[`b_${orderBy}`] > obj[`a_${orderBy}`]] || 0;
  };

  applyFilters = ({ order, nameOrPhone, status }) => {
    const orderArr = [...order];
    const orderDir = orderArr.splice(-1).join('');
    const orderBy = orderArr.join('');
    const { orderDir: oldOrderDir, orderBy: oldOrderBy, nameOrPhone: oldNameOrPhone, status: oldStatus } = this.state;
    let newState = {};
    if (orderDir !== oldOrderDir || orderBy !== oldOrderBy) {
      newState = { orderBy, orderDir };
    }
    if (nameOrPhone !== oldNameOrPhone) {
      newState.nameOrPhone = nameOrPhone;
    }
    if (status !== oldStatus) {
      newState.status = status;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
    this.props.setExport({ type: 'sales', filters: newState });
  };

  getActions = (refund_id, status) => {
    const { classes, socket } = this.props;
    const changeStatus = () => {
      socket.emit('finances', {
        type: 'changeRefundStatus',
        data: { refund_id, status: !status }
      });
    };
    return <Tooltip
      id='tooltip-top'
      title={`Mark${status ? ' not ' : ' '}refunded`}
      placement='top'
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        onClick={changeStatus}
        className={classes.tableActionButton}
      >
        {status ? <SettingsBackupRestore /> : <Done />}
      </IconButton>
    </Tooltip>;
  };

  renderContent = (list = []) => {
    const { nameOrPhone, status } = this.state;
    const filtered = list.filter(({ username, phone, payed }) => {
      const lower = (nameOrPhone || '').toLowerCase();
      const matchByNameOrPhone = nameOrPhone ? (username || '').toLowerCase().includes(lower) || (phone || '').toLowerCase().includes(lower) : true;
      const matchByStatus = status === 'all' ? true : +status === +payed;
      return matchByNameOrPhone && matchByStatus;
    });
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getExcludeWeek =(exclude_week_day)=>(exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const rows = filtered.sort(this.sort).map((item, idx) => {
      const { id, username, phone, sum, payed, payment_date, order_info } = item;
      const { start, end, diet, plan, days_count, exclude_week_day } = order_info || {};
      return <TableRow key={idx}>
        <TableCell>
          <Text inline={false} bold>{`${idx + 1}. ${username}`}</Text>
          <Text inline={false} style={{ fontSize: 'smaller' }}>{phone}</Text>
        </TableCell>
        <TableCell>
          <Text inline={false} bold>{diet}</Text>
          <Text inline={false} style={{ fontSize: 'smaller' }}>{(days_count<=0)?plan:days_count+' Days - '}{(days_count>0)?getExcludeWeek(exclude_week_day):''}</Text>
        </TableCell>
        <TableCell>{`${moment.unix(start).utc().format('DD MMM, YYYY')} - ${moment.unix(end).utc().format('DD MMM, YYYY')}`}</TableCell>
        <TableCell>{`AED ${sum / 100}`}</TableCell>
        <TableCell>{payed ? 'Done' : 'pending'}</TableCell>
        <TableCell>{payed ? moment.unix(payment_date).format('LLL') : '-'}</TableCell>
        <TableCell>{this.getActions(id, payed)}</TableCell>
      </TableRow>;
    });
    return <Table>
      <TableHead className='primaryTableHeader roundedHeader not-align-right'>
        <TableRow id='header-row'>
          <TableCell>Client</TableCell>
          <TableCell>Order info</TableCell>
          <TableCell>Order dates</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Payed at</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
      </TableBody>
    </Table>;
  };

  render() {
    const { xyz, list } = this.state;
    return (
      <div ref={el => (this.RefundsRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
        <div className='filtesHolder finances'>
          <Filters applyFilters={this.applyFilters} />
        </div>
        <CustomCard marginOnePx>
          <div>
            {this.renderContent(list)}
          </div>
        </CustomCard>
      </div>
    );
  }
}

Refunds.propTypes = {
  classes: PropTypes.object.isRequired,
  socket: PropTypes.object,
  setExport: PropTypes.func
};

export default socketConnect(withStyles(tasksStyle)(Refunds));
