import React, { Component } from 'react';
import { browserHistory, Router } from 'react-router';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { userUpdate, userLogout } from 'store/user';
import { updateDataArray } from 'store/dataArray';
import { setSettings } from 'store/settings';
import { setCustomPlans } from 'store/customPlans';
import { setDefaultDishes } from 'store/defaultDishes';
import { setDefaultDishSides } from 'store/defaultDishSides';
import { setCustomPlanSides } from 'store/customPlanSides';
import rstr from 'randomstring';

const hosts = {
  production: 'https://healthyme.loseweight.ae',
  development: 'https://healthyme.loseweight.ae',
  undefined: 'https://healthyme.loseweight.ae'
};
const socketServerLink = hosts[process.env.NODE_ENV];
const browserId = rstr.generate(16).toUpperCase();

let socket = null;

class App extends Component {
  constructor(props) {
    super(props);
    this.events = [];
  }

  socketClientCheck = ({ type, data }) => {
    if (type === 'setUser') {
      this.props.update(data);
      socket.emit('settings', { type: 'get', data: {} });
    } else if (type === 'success') {
    } else {
      this.props.logout({ browserId: rstr.generate(16).toUpperCase() });
      browserHistory.push('/');
    }
  };

  socketLogin = ({ type, data }) => {
    if (type === 'logoutOk') {
      const { logout, user } = this.props;
      const { browserId } = data;
      user.browserId === browserId && logout({ browserId: rstr.generate(16).toUpperCase() });
      browserHistory.push('/');
    }
  };

  defaultDishesListener = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setDefaultDishes(data['defaultDishes']);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  defaultDishSidesListener = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setDefaultDishSides(data.defaultDishSides);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  socketGetArrays = ({ type, data }) => {
    if (type === 'success') {
      this.props.updateDataArray(data);
    } else {
      console.log(data);
    }
  };

  settingsListener = ({ type, status, data }) => {
    if (type === 'get' && status === 'ok') {
      this.props.setSettings(data);
    }
  };

  socketCustomPlan = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setCustomPlans(data.customPlans);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  socketCustomPlanSides = ({ type, data }) => {
    if (type === 'getOk') {
      console.error(data);
      this.props.setCustomPlanSides(data.customPlanSides);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  
  componentWillMount() {
    browserHistory.listen(() => {
      // this.props.burgerHide();
    });
    socket = io(socketServerLink, {
      transports: ['websocket']
    });
    socket.on('connect', () => {
      socket.on('client_check', this.socketClientCheck);
      socket.on('login', this.socketLogin);
      socket.on('default_dishes', this.defaultDishesListener);
      socket.on('default_dish_sides', this.defaultDishSidesListener);
      socket.on('get_arrays', this.socketGetArrays);
      socket.on('custom_plan', this.socketCustomPlan);
      socket.on('custom_plan_sides', this.socketCustomPlanSides);
      socket.on('settings', this.settingsListener);
      socket.on('export', this.exportListener);
      const { token, browserId } = this.props.user;
      if (token) {
        socket.emit('client_check', { type: 'get', data: { token, browserId } });
        const { dataArray } = this.props;
        const types = [
          'usersPrivilege',
          'gender',
          'types',
          'language',
          'ingredientList',
          'ingredientListFull',
          'sidesTypes',
          'suppliers',
          'dietList',
          'dietListFull',
          'deliveryTime',
          'emirate',
          'planList',
          'typeList',
          'priceList',
          'couponTypes',
          'products',
          'blogCategories'
        ].filter(el => !Object.keys(dataArray).includes(el));
        if (types.length) {
          this.getArrays(types);
        }
      }
    });
  }

  getArrays = (type) => {
    socket.emit('get_arrays', {
      type: 'get',
      data: { type }
    });
  };

  exportListener = ({ type, status, data }) => {
    if (status === 'ok') {
      if (['sales', 'refunds'].includes(type) && data.file) {
        window.open(data.file);
      }
    } else {
      console.error(data);
    }
  };

  componentWillUnmount() {
    socket.removeListener('default_dishes', this.defaultDishesListener);
    socket.removeListener('default_dish_sides', this.defaultDishSidesListener);
    socket.removeListener('client_check', this.socketClientCheck);
    socket.removeListener('custom_plan', this.socketCustomPlan);
    socket.removeListener('custom_plan_sides', this.socketCustomPlanSides);
    socket.removeListener('login', this.socketLogin);
    socket.removeListener('get_arrays', this.socketGetArrays);
    socket.removeListener('settings', this.settingsListener);
    socket.removeListener('export', this.exportListener);
    socket.disconnect();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.props.update({ browserId });
  }

  render() {
    const { routes, persistor, store } = this.props;
    return (
      <PersistGate persistor={persistor}>
        <SocketProvider socket={socket}>
          <Provider store={store}>
            <Router history={browserHistory} children={routes} />
          </Provider>
        </SocketProvider>
      </PersistGate>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  update: PropTypes.func,
  logout: PropTypes.func,
  setSettings: PropTypes.func,
  setCustomPlans: PropTypes.func,
  setCustomPlanSides: PropTypes.func,
  setDefaultDishes: PropTypes.func,
  setDefaultDishSides: PropTypes.func,
  updateDataArray: PropTypes.func
};

const actions = dispatch => ({
  logout: (pl) => dispatch(userLogout(pl)),
  update: user => dispatch(userUpdate(user)),
  updateDataArray: arr => dispatch(updateDataArray(arr)),
  setCustomPlans: arr => dispatch(setCustomPlans(arr)),
  setCustomPlanSides: arr => dispatch(setCustomPlanSides(arr)),
  setDefaultDishes: obj => dispatch(setDefaultDishes(obj)),
  setDefaultDishSides: obj => dispatch(setDefaultDishSides(obj)),
  setSettings: obj => dispatch(setSettings(obj))
});

const props = state => ({
  user: state.user,
  dataArray: state.dataArray
});

export default connect(props, actions)(App);
