import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Paginator from 'components/Paginator';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';
import { browserHistory } from 'react-router';

class List extends Component {
  
  getRowsByMeal(newdata) {
    const { name, perprice, foodcostper, foodcost, saleprice, dish_id} = newdata;
    var i=0;
    const data = {
      title: <Text customColor='#0068c1' inline bold>{name} - {dish_id}</Text>,
      foodcost: <Text color='warning' inline bold>{foodcost}</Text>,
      sellingprice: <Text color='warning' inline bold>{saleprice}</Text>,
      foodcostper: <Text color='warning' inline bold>{foodcostper}%</Text>
    }
    return data;
  }
  callBack =(dish_id)=>{
    browserHistory.push(`/admin/menu/edit/${dish_id}`);
  }
  renderContent() {
    const { list,dictionaries, splitter } = this.props;
    const { type, values } = splitter;
    const { typeList,productsFull } = dictionaries;
    // console.log(productsFull);
    let content = null;
    let fcontent = [];
    let cols = {};
    let rows = {};
    let foodcostpertotal = 0;
    let foodcosttotal = 0;
    var i=0;
    let key = 0;
        cols = {
          title: 'Dish Name',
          foodcost: 'Cost Price p/unit',
          sellingprice: 'Selling Price p/unit',
          foodcostper: 'Food Cost %'
        };
        rows=Object.keys(list).reduce((acc, cur) => {
          const { name, perprice, foodcostper, foodcost, saleprice, dish_id} = list[cur];
          const { title } = productsFull[dish_id] || {};
          var i=0;
          var color='green';
          if(foodcostper>=31){
            color='red';
          }else if(foodcostper<=30 && foodcostper>=25){
            color='orange';
          }
          const data = {
            title: <Text customColor={color} inline bold ><span onClick={() => this.callBack(dish_id)}>{title || name} - {dish_id}</span></Text>,
            foodcost: <Text color='warning' inline bold>{foodcost.toFixed(2)}</Text>,
            sellingprice: <Text color='warning' inline bold>{saleprice}</Text>,
            foodcostper: <Text color='warning' inline bold>{foodcostper}%</Text>
          }
          return { ...acc, [cur]: data };
        }, {});
        content=Object.keys(rows).length ? <Table {...{ cols, rows, key}} /> : null;
        fcontent.push(content);   
    return fcontent;
  }

  render() {
    const { page, maxPages, goTo, date } = this.props;
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

List.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  splitter: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.string
};

List.defaultProps = {
  limit: 20,
  page: 1,
  maxPages: 1,
  goTo: () => {},
  splitter: {},
  date: ''
};
export default List;
