import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, Select, InputLabel, MenuItem, FormControl, withStyles } from 'material-ui';
import { Clear, Check } from '@material-ui/icons';
import Text from 'components/Typography/Text';
import { RadioButtonChecked, RadioButtonUnchecked, ErrorOutline } from '@material-ui/icons/index';
import customInputStyle from '../../assets/jss/material-dashboard-react/customInputStyle';
import cx from 'classnames';

class ChoiceSelect extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: props.value
      };
    }
    renderOptions = (options,isCheck,list)=> {
       const { name,value,onClick } = this.props;
       const content =options && options.sort().map((planId,idx) => {
        planId=parseInt(planId);
         const { title } = list[planId];
          const chk =(isCheck)? (value || []).includes(planId):(value==planId);
          return (
            <Grid item key={idx} md={2} lg={12/5}>
              <Checkbox
                checked={chk}
                icon={(!isCheck)?<RadioButtonUnchecked className='unchecked-radio' />:<Check className='unChecked' />}
                checkedIcon={(!isCheck)?<RadioButtonChecked className='checked-radio' />:<Check className='checked' />}
                onClick={() =>{ 
                  onClick(name, planId);
                  this.setState({value:planId});
                }}
              />
              <Text>{(title==undefined)?list[planId]:title}</Text>
            </Grid>
          );
        });
        return content;
      }
render() {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    value,
    empty,
    items,
    multiple,
    mainvalues
  } = this.props;
  // const { value } = this.state;
  const labelClasses = cx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });
  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  });
  console.log(value);
  return (
    
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl + ' inputHolder'}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Grid container>
    {this.renderOptions(mainvalues,multiple,items)}  </Grid>
    <p>Others</p>
      <Select
        multiple={multiple && Array.isArray(value)}
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          select: classes.select
        }}
        value={value}
        id={id}
        onOpen={e => {
          window.ChoiceSelect = document.getElementById(id);
        }}
        {...inputProps}
      >
        {empty && <MenuItem key='empty' value=''>
          <em>{empty}</em>
        </MenuItem>}
        {Object.keys(items).filter(el =>!mainvalues.includes(parseInt(el))).map((el, idx) => <MenuItem key={idx} value={parseInt(el)}>{items[el]}</MenuItem>)}
      </Select>
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}
}

ChoiceSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  items: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  empty: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool
};

ChoiceSelect.defaultProps = {
  id: 'custom-select',
  empty: '',
  multiple: false
};

export default withStyles(customInputStyle)(ChoiceSelect);