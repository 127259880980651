import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import './style.css';

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false,
      show: this.props.show || false
    };
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  componentWillReceiveProps(next) {
    if (this.WrapperRef && next.show !== this.state.show) {
      this.setState({ show: next.show });
    }
  }

  render() {
    const { closeButton, close, children, className } = this.props;
    const { xyz, show } = this.state;
    return (
      <div ref={el => (this.WrapperRef = el)}>
        {show && <div className='modal-back-layer' onClick={() => close()}>
          <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-wrapper-custom pd-layer b-rounded-blue ${className}`} onClick={e => e.stopPropagation()}>
            <div className={'modal-body'}>
              {closeButton && <div className='close-button modal-close-button' onClick={() => close()}>
                <Close />
              </div>}
              {children}
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  closeButton: PropTypes.bool,
  md: PropTypes.number,
  show: PropTypes.bool,
  className: PropTypes.string
};

ModalWrapper.defaultProps = {
  closeButton: true,
  md: 12,
  className: '',
  close: () => this.setState({ show: false })
};

export default ModalWrapper;
