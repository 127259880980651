export const SET_PLAN_PARAM_SIDES = 'SET_PLAN_PARAM_SIDES';
export const UPDATE_PLAN_PARAM_SIDES = 'UPDATE_PLAN_PARAM_SIDES';
export const CLEAR_PLAN_PARAM_SIDES = 'CLEAR_PLAN_PARAM_SIDES';

const initialState = {};

export function setPlanParamSides(obj) {
  return {
    type    : SET_PLAN_PARAM_SIDES,
    payload : obj
  };
}

export function updatePlanParamSides(obj) {
  return {
    type    : UPDATE_PLAN_PARAM_SIDES,
    payload : obj
  };
}

export function clearPlanParamSides() {
  return {
    type    : CLEAR_PLAN_PARAM_SIDES
  };
}

export default function planParamSidesReducer(state = initialState, action) {

  if (action.type === SET_PLAN_PARAM_SIDES) {
    return action.payload;
  }
  if (action.type === UPDATE_PLAN_PARAM_SIDES) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_PLAN_PARAM_SIDES) {
    return initialState;
  }
  return state;
}
