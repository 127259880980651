import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';

class ProfileLog extends Component {
  
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      orderlogs: [],
      isToggleOn:false
    };
    this.getProfileLogs(user_id); 
  }
  
  getProfileLogs = user_id => this.props.socket.emit('order', { type: 'getProfileLogs', data: { user_id } });

  listener = action => {
    const { type, data } = action;
    if (type === 'getProfileLogsOk') {
      this.setState({orderlogs:data.orderlogs});
      console.log(this.state);
    } else if (type === 'getProfileLogs') {
      console.err(data.message);
    }
  };

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.ProfileLogRef && next.user_id !== this.props.user_id) {
      this.getProfileLogs(next.user_id);
    }
  }
 
  render() {
    const { orderlogs,isToggleOn } = this.state;
    const { user_id } = this.props;
    var logs = [];
    console.log(this.props);
  
    
    if (orderlogs) {
      orderlogs.map( (el, idx) => {
       
        logs.push(
          <li>
          <a href="javascript:void(0);">{el.action} by {el.modified_by} : {el.user_type} </a>
        
          <a href="#" className="float-right">{moment.unix(el.created_date).format('DD MMM, YYYY h:mm:ss A')}</a>
          <p>{el.description}</p>
        </li>
            )
            
      });
    }

    return (
      <div>
      <ul className="timeline">
        {logs}
      </ul>
      
      </div>
    );
  }
}

ProfileLog.propTypes = {
  socket: PropTypes.object,
  orderlogs: PropTypes.object,
  user_id: PropTypes.number.isRequired
};
const props = state => ({
  products: state.dataArray.products
});
export default socketConnect(connect(props, null)(ProfileLog));
