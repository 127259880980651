import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import moment from 'moment';
import TouchSpin from 'components/TouchSpin';
import Text from 'components/Typography/Text';
import DateTime from 'components/DateTime';
import ToolTip from 'components/ToolTip';

class ProgressEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().utc().startOf('day'),
      changedData: {}
    };
  }

  renderDay = (props, curd) => {
    const { regDate, progress } = this.props;
    const { changedData, selectedDate } = this.state;
    const { weight, calories, waist } = progress;
    const { weight: newWeight, calories: newCalories, waist: newWaist } = changedData;
    const stamp = curd.unix();
    const date = moment.unix(regDate).startOf('day');
    let trigger = [];
    if (!curd.isBetween(date, moment(), null, '[]')) {
      props.className = `${props.className} disabled`;
      delete props.onClick;
    } else {
      if (stamp in weight || stamp in waist || stamp in calories || stamp in (newWeight || {}) || stamp in (newWaist || {}) || stamp in (newCalories || {})) {
        trigger = ['hover'];
      }
      if (curd.isSame(selectedDate)) {
        props.className = `${props.className} selected`;
      }
      props.onClick = () => {
        this.setState({ selectedDate: curd });
      };
    }
    const weightVal = ((newWeight && newWeight[stamp]) || weight[stamp]);
    const waistVal = ((newWaist && newWaist[stamp]) || waist[stamp]);
    const calVal = ((newCalories && newCalories[stamp]) || calories[stamp]);
    const valsSum = !!weightVal + !!waistVal + !!calVal;
    const filled = valsSum === 3 ? 'full' : (valsSum === 0 ? 'empty' : 'partial');
    props.className = `${props.className} ${filled}`;
    return <ToolTip
      key={props.key}
      tooltipText={
        <div>
          {weightVal && <div className='t-row'>
            <span className='regular-bold'>Weight: </span><span className='green'>{`${weightVal / 1000} kg`}</span>
          </div>}
          {waistVal && <div className='t-row'>
            <span className='regular-bold'>Waist: </span><span className='green'>{`${waistVal / 10} cm`}</span>
          </div>}
          {calVal && <div className='t-row'>
            <span className='regular-bold'>Calories: </span><span className='green'>{`${calVal} cal`}</span>
          </div>}
        </div>
      }
      tooltipDirection={'top'}
      trigger={trigger}
    >
      <td {...props}>{ curd.date() }</td>
    </ToolTip>;
  };

  render() {
    const { selectedDate, changedData } = this.state;
    const { regDate, progress, saveProgress } = this.props;
    const { weight, calories, waist } = progress;
    const { weight: newWeight, calories: newCalories, waist: newWaist } = changedData;
    const InputGroup = ({ children, label }) => <Grid item md={12} style={{ marginBottom: '30px' }}>
      <Text paragraph style={{ fontSize: 'larger' }}>{label}</Text>
      {children}
    </Grid>;
    const stamp = selectedDate.unix();
    const changeHandler = (name, value, mul = 1) => this.setState({ changedData: { ...changedData, [name]: { ...changedData[name], [+stamp]: value * mul } } });
    let prevWeight = false;
    let prevWaist = false;
    const today = moment().utc().startOf('day');
    if (selectedDate.clone().isSame(today)) {
      const yesterday = today.clone().subtract(1, 'd').unix();
      prevWeight = weight[today.unix()] ? prevWeight : weight[yesterday];
      prevWaist = waist[today.unix()] ? prevWaist : waist[yesterday];
    }
    return (
      <Grid container>
        <Grid item md={12} style={{ marginBottom: '25px' }}>
          <Text bold paragraph color={'darkBlue'} style={{ fontSize: 'x-large' }}>Fill progress: {selectedDate.format('MMM DD, YYYY')}</Text>
        </Grid>
        <Grid item md={6} style={{ padding: '0 10px' }}>
          <DateTime
            controlled
            value={selectedDate}
            onChange={() => {}}
            onViewModeChange={() => false}
            renderDay={this.renderDay}
            onNavigateBack={(a, t, c) => c.clone().subtract(a, t).endOf(t).isAfter(moment.unix(regDate))}
            onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment())}
          />
        </Grid>
        <Grid item md={6} style={{ padding: '0 10px' }}>
          <Grid container>
            <InputGroup label={'Weight'}>
              <TouchSpin
                value={((newWeight || {})[stamp] || prevWeight || weight[stamp]) / 1000}
                onChange={value => changeHandler('weight', value, 1000)}
                fractionDigits={1}
                step={0.1}
                min={0}
                max={500}
                useInput
                controlled
              />
            </InputGroup>
            <InputGroup label={'Waist'}>
              <TouchSpin
                value={((newWaist || {})[stamp] || prevWaist || waist[stamp]) / 10}
                onChange={value => changeHandler('waist', value, 10)}
                fractionDigits={1}
                step={0.1}
                min={0}
                max={300}
                useInput
                controlled
              />
            </InputGroup>
            <InputGroup label={'Calories'}>
              <TouchSpin
                value={((newCalories || {})[stamp] || calories[stamp])}
                onChange={value => changeHandler('calories', value)}
                step={1}
                min={0}
                max={10000000}
                useInput
                controlled
              />
            </InputGroup>
          </Grid>
          <Grid item md={12}>
            <Text bold paragraph style={{ fontSize: 'medium' }}>Shift: &#xd7;10; Ctrl: &#xd7;100; Ctrl+Shift: &#xd7;1000.</Text>
          </Grid>
          <Grid item md={12}>
            <Button color={'darkBlue'} size={'large'} onClick={() => saveProgress(changedData)} pullRight style={{ marginTop: '30px' }}>Save</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ProgressEdit.propTypes = {
  saveProgress: PropTypes.func.isRequired,
  progress: PropTypes.object.isRequired,
  regDate: PropTypes.number.isRequired
};

export default ProgressEdit;
