import React, { Component } from 'react';

class ModalWrapper extends Component {
  render() {
    return (<div style={{}} className='not-found'>
      <p>404</p>
      <p>Not Found!</p>
    </div>);
  }
}

export default ModalWrapper;
