import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Text from 'components/Typography/Text';
import NoImg from 'assets/img/noimg.svg';
import { getArrays } from 'utils';

class ViewRecipe extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      id,
      dishIngredients:[]
    };
    this.getDishIngredients(id);
  }

  listener = ({ data, type }) => {
    if (this.ViewRecipeRef) {
      switch (type) {
        case 'dishIngredientsOk':
          this.setState({dishIngredients: data.dishData});
          break;
        case 'dishIngredientsErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };

  componentWillMount() {
  
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  componentDidMount() {
   const { socket, dataArray } = this.props;
    const types = ['typeList', 'productsFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  getDishIngredients = dishId => this.props.socket.emit('kitchen', { type: 'dishIngredients', data: { dishId } });
  renderIngredientFields = () => {
    const { dataArray } = this.props;
    const { dishIngredients } = this.state;
    const { ingredientListFull } = dataArray;  
    const table = ingredientListFull && Object.keys(dishIngredients).map((el,index) => {
      const { name , unit_name } = ingredientListFull[el];
      return (
          <tr key={index}><td>{name}</td><td>{dishIngredients[el].raw_quantity} {unit_name}</td><td>{dishIngredients[el].quantity} {unit_name}</td></tr>
      );
    });
    return table;
  }
  renderContent = () => {
    
  }
  render() {
        const {  dataArray }  = this.props;
        const {  id }  = this.state;
        const { productsFull,portionSizeList,dishPacks } = dataArray;
        const RecipeInfo = ()=>{
          const {title,prepare_method,image,ingredients,pack_image,type , pack}=productsFull[id] || {};        // console.log(productsFull[product_id]);
        var thead ='';
        
        if(type=='composite'){
          var thead = (portionSizeList) ? [<th>Portion Size</th>, Object.keys(portionSizeList).map((el,index) => <th>{portionSizeList[el]}</th>)] : [];
          var thead1=thead;
        }else{
          var thead1 =[<th>Ingredient</th>, <th>Raw Qty</th>, <th>Prepared Qty</th>];
        }
       

        if(type=='composite'){
          thead1.push(<th>Total</th>);
        }
        
        var pimage = (<img  src={NoImg}></img>);
        if (image) {
            pimage = (<img  src={image}></img>);
        } 
        var ppack_image = (<img  src={NoImg}></img>);
        if (pack_image) {
            ppack_image = (<img src={pack_image}></img>);
        } 
        return <div ><div className="receipe-container"><div className="rec-h-holder">
        <h2>{title}</h2>
      </div>
      <div className="rec-body clearfix row">
          <div className="col-lg-6 rec-img-lt">
             {pimage}
          </div>
          <div className="col-lg-6 rec-img-lt d-flex align-items-center">
            {ppack_image}
          </div>
      </div>
      <div className="rec-table-wrapper">
      <table className="table table-bordered">
  <thead className="recp-table-header">
    <tr>
      {thead}
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Packaging Container</th>
      <td>{dishPacks&&dishPacks[pack]!=undefined&&dishPacks[pack]}</td>
    </tr>
  </tbody>
</table>
</div>
<div className="rec-table-wrapper">
<table className="table table-bordered">
<thead className="recp-table-header">
<tr key={0}>
{thead1}
</tr>
</thead>
<tbody>
{type=='composite' && <tr>
<th scope="row">Enter Qty</th>
<td>
  <div className="input-group input-group-sm mb-3">

</div>
</td>
<td><div className="input-group input-group-sm mb-3">

</div></td>
<td><div className="input-group input-group-sm mb-3">

</div></td>
<td >70g</td>
</tr>}
{this.renderIngredientFields()}
</tbody>
</table>
</div>
<div className="rec-table-wrapper">
 
<div className="rec-h-holder">
<h2>Preparation Method</h2>
              </div>
              <Text style={{whiteSpace: 'pre-line'}}>{prepare_method}</Text>
           
  </div></div></div>;
        }
       const content = (productsFull!=undefined)?<RecipeInfo />:'';
        return <div ref={el => (this.ViewRecipeRef = el)}>
          {content}
        </div>;
  }
}

ViewRecipe.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired, 
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(ViewRecipe));
