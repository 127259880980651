import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Close } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';

class CompositeIngred extends Component {
  constructor(props) {
   super(props);
    this.state = {
      ingredients: props.ingredients || {},
      order: (props.ingredients && Object.keys(props.ingredients)) || []
    }; 
  }
  renderQuantity=(data,id,mealid)=>{
     const { ingredients } = this.state;
     const {quantity}=data[mealid]||{};
     console.log("ID="+id);
     console.log("Meal ID="+mealid);
     console.log(quantity);
    //  console.log(id);
    //  console.log(mealid);
     const qtyChange = (e,mealId, min = -Infinity, max = Infinity) => {
      const { name, value } = e.target;
      // console.log(ingredients);
      id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id],[mealId]:{...ingredients[id][mealId], [name]: value < min ? min : (value > max ? max : value) } } } });
    };
    return <td>
    {<CustomInput
      labelText={'Quantity (Prepared)'}
      formControlProps={{
        fullWidth: true
      }}
      error={false}
      inputProps={{
        value: quantity,
        type: 'number',
        name: 'quantity',
        onChange: e => qtyChange(e,id, 0, 10000)
      }}
    />}
  </td>;
  }
  renderIngredientFields = (id) => {
    const { ingredientList, ingredientListFull,portionSizeList } = this.props;
    
    const { ingredients, order: oldOrder } = this.state;
    // console.log(id);
    console.log(ingredients);
    const list = Object.keys(ingredientList).reduce((acc, cur) => (!ingredients[cur] || cur === id) ? { ...acc, [cur]: ingredientList[cur] } : acc, {});
    const onChange = val => {
      let ings = { ...ingredients };
      let order = [...oldOrder];
      if (id && id in ings) {
        ings = { ...ings, [val]: ings[id] };
        delete ings[id];
        order[order.indexOf(id)] = val;
      } else {
        ings = { ...ings, [val]: {} };
        order = [...order, val];
      }
      this.setState({ ingredients: ings, order });
    };
    const { raw_quantity, quantity, allergen } = ingredients[id] || {};
    const qtyChange = (e,mealId, min = -Infinity, max = Infinity) => {
      const { name, value } = e.target;;
      // id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id],[mealId]:{...ingredients[id][mealId], [name]: value < min ? min : (value > max ? max : value) } } } });
      id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id], [name]: value < min ? min : (value > max ? max : value) } } });
    };
    const remove = () => {
      if (id in ingredients) {
        let ings = { ...ingredients };
        delete ings[id];
        this.setState({ ingredients: ings, order: oldOrder.filter(el => el !== id) });
      }
    };
    const ingerdata=(ingredients[id]!=undefined)?ingredients[id]:{};
    return (
      <tr>
        <td><Select
            placeholder={'Ingredients'}
            list={list}
            value={id || ''}
            name={'components'}
            onChange={(name, value) => onChange(value)}
          /></td>
      {Object.keys(portionSizeList).map(mealId => (
        <td>
          {<CustomInput
            labelText={'Quantity (Prepared)'}
            formControlProps={{
              fullWidth: true
            }}
            error={false}
            inputProps={{
              value: (ingerdata['portion_'+mealId]!=undefined)?ingerdata['portion_'+mealId]:0,
              type: 'number',
              name: 'portion_'+mealId,
              onChange: e => qtyChange(e,mealId, 0, 10000)
            }}
          />}
        </td>
        // this.renderQuantity(ingerdata,id,mealId)
      ))}
      <td>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</td>
    </tr>
    );
  };

  render() {
    const { portionSizeList } = this.props;
    const { order } = this.state;
    const thead = portionSizeList ? [<td>Portion Size</td>, Object.keys(portionSizeList).map((el,index) => <td>{portionSizeList[el]}</td>)] : [];
    return (
      <Grid container ref={el => (this.CompositeIngRef = el)}>
        <table className='prices-table'>
                <thead>
                  <tr>
                    {thead}
                  </tr>
                </thead>
                <tbody>
                {order.map(this.renderIngredientFields)}
                {this.renderIngredientFields()}
                </tbody>
        </table> 
       
      </Grid>
    );
  }
}

CompositeIngred.propTypes = {
  ingredientList: PropTypes.object.isRequired,
  ingredientListFull: PropTypes.object.isRequired,
  portionSizeList: PropTypes.object,
  ingredients: PropTypes.object
};

export default CompositeIngred;
