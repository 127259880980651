import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import './style.css';

class InputCalendar extends Component {
  constructor(props) {
    super(props);
    const { value, defaultValue } = props;
    this.value = value || defaultValue;
    this.state = {
      value: this.value
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    const { value: thisValue } = this.props;
    if (value !== thisValue) {
      this.setState({ value });
    }
  }

  changeDate = date => {
    const { controlled, onChange } = this.props;
    const value = date.clone().startOf('d');
    controlled ? onChange(value) : this.setState({ value });
  };

  render() {
    const { className, classNameCalendar, locale, viewMode, renderDay, renderMonth, dateValidator,
      renderYear, defaultValue, onViewModeChange, onNavigateForward, onNavigateBack } = this.props;
    const { value } = this.state;
    return (
      <div className={className}>
        <Datetime
          locale={locale}
          className={classNameCalendar}
          onChange={this.changeDate}
          viewMode={viewMode}
          input={false}
          value={value}
          timeFormat={false}
          utc
          isValidDate={dateValidator}
          renderDay={renderDay}
          renderMonth={renderMonth}
          renderYear={renderYear}
          defaultValue={defaultValue}
          onViewModeChange={onViewModeChange}
          onNavigateBack={onNavigateBack}
          onNavigateForward={onNavigateForward}
        />
      </div>
    );
  }
}

InputCalendar.propTypes = {
  controlled: PropTypes.bool,
  viewMode: PropTypes.oneOf(['years', 'months', 'days', 'time']),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  classNameCalendar: PropTypes.string,
  name: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  renderDay: PropTypes.func,
  onNavigateForward: PropTypes.func,
  onNavigateBack: PropTypes.func,
  renderMonth: PropTypes.func,
  renderYear: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  showCalendarForSure: PropTypes.bool,
  onViewModeChange: PropTypes.func,
  dateValidator: PropTypes.func
};

InputCalendar.defaultProps = {
  controlled: false,
  viewMode: 'days',
  locale: 'GB',
  className: '', // 'search-profile-main-cal form-input calendar-input pointer',
  classNameCalendar: '',
  name: '',
  onChange: () => {},
  onViewModeChange: () => true,
  onNavigateBack: () => true,
  onNavigateForward: () => true,
  dateValidator: () => true,
  renderDay: (props, currentDate, selectedDate) => {
    return <td {...props}>{ currentDate.date() }</td>;
  },
  renderMonth: (props, month, year, selectedDate) => {
    return <td {...props}>{ moment().month(month).format('MMM') }</td>;
  },
  renderYear: (props, year, selectedDate) => {
    return <td {...props}>{ year }</td>;
  },
  defaultValue: moment().startOf('day')
};

export default InputCalendar;
