export const SET_DEFAULT_DISHES = 'SET_DEFAULT_DISHES';
export const UPDATE_DEFAULT_DISHES = 'UPDATE_DEFAULT_DISHES';
export const CLEAR_DEFAULT_DISHES = 'CLEAR_DEFAULT_DISHES';

const initialState = {};

export function setDefaultDishes(obj) {
  return {
    type    : SET_DEFAULT_DISHES,
    payload : obj
  };
}

export function updateDefaultDishes(obj) {
  return {
    type    : UPDATE_DEFAULT_DISHES,
    payload : obj
  };
}

export function clearDefaultDishes() {
  return {
    type    : CLEAR_DEFAULT_DISHES
  };
}

export default function defaultDishesReducer(state = initialState, action) {
  if (action.type === SET_DEFAULT_DISHES) {
    return action.payload;
  }
  if (action.type === UPDATE_DEFAULT_DISHES) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_DEFAULT_DISHES) {
    return initialState;
  }
  return state;
}
