/* eslint-disable no-extend-native */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ChartHandler from './ChartHandler';
import { Line, Bar } from 'components/Charts';
import moment from 'moment';
import { socketConnect } from 'socket.io-react';
import Button from 'components/CustomButtons/Button';
import { pushModal, clearModalStack } from 'store/modalStack';
import ProgressEdit from './ProgressEdit';

Array.prototype.sum = function () {
  return this.reduce((acc, cur) => acc + cur, 0);
};

Array.prototype.mid = function () {
  return this.sum() / this.length;
};

class TabProgress extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      progress: {
        weight: {},
        waist: {},
        calories: {}
      }
    };
    this.getProgress(user_id);
  }

  getProgress = user_id => this.props.socket.emit('progress', { type: 'get', data: { user_id } });

  listener = ({ type, data }) => {
    if (this.ProgressRef) {
      if (type === 'getOk') {
        this.setState(data);
      } else if (type === 'setOk') {
        this.getProgress(this.props.user_id);
        this.props.clearModalStack();
      } else if (['getErr', 'setErr'].includes(type)) {
        console.error(data);
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('progress', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('progress', this.listener);
  }

  getLabels = (type, initialDate = moment().utc().startOf('day'), amount = 1) => {
    let labels = {};
    let lowDate = initialDate.clone();
    switch (type) {
      case 'year':
        lowDate.subtract(12 * amount, 'month').startOf('month');
        while (lowDate.add(1, 'month').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('MMM') };
        }
        break;
      case 'month':
        lowDate.subtract(amount, 'month');
        while (lowDate.add(1, 'day').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('DD') };
        }
        break;
      case 'week':
        lowDate.subtract(amount, 'week');
        while (lowDate.add(1, 'day').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          labels = { ...labels, [stamp]: lowDate.format('dd') };
        }
        break;
    }
    return labels;
  };

  groupData = (data, dates, calc = 'sum', mul = 1) => {
    const stamps = Object.keys(dates);
    const dif = +stamps[1] - +stamps[0];
    let dataSet = stamps.reduce((acc, cur) => {
      const tmp = Object.keys(data).reduce((res, stamp) => +stamp >= +cur && +stamp < +cur + dif ? [...res, data[stamp]] : res, []);
      return { ...acc, [+cur]: tmp };
    }, {});
    return Object.values(dataSet).map(set => set.length ? (set[calc]() * mul).toFixed(2) : null);
  };

  getProps = (dataObject) => {
    const { label, data, period, calcType, mul, additional, initialDate, amount } = dataObject;
    const labels = this.getLabels(period, initialDate, amount);
    return {
      labels: Object.values(labels),
      timestamps: Object.keys(labels),
      dataSets: {
        [label]: {
          data: this.groupData(data, labels, calcType, mul),
          ...additional
        }
      }
    };
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user_id !== nextProps.user_id) {
      this.getProgress(nextProps.user_id);
    }
  }

  saveProgress = (progress, user_id) => {
    this.props.socket.emit('progress', {
      type: 'set',
      data: { progress, user_id }
    });
  };

  openEditModal = () => {
    const { userData } = this.props;
    const { regDate, user_id } = userData;
    const { progress } = this.state;
    this.props.pushModal({
      content: <ProgressEdit regDate={+regDate} progress={progress} saveProgress={progress => this.saveProgress(progress, user_id)} />,
      size: {
        width: 760,
        height: 490
      }
    });
  };

  render() {
    const { progress } = this.state;
    const { weight, calories, waist } = progress;
    const buttonStyle = {
      position: 'absolute',
      top: '25px',
      right: '25px'
    };
    return (
      <div ref={el => (this.ProgressRef = el)} className='progress scrollable-h'>
        <Grid container>
          <ChartHandler
            caption={'Calories intake'}
            renderer={props => <Bar {...props} />}
            propser={(period, amount) => this.getProps({
              label: 'Calories',
              data: calories,
              calcType: 'sum',
              mul: 1,
              initialDate: moment().utc().startOf('day'),
              period,
              amount
            })}
            isEmpty={!(calories && Object.keys(calories).length)}
          />
          <ChartHandler
            caption={'Weight progress'}
            renderer={props => <Line {...props} />}
            propser={(period, amount) => this.getProps({
              label: 'Weight',
              data: weight,
              calcType: 'mid',
              mul: 0.001,
              initialDate: moment().utc().startOf('day'),
              period,
              amount
            })}
          />
          <ChartHandler
            caption={'Waist progress'}
            renderer={props => <Line {...props} />}
            propser={(period, amount) => this.getProps({
              label: 'Waist',
              data: waist,
              calcType: 'mid',
              mul: 0.1,
              initialDate: moment().utc().startOf('day'),
              period,
              amount
            })}
            isEmpty={!(waist && Object.keys(waist).length)}
          />
        </Grid>
        <Button
          color={'darkBlue'}
          size={'medium'}
          pullRight
          onClick={this.openEditModal}
          style={buttonStyle}
        >
          Edit progress
        </Button>
      </div>
    );
  }
}

TabProgress.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object,
  pushModal: PropTypes.func,
  clearModalStack: PropTypes.func
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  pushModal: obj => dispatch(pushModal(obj)),
  clearModalStack: () => dispatch(clearModalStack())
});

export default connect(props, actions)(socketConnect(TabProgress));
