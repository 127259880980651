import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Delete } from '@material-ui/icons';
import CustomCard from 'components/Cards/CustomCard';
import { getFormData } from 'utils';
import Input from 'components/CustomInput/ItselfInput';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import Select from 'components/Select';

const buttStyle = {
  padding: '0',
  height: '30px',
  minWidth: '30px',
  backgroundColor: '#e2e2e2',
  color: '#4a4a4a',
  right: '10px',
  position: 'absolute',
  margin: '0'
};

class TabNotes extends Component {
  constructor(props) {
    super(props);
    const { user_id, nutritionist_notes, manager_notes, adminUsers} = props;
    
    const nutritionistList = {
      76: 'Rawda',
      129: 'Rayana',
      85: 'Justine',
      34: 'Sarath',
      2260: 'Lany',
      2240: 'Bayan',
    }

    const managerList = {
      145: 'Edward',
      146: 'John',
      34: 'Sarath',
      458: 'Paolo'
    }

    this.FormRefs = {};
    this.nutrFormRefs = {};
    this.state = {
      user_id,
      notes: [],
      adminUsers: adminUsers,
      manager_notes: manager_notes,
      nutritionistList: nutritionistList,
      managerList: managerList,
      nutritionist_notes: nutritionist_notes,
      nutritionist_id: false,
      manager_id: false,
    };
    user_id && this.getNotes(false, user_id);
    // user_id && this.getNotes('manager', user_id);
    // user_id && this.getNotes('nutritionist', user_id);
  }

  changeUser = (name, value) => {
    this.setState({
      [name]: value,
    });
  }

  getNotes = (type, user) => {
    const user_id = user || this.state.user_id;
    this.props.socket.emit('user_note', { type: 'get',  data: { user_id } });
  };

  removeNote = id => {
    this.props.socket.emit('user_note', { type: 'del', data: { id } });
  };

  addMyNote = (type, user) => {
    const user_id = user || this.props.user_id;
    var formData = false;
    var owner_id = false;
    formData = getFormData(this.FormRefs);
    console.log(formData);
    this.props.socket.emit('user_note', { type: 'set', action: '', owner_id: owner_id, UserNote: { user_id, ...formData } });
  };

  listener = resaction => {
    const { type, data, listaction, delaction } = resaction;
    const {action} = data;
  
    switch (type) {
      case 'getOk':
        switch(listaction) {
          case 'manager':
            this.setState({
              manager_notes: data.notes
            });
          break;

          case 'nutritionist':
            this.setState({
              nutritionist_notes: data.notes
            });
            
          break;

          default:
           
            this.setState(data);
          break;
        }
      break;
      case 'setOk':
        switch(action) {
          case 'manager':
            this.setState({ manager_notes: [...this.state.manager_notes, data.note] });
            Object.values(this.FormRefs).forEach(el => {
              if (el && el.ItselfInputRef) {
                const input = el.ItselfInputRef.querySelector('input') || el.ItselfInputRef.querySelector('textarea');
                input.value = '';
              }
            });
          break;

          case 'nutritionist':
            this.setState({ nutritionist_notes: [...this.state.nutritionist_notes, data.note] });
            Object.values(this.nutrFormRefs).forEach(el => {
              if (el && el.ItselfInputRef) {
                const input = el.ItselfInputRef.querySelector('input') || el.ItselfInputRef.querySelector('textarea');
                input.value = '';
              }
            });
          break;

          default:
            this.setState({ notes: [...this.state.notes, data.note] });
            Object.values(this.FormRefs).forEach(el => {
              if (el && el.ItselfInputRef) {
                const input = el.ItselfInputRef.querySelector('input') || el.ItselfInputRef.querySelector('textarea');
                input.value = '';
              }
            });
          break;
        }  
      break;
      case 'delOk':
          const manager_notes = this.state.manager_notes.filter(el => +el.id !== +data.id);
          this.setState({ manager_notes });
      
          const nutrition_notes = this.state.nutritionist_notes.filter(el => +el.id !== +data.id);
          this.setState({ nutritionist_notes: nutrition_notes });
        
          const notes = this.state.notes.filter(el => +el.id !== +data.id);
          this.setState({ notes });
      break;
      default:
        if (['getErr', 'setErr', 'delErr'].includes(type)) {
          console.error(data);
        } else {
          console.log(resaction);
        }
        break;
    }
  };

  componentWillMount() {
    this.props.socket.on('user_note', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('user_note', this.listener);
  }

  componentWillReceiveProps(next) {
    if (next.user_id !== this.props.user_id) {
      this.getNotes(false,next.user_id);
      // this.getNotes('manager', next.user_id);
      // this.getNotes('nutritionist', next.user_id);
    }
  }

  render() {
    const { notes, manager_notes, nutritionist_notes, nutritionistList, managerList, nutritionist_id, manager_id } = this.state;
    const { user_id } = this.props;
    const list_manager = manager_notes.map(el => {
    const { id, title, text, date, owner_id } = el;
    
    return (
        <Grid item md={12} key={id}>
          <CustomCard marginOnePx>
            <Button size='small' style={buttStyle} onClick={() => this.removeNote(id)}>
              <Delete style={{ fontSize: '20px' }} />
            </Button>
            <span className="notes-date-side">{managerList[owner_id]}  ({date})</span><br/>
            <Text bold inline color='darkBlue'>{title}</Text>
            <Text paragraph inline={false} >{text}</Text>
          </CustomCard>
        </Grid>
    );
    });
    const list_nutritionist = nutritionist_notes.map(el => {
    const { id, title, text, owner_id, date } = el;
      return (
        <Grid item md={12} key={id}>
          <CustomCard marginOnePx>
            <Button size='small' style={buttStyle} onClick={() => this.removeNote(id)}>
              <Delete style={{ fontSize: '20px' }} />
            </Button>
            <span className="notes-date-side">{nutritionistList[owner_id]} ({date})</span><br/>
            <Text bold inline color='darkBlue'>{title}</Text>
            <Text paragraph inline={false} >{text}</Text>
          </CustomCard>
        </Grid>
      );
    });
    const list_notes = notes.map(el => {
      const { id, title, text, owner_id, date } = el;
        return (
          <Grid item md={6} key={id}>
            <CustomCard marginOnePx>
              <Button size='small' style={buttStyle} onClick={() => this.removeNote(id)}>
                <Delete style={{ fontSize: '20px' }} />
              </Button>
              <Text bold inline color='darkBlue'>{title}</Text>
              <Text paragraph inline={false} >{text}</Text>
            </CustomCard>
          </Grid>
        );
      });

    return ( 
      <div className="all-notes">
        <div className="left-notes1">
          <div className='notes'>
            <Grid container>
              <h3 className="h3-notes"> Notes</h3>
              <Grid item md={12}>
                <Grid container>
                  <div className='innre scrollable-h'>
                    {list_notes}
                   
                  </div>
                </Grid>
              </Grid>
              <div className='notes-form'>
                      <Input
                        ref={el => (this.FormRefs['title'] = el)}
                        labelText='Title'
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      <Input
                        ref={el => (this.FormRefs['text'] = el)}
                        labelText='Description...'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 3
                        }}
                      />
                      <Button color='success' onClick={() => this.addMyNote('nutritionist', user_id)} pullRight>Add Note</Button>
                    </div>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

TabNotes.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired
};

export default socketConnect(TabNotes);
