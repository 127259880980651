import React from 'react';
import { socketConnect } from 'socket.io-react';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from 'material-ui';

import PropTypes from 'prop-types';
import UserRow from 'components/Dialog/UserRow';
import tableStyle from 'assets/jss/material-dashboard-react/tableStyle';
import { setModal, clearModal } from 'store/modal';
import { connect } from 'react-redux';
import { setNotify, clearNotify } from 'store/notify';

function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor, list, ids, setModal, header } = props;

  function showModal(key, list, ids) {
    var id = ids[key];
    var names = [];
    if (typeof(list[id]) != 'undefined') {
      Object.keys(list[id]).map(function(key, index) {
          names.push([list[id][key]['id'], list[id][key]['name']]);
      });
    }
   
    console.log(names);
    setModal({ content: (
     <UserRow rows={names} header={header} />
    ) });
  }

  return (
    <div className="table table-responsive">
      <Table className="table">
        {tableHead !== undefined ? (
          <TableHead className="success TableHeader">
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className="test"
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow className="modalrow" key={key} onClick={() => {showModal(key, list, ids)}}>
                {prop.map((prop, key) => {
                  return (
                    <TableCell className="test" key={key}>
                      {prop}

                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray'
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray'
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

const actions = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal())
});

const props = state => ({
  userType: (state.user || {}).type || '',
  dataArray: state.dataArray
});

//export default withStyles(tableStyle)(CustomTable);
export default  socketConnect(connect(props, actions)(CustomTable));