import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar';
import moment from 'moment';
import CustomInput from 'components/CustomInput/CustomInput';

class Calendar extends Component {
  constructor(props) {
    super(props);
    const { date } = props;
    this.onCalendar = false;
    this.state = {
      dateLocal: date,
      showCalendar: false,
      value: null
    };
  }

  onChange = date => {
    const { onChange, controlled, name } = this.props;
    const year = date.getFullYear();
    const month = ('' + (date.getMonth() + 1)).padStart(2, '0');
    const day = ('' + (date.getDate())).padStart(2, '0');
    const isoDate = `${year}-${month}-${day}T00:00:00.000Z`;
    const unix = moment(isoDate).unix();
    if (controlled) {
      onChange(unix, name);
    }
    this.setState({ dateLocal: unix, value: +unix, showCalendar: false });
  };

  getNative = unix => unix ? new Date(unix * 1000) : new Date();

  toggle = () => this.setState({ showCalendar: !this.state.showCalendar });

  render() {
    const { controlled, date, minDate, maxDate, error, name, format, title, disabled, formControlProps, view, keepEmpty, maxDetail } = this.props;
    const { dateLocal, showCalendar } = this.state;
    let current = moment();
    const today = +new Date();
    if (controlled) {
      current = moment.unix((date || today) > minDate ? ((date || today) < maxDate ? (date || today) : maxDate) : minDate);
    } else {
      current = moment.unix((dateLocal || today) > minDate ? ((dateLocal || today) < maxDate ? (dateLocal || today) : maxDate) : minDate);
    }
    const formatted = keepEmpty && (controlled ? !date : !dateLocal) ? '' : current.format(format);
    return (
      <div style={{ display: 'inline-block', width: formControlProps.fullWidth ? '100%' : 'auto' }}>
        <CustomInput
          labelText={title}
          formControlProps={formControlProps}
          error={error}
          inputProps={{
            value: formatted,
            type: 'text',
            name,
            readOnly: true,
            disabled,
            onClick: disabled ? () => {} : this.toggle,
            onBlur: () => this.onCalendar === false && this.setState({ showCalendar: false })
          }}
        />
        {showCalendar && <div
          className={'calendar-handler'}
          style={{ position: 'absolute', 'zIndex': 9, boxShadow: '0 0 5px' }}
          onMouseEnter={() => (this.onCalendar = true)}
          onMouseLeave={() => (this.onCalendar = false)}
        >
          <ReactCalendar
            value={this.getNative(controlled ? (+(date || 0) < +(minDate || 0) ? minDate : date) : dateLocal)}
            onChange={this.onChange}
            minDate={minDate ? this.getNative(minDate) : undefined}
            maxDate={maxDate ? this.getNative(maxDate) : undefined}
            view={view}
            maxDetail={maxDetail}
          />
        </div>}
      </div>
    );
  }
}

Calendar.propTypes = {
  date: PropTypes.number,
  onChange: PropTypes.func,
  controlled: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  error: PropTypes.bool,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  formControlProps: PropTypes.object,
  keepEmpty: PropTypes.bool,
  view: PropTypes.oneOf(['month', 'year', 'decade', 'century'])
};

Calendar.defaultProps = {
  controlled: false,
  error: false,
  disabled: false,
  format: 'DD MMMM, YYYY',
  title: 'Date',
  formControlProps: {
    fullWidth: true
  },
  keepEmpty: true,
  minDate: -Infinity,
  maxDate: Infinity
};

export default Calendar;
