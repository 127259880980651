import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox } from 'material-ui';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { Check, ChevronRight, ChevronLeft } from '@material-ui/icons/index';
import { Text } from 'components/Typography';
import noImage from 'assets/no_img.svg';
import SelectInput from 'components/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';

require('moment-weekday-calc'); 

class EditMealPlanModal extends Component {
  constructor(props) {
    super(props);
    const { errors, mealPlan, planParams, dish_notes } = props;
    const minDate = moment().utc().add(2, 'd').startOf('day').unix();
    const allDates = Object.keys(mealPlan);
    const dates = allDates.filter(el => +el > +minDate);
    const currentDate = props.currentDate || dates[0];
    const currentType = planParams.mealType[0];

    if (typeof(dish_notes[currentDate]) == 'undefined') {
      dish_notes[currentDate] = {};
    }
    this.state = {
      errors,
      mealPlan,
      planParams,
      currentDate,
      currentType,
      skipped: {},
      dates,
      allDates,
      notes: {},
      dish_notes: dish_notes,
    };
  }

  skipDay = (date) => {
    if (this.EditMealPlanModalRef) {
      const { allDates, planParams, mealPlan, skipped, dates } = this.state;
      const { dataArray, excludedDates } = this.props;
      const { planList } = dataArray;
      const plan = (planList && planParams['mealPlan']) && planList[planParams['mealPlan']];
      const excludedDays = (plan && plan['excludeWeekDay']) || [];
      const includedDays = Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(el));
      const newDate = moment.unix([...allDates].reverse()[0]).utc().addWeekdaysFromSet(1, includedDays, excludedDates);
      this.setState({
        skipped: { ...skipped, [newDate.unix()]: date },
        mealPlan: { ...mealPlan, [newDate.unix()]: {} },
        dates: [...dates.filter(el => +el !== +date), newDate.unix()],
        allDates: [...allDates, newDate.unix()],
        currentDate: newDate.unix()
      });
    }
  };

  unskipDay = date => {
    let skipped = { ...this.state.skipped };
    let mealPlan = { ...this.state.mealPlan };
    const { dates, allDates } = this.state;
    const oldDate = skipped[date];
    delete skipped[date];
    delete mealPlan[date];
    this.setState({
      skipped,
      mealPlan,
      dates: [...dates.filter(el => +el !== +date), oldDate],
      allDates: allDates.filter(el => +el !== +date),
      currentDate: oldDate
    });
  };

  btnAddNoteClick(dishId) {
    const {saveNote} = this.props;
    const {currentDate, planParams, dish_notes} = this.state;
    const {id, userId} = planParams;
    saveNote(id, userId, dishId, dish_notes[currentDate][dishId], currentDate);
  }

  changeText(note_value, dishId) {
    var notes = this.state.dish_notes;
    const {currentDate} = this.state;
    notes[currentDate][dishId] = note_value;

    this.setState({
      dish_notes: notes,
    });

    console.log(this.state.dish_notes);
  }

  render() {
    const { mealPlan, planParams, currentDate, currentType, dates, allDates, skipped } = this.state;
    const { dataArray, save, userData, customPlans } = this.props;
    const dayNum = moment.unix(currentDate).date() - 1;
    const plan = customPlans.find(el => +el.mealType_id === +currentType) || {};
    let prodKeys = (plan.data || {})[dayNum] || [];
    const { dietList, typeList, productsFull, slotsList } = dataArray;
    const deliveryTimesList = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const getRating = (rating) => Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
    const { diet: dietId, mealType: types, cutlery, deliveryTime: deliverySlot } = planParams || {};
    const { firstName, lastName } = userData;
    const userName = `${firstName} ${lastName}`;
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const todayDishes = mealPlan[currentDate];
    const isSkipped = currentDate in skipped;
    const dishList = productsFull ? prodKeys.map((dishId, idx) => {
      const { title, image, calories, rating } = productsFull[dishId];
      const imgUrl = image ? `${image}` : noImage;
      return (
        <div className='dish' key={idx}>
          <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
          {dates.includes(currentDate) && <div className='meal-check-holder'>
            <Checkbox
              checked='false'
              checkedIcon={<Check className={`${todayDishes[+currentType] === +dishId ? 'checked' : 'unChecked'} `} />}
              icon={<Check />}
              onClick={() => this.setState({ mealPlan: { ...mealPlan, [currentDate]: { ...mealPlan[currentDate], [currentType]: +dishId } } })}
              classes={{
                checked: 'unChecked'
              }}
            />
          </div>}
          {(!dates.includes(currentDate) && todayDishes[+currentType] === +dishId) && <div className='meal-check-holder'>
            <Checkbox
              checked='false'
              checkedIcon={<Check className='checked' />}
              icon={<Check />}
            />
          </div>}
          <p className='title'>{title}</p>
          <p className="add-note-text">{this.state.dish_notes[currentDate][dishId]}</p>
          <div className="input-notes">
                      <CustomInput
                        labelText='Note'
                        formControlProps={{
                          fullWidth: true
                        }}
                       
                        inputProps={{
                          value: this.state.dish_notes[currentDate][dishId] || '',
                          onChange: e => this.changeText(e.target.value, dishId)
                        }}
                      /> <br/>
                      <Button color='success' onClick={() => this.btnAddNoteClick(dishId)}>Save Note</Button><br/>
          </div>
          <p className='cal'>{ calories / 1000 } kCal</p>
          <p className='rating'>{getRating(rating)}</p>
        </div>
      );
    }) : [];
    const tabsButtons = types && typeList ? types.map((typeId, idx) => {
      const type = typeList[typeId];
      return (
        <Button key={idx} color={+typeId === +currentType ? 'darkBlue' : 'white'} onClick={() => this.setState({ currentType: typeId })}>{type.title}</Button>
      );
    }) : [];
    const curDateIdx = allDates.indexOf(currentDate);
    const prevDate = curDateIdx > -1 ? allDates[curDateIdx - 1] : false;
    const nextDate = curDateIdx > -1 ? allDates[curDateIdx + 1] : false;
    const checkPlan = () => {
      let flag = true;
      const typesAmount = planParams.mealType.length;
      for (const key in mealPlan) {
        const { cooked, packed, delivered, total, ...el } = mealPlan[key];
        if (Object.keys(el).length < typesAmount) {
          flag = false;
        } else {
          for (const type in el) {
            if (!el[type]) {
              flag = false;
            }
          }
        }
      }
      return flag;
    };
    const changeParams = (name, value) => {
      this.setState({ planParams: { ...planParams, [name]: value } });
    };
    return (
      <div ref={el => (this.EditMealPlanModalRef = el)} className='edit-modal-inner'>
        <div>
          <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
          <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
        </div>
        <div className={'d-flex space-between'}>
          <div style={{ textAlign: 'left', paddingTop: '20px' }}>
            {prevDate && <Text className='link-butt prev-date' onClick={() => this.setState({ currentDate: prevDate })}>
              <ChevronLeft style={{ verticalAlign: 'bottom' }} /> Prev: {moment.unix(prevDate).format('DD MMM')}
            </Text>}
          </div>
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <div className='day-editing' style={{ padding: '0 10px' }}>Menu editing for <span>{moment.unix(currentDate).format('DD MMM')}</span></div>
          </div>
          <div style={{ textAlign: 'right', paddingTop: '20px' }}>
            {nextDate && <Text className='link-butt next-date' onClick={() => this.setState({ currentDate: nextDate })}>
              Next: {moment.unix(nextDate).format('DD MMM')} <ChevronRight style={{ verticalAlign: 'bottom' }} />
            </Text>}
          </div>
        </div>
        <div>
          {tabsButtons}
        </div>
        <div style={{ borderTop: '1px solid #bebebe', borderBottom: '1px solid #bebebe', paddingBottom: '10px', width: '800px', marginBottom: '10px' }}>
          <div className='holder'>
            <div className='day'>
              {dishList}
            </div>
          </div>
        </div>
        <div className={'d-flex mb-10'}>
          <div className={'mr-10'}>
            <Text inline bold style={{ fontSize: 'large' }} customColor='#555'>Cutlery required</Text>
            <Checkbox
              checked={!!cutlery}
              icon={<Check className='unChecked' />}
              checkedIcon={<Check className='checked' />}
              onClick={e => changeParams('cutlery', e.target.checked)}
              classes={{ checked: 'unChecked' }}
            />
          </div>
          <div className={'mr-10'}>
            <SelectInput 
              labelText='Delivery time'
              items={deliveryTimesList || {}}
              value={'' + deliverySlot}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => changeParams('deliveryTime', e.target.value)
              }}
            />
          </div>
        </div>
        <div className='modal-button-handler space-between'>
          {dates.includes(currentDate) && (isSkipped
            ? <Button color='darkBlue' onClick={() => this.unskipDay(currentDate)} style={{ marginLeft: '10px' }}>Remove from skipped</Button>
            : <Button color='darkBlue' onClick={() => this.skipDay(currentDate)} style={{ marginLeft: '10px' }}>Skip this day</Button>)
          }
          <Button color='darkBlue' onClick={() => save({ mealPlan, planParams, skipped }, 'edit')} disabled={!checkPlan()}>Save</Button>
        </div>
      </div>
    );
  }
}

EditMealPlanModal.propTypes = {
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  mealPlan: PropTypes.object,
  planParams: PropTypes.object,
  excludedDates: PropTypes.array,
  currentDate: PropTypes.number,
  dataArray: PropTypes.object.isRequired,
  customPlans: PropTypes.array,
  save: PropTypes.func.isRequired
};

const props = ({ customPlans }) => ({ customPlans });

export default connect(props)(EditMealPlanModal);
