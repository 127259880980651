import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import { Remove, Add } from '@material-ui/icons/index';
import { setModal, clearModal } from 'store/modal';
import { setNotify, clearNotify } from 'store/notify';
import { getArrays } from 'utils';
import moment from 'moment/moment';
import ModalWrapper from 'components/Modal';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import List from './List';
import Filters from './Filters';
import printJS from 'print-js';

class FoodCostAll extends Component {
  constructor(props) {
    super(props);
    const limit = 10000;
    const page = 1;
    const today = moment().utc().startOf('day');
    const filters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(1, 'd').unix()
    };
    this.state = {
      xyz: false,
      list: false,
      showCookedModal: false,
      limit,
      page,
      maxPages: 1,
      filters,
      splitter: {},
      errors: {},
      cookedData: {},
      mealTypes: [],
      dishCostPrice: [],
      deliveries: [],
      dishGroups: [],
      activeTab: 'breakfast',
      tabs: [
        { title: 'Breakfast', slug: 'breakfast', to: 'breakfast' },
        { title: 'Mains', slug: 'mains', to: 'mains' },
        { title: 'Snacks', slug: 'snacks', to: 'snacks' },
        { title: 'Others', slug: 'others', to: 'others' }
      ]
    };
    this.getFoodCostAll();
  }
  getFoodCostAll() {
    this.props.socket.emit('kitchen', {
      type: 'getFoodCostAll',
      data:{filterType:this.state.activeTab}
    });
  }

  listener = action => {
    
    if (this.FoodCostAllRef) {
      console.log(action);
      switch (action.type) {
        case 'getFoodCostAllOk':
          this.setState({list:action.data.list});
          break;
        default:
          if (action.type.includes('Err')) {
            const { errors } = action.data;
            this.setState({ errors });
          }
          break;
      }
    }
  };

  goTo = page => {
    const { limit, filters } = this.state;
    this.getList((page - 1) * limit, limit, filters);
    setTimeout(() => this.setState({ page }), 5);
    this.FoodCostAllRef && this.FoodCostAllRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
  };

  componentWillMount() {
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  componentDidMount() {
    if (this.FoodCostAllRef) {
      setTimeout(() => this.FoodCostAllRef && this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['typeList', 'dishGroups', 'dishTypes', 'deliveryTime', 'productsFull'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }

  
  render() {
    const { xyz, page, maxPages, list, limit, errors, splitter, mealTypes, dishGroups: activeDishGroups, deliveries, showCookedModal, cookedData,tabs,activeTab } = this.state;
    
    const { productsFull, dishGroups, dishTypes, typeList } = this.props.dataArray;
    const render = list && productsFull;
    const actions = {
      cooked: (id, { date, maxAmount }) => this.setState({ showCookedModal: true, cookedData: { dish: id, date, maxAmount, count: 1 } }),
      print: (id, row) => {
        this.props.setModal({
          content: <div>
            {/*<div id={'printable-block'} style={{ width: '100%', height: '100%', padding: '25px' }}>
              
            </div>
            <Button size={'large'} color={'darkBlue'} pullRight onClick={() => printJS('printable-block', 'html')}>Print</Button>*/}
           
          </div>
        });
      }
    };
    const buttons = tabs.map((tab, idx) => {
      const { title, slug, to } = tab;
      return <Button key={idx} color={(slug === activeTab) ? 'darkBlue' : 'white'} onClick={() => this.setState({activeTab:to}, () => {
        this.getFoodCostAll()
       })}>{title}</Button>;
    });
    const props = { page, maxPages, limit, dictionaries: { productsFull, dishGroups, dishTypes, typeList }, splitter };
  
    const content = render ?  <List key={0} {...props}  list={list}   /> : null;
    return (
      <Fragment>
         <CustomCard md={12}>
          {buttons}
        </CustomCard>
        <CustomCard marginOnePx>
          <div ref={el => (this.FoodCostAllRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
            {content}
          </div>
        </CustomCard>
      </Fragment>
    );
  }
}

FoodCostAll.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(FoodCostAll));
