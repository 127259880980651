import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import moment from 'moment';
import { Grid } from 'material-ui';

import './style.css';
import SelectInput from '../../../../../components/SelectInput';
class ChartHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 'year',
      amount: 1,
      activeYear:props.activeYear
    };
  }
  renderMonthDatas=(timestamps,datas,yearList)=>{
    const { activeYear } = this.state;
    // console.log(activeYear);
    let existm={};
    (timestamps)&& timestamps.map((timestamp,ind)=>{
       let timm=moment.unix(timestamp).format('MMM');
       if(existm[timm]==undefined){
         existm[timm]=[datas[ind]];
       }else{
        existm[timm].push(datas[ind]);
       }
    });
    const listval=(existm)&&Object.keys(existm).map((key)=>{
        const subval = (existm[key]!=undefined)&&(existm[key].filter(el=>(el!=null)).map((val)=>{
            return <li>+ {val}</li>;
        }));
      return <li>{key}{subval?<ul>{subval}</ul>:''}</li>;
    })
    // console.log(existm);
    return  <ul className='bd-month-year'>
      <li> 
                <SelectInput
                    labelText='Select Year'
                    items={yearList}
                    value={activeYear.toString() || null}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // disabled: vouchersAreDisabled || editaredisabled,
                      title: (yearList || []).length ? '' : 'This user have no any vouchers',
                      onChange: e => this.setState({activeYear:e.target.value})
                    }}
                  />
                </li>
        {listval}
    </ul>;
  }
  render() {
    const { renderer, propser, caption, isEmpty,yearList } = this.props;
    const { period, activeYear } = this.state;
    const props = propser(period, activeYear);
    const { timestamps,label,dataSets } =props;
    console.log(label);
    console.log(dataSets[label]);
    // const setParams = (period, activeYear) => this.setState({ period, activeYear });
    return (
      <div className='progress-holder'>
        <div className='part-head'>
          <h5 className='green'>{caption} - {activeYear}</h5>
          {timestamps && <Grid container>
             {this.renderMonthDatas(timestamps,dataSets[label]['data'],yearList)}
          </Grid>}
        </div>
        <div className={isEmpty ? 'chart-holder empty-chart' : 'chart-holder'}>
          {renderer(props)}
        </div>
      </div>
    );
  }
}

ChartHandler.propTypes = {
  renderer: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  propser: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired
};

export default ChartHandler;

