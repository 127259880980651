import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import moment from 'moment';
import Calendar from 'components/Calendar';

class Filters extends Component {
  constructor(props) {
    super(props);
    const {dateFrom,dateTo} = this.props;
    this.state = {
      periodTo: dateTo,
      periodFrom: dateFrom,
      errors : {},
    }
  }
 
  render() {
    const {periodFrom, periodTo, errors } = this.state;
    const { applyFilters } = this.props;
    return (
      <div className='fix-on-filters'>
          <Calendar
            date={periodFrom}
            name={'periodFrom'}
            title={'From date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              this.setState({
                periodFrom: value,
              });
              // if (date.isAfter(moment.unix(periodTo))) {
                setTimeout(() => this.setState({
                  periodTo: moment.unix(value).clone().endOf('month').unix(),
                }), 10);
              // }
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
            view={'year'}
            maxDetail={'year'}
          />

          <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'To date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
            view={'year'}
            maxDetail={'year'}
          />
         
          {/* <Button color='danger' size='large' onClick={() => {
              this.setState({
                periodFrom: null,
                periodTo: null,
              });
          }}>Clear</Button> */}
          <Button color='darkBlue' size='large' onClick={ () => {applyFilters({periodFrom,periodTo})}}>Apply</Button>
      </div>
    );
  }
}

export default Filters;