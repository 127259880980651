import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Close } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/SelectInput';
import Dropzone from 'react-dropzone';
import CustomInput from 'components/CustomInput/CustomInput';
import { setNotify, clearNotify } from 'store/notify';
import { ErrorOutline } from '@material-ui/icons/index';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Select2 } from "select2-react-component";
class Calories extends Component {
  constructor(props) {
    super(props);
    const {product_id,caloriesdata,nutritional_images} = props;
    const calories=caloriesdata||{};
    const images=nutritional_images||{};
    this.state = {
      errors : {},
      calories:calories,
      totalcal:0,
      images:images
    };
    // this.getCalories(product_id);
  }

  showNotification(message, color = 'success') {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      place: 'tc',
      color,
      icon: ErrorOutline,
      message: message,
      open: true
    });
    setTimeout(() => clearNotify(), 5000);
  }

  getCalories = (product_id) => {
    this.props.socket.emit('menu_builder', { type: 'getCalories', data: { product_id: product_id } });
  }

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  listener = data => {
      switch (data.type) {
        case 'getCaloriesOk':
          // this.setState({
          //   calories: data.res
          // });
        break;
        case 'saveCaloriesOk':
          this.showNotification(data.data.message);
        break;
      }
  };

  onChange(type, diet_id, val) {
    var {calories} = this.state;
    // console.log(calories);
    var total=0;
    if(calories[diet_id]==undefined){
      calories[diet_id]={};
    }
    calories[diet_id][type] = val;
    if(type=='calories'){
      // totalcal+=val;
     
      Object.keys(calories).map((number) => total=total+ parseInt(calories[number].calories) );
      // console.log(total);
    }

    this.setState({
      calories: calories,
      totalcal:total
    });
  }
  onDropPackImage = (type, diet_id,files) => {
    const { images } = this.state;
    
    if(images[diet_id]==undefined){
      images[diet_id]={};
    }
    images[diet_id][type]=files[0];
    this.setState({ images: images });
  };

  saveCalories() {
    const {calories} = this.state;
    const {product_id,SidesRef} = this.props;
    console.log(' ======= save =======');
    console.log(calories); 
    console.log(SidesRef); 
    console.log(' ======= save =======');
    if(product_id){
    //  this.props.socket.emit('menu_builder', { type: 'saveCalories', data: { product_id: product_id, calories: calories } });
    }else{
      this.showNotification('Please save main product first!!!','error');
    }
  }
  renderFields=(label,name,type,value,product_id,list=false)=>{
    const {errors,images} = this.state;
      if(type=='select'){
          return <SelectInput
          labelText={label}
          multiple={false}
          items={list}
          empty={''}
          value={value[name] || ''}
          name={name}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => this.onChange(name, product_id, e.target.value)
          }}
        />
      }else if(type=='image'){
        // const packImageUrl = (value[name] && value[name].preview) || value[name];
        const packImageUrl = (images[product_id]!=undefined&&images[product_id][name] && images[product_id][name].preview) || (images[product_id]!=undefined && images[product_id][name]);
        return <Dropzone
        onDrop={files => this.onDropPackImage(name, product_id,files)}
        className='drop-zone'
        style={{
          backgroundImage: `url(${packImageUrl})`,
          borderColor:name in errors ? 'red' : '#a7a7a7'
        }}
      >
        {!packImageUrl && <div className='drop-zone-label'><p>Click to upload or nutritional info image here</p></div>}
      </Dropzone>
      }
      return <CustomInput
      labelText={label}
      formControlProps={{
          fullWidth: true
      }}
      
      error={'name' in errors}
        inputProps={{
            value: value[name] || '',
            type: type,
            name: name,
            onChange: (e) => { this.onChange(name, product_id, e.target.value)}
        }}
    />;
  }
  renderCaloriesFields = () => {
    const {dietList,portionSizeList,dishPacks,heatingInstructionsList} = this.props;
    const {errors, calories} = this.state;
    // console.log(calories);
    const maininfo={'calories':{'name':'Calories','type':'text','list':false},'protein':{'name':'Protein','type':'text','list':false},'fat':{'name':'Fat','type':'text','list':false},'cho':{'name':'CHO','type':'text','list':false},'image':{'name':'Nutritional Info Photo','type':'image','list':false},'price':{'name':'Price','type':'text','list':false},'pack_id':{'name':'Packaging Container','type':'select','list':dishPacks},'heating_ins_id':{'name':'Re-heating Instructions','type':'select',list:heatingInstructionsList}};
    const thead = portionSizeList ? [<td key={0}>Portion Size</td>, Object.keys(portionSizeList).map((el,index) => <td key={el+1}>{portionSizeList[el]}</td>)] : [];
    const table = maininfo && portionSizeList && Object.keys(maininfo).map(fname => {
    const caloriesdata=calories||{};
      return (
        <tr key={fname}>
          <td className='first-col'>{maininfo[fname].name}</td>
          {Object.keys(portionSizeList).map(portionId => (
            <td key={portionId}>
              {this.renderFields(maininfo[fname].name,fname,maininfo[fname].type,caloriesdata[portionId]||'',portionId,maininfo[fname].list)}
            </td>
          ))}
        </tr>
      );
    });
   return <table className='prices-table'>
      <thead>
          <tr>
            {thead}
          </tr>
          </thead>         
          <tbody>
            {table}
          </tbody>
    </table>
  };

  render() {
    return (
      <Grid container ref={el => (this.CalRef = el)}>
        {this.renderCaloriesFields()}<br/>
        {/* <Button color='darkBlue' onClick={ (e) => this.saveCalories()}>Save</Button> */}
      </Grid>
    );
  }
}

Calories.propTypes = {
  dietList: PropTypes.object.isRequired,
  portionSizeList: PropTypes.object.isRequired,
  heatingInstructionsList: PropTypes.object.isRequired
};
export default Calories;
// const props = state => ({
//   //user: state.user,
//   //dataArray: state.dataArray
// });

// const actions = dispatch => ({
//   clearNotify: () => dispatch(clearNotify()),
//   setNotify: props => dispatch(setNotify(props))
// });

// export default socketConnect(connect(props, actions)(Calories));