import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import { Table, TableBody, TableCell, TableRow } from 'material-ui';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import TableHead from 'material-ui/Table/TableHead';
import moment from 'moment';
import Filters from './Filters';
import Text from 'components/Typography/Text';
 
class DailySales extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      dailySales: [],
      xyz: false,
      orderBy: 'username',
      orderDir: '+',
      onlyActive: false
    };
    this.getDailySales();
  }

  componentWillMount() { 
    this.props.socket.on('finances', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('finances', this.listener);
  }

  listener = ({ type, data }) => {
    
    switch (type) {
      case 'getDailySalesOk':
        this.setState({
          dailySales: data,
        })
      break;

      default:
        console.log(data.message);
      break;
    }
    
  };

  getDailySales = () => this.props.socket.emit('finances', { type: 'getDailySales', data: {} });

 
  render() {
    const { dailySales } = this.state;
    console.log(dailySales);
    var rows = [];
    var num = 0;
    var dayold = 99;
    var show = false;
    Object.keys(dailySales).map( (year, idx) => {
     // Object.keys(dailySales[year]).map( (month, idl) => {
      for (var month=12; month>0; month--) {
        if (typeof(dailySales[year][month]) != 'undefined') {
          rows.push(<tr className="days-left-line">
            <td colspan="6">{moment().month(parseInt(month)-1).format("MMM")}, {year}</td>
          </tr>);
          var num = 0;
          var dayold = 99;
          var totals = dailySales[year][month]['total'];

          var daytotal_amount = 0;
          var daytotal_discount = 0;
          var daytotal_count = 0;
          
        Object.keys(dailySales[year][month]).map( (day, idm) => {
          if (day != 'total') {
            var i = 0;
            Object.keys(dailySales[year][month][day]).map( (area, idn) => {
                i = i + 1;
                if (day != dayold) {
                  num = num+1;
                  show = true;
                } else {
                  show = false;
                }
                dayold = day;

                var d = dailySales[year][month][day][area];
                daytotal_amount += d.amount;
                daytotal_discount += d.discount;
                daytotal_count += d.count;

                rows.push(<tr>
                  <td>{show ? 'Day '+num : ''}</td>
                  <td>{show ? day+'.'+month+'.'+year : ''}</td>
                  <td className="blue-text">{area}</td>
                  <td>{d.count}</td>
                  <td className="green-text">{d.amount/100} AED</td>
                  <td className="red-text">{d.discount/100} AED</td>
                </tr>)

                if (i == Object.keys(dailySales[year][month][day]).length) {
                  rows.push(<tr className="finday">
                   
                    <td colspan="3"><b>Day Total:</b></td>
                    <td><b>{daytotal_count}</b></td>
                    <td className="green-text"><b>{(daytotal_amount/100).toFixed(2)} AED</b></td>
                    <td className="red-text"><b>{(daytotal_discount/100).toFixed(2)} AED</b></td>
                  </tr>)

                  daytotal_amount = 0;
                  daytotal_discount = 0;
                  daytotal_count = 0;
          
                }
            });
          }
        });

       

        rows.push(<tr className="row-border-top">
                <td colspan="3" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
                <td><b>Total Customers</b></td>
                <td className="green-text"> <b>Total Amount</b></td>
                <td className="red-text"> <b>Total Discount</b></td>
        </tr>)
        var total_count = 0;
        var total_amount = 0;
        var total_discount = 0;
        Object.keys(totals).map( (tot, idz) => {
          rows.push(<tr className="row-border-top">
                <td colspan="3" align="left">{tot}</td>
                <td><b>{totals[tot].count}</b></td>
                <td className="green-text"> <b>{(totals[tot].amount/100).toFixed(2)} AED</b></td>
                <td className="red-text"> <b>{(totals[tot].discount/100).toFixed(2)} AED</b></td>
          </tr>)
          total_count += totals[tot].count;
          total_amount += totals[tot].amount;
          total_discount += totals[tot].discount;
        });

          rows.push(<tr className="row-border-top-final">
                <td colspan="3" align="left">Final Total Results:</td>
                <td><b>{total_count}</b></td>
                <td className="green-text"><b>{(total_amount/100).toFixed(2)} AED</b></td>
                <td className="red-text"><b>{(total_discount/100).toFixed(2)} AED</b></td>
          </tr>)
      //});
      } }
    });

    return (
      <div className='xyz-fin'>
        <div className='filtesHolder finances'>
          
        </div>
        <CustomCard marginOnePx>
          <div>
            <table className="Tabled fintable">
              <thead className="roundedHeader financetbl">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Mealplan Area</th>
                  <th scope="col">No of customers</th>
                  <th scope="col">Sales per day by location</th>
                  <th scope="col">Amount Discounted</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </CustomCard>
      </div>
    );
  }
}

DailySales.propTypes = {
  socket: PropTypes.object,
  setExport: PropTypes.func
};

export default socketConnect(DailySales);
