import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';

import { browserHistory } from 'react-router';
import Button from 'components/CustomButtons/Button';

class MenuBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        'dishes': { title: 'Dishes', slug: 'menu', to: '' },
        'programs': { title: 'Programs', slug: 'programs', to: 'programs' },
        'sidesprograms': { title: 'Sides Programs', slug: 'sidesprograms', to: 'sidesprograms' },
        'programslogs': { title: 'Program Logs', slug: 'programslogs', to: 'programslogs' },
        'prices': { title: 'Prices', slug: 'prices', to: 'prices' },
        'newprices': { title: 'NewPrices', slug: 'newprices', to: 'newprices' },
        'discountprices': { title: 'Diet-Mealtype Discounts', slug: 'discountprices', to: 'discountprices' },
        'add': { title: 'Add new dish +', slug: 'add', to: 'add' }
      }
    };
  }

  changeTab = to => browserHistory.push(`/admin/menu${to ? `/${to}` : ''}`);

  render() {
    const { location } = this.props;
    const { tabs } = this.state;
    const routeArr = location.pathname.split('/').filter(el => el);
    const current = (routeArr.length < 4) ? routeArr.reverse()[0] : routeArr.reverse()[1];
    const buttons = Object.keys(tabs).map((key, idx) => {
      const { title, slug, to } = tabs[key];
      return <Button key={idx} color={(slug === current) ? 'darkBlue' : 'white'} onClick={() => this.changeTab(to)}>{title}</Button>;
    });
    const children = this.props.children && Children.map(
      this.props.children,
      child => cloneElement(child, {})
    );
    return <Grid container>
      <div className='tabsCustom'>
        <ItemGrid md={12}>
          {buttons}
        </ItemGrid>
      </div>
      <div className='afterTabsCustom'>
        <div className={'full-w'}>
          {children}
        </div>
      </div>
    </Grid>;
  }
}

MenuBuilder.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default MenuBuilder;
