import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Route from './Route';

class Cars extends Component {
  constructor(props) {
    super(props);
    const { activePartner } = this.props;
    this.state = {
      activeWindowPartner :activePartner
    };
  }

  componentWillReceiveProps(nextProps) {
    const { activeDay: oldDay, activeSlot: oldSlot, activePartner: oldActivePartner } = this.props;
    const { activeDay, activeSlot,activePartner } = nextProps;
    if (oldDay !== activeDay || oldSlot !== activeSlot|| oldActivePartner !== activePartner) {
      this.setState({ activeWindowPartner: 0 });
    }
  }

  render() {
    const { deliveryPartnerFull, deliveryParList, activeDay, routes, bagVisits, clientsList, windows, setWindow,
      deliveryVisits, activeSlot,  currentClients, used, bagVisitsInit, activeWindow, timesList,notUsedRoutes,activePartner } = this.props;
    const notUsed = notUsedRoutes[activeDay]?notUsedRoutes[activeDay][activeSlot]||[]: [];
    const { activeWindowPartner } = this.state;
    const activeRoute = ((routes[activeDay] || {})[activeWindowPartner] || {})[activeWindow] || [];

    const getDelivered = (route = []) => route.filter(el => !!(currentClients[el] || {})['delivered']);
    const getClaimedBags = (route = []) => route.filter(el => bagVisits.includes(el));
    // const slotBags = bagVisitsInit.filter(el => clientsList[el] && clientsList[el].slot === activeSlot);
    const slotBags = bagVisitsInit.filter(el => clientsList[el] && clientsList[el][activeDay][+activeSlot]!= undefined);
    const orders = (timesList || []).reduce((acc, { id, order }) => ({ ...acc, [+id]: +order }), {});

    const deliveryPartnerList = Object.keys(deliveryParList).filter(function (el) {
      return clientsList[el]!=undefined && clientsList[el][activeDay]!=undefined &&clientsList[el][activeDay][+activeSlot]!= undefined && (activePartner==0 || el==activePartner);
    }).map((driverId, idx) => {
      
        if(driverId!=0){
         
          const {  name } = deliveryPartnerFull[driverId];
          const driversRoutes = (routes[activeDay] || {})[driverId] || {};
          const classNames = +activeWindowPartner == +driverId ? 'active car' : 'car';
          return (
            <div
              className={classNames}
              key={idx}
              onClick={() => this.setState({ activeWindowPartner: +driverId })}
            >
              <div className='total'>
                <div className='right' />
                <p className='normal'>{name} </p>
              </div>
              <div className='visits'>
                {Object.keys(windows).sort((a, b) => orders[+a] - orders[+b]).map((windowId, idx) => {
                  const buttColor = +activeWindow == +windowId ? 'success' : 'white';
                  const changeWindow = () => setWindow(+windowId);
                 
                    return <p className={buttColor} key={idx} onClick={changeWindow}>
                      <span className='window no-visits'>{windows[windowId]}</span>
                      <span className='no-visits'>Manage visits</span>
                    </p>;
                 
                })}
              </div>
            </div>
          );
        }
      
    });
 
    if(activePartner==0 || clientsList[activePartner]!=undefined){
        const planned = (activePartner==0)?used.length:(clientsList[activePartner] != undefined && clientsList[activePartner][activeDay] != undefined)?Object.keys(clientsList[activePartner][activeDay][activeSlot] || {}).length:0;
        const notPlanned = notUsed.length;
        return <div className='log-holder'>
          <div className='cars-holder rounded-shadowed '>
            <div className='head'>
              <span className='planned'>Scheduled: {planned}</span>
              {activePartner==0 && <span className='not-planned'>Need to schedule: {notPlanned}</span>}
            </div>
            <div className='body scrollable-h'>
              {deliveryPartnerList}
            </div>
          </div>
          <Route {...{ ...this.props, activeWindowPartner, activeRoute, getDelivered }} />
        </div>;
    }else{
      return <div className='log-holder'>
      <div className='cars-holder rounded-shadowed '>
        <div className='head'>
          <span className='planned'>Scheduled: 0</span>
          <span className='not-planned'>Need to schedule: 0</span>
        </div>
        <div className='body scrollable-h'>
        </div>
      </div>
      <Route {...{ ...this.props, activeWindowPartner, activeRoute, getDelivered }} />
    </div>;
    }
  }
}

Cars.propTypes = {
  activeDay: PropTypes.number,
  activeSlot: PropTypes.number,
  activeWindow: PropTypes.number,
  deliveryParList: PropTypes.object,
  clientsList: PropTypes.object,
  deliveries: PropTypes.object,
  activeRoute: PropTypes.object,
  bagVisits: PropTypes.array,
  todaySlotsIds: PropTypes.array || PropTypes.object,
  currentDaySlots: PropTypes.array,
  notUsed: PropTypes.array,
  deliveryVisits: PropTypes.array,
  bagVisitsInit: PropTypes.array,
  timesList: PropTypes.array,
  used: PropTypes.array,
  areas: PropTypes.object,
  emirates: PropTypes.object,
  priorities: PropTypes.array,
  routes: PropTypes.object,
  windows: PropTypes.object,
  slots: PropTypes.object,
  transportTypes: PropTypes.object,
  currentClients: PropTypes.object,
  setRoute: PropTypes.func,
  setWindow: PropTypes.func,
  setPriority: PropTypes.func,
  getDelivered: PropTypes.func
};

Cars.defaultProps = {
  driversList: {},
  clientsList: {},
  deliveries: {},
  currentClients: {},
  transportTypes: {},
  currentDayWindows: []
};

export default Cars;
