import React, { Component } from 'react';
import SelectInput from 'components/SelectInput';
class SideDishModal extends Component {

	constructor(props) { 
        super(props);
        const { dishId } = this.props;
        this.state = {
            dishId
        };
    }
    onChange = (value) => {
        this.setState({dishId:value});
    }
    render() {
        const { currentType , sideId, changeMealside,sidelist } = this.props;
        const { dishId } =this.state;
        return (<SelectInput
            labelText='Change Sides'
            items={sidelist}
            value={dishId.toString() || ''}
            name='sideId'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e =>{
                    this.onChange(e.target.value);
                    changeMealside(currentType,sideId, e.target.value)
                }
            }}
          />);
    }
}
export default SideDishModal;