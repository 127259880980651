import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Text from 'components/Typography/Text';
import { Check, RadioButtonChecked, RadioButtonUnchecked, ErrorOutline } from '@material-ui/icons/index';
import SelectInput from 'components/SelectInput';
import Calendar from 'components/InputCalendar';
import Tooltip from 'components/ToolTip';

class PlanInfo extends Component {
  constructor(props) {
    super(props);
    const {planParams} = this.props;
    const {deliverySlot} = planParams;
    var is_evening = false;
    if (deliverySlot == 4) {
      is_evening = true;
    }
    this.state = { 
      step: 1,
      is_evening: is_evening,
    };
  }

  renderDay = (props, curd, seld, exWeekdays, exDates, minDate) => {
    const {is_evening} = this.state;
    var day = false;
    if (is_evening) {
      day = moment(curd._d).format('dddd');  
    }
    if(minDate && curd.isBefore(minDate)){
      props.className = `${props.className} disabled`;
      delete props.onChange;
      delete props.onClick;
    }
    /*if ( (minDate && curd.isBefore(minDate)) || (day == 'Friday' || day == 'Thursday') ) {
      props.className = `${props.className} disabled`;
      delete props.onChange;
      delete props.onClick;
    }*/
    return <td {...props}>{ curd.date() }</td>;
  };

  render() {
    const { dataArray, userData, planParams, changeParams, errors, excludedDates, build, minDate: minRenewDate, bagPrice, changeBag,prevPlanParams } = this.props;
    const useMinRenewDate = build === 'renew';
    const { vouchers } = userData || {};
    const { date:prevdate } = prevPlanParams;
    const { mealType, cutlery, diet, mealPlan, date, deliverySlot, coupon, price, paymentMethod, payed, pricetype, payment_method_id } = planParams;
    const { typeList, requiredTypes, dietList, planList, slotsList, paymentMethods, commonVouchers,dietListFull } = dataArray;
    const deliveryTimesList = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const { excludeWeekDay: excluded } = (planList || {})[mealPlan] || {};
    const now = moment().utc().unix();
    const vouchersList = [...(vouchers || []), ...(commonVouchers || [])].reduce((acc, { id, type, value, code, start_date, end_date, active }) => {
      const format = {
        fixed: (val) => `AED ${val / 100}`,
        percent: val => `${val}%`
      };
      const title = `${format[type](value)} (${code})`;
      if (start_date && end_date) {
        if (now > start_date && now < end_date && active) {
          return { ...acc, [`c_${id}`]: `Common: ${title}` };
        } else {
          return acc;
        }
      } else {
        return { ...acc, [`p_${id}`]: `Personal: ${title}` };
      }
    }, { '': 'None' });
    //const minDate = useMinRenewDate ? moment.unix(minRenewDate).utc() : null;
 
    const DateSelectionTooltip = () => {
      return <div className='tooltip-yn my-tt' style={{ width: '100%', textAlign: 'center', fontSize: '16px', marginTop: '5px', fontWeight: 600 }}>
        Select diet and plan first
      </div>;
    };
    const today = moment().utc().startOf('d');
    let minDate = useMinRenewDate ? today : null;
    minDate =(build==='editplan')?moment.unix(prevdate).startOf('d'):minDate;
    const vouchersAreDisabled = Object.keys(vouchersList).length < 2;
    const editaredisabled=build==='editplan';
    const disabledDietList =[30,33]; // Disable Keto, Weight Loss selection
    var displayPrice = 'N\A';
    if (typeof(price) != 'undefined') {
      displayPrice = 'AED ' + (price / 100);
    }
    var pricetypes={0:'Old Price',1:'New Price'};
    
    var priceTypeval = pricetype;
    if(pricetype==undefined){
      changeParams('pricetype', 1);
      priceTypeval=1;
    }

    return (
      <div>
        <div className='scroll-wrapper' style={{ paddingBottom: '40px' }}>
          <Grid container>
            <Grid item md={3}>
              <Text bold style={{ fontSize: 'large' }} customColor='#555'>Select meals</Text>
              {!!(typeList) && Object.keys(typeList).sort((a, b) => typeList[a].order - typeList[b].order).map((typeId, idx) => {
                 const { title, required, parent_id:typeParentId,children } = typeList[typeId];
                const chk = (mealType || []).includes(+typeId);
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={chk}
                      icon={<Check className='unChecked' />}
                      checkedIcon={<Check className='checked' />}
                      onClick={() => {
                        if(diet){
                        let value = !mealType && [+typeId];
                        if (mealType && !value) {
                          //const req = mealType.filter(el => requiredTypes.includes(el)).length;
                          if (mealType.includes(+typeId)) {
                            /*if (req > 2 || (req === 2 && !required)) {
                              value = mealType.filter(el => +el !== +typeId);
                            } else {
                              value = mealType;
                            }*/
                            value = mealType.filter(el => +el !== +typeId);
                            if(children && dietListFull[diet] && dietListFull[diet].sides_id!=null){
                              value = value.filter(el => +el !== +children.id);
                            }
                          } else {
                            value = [...mealType, +typeId];
                            if(children && dietListFull[diet] && dietListFull[diet].sides_id!=null){
                              value = [...value, +children.id];
                            }
                          }
                        } 
                        if((typeParentId==0 || typeParentId==null) || mealType.includes(+typeParentId)){
                          changeParams('mealType', value);
                        }else{
                          alert('Please select parent Type '+typeList[typeParentId].title);
                        }
                      }else{
                        alert('Please select Diet Type before select meal types');
                      }
                      }}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text>{title}</Text>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item md={3}>
              <Text bold style={{ fontSize: 'large' }} customColor='#555'>Select diet</Text>
              {'diet' in errors && <ErrorOutline color='error' />}
              {dietList && Object.keys(dietList).map((dietId, idx) => {
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={+diet === +dietId}
                      disabled={editaredisabled || disabledDietList.indexOf(+dietId)!=-1}
                      icon={<RadioButtonUnchecked className='unchecked-radio' />}
                      checkedIcon={<RadioButtonChecked className='checked-radio' />}
                      onClick={() =>{ 
                       
                        if(dietListFull[diet]!=undefined && dietListFull[diet].sides_id!=dietListFull[dietId].sides_id){
                          let value = mealType;
                         
                          if(dietListFull[dietId].sides_id==null){
                            value.filter(function (type) {
                                return (typeList[type].parent_id==0 || typeList[type].parent_id==null);
                            });
                            changeParams('mealType', value);
                          }
                        }
                        changeParams('diet', +dietId)
                        }}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text>{dietList[dietId]}</Text>
                  </Grid>
                );
              })}
              <Text bold style={{ fontSize: 'large' }} customColor='#555'>Select Price Type</Text>
              {'pricetype' in errors && <ErrorOutline color='error' />}
              {pricetypes && Object.keys(pricetypes).map((priceType, idx) => {
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={+priceTypeval === +priceType}
                      disabled={priceType==0}
                      icon={<RadioButtonUnchecked className='unchecked-radio' />}
                      checkedIcon={<RadioButtonChecked className='checked-radio' />}
                      onClick={() => changeParams('pricetype', +priceType)}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text>{pricetypes[priceType]}</Text>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item md={3}>
              <Text bold style={{ fontSize: 'large' }} customColor='#555'>Select plan</Text>
              {'mealPlan' in errors && <ErrorOutline color='error' />}
              {planList && Object.keys(planList).map((planId, idx) => {
                const { title } = planList[planId];
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={+mealPlan === +planId}
                      disabled={editaredisabled}
                      icon={<RadioButtonUnchecked className='unchecked-radio' />}
                      checkedIcon={<RadioButtonChecked className='checked-radio' />}
                      onClick={() => changeParams('mealPlan', +planId)}
                    />
                    <Text>{title}</Text>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item md={3}>
              <Grid container>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Select start date</Text>
                  {'date' in errors && <ErrorOutline color='error' />}
                  <Tooltip
                    tooltipText={<DateSelectionTooltip />}
                    tooltipDirection={'top'}
                    trigger={['hover']}
                    hide={!!(diet && mealPlan)}
                  >
                    <div>
                      <Calendar
                        controlled
                        value={date || today.unix()}
                        placeholder={'Date'}
                        disable={!(diet && mealPlan)}
                        locale={'GB'}
                        onChange={date => changeParams('date', date)}
                        onViewModeChange={() => false}
                        renderDay={(a, b, c) => this.renderDay(a, b, c, excluded, excludedDates, minDate)}
                        onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(1, 'year'))}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Select delivery time window</Text> 
                  {'deliverySlot' in errors && <ErrorOutline color='error' />}
                  <SelectInput
                    labelText='Delivery time'
                    items={deliveryTimesList || {}}
                    value={'' + deliverySlot}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:editaredisabled,
                      onChange: e => {
                        if (e.target.value == 4) {
                          this.setState({ is_evening: true });
                        } else {
                          this.setState({ is_evening: false });
                        }
                        changeParams('deliverySlot', e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Select payment method</Text>
                  {'payment_method_id' in errors && <ErrorOutline color='error' />}
                  <SelectInput
                    labelText='Payment method'
                    items={paymentMethods || {}}
                    value={payment_method_id || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:editaredisabled,
                      onChange: e => changeParams('payment_method_id', e.target.value)
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Select discount or credit</Text>
                  <SelectInput
                    labelText='Vouchers'
                    items={vouchersList}
                    value={'' + coupon}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: vouchersAreDisabled || editaredisabled,
                      title: (vouchers || []).length ? '' : 'This user have no any vouchers',
                      onChange: e => changeParams('coupon', e.target.value)
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Cutlery required</Text>
                  <Checkbox
                    checked={!!cutlery}
                    icon={<Check className='unChecked' />}
                    disabled={ build==='editplan'}
                    checkedIcon={<Check className='checked' />}
                    onClick={e => changeParams('cutlery', e.target.checked)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Already payed</Text>
                  <Checkbox
                    checked={payed}
                    icon={<Check className='unChecked' />}
                    disabled={ build==='editplan'}
                    checkedIcon={<Check className='checked' />}
                    onClick={e => changeParams('payed', e.target.checked)}
                  />
                </Grid>
                 <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>No Bag Deposit</Text>
                  <Checkbox
                    icon={<Check className='unChecked' />}
                    checkedIcon={<Check className='checked' />}
                    onClick={changeBag}
                  />
                </Grid>
                {!editaredisabled&&<Grid item md={12}> 
                  <Text bold style={{ fontSize: 'large' }} customColor='#555'>
                    Price: <Text bold style={{ fontSize: 'large' }} color='success'>{displayPrice}</Text> <br/>
                    Bag Price: <Text bold style={{ fontSize: 'large' }} color='success'>AED {bagPrice}</Text> <br/>
                    Total Price: <Text bold style={{ fontSize: 'large' }} color='success'>{ `AED ${price/100 + parseFloat(bagPrice)}` }</Text>
                  </Text>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

PlanInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  changeParams: PropTypes.func.isRequired,
  excludedDates: PropTypes.array,
  build: PropTypes.string,
  minDate: PropTypes.number
};

export default PlanInfo;
