import React from 'react';
import { withStyles, Button } from 'material-ui';
import PropTypes from 'prop-types';
import cx from 'classnames';

import buttonStyle from 'assets/jss/material-dashboard-react/buttonStyle';

function RegularButton({ ...props }) {
  const {
    classes,
    color,
    size,
    round,
    children,
    fullWidth,
    disabled,
    pullRight,
    ...rest
  } = props;
  const btnClasses = cx({
    [classes[color]]: color,
    [classes[size]]: size,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.pullRight]: pullRight
  });
  return (
    <Button {...rest} className={classes.button + ' ' + btnClasses}>
      {children || ''}
    </Button>
  );
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'simple',
    'transparent',
    'darkBlue',
    'gray'
  ]),
  round: PropTypes.bool,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  pullRight: PropTypes.bool,
  children: PropTypes.any
};

export default withStyles(buttonStyle)(RegularButton);
