export const SET_MODAL = 'SET_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';

const initialState = {
  display: false,
  content: null
};

export function setModal(obj) {
  return {
    type    : SET_MODAL,
    payload : obj
  };
}

export function clearModal() {
  return {
    type    : CLEAR_MODAL
  };
}

export function updateModal(obj) {
  return {
    type    : UPDATE_MODAL,
    payload : obj
  };
}

export default function modalReducer(state = initialState, action) {
  if (action.type === SET_MODAL) {
    return { ...action.payload, display: true };
  }
  if (action.type === UPDATE_MODAL) {
    return { ...initialState, ...action.payload };
  }
  if (action.type === CLEAR_MODAL) {
    return initialState;
  }
  return state;
}
