export const SET_DEFAULT_SIDE_DISHES = 'SET_DEFAULT_SIDE_DISHES';
export const CLEAR_DEFAULT_SIDE_DISHES = 'CLEAR_DEFAULT_SIDE_DISHES';

const initialState = {};


export function setDefaultDishSides(obj) {
  return {
    type    : SET_DEFAULT_SIDE_DISHES,
    payload : obj
  };
}

export function clearDefaultDishSides() {
  return {
    type    : CLEAR_DEFAULT_SIDE_DISHES
  };
}

export default function defaultDishSidesReducer(state = initialState, action) {
  if (action.type === SET_DEFAULT_SIDE_DISHES) {
    return action.payload;
  }
 
  if (action.type === CLEAR_DEFAULT_SIDE_DISHES) {
    return initialState;
  }
  return state;
}
