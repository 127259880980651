import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'components/Modal/Modal3';
import { unsetModal } from 'store/modalStack';

class ModalStack extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { stack, unsetModal } = this.props;
    return stack.map((modal, idx) => {
      const props = modal.show().props;
      const handleClose = () => {
        unsetModal(idx);
        props.onClose && props.onClose();
      };
      return <Modal key={idx} {...props} handleClose={handleClose} />;
    });
  }
}

ModalStack.propTypes = {
  stack: PropTypes.array.isRequired,
  unsetModal: PropTypes.func
};

const props = state => ({
  stack: state.modalStack
});

const actions = dispatch => ({
  unsetModal: idx => dispatch(unsetModal(idx))
});

export default connect(props, actions)(ModalStack);
