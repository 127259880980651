import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import { Table, TableBody, TableCell, TableRow } from 'material-ui';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import TableHead from 'material-ui/Table/TableHead';
import moment from 'moment';
import Filters from './Filters';
import Text from 'components/Typography/Text';
import Calendar from 'components/Calendar';
import Button from 'components/CustomButtons/Button';  
class RevenueSales extends Component {
  constructor(props) {
    super(props); 
    const today = moment().utc().startOf('day').unix();
    this.state = {
      RevenueSales: [],
      xyz: false,
      orderBy: 'username',
      orderDir: '+',
      onlyActive: false,
      periodTo: today,
      errors : {},
      minDateMore:'',
      lazyfilter:{limit:2,page:1},
    };
    this.getRevenueSales();
  }

  componentWillMount() { 
    this.props.socket.on('finances', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('finances', this.listener);
  }

  listener = ({ type, data }) => {
    
    switch (type) {
      case 'getRevenueSalesOk':
        // this.setState({
        //   RevenueSales: data,
        // })
        const { lazyfilter,RevenueSales } = this.state;
        if(lazyfilter.page==1){
          this.setState({
            RevenueSales : data.RevenueSales,
            minDateMore:data.minDate
          });
        }else{
          // let notify = {...RevenueSales,...data.RevenueSales };
          if(RevenueSales!=undefined){
            let notify = this.mergeObject(RevenueSales,data.RevenueSales);
            this.setState({
              RevenueSales : notify
            });
          }
          
        }
      break;

      default:
        console.log(data.message);
      break;
    }
    
  };

   mergeObject = (target, source) => {
    // console.log(target);
    // console.log(source);
    if(target!=undefined){
      // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
      for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], this.mergeObject(target[key], source[key]))
      }
    
      // Join `target` and modified `source`
      Object.assign(target || {}, source)
      return target;
    }
  }
  getRevenueSales = () =>{ 
    const { lazyfilter } = this.state;  
    this.props.socket.emit('finances', { type: 'getRevenueSales', data: {lazyfilter} })
  };

  exportDaycsv() {
    const { periodTo } = this.state;
    window.open(
      'http://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/dailysalecsv?date='+periodTo,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  exportDayMonthcsv() {
    const { periodTo } = this.state;
    window.open(
      'http://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/dailysalecsv?date='+periodTo+'&ismonth=true',
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  render() {
    const { RevenueSales , periodTo, errors,minDateMore} = this.state;
    // console.log(RevenueSales);
    var rows = [];
    var num = 0;
    var dayold = 99;
    var show = false;
    Object.keys(RevenueSales).reverse().map( (year, idx) => {
     // Object.keys(RevenueSales[year]).map( (month, idl) => {
     

      for (var month=12; month>0; month--) {
        if (typeof(RevenueSales[year][month]) != 'undefined') {
          var refund_amount = 0;
          var refund_count = 0;
          rows.push(<tr className="days-left-line">
            <td colspan="7">{moment().month(parseInt(month)-1).format("MMM")}, {year}</td>
          </tr>);
          var num = 0;
          var dayold = 99;
          var totals = RevenueSales[year][month]['total'];

          var daytotal_amount = 0;
          var daytotal_discount = 0;
          var daytotal_count = 0; 
          
        Object.keys(RevenueSales[year][month]).map( (day, idm) => {
          if (day != 'total') {
            var i = 0;
            Object.keys(RevenueSales[year][month][day]).map( (area, idn) => {
                i = i + 1;
                if (day != dayold) {
                  num = num+1;
                  show = true;
                } else {
                  show = false;
                }

                dayold = day;
                var d = RevenueSales[year][month][day][area];

                daytotal_amount += d.amount;
                daytotal_discount += d.discount;
                daytotal_count += d.count;

                rows.push(<tr>
                  <td>{show ? 'Day '+num : ''}</td>
                  <td>{show ? day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year : ''} </td>
                  <td className="blue-text">{area}</td>
                  <td>{d.count}</td>
                  <td className="green-text">{(d.amount/100).toFixed(2)} AED</td>
                  <td className="green-text">{((d.amount/100)/1.05).toFixed(2)} AED</td>
                  <td className="red-text">{(d.discount/100).toFixed(2)} AED</td>
                </tr>)
                /* Display Cancellation amount & Credit Card amount total  */
                if(d.is_refund!=undefined&&d.is_refund){
                  refund_amount += d.refund_amount;
                  refund_count++;
                  rows.push(<tr>
                    <td colspan="3" className="red-text"><b>Cancellation amount & Credit Card amount total</b></td>
                    <td></td>
                    <td className="green-text" colspan="1">{(d.refund_amount/100).toFixed(2)} AED</td>
                  </tr>)
                }
                
                if (i == Object.keys(RevenueSales[year][month][day]).length) {
                  rows.push(<tr className="finday">
                   
                    <td colspan="3"><b>Day Total:</b></td>
                    <td><b>{daytotal_count}</b></td>
                    <td className="green-text"><b>{(daytotal_amount/100).toFixed(2)} AED</b></td>
                    <td className="green-text"><b>{((daytotal_amount/100)/1.05).toFixed(2)} AED</b></td>
                    <td className="red-text"><b>{(daytotal_discount/100).toFixed(2)} AED</b></td>
                  </tr>)

                  daytotal_amount = 0;
                  daytotal_discount = 0;
                  daytotal_count = 0;
          
                }
            });
          }
        });

       

        rows.push(<tr className="row-border-top">
                <td colspan="3" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
                <td><b>Total Deliveries</b></td>
                <td className="green-text"> <b>Total Amount</b></td>
                <td className="green-text"> <b>Total Sales Excluding Tax</b></td>
                <td className="red-text"> <b>Total Discount</b></td>
        </tr>)
        var total_count = 0;
        var total_amount = 0;
        var total_discount = 0;
        Object.keys(totals).map( (tot, idz) => {
          rows.push(<tr className="row-border-top">
                <td colspan="3" align="left">{tot}</td>
                <td><b>{totals[tot].count}</b></td>
                <td className="green-text"> <b>{(totals[tot].amount/100).toFixed(2)} AED</b></td>
                <td className="green-text"> <b>{((totals[tot].amount/100)/1.05).toFixed(2)} AED</b></td>
                <td className="red-text"> <b>{(totals[tot].discount/100).toFixed(2)} AED</b></td>
          </tr>)
          total_count += totals[tot].count;
          total_amount += totals[tot].amount;
          total_discount += totals[tot].discount;
        });

          rows.push(<tr className="row-border-top-final">
                <td colspan="3" align="left">Final Total Results:</td>
                <td><b>{total_count}</b></td>
                <td className="green-text"><b>{(total_amount/100).toFixed(2)} AED</b></td>
                <td className="green-text"><b>{((total_amount/100)/1.05).toFixed(2)} AED</b></td>
                <td className="red-text"><b>{(total_discount/100).toFixed(2)} AED</b></td>
          </tr>)
          {refund_amount>0&&rows.push(<tr className="row-border-top-final">
          <td colspan="3" className="red-text" align="left">Cancellation amount & Credit Card amount total:</td>
          <td>{refund_count}</td>
          <td colspan="1" className="red-text" align="center"><b>{(refund_amount/100).toFixed(2)} AED</b></td>
          <td></td>
          <td></td>
    </tr>)}
      //});
      } }
    });
    let revenuneYears = Object.keys(RevenueSales).reverse();
    let lastYear =revenuneYears[revenuneYears.length-1];
    let lastMonth =(RevenueSales[lastYear]!=undefined )?Object.keys(RevenueSales[lastYear]).reverse():[];
    if(revenuneYears.length>0 && minDateMore != lastYear+'-'+lastMonth[lastMonth.length-1]){
      rows.push(<tr >
        <td colSpan={7}><Button color={ 'success'} size='medium' onClick={() => {
        const { lazyfilter } = this.state;
        this.setState({
          lazyfilter:{...lazyfilter,page:lazyfilter.page+1},
        },function() {  this.getRevenueSales() });

      }}>Load next 2 months</Button></td>
      </tr>);
    }
    return (
      <div className='xyz-fin'>
        <div className='filtesHolder finances'>
        <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'To date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
          <Button color='success' size='large' onClick={ () => {this.exportDaycsv()}}>Export Day CSV</Button>
          <Button color='success' size='large' onClick={ () => {this.exportDayMonthcsv()}}>Export By Month</Button>
        </div>
        <CustomCard marginOnePx>
          <div>
            <table className="Tabled fintable">
              <thead className="roundedHeader financetbl">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Mealplan Area</th>
                  <th scope="col">No of customers (deliveries per day)</th>
                  <th scope="col">Sales per day by location</th>
                  <th scope="col">Sales excluding Tax</th>
                  <th scope="col">Amount Discounted</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </CustomCard>
      </div>
    );
  }
}

RevenueSales.propTypes = {
  socket: PropTypes.object,
  setExport: PropTypes.func
};

export default socketConnect(RevenueSales);
