import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import { Grid } from 'material-ui';
import Text from '../Typography/Text';

class DelUser extends Component {
  render() {
    const { email, username, confirm, cancel } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item md={12}>
            <RegularCard
              cardTitle={`Are you sure you want to delete ${username}`}
              cardSubtitle={email}
              content={
                <div>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Text paragraph inline={false}>
                        <Text bold inline color={'info'} tag={'span'}>Deactivate</Text>
                        {' option will only set status "deleted" to user and save all information about user.'}
                      </Text>
                      <Text paragraph inline={false}>
                        <Text bold inline color={'danger'} tag={'span'}>Delete</Text>
                        {' option will fully delete all info about user except orders, refunds and other financial information.'}
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Button onClick={() => confirm('deactivate')} color={'info'}>Deactivate</Button>
                      <Button onClick={() => confirm('delete')} color={'danger'}>Delete</Button>
                      <Button onClick={cancel} pullRight color={'darkBlue'}>Cancel</Button>
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

DelUser.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default DelUser;
