import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noImage from 'assets/no_img.svg';
import { ArrowBack } from '@material-ui/icons';
import { updatePlanParams } from 'store/planBuilder';

class DayEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { products, activeDay, includedDishes, updatePlanParams, activeType, activeDiet, dishGroups } = this.props;
    const getRating = (rating) => Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
    const dishList = activeDay !== null ? Object.keys(products || {}).sort((a, b) => products[b].active - products[a].active).reduce((acc, cur, idx) => {
      if (!includedDishes.includes(cur)) {
        const { title, nickname, image, calories, rating, type, diet, active, group } = products[cur];
        const imgUrl = image ? `${image}` : noImage;
        var allergy = '';
        if (products[cur].hasAllergens) {
          allergy = (<span className="allergy-notice-warning">Has allergy Ingredients</span>);
           /*products[cur].allergens.map( (allergen_id, index) => {
            allergy = allergy + ' ' + allergen_id;
          });*/ 
        } 


        return (type.includes(activeType) && diet.includes(activeDiet)) ? [
          ...acc,
          (
            <div className='day' key={idx}>
              <div className='dish'>
                <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
                <p className='title'>{nickname != '' ? nickname : title} - {cur}</p>
                <p className='cal'>{ calories / 1000 } kCal <span className='group'> {dishGroups[group]}</span></p>
                <p className='allergy'>{allergy}</p>
                <p className='rating'>{getRating(rating)}</p>
                <p className={`to-list ${active ? '' : 'disabled'}`} onClick={() => active && updatePlanParams({ [activeDay]: [...includedDishes, cur] })}><ArrowBack /></p>
              </div>
            </div>
          )
        ] : acc;
      } else {
        return acc;
      }
    }, []) : [];
    return ( 
      <Fragment>
        <h5 className='dishes-head'>Available dishes:</h5>
        <div className='holderWithBg dishes scrollable-h'>
          {activeDay !== null ? (dishList.length !== 0 ? dishList : <div className='click-to-edit no-dishes'>No available dishes.</div>)
            : <div className='click-to-edit'>Start editing the daily menu to see the list of available dishes.</div>}
        </div>
      </Fragment>
    );
  }
}

DayEdit.propTypes = {
  products: PropTypes.object,
  dishGroups: PropTypes.object,
  activeDay: PropTypes.number,
  activeType: PropTypes.number,
  activeDiet: PropTypes.number,
  includedDishes: PropTypes.array,
  updatePlanParams: PropTypes.func
};

const props = state => ({
  typeList: state.dataArray.typeList,
  dietList: state.dataArray.dietList,
  planList: state.dataArray.planList,
  //products: state.dataArray.productsFull
});

const actions = dispatch => ({
  updatePlanParams: obj => dispatch(updatePlanParams(obj))
});

export default connect(props, actions, null, { withRef: true })(DayEdit);
