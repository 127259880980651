import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import { ErrorOutline } from '@material-ui/icons';
import { browserHistory } from 'react-router';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ItemGrid from 'components/Grid/ItemGrid';
import SelectInput from 'components/SelectInput';
import sendImage from 'handler/sendImage';
import { unsetErrors } from 'utils';
import { setNotify, clearNotify } from 'store/notify';
import { setBackLocation } from 'store/backLoc';
import TinyMCE from 'react-tinymce';
import Script from 'react-load-script';
import Text from 'components/Typography/Text';

class Editor extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      errors: {},
      data: {},
      tinymceLoaded: !!window.tinymce || false
    };
    this.getPageData(params.id);
  }

  listener = ({ data, type, status }) => {
    if (this.ThisRef && status === 'ok') {
      if (type === 'get') {
        const { data: pageData } = data;
        let content = '';
        try {
          content = decodeURI(atob(pageData.content || ''));
        } catch (e) {
          content = pageData.text || '';
        }
        this.setState({ data: { ...pageData, content } });
      } else if (type === 'set') {
        const { message } = data;
        this.props.setNotify({
          place: 'tc',
          color: 'success',
          icon: ErrorOutline,
          message: message,
          open: true
        });
        setTimeout(() => this.props.clearNotify(), 5000);
        browserHistory.push('/admin/static-pages/');
      }
    } else if (status === 'err') {
      const { message, ...rest } = data;
      'errors' in rest ? this.setState({ errors: rest.errors }) : this.setState({ errors: data });
      console.error(message, rest);
    }
  };

  getPageData = id => this.props.socket.emit('static_pages', { type: 'get', data: { id } });

  submit = e => {
    e.preventDefault();
    const { params } = this.props;
    let pageData = { ...this.state.data };
    pageData.content = btoa(encodeURI(pageData.content));
    if (params.id && params.id !== 'add') {
      pageData.id = params.id;
    }
    this.props.socket.emit('static_pages', { type: 'set', data: pageData });
  };

  componentWillMount() {
    const { socket, setBackLocation } = this.props;
    socket.on('static_pages', this.listener);
    setBackLocation('/admin/static-pages');
  }

  componentWillUnmount() {
    this.props.socket.removeListener('static_pages', this.listener);
  }

  render() {
    const { data, errors, tinymceLoaded } = this.state;
    const { name, description, alias, url, published, content } = data;
    const changeData = (name, value, errorsToUnset) => this.ThisRef && this.setState({
      data: { ...data, [name]: value },
      errors: errorsToUnset ? unsetErrors(errors, errorsToUnset) : unsetErrors(errors, [name])
    });
    const flag = !!Object.keys(data || {}).length;
    return (
      <div ref={el => (this.ThisRef = el)} className='blog'>
        {!window.tinymce && <Script url='//cdn.tinymce.com/4/tinymce.min.js' onLoad={() => this.setState({ tinymceLoaded: true })} />}
        <Grid container>
          {'content' in errors && <ItemGrid md={8}>
            <Text color={'danger'}>Content cannot be blank.</Text>
          </ItemGrid>}
          <ItemGrid md={8}>
            {(window.tinymce && tinymceLoaded && flag) && <TinyMCE
              content={content}
              config={{
                plugins: 'autolink link lists print image imagetools preview code',
                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright code',
                min_height: 500,
                extended_valid_elements: 'span[class]',
                content_css: '/client-styles/main.css',
                imagetools_toolbar: 'imageoptions',
                file_picker_callback: function (callback, value, meta) {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*,image/jpeg,image/png';
                  input.onchange = function () {
                    const file = this.files[0];
                    const reader = new FileReader();
                    reader.onload = function () {
                      const id = 'blobid' + (new Date()).getTime();
                      // eslint-disable-next-line no-undef
                      const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                      const base64 = reader.result.split(',')[1];
                      const blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);
                      callback(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };
                  input.click();
                },
                file_picker_types: 'image',
                images_upload_handler: (blobInfo, success, failure) => {
                  if (blobInfo.blob()) {
                    const formData = new FormData();
                    formData.append('access_token', this.props.userToken);
                    formData.append('type', 'blogPostImage');
                    formData.append('Gallery[image]', blobInfo.blob(), blobInfo.filename());
                    sendImage.call(this, formData, action => {
                      if (action.status === 'success') {
                        success(action.location);
                      } else if (action.status === 'error') {
                        failure(action.message);
                      }
                    });
                  }
                }
              }}
              onChange={e => changeData('content', e.target.getContent())}
            />}
            <Button color='success' onClick={this.submit}>Save</Button>
          </ItemGrid>
          <ItemGrid md={4}>
            <Grid container>
              <ItemGrid md={12}>
                <RegularCard
                  cardTitle='Info'
                  cardSubtitle=''
                  content={
                    <Grid container>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Name'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'name' in errors}
                          inputProps={{
                            value: name || '',
                            onChange: e => changeData('name', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={8}>
                        <CustomInput
                          labelText='Alias'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'alias' in errors}
                          inputProps={{
                            value: alias || '',
                            onChange: e => changeData('alias', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={4}>
                        <SelectInput
                          labelText='Published'
                          items={{
                            '1': 'Yes',
                            '0': 'No'
                          }}
                          value={'' + published || '1'}
                          name='published'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeData('published', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Description'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'description' in errors}
                          inputProps={{
                            value: description || '',
                            onChange: e => changeData('description', e.target.value),
                            multiline: true,
                            rows: '5'
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Alternative URL'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'url' in errors}
                          inputProps={{
                            value: url || '',
                            onChange: e => changeData('url', e.target.value)
                          }}
                        />
                      </ItemGrid>
                    </Grid>
                  }
                />
              </ItemGrid>
            </Grid>
          </ItemGrid>
        </Grid>
      </div>
    );
  }
}

Editor.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired,
  setBackLocation: PropTypes.func.isRequired
};

const props = state => ({
  userToken: state.user.token
});

const actions = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props)),
  setBackLocation: loc => dispatch(setBackLocation(loc))
});

export default socketConnect(connect(props, actions)(Editor));
