import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import Calendar from 'components/Calendar';
import Button from 'components/CustomButtons/Button'; 
import moment from 'moment';

class CarryForward extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            carryForward: [],
            periodTo:null,
            periodFrom:null,
            errors:[]
        }
        this.getCarryForward();
    }
    componentWillMount() { 
        this.props.socket.on('finances', this.listener);
    }
    
    componentWillUnmount() {
     this.props.socket.removeListener('finances', this.listener);
    }
    
    listener = ({ type, data }) => {
    
        switch (type) {
            case 'carryForwardOk':
            this.setState({
                carryForward: data,
            })
            break;

            default:
            console.log(data.message);
            break;
        }
    
    }
    getCarryForward = () => this.props.socket.emit('finances', { type: 'carryForward', data: {} });
    exportDaycsv() {
        const { periodTo } = this.state;
        window.open(
          'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/carry-forward?date='+periodTo,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
      render() {
        const { periodTo, periodFrom,errors ,carryForward } = this.state;
        var rows = [];
        carryForward&&Object.keys(carryForward).map( (year, idx) => {
          let tactivemonth=0;
          let tpausemonth=0;
          let tccount=0;
          let tpcount=0;
           Object.keys(carryForward[year]).map( (month, idm) => {
               const { pcount,ccount, activemonth, pausemonth } = carryForward[year][month];
               tactivemonth+=activemonth;
               tpausemonth+=pausemonth;
               tccount+=ccount;
               tpcount+=pcount;
            rows.push(<tr>
            <td >{year}</td>
            <td >{moment().month(parseInt(month)-1).format("MMM")}</td>
            <td >{ccount}</td>
            <td >{pcount}</td>
            <td >{(activemonth/100).toFixed(2)} AED</td>
            <td >{(pausemonth/100).toFixed(2)} AED</td>
            <td >0 AED</td>

          </tr>);
           });
           rows.push(<tr className="finday">
                   
           <td colspan="2"><b>Total:</b></td>
           <td><b>{tccount}</b></td>
           <td><b>{tpcount}</b></td>
           <td className="green-text"><b>{(tactivemonth/100).toFixed(2)} AED</b></td>
           <td className="green-text"><b>{(tpausemonth/100).toFixed(2)} AED</b></td>
           <td ><b>0 AED</b></td>
         </tr>)
        });

        return <div>   
            <div className=' finances'>
      
        <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'Select month to export'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            view={'year'}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
         
          <Button color='success' size='large' onClick={ () => {this.exportDaycsv()}}>Export CSV</Button>
        </div>
            <CustomCard marginOnePx>
        <div>
          <table className="Tabled fintable">
            <thead className="roundedHeader financetbl">
              <tr id="header-row">
                <th scope="col" rowspan="2">Year</th>
                <th scope="col" rowspan="2">Month</th>
                <th scope="col" colspan="2">No Customer</th>
                <th scope="col" colspan="2">End of the Month-Balance</th>
                <th scope="col" rowspan="2">Bag Deposit Balance</th>
              </tr>
              <tr className='days-left-line'>
                <th>Active</th>
                <th>Pause</th>
                <th>Active</th>
                <th>Pause</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </CustomCard></div>;
      }
}
CarryForward.propTypes = {
    socket: PropTypes.object,
    setExport: PropTypes.func
  };
export default socketConnect(CarryForward);
  