import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui';

import typographyStyle from 'assets/jss/material-dashboard-react/typographyStyle.jsx';

function Text({ ...props }) {
  const { classes, children, color, customColor, bold, inline, paragraph, style, className, tag, ...rest } = props;
  const colorClass = classes[`${color}Text`];
  let classNames = classes.defaultFontStyle;
  classNames = customColor ? classNames : `${classNames} ${colorClass}`;
  classNames = bold ? `${classNames} ${classes.bold}` : classNames;
  classNames = inline ? `${classNames} ${classes.inline}` : classNames;
  classNames = className ? `${classNames} ${className}` : classNames;
  const customStyle = style || {};
  customColor && (customStyle.color = customColor);
  const Tag = tag || (paragraph ? 'p' : 'div');
  return (
    <Tag {...rest} className={classNames} style={customStyle}>
      {children}
    </Tag>
  );
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  children: PropTypes.any,
  color: PropTypes.oneOf(['muted', 'primary', 'info', 'success', 'warning', 'danger', 'white', 'darkBlue']),
  customColor: PropTypes.string,
  className: PropTypes.string,
  bold: PropTypes.bool,
  paragraph: PropTypes.bool,
  inline: PropTypes.bool,
  tag: PropTypes.oneOf(['div', 'span', 'p'])
};

Text.defaultProps = {
  color: 'muted',
  inline: true,
  paragraph: false
};

export default withStyles(typographyStyle)(Text);
