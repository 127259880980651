import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'components/ToolTip';
import './marker_style.css';

class Marker extends Component {
  render() {
    const { hoveredAtTable, title, image, hint, style } = this.props;
    const height = 36;
    const width = height / 1.33;
    const left = -width / 2;
    const top = -height;
    const marker = <div
      className='marker-holder'
      style={{
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
        backgroundImage: `url(${image})`,
        zIndex: hoveredAtTable ? 11000 : 6500,
        ...style
      }}
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      onClick={this.props.onClick}
    >
      <p className='marker-title'>
        {title}
      </p>
    </div>;
    return hint ? <ToolTip tooltipText={hint} trigger={['hover']} tooltipDirection={'top'} children={marker} /> : marker;
  }
}

Marker.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hoveredAtTable: PropTypes.bool,
  style: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func
};

Marker.defaultProps = {
  style: {}
};

export default Marker;
