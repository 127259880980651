import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
//import CustomInput from 'components/CustomInput/CustomInput';
//import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class AdditionalNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: null,
    };
  }

  render() {
    const { note } = this.state;
    const { save, orderId, date, dishId, dishName } = this.props;
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Add a Note to the {dishName}</Text>
            </Grid>
            <Grid item md={12}>
                  <textarea 
                    className="notePopupTextArea"
                    onChange={ 
                      (e) => this.setState({note: e.target.value})
                    }
                  >
                  </textarea>
            </Grid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(dishId, orderId, note, date)}>Save</Button>
        </div>
      </div>
    );
  }
}


AdditionalNotesModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default AdditionalNotesModal;