import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import { Select2 } from "select2-react-component";
import CustomInput from 'components/CustomInput/CustomInput';
import { Close, Check } from '@material-ui/icons/index';

class Sides extends Component { 
  constructor(props) {
    super(props);
    const {product_id, products, side_type} = props;
    this.state = {
        side: null,
        protein: 0,
        calories: 0,
        fat: 0,
        cho: 0,
        errors: [],
        product_id: product_id,
        diet: null,
        is_default: false,
    };
  }

  onChange(name, value) {
    switch(name) {
      case 'side':
        const {products} = this.props;
        this.setState({
          protein: products[value].protein/1000,
          calories: products[value].calories/1000,
          fat: products[value].fat/1000,
          cho: products[value].carb/1000,
          side: value,
        });
      break;

      case 'is_default':
        const {is_default} = this.state;
        this.setState({
          is_default: !is_default
        })
      break;
      
      default:
      this.setState({
        [name]: value,
      })
      break;
    }

  }

  saveSides() {
    const {side, product_id, diet, is_default} = this.state;
    var data = { productId: product_id, sideId: side, diet: diet, isDefault: is_default }
    
    this.props.socket.emit('menu_builder', {
      type: 'saveSides',
      data: data
    }); 
  }

  delSides(id) {
    var data = { dataId: id }
    
    this.props.socket.emit('menu_builder', {
      type: 'delSides',
      data: data
    }); 
  }

  renderList = () => {
    const {sidesdata, dietList, products} = this.props;

    var rows = [];
    Object.keys(sidesdata).map( (el, idx) => {
      var item = sidesdata[el];
      console.log(products[item.side_id]);
      rows.push(
        <ItemGrid md={12} container>
          <ItemGrid md={2}>
            {dietList[item.diet_id] || 'no diet'}
          </ItemGrid>
          <ItemGrid md={3}>
            {products[item.side_id].title || 'no product'}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].fat/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].protein/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].carb/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].calories/1000 || 0}
          </ItemGrid>
          <ItemGrid md={2}>
            {item.is_default ? <span className="defaultDish">default</span> : ''}
          </ItemGrid>
          <ItemGrid md={1}>
            <Button size='small' color='white' onClick={(e) => {this.delSides(item.id)}}><Close color='error' /></Button>
          </ItemGrid>
        </ItemGrid>
      )
    });
    
    return rows;
  }

  renderComponentFields = (id) => {
    const {protein, fat, cho, calories, side, diet, is_default, errors} = this.state;
    const {products, dietList, sidesList} = this.props;
  

    return (
      <ItemGrid md={12} key={side} container>
        <ItemGrid md={2}>
          <Select
            placeholder={'Diet'}
            list={dietList}
            multiple={true}
            value={diet || ''}
            name={'diet'}
            onChange={(name, value) => this.onChange(name, value)}
          />
        </ItemGrid>
        <ItemGrid md={3}>
          {/*<Select
            placeholder={'Side'}
            list={sides}
            value={side || ''}
            name={'side'}
            onChange={(name, value) => this.onChange(name, value)}
          />*/}

          {sidesList && <Select2 
            placeholder='Side'
            data={sidesList} 
            value={side || ''}
            update={ 
              (value) => this.onChange('side', value)
            }>
          </Select2>}
        
        </ItemGrid>
        <ItemGrid md={1}>
                      <CustomInput
                        labelText={'fat'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'fat' in errors}
                          inputProps={{
                              value: fat || '',
                              type: 'text',
                              name: 'symbol',
                              disabled: 'disabled',
                              onChange: (e) => { this.onChange('fat', e.target.value)}
                          }}
                      />
        </ItemGrid>
        <ItemGrid md={1}>
                      <CustomInput
                        labelText={'protein'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'protein' in errors}
                          inputProps={{
                              value: protein || '',
                              type: 'text',
                              name: 'symbol',
                              disabled: 'disabled',
                              onChange: (e) => { this.onChange('protein', e.target.value)}
                          }}
                      />
        </ItemGrid>
        <ItemGrid md={1}>
                      <CustomInput
                        labelText={'cho'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'cho' in errors}
                          inputProps={{
                              value: cho || '',
                              type: 'text',
                              name: 'symbol',
                              disabled: 'disabled',
                              onChange: (e) => { this.onChange('cho', e.target.value)}
                          }}
                      />
        </ItemGrid>
        <ItemGrid md={1}>
                      <CustomInput
                        labelText={'calories'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'calories' in errors}
                          inputProps={{
                              value: calories || '',
                              type: 'text',
                              name: 'symbol',
                              disabled: 'disabled',
                              onChange: (e) => { this.onChange('calories', e.target.value)}
                          }}
                      />
        </ItemGrid>
        <ItemGrid md={3}>
            <div className='checkHolder'>
              <Checkbox
                checked='false'
                checkedIcon={<Check className={`${is_default ? 'checked' : 'unChecked'} `} />}
                icon={<Check />}
                onClick={(e) => { this.onChange('is_default') } }
                classes={{
                  checked: 'unChecked'
                }}
              /> default
            </div>
        </ItemGrid>
        {/*<ItemGrid md={2}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>*/}
      </ItemGrid>
    );
  };

  render() {
    const {sidesdata} = this.props;

    return (
      <div>
      <Grid container>
        {this.renderComponentFields()}
        <Button color='darkBlue' onClick={ (e) => this.saveSides()}>Save</Button>
      </Grid>
      <h3 className="list-subhead">Side Lists</h3>
        <div className="sides-text">
        {sidesdata && <Grid container>{this.renderList()}</Grid>}
        </div>
      </div>
    );
  }
}

Sides.propTypes = {
  products: PropTypes.object.isRequired,
};

export default Sides;