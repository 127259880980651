import React,{ Component,Fragment } from 'react';
import { ArrowDropDown,ArrowDropUp } from '@material-ui/icons';
import { browserHistory } from 'react-router';
const ITEM_HEIGHT = 48;
class DropdownButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
        isListOpen: false,
    }
  }
  toggleList = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
   }))
 }

 handleClose = () => {
    this.setState({
      isListOpen: false,
    });
  }
  close = () => {
    this.setState({
      isListOpen: false,
    });
  }
  componentDidUpdate(){
    const { isListOpen } = this.state;
  
    setTimeout(() => {
      if(isListOpen){
        window.addEventListener('click', this.close)
      }
      else{
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }
  render() {
    const { isListOpen } = this.state;
    const { labelText, options,showModal,classes,is_future } =this.props;
    const isOpenclass= (isListOpen)?'dropOpen':'';
    return (
      <div className="dd-wrapper">
        <button
          type="button"
          className={`dd-header actionBtn ${classes} ${isOpenclass}`}
          onClick={this.toggleList}
        >
          <span className="dd-header-title">{labelText}</span>
          {/* {isListOpen
            ? <ArrowDropUp  />
            : <ArrowDropDown  />} */}
        </button>
        {isListOpen && (
          <ul
            role="list"
            className={`dd-list dropdownMenu ${classes}`}
          >
            {options && options.filter(el => el.isactive).map((option) => (
              <li
                type="button"
                className="dd-list-item dropDownMenuitem"
                key={option.label} 
                onClick={(e) => { e.stopPropagation(); this.handleClose(); 
                    const { actionType,action,param,callfunc } =option;
                        if(action!=undefined){
                         showModal(action,param,is_future||false);
                        }else if(actionType!=undefined && actionType=='browser'){
                          browserHistory.push(param);
                        }else if(actionType!=undefined && actionType=='func' && callfunc != undefined){
                          if(typeof param === 'object' && option.label!='Payement Link Status'){
                            const {user_id, order_id,$is_arabic} =param;
                            if(labelText=='Communications'){
                              callfunc(order_id,$is_arabic);
                            }else{
                               callfunc(user_id, order_id,$is_arabic);
                            }
                          }else{
                           callfunc(param);
                          }
                         
                        }
                    }
                    }
              >
                {option.label}
                {' '}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

export default DropdownButtons;