import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import moment from 'moment';
import Calendar from 'components/Calendar';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day').unix();
    //const fromval = moment().subtract(30, 'days').calendar();
    this.state = {
      periodFrom: null,
      periodTo: today,
      errors : {},
    }
  }

  doFilter() {
    this.props.socket.emit('order', { type: 'getNotifications', data: { dateFrom: this.state.periodFrom, dateTo: this.state.periodTo } });
  }
 
  render() {
    const {periodFrom, periodTo, errors} = this.state;
   

    return (
      <div className='fix-on-filters'>
          <Calendar
            date={periodFrom}
            name={'periodFrom'}
            title={'From date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodFrom: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />

          <Calendar
            date={periodTo}
            name={'periodTo'}
            title={'To date'}
           // minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              this.setState({
                periodTo: value,
              });
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
         
          <Button color='danger' size='large' onClick={() => {
              this.setState({
                periodFrom: null,
                periodTo: null,
              });
          }}>Clear</Button>
          <Button color='darkBlue' size='large' onClick={ () => {this.doFilter()}}>Apply</Button>
          
      </div>
    );
  }
}

export default Filters;