export const ADD_PIN = 'ADD_PIN';
export const REMOVE_PIN = 'REMOVE_PIN';
export const CLEAR_GROUP_PINS = 'CLEAR_GROUP_PINS';
export const CLEAR_ALL_PINS = 'CLEAR_ALL_PINS';

const initialState = {
  customers: {}
};

export function addPin(group, pin) {
  return {
    type    : ADD_PIN,
    payload : { group, pin }
  };
}

export function removePin(group, pin) {
  return {
    type    : REMOVE_PIN,
    payload : { group, pin }
  };
}

export function clearGroupPins(group) {
  return {
    type    : CLEAR_GROUP_PINS,
    payload : { group }
  };
}

export function clearAllPins() {
  return {
    type    : CLEAR_ALL_PINS
  };
}

export default function pinsReducer(state = initialState, action) {
  const { group, pin } = action.payload || {};
  if (action.type === ADD_PIN && group && pin) {
    return { ...state, [group]: { ...(state[group] || {}), ...pin } };
  }
  if (action.type === REMOVE_PIN && group && pin) {
    if (state[group] && state[group][pin]) {
      let newState = { ...state };
      delete newState[group][pin];
      return newState;
    }
    return state;
  }
  if (action.type === CLEAR_GROUP_PINS && group) {
    return { ...state, [group]: {} };
  }
  if (action.type === CLEAR_ALL_PINS) {
    return initialState;
  }
  return state;
}
