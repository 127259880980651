import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from 'material-ui';
import { Add ,Edit, Delete, Visibility,AccountCircle,Lock, FileDownload, HighlightOff, CheckCircle } from '@material-ui/icons';
import tasksStyle from 'assets/jss/material-dashboard-react/tasksStyle.jsx';
import TableHead from 'material-ui/Table/TableHead';
import CustomInput from 'components/CustomInput/ItselfInput';
import Select from 'components/Select';
import noImage from 'assets/no_img.svg';
import Button from 'components/CustomButtons/Button';
import Calendar from 'components/Calendar';

class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.filtersRefs = {};
  }

  renderFilter = (name, { type, label, placeholder, value, callback, items }) => {
    let filterInput = '';
    switch (type) {
      case 'text':
        filterInput = <CustomInput
          ref={el => (this.filtersRefs[name] = el)}
          labelText={label || ''}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value,
            name,
            onChange: callback
          }}
        />;
        break;
      case 'number':
        filterInput = <CustomInput
          ref={el => (this.filtersRefs[name] = el)}
          labelText={label || ''}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: 'number',
            value,
            name,
            onChange: callback
          }}
        />;
        break;
      case 'select':
        filterInput = <Select
          ref={el => (this.filtersRefs[name] = el)}
          placeholder={label || ''}
          list={items}
          value={value}
          itself
        />;
        break;
      case 'switch':
        filterInput = <Select
          ref={el => (this.filtersRefs[name] = el)}
          placeholder={label || ''}
          list={{
            1: 'Yes',
            0: 'No'
          }}
          value={value}
          itself
        />;
        break;
      case 'date':
        filterInput = <Calendar
          date={value}
          name={name}
          title={placeholder || label || ''}
          ref={el => (this.filtersRefs[name] = el)}
          formControlProps={{
            fullWidth: true
          }}
        />;
        break;
    }
    return filterInput;
  };

  collectFilters = (e) => {
    e.preventDefault();
    const { filters } = this.props;
    let flag = false;
    Object.keys(this.filtersRefs).forEach(name => {
      const ref = this.filtersRefs[name];
      if (ref && ref.state) {
        flag = flag || ref.state.value !== filters[name].value;
        filters[name].value = ref.state.value;
      }
    });
    flag && this.props.submitFilters(filters);
  };

  renderHeaderRow = (cols, classes) => {
    return <TableRow id='header-row'>
      {Object.keys(cols).map((col, key) => {
        const prop = cols[col];
        let width = 'auto';
        switch (col) {
          case 'index':
            width = '50px';
            break;
          case 'actions':
            width = '100px';
            break;
        }
        return (
          <TableCell
            className={classes.tableCell + ' ' + classes.tableHeadCell}
            key={key}
            style={{ width }}
          >
            {prop instanceof Object ? prop.label : prop}
          </TableCell>
        );
      })}
    </TableRow>;
  };

  renderActions = (id, row) => {
    const { classes, actions } = this.props;
    const commonClasses = classes.tableActionButtonIcon;
    const getClasses = classes => `${commonClasses} ${classes}`;
    return Object.keys(actions).map((el, idx) => {
      const { tooltip, button, callback, icon } = actions[el];
      const onClickAttr = el === 'download' ? row.filename : id;
      const icons = {
        add: <Add className={getClasses(classes.add)} />,
        update: <Edit className={getClasses(classes.edit)} />,
        close: <Delete className={getClasses(classes.close)} />,
        view: <Visibility className={getClasses(classes.view)} />,
        pass: <Lock className={getClasses(classes.view)} />,
        download: <FileDownload className={getClasses(classes.view)} />,
        activate: <CheckCircle className={getClasses(classes.success)} />,
        del: <HighlightOff className={getClasses(classes.close)} />,
        editaccess: <AccountCircle className={getClasses(classes.edit)} />,
        custom: typeof icon === 'function' && icon(row, type => getClasses(classes[type]))
      };
      return <Tooltip
        key={idx}
        id='tooltip-top'
        title={typeof tooltip === 'function' ? tooltip(row) : tooltip}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          onClick={() => callback(onClickAttr)}
          aria-label={button || ''}
          className={classes.tableActionButton}
        >
          {icons[el]}
        </IconButton>
      </Tooltip>;
    });
  };

  getMiddleware = middleware => {
    const list = {
      decToArray(value) {
        return [...value.toString(2).padStart(7, 0)].reverse().reduce((acc, cur, idx) => +cur ? [...acc, idx] : acc, []);
      },
      formatVouchers(value = '', { props }, { type }) {
        const { template, mul } = (props['formatter'] || {})[type];
        return (template || '').replace('$VAL$', `${+value * (mul || 1)}`);
      },
      usersPrivilege(value = '', { datas }, { type }){
        const accesslevel=value&&Object.keys(value).filter(key => value[key]!=undefined && value[key]==1).map(function(key){
            return datas[key];
        });
        return (accesslevel)?accesslevel.join(", "):'';
      },
      default: data => data
    };
    return list[middleware] || list.default;
  };
  isJsonString =(str) =>{
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
  renderCell = (row, elName) => {
    let data = row[elName];
    const { mappedList } = this.props;
    if(data == undefined && mappedList && elName){
      data =(mappedList[row.id]!=undefined)?mappedList[row.id][elName]||'':data;
    }
    const { cols } = this.props;
    const item = cols[elName];
    if (item instanceof Object) {
      let result = '';
      const { type, props } = item;
      const mw = item['middleware'];
      if (mw) {
        const func = this.getMiddleware(mw);
        result = func(data, item, row);
      } else {
        switch (type) {
          case 'text':
            result = (data || '').length > 30 ? `${data.substr(0, 30)}...` : (data || '');
            break;
          case 'number':
            result = data * (props.mul || 1);
            break;
          case 'image':
            const image = data ? props['pathMin'] + data : noImage;
            result = <div className='image-preview-320' style={{ backgroundImage: `url(${image})` }} />;
            break;
          case 'select':
            const items = props.items || {};
            if (props.multiple) {
              if(this.isJsonString(data)){
                data = JSON.parse(data);
                if(data && data.length==undefined){
                  data=[data];
                }
              }else if (typeof data === 'string' ){
                data = data.split(',');
              }
              result = data && data.reduce && data.length ? data.reduce((acc, cur, idx) => `${acc}${items[cur]}${idx < data.length - 1 ? ', ' : ''}`, '') : '';
            } else {
              result = items[data];
            }
            break;
          case 'switch':
            result = +data ? 'Yes' : 'No';
            break;
          case 'date':
            result = moment.unix(data).format(props.format || 'DD/MM/YYYY');
            break;
          default:
            result = data;
            break;
        }
      }
      return result;
    } else {
      return data;
    }
  };

  componentDidMount() {
    // this.equalCols();
  }

  equalCols = () => {
    const filters = document.getElementById('filter-row');
    if (filters) {
      const header = document.getElementById('header-row');
      const fChildren = [...filters.children];
      const hChildren = [...header.children];
      fChildren.forEach((el, idx) => {
        el.style.width = window.getComputedStyle(hChildren[idx]).width;
      });
    }
  };

  componentWillReceiveProps(next) {
    this.equalCols();
  }

  filterClear = (e) => {
    e.preventDefault();
    Object.keys(this.filtersRefs).forEach(name => {
      const ref = this.filtersRefs[name];
      if (ref.state) {
        this.props.filters[name].value = '';
        ref.state.value = '';
      }
    });
    this.props.submitFilters(this.props.filters);
  };

  render() {
    const { classes, rows, cols, filters } = this.props;
    return (
      <Fragment>
        <form onSubmit={this.collectFilters}>
          <div className={'filters styled'}>
            <div className='canclear-form-inputs'>
              {!!Object.keys(filters).length && <div className={'whiteRow finances'} id='filter-row'>
                {Object.keys(filters).map((elName, idx) => {
                  const filter = elName in filters ? this.renderFilter(elName, filters[elName]) : '';
                  return <div
                    key={idx}
                    className={'filter'}
                  >
                    {filter}
                  </div>;
                })}
                <div className='buttons-on-filters'>
                  <Button color='danger' size='large' onClick={(e) => {
                    this.filterClear(e);
                  }}>Clear</Button>
                  <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
                </div>
              </div>}
            </div>
          </div>
        </form>
        <Table className={classes.table}>
          {(cols && Object.keys(cols).length) ? (
            <TableHead
              className='primaryTableHeader roundedHeader'
            >
              {this.renderHeaderRow(cols, classes)}
            </TableHead>
          ) : null}
          <TableBody>
            {rows.map((row, idx) => {
              const id = row.id || row.index || row.slug;
              return <TableRow key={idx} className={classes.tableRow + ' whiteRow'}>
                {Object.keys(cols).map((elName, idx) => {
                  let res = '';
                  let className = '';
                  if (elName === 'actions') {
                    res = this.renderActions(id, row);
                    className = classes.tableActions;
                  } else {
                    res = this.renderCell(row, elName);
                    className = classes.tableCell;
                  }
                  return (
                    <TableCell key={idx} className={className}>
                      {res}
                    </TableCell>
                  );
                })}
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

DataGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  submitFilters: PropTypes.func.isRequired,
  cols: PropTypes.object,
  actions: PropTypes.object,
  filters: PropTypes.object
};

DataGrid.defaultProps = {
  cols: {
    index: '#',
    content: 'Content',
    actions: 'Actions'
  },
  actions: {
    update: () => {},
    delete: () => {}
  },
  filters: {}
};

export default withStyles(tasksStyle)(DataGrid);
