export const SET_BACK_LOCATION = 'SET_BACK_LOCATION';
export const CLEAR_BACK_LOCATION = 'CLEAR_BACK_LOCATION';

const initialState = '';

export function setBackLocation(str) {
  return {
    type    : SET_BACK_LOCATION,
    payload : str
  };
}

export function clearBackLocation() {
  return {
    type    : CLEAR_BACK_LOCATION
  };
}

export default function backLocationReducer(state = initialState, action) {
  if (action.type === SET_BACK_LOCATION) {
    return action.payload;
  }
  if (action.type === CLEAR_BACK_LOCATION) {
    return initialState;
  }
  return state;
}
