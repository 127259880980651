export const BURGER_TOGGLE = 'BURGER_TOGGLE';
export const BURGER_HIDE = 'BURGER_HIDE';

const initialState = false;

export function burgerHide() {
  return {
    type : BURGER_HIDE
  };
}

export function burgerToggle() {
  return {
    type : BURGER_TOGGLE
  };
}

export default function burgerMenuReducer(state = initialState, action) {
  if (action.type === BURGER_TOGGLE) {
    return !state;
  }
  if (action.type === BURGER_HIDE) {
    return initialState;
  }
  return state;
}
