import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || this.props.inputProps.min || 0
    };
  }

  componentWillReceiveProps(next) {
    const { value } = next;
    if (this.Ref && value !== this.state.value) {
      this.setState({ value });
    }
  }

  render() {
    let { value } = this.state;
    let { inputProps } = this.props;
    inputProps.type = 'number';
    const index = inputProps.step ? inputProps.step.indexOf('.') : -1;
    if (index > -1) {
      const fix = inputProps.step.substr(index + 1).length;
      value = value.toFixed(fix);
    }
    return <input
      {...inputProps}
      value={value}
      onChange={e => this.setState({ value: +e.target.value })}
      ref={el => (this.Ref = el)}
    />;
  }
}

NumberInput.propTypes = {
  value: PropTypes.number.isRequired,
  inputProps: PropTypes.object
};

NumberInput.defaultProps = {
  inputProps: {}
};

export default NumberInput;
