import React, { Component, Fragment } from 'react';
import { browserHistory, Link } from 'react-router';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import Model from 'routes/Model';

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    const { setExport, params } = this.props;
    setExport({ type: params.type });
  }

  render() {
    const { loading } = this.state;
    const { params } = this.props;
    const { type } = params;
    const Butt = ({ to, title }) => {
      return <Link to={`/admin/finances/vouchers/${to}`} onlyActiveOnIndex={false}>
        <Button color={to === type ? 'darkBlue' : 'white'} onClick={() => this.setState({ loading: true })}>{title}</Button>
      </Link>;
    };
    return <Fragment>
      <Butt to={'personal'} title={'Personal'} />
      <Butt to={'common'} title={'Common'} />
      <div style={{ display: loading ? 'none' : 'block' }}>
        {type && <Model
          customModelName={{ personal: 'Coupon', common: 'CommonVoucher' }[type]}
          needBackButt={false}
          onLoaded={() => this.setState({ loading: false })}
          needTitle={false}
          customAddLink={`/admin/finances/vouchers/${type}/add`}
          customEditCallback={id => browserHistory.push(`/admin/finances/vouchers/${type}/edit/${id}`)}
        />}
      </div>
    </Fragment>;
  }
}

Vouchers.propTypes = {
  setExport: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
};

export default Vouchers;
