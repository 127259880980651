import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        sort: '-id'
      },
      offset: props.offset
    };
  }

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['dietList', 'dishTypes', 'dishGroups', 'dishPacks', 'typeList'].filter(el => !(el in dataArray));
    if (types.length) {
      getArrays(socket, types);
    }
  }

  componentWillReceiveProps(next) {
    if (this.FilterRef && next.offset !== this.state.offset) {
      this.setState({ offset: next.offset });
    }
  }

  render() {
    const { filters, offset } = this.state;
    const { dataArray, errors, applyFilters } = this.props;
    const onChange = e => {
      const { name, value } = e.target;
      this.setState({ filters: { ...filters, [name]: value }, offset: name === 'sort' ? offset : 0 });
    };
    const { dietList, dishTypes, typeList: originTypeList } = dataArray;
    const sortList = {
      '-id': 'Newest First',
      '+id': 'Latest First',
      '+name': 'Title ASC',
      '-name': 'Title DESC',
      '+rating': 'Rating ASC',
      '-rating': 'Rating DESC',
      '+calories': 'Calories ASC',
      '-calories': 'Calories DESC'
    };
    const typeList = originTypeList && Object.keys(originTypeList).reduce((acc, cur) => ({ ...acc, [+cur]: originTypeList[+cur].title }), {});
    const rFlag = !!(dietList && dishTypes && typeList);
    return rFlag ? (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters, offset);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters, offset);
            }
          }}
        >
          <CustomInput
            labelText='Search by dish number'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '9%'
              }
            }}
            error={'id' in errors}
            inputProps={{
              value: filters.id || '',
              type: 'text',
              name: 'id',
              onChange
            }}
          />
          <CustomInput
            labelText='Search by title'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '20%'
              }
            }}
            error={'title' in errors}
            inputProps={{
              value: filters.title || '',
              type: 'text',
              name: 'title',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by program'
            items={{ '': 'All', ...dietList }}
            value={filters.diet || ''}
            error={'diet' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '14%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'diet',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by type'
            items={{ '': 'All', ...dishTypes }}
            value={filters.type || ''}
            error={'type' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '14%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'type',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by dish or meal type'
            items={{ '': 'All', ...typeList }}
            value={filters.mealType || ''}
            error={'mealType' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '14%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'mealType',
              onChange
            }}
          />
          <SelectInput
            labelText={`Sort by ${sortList[filters.sort] || ''}`}
            items={sortList}
            value={filters.sort || ''}
            error={'sort' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '14%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'sort',
              onChange
            }}
          />
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters({ sort: '-id' }, offset);
              this.setState({ filters: { sort: '-id' } });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    ) : null;
  }
}

Filters.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  errors: PropTypes.object,
  offset: PropTypes.number.isRequired,
  applyFilters: PropTypes.func.isRequired
};

Filters.defaultProps = {
  errors: {}
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props, null)(Filters));
