import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import Days from 'components/EditPlanDay';
import { clearModal, setModal } from 'store/modal';
import { SidesModalNew,SideDishModal } from './Details/Modals';
import { Alert } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ModalWrapper from 'components/Modal';
class EditPlan extends Component {

	constructor(props) { 
    	super(props);
    	const {params, dataArray, userData} = props; 
  		const {order_id, user_id} = params;
  		this.initialState = { 
	     	order_id: order_id,
    		user_id: user_id,
    		mealplan: false,
        altmeals: false, 
        altmealSides: false, 
    		edit: false,
        firstName: null,
        lastName: null, 
        all_notes: [],
        pauses: false,
        usersides: [],
        sidesList: [],
        showDialog:false,
        openDialog:false,
        openDialogContent:'',
        editdaylist:{}
	    };
    	this.state = this.initialState;
    	this.getOrder(order_id);
      this.getAlternativeMeals(order_id);
      this.getAlternativeMealSides(order_id);
      this.getProfile(user_id);
      this.getNotes(user_id, order_id);
      this.getPauseInfo(order_id);
      this.getDietProducts(order_id);
      this.getUserSides(user_id, order_id);
      this.getSidesList(order_id);
	}

  	componentWillMount() {
  		this.props.socket.on('order', this.listener);
      this.props.socket.on('profile', this.listener);
      this.props.socket.on('default_dishes', this.listener);
      this.props.socket.on('default_dish_sides', this.listener);
      this.props.socket.on('menu_builder', this.listener);
  	}

  	componentWillUnmount() {
    	this.props.socket.removeListener('order', this.listener);
      this.props.socket.removeListener('profile', this.listener);
      this.props.socket.removeListener('default_dishes', this.listener);
      this.props.socket.removeListener('default_dish_sides', this.listener);
      this.props.socket.removeListener('menu_builder', this.listener);
  	}

  	getOrder(orderId) {
	    this.props.socket.emit('order', { type: 'getAllPlan', data: { orderId: orderId } });
  	}

    getPauseInfo(orderId) {
      this.props.socket.emit('order', { type: 'getPause', data: { orderId: orderId } });
    };

    getAlternativeMeals(orderId) {
      this.props.socket.emit('order', { type: 'getAlternativeMeals', data: { orderId: orderId, withsides: true } });
    }
    getAlternativeMealSides(orderId) {
      this.props.socket.emit('order', { type: 'getAlternativeMealSides', data: { orderId: orderId, withsides: true } });
    }
    getProfile(userId) {
      this.props.socket.emit('profile', { type: 'get', data: { userId: userId } });
    }

    getNotes(user_id, order_id) {
      this.props.socket.emit('default_dishes', { type: 'getNotes', data: { order_id: order_id, user_id: user_id } });
    }

    getDietProducts(order_id, user_id) { 
      this.props.socket.emit('order', { type: 'getDietProducts', data: { orderId: order_id, userId: user_id} });
    }

    getUserSides(user_id, order_id) {
      var data = {userId: user_id, orderId: order_id}
      this.props.socket.emit('order', { type: 'getUserSides', data: data });
    }

    
    getSidesList = (order_id) => this.props.socket.emit('menu_builder', { type: 'getSidesList', data: { orderId: order_id } });
    
    delUserSides = (entry_id) => this.props.socket.emit('order', { type: 'delUserSides', data: { entryId: entry_id } });

    handleClose =()=>{
      this.setState({openDialog:false});
    }
    showSideModal = (prodKeys,productsFull,dishId,sideId,currentType,changeMealside) =>{
      const { userData } = this.state;
      const { dishdislikes,eliminate_groups } = userData;  
      const sidelist =prodKeys.filter(function (dishId1) {
        const { group } = productsFull[dishId1] || {};
        let isEliminate = true;
        if(eliminate_groups.length>0){ 
          isEliminate = !(eliminate_groups.indexOf(group)>-1); 
        }
        if(dishdislikes.length>0 && isEliminate){
          isEliminate = !(dishdislikes.indexOf(+dishId1)>-1); 
        }
        return isEliminate;
    }).reduce((dishId1, currentValue) => {
        const { title, fat, protein, carb, calories } = productsFull[currentValue] || {}; 
        dishId1[currentValue] = title + ' (fat: '+fat/1000+', protein '+protein/1000+', carbs '+carb/1000+', calories '+calories/1000+')';
        return dishId1;
      },[]);

      const props = {
        width: '640px',
        height: '56vh',
        display: true
      };
     
      props.content =<div style={{minWidth:"360px"}}>
      <h6>Change Side Dish</h6>
       <SideDishModal sidelist={sidelist} currentType={currentType} sideId={sideId} dishId={dishId} changeMealside={changeMealside} />   
       </div>;
       this.setState({openDialog:true,openDialogContent:props.content})
      // props.content && setModal(props);
    }
    editSides = (prod_id, date,type_id) => {
      
      const {setModal, dataArray} = this.props;
      const {usersides, sidesList, products,order_id} = this.state;

      const props = {
        width: '640px',
        height: '56vh',
        display: true
      };

      var sides = [];

      if (typeof(usersides[date]) != 'undefined') {
        sides = usersides[date];  
      }

      props.content = <SidesModalNew
         //products={products}
         usersides={usersides}
         date={date}
         sidesList={sidesList}
         orderId={order_id}
         typeId={type_id}
         productId={prod_id}
         products={products}
         delSides={this.delUserSides}
         socket={this.props.socket}
         save={ (productId, sides, date, thetype) => {
          const {params} = this.props;
          const {user_id, order_id} = params;
          
          if (thetype == 2) {
            thetype = 'addons';
          } else {
            thetype = 'alternative';
          } 
          var data = {productId: productId, sides: sides, user_id: user_id, order_id: order_id, date: date, 'typeSides': thetype}
          console.log(data);
          this.props.socket.emit('order', { type: 'setUserSides', data: data });
         }}
      />;
      props.content && setModal(props);
    }

  	listener = ({ type, data }) => {
        const {user_id, order_id} = data;
        const {clearModal} = this.props;
    	//if (this.CustomersRef) {
      		switch (type) {
        		case 'getAllPlanOk':
        			this.setState({
        				mealplan: data,
        			});
        		break;

            case 'getSidesListOk':
              this.setState({
                sidesList: data,
              });
            break;

            case 'delUserSidesOk':
               this.getUserSides(user_id, order_id);
               clearModal();
            break;

            case 'setUserSidesOk':
              this.getUserSides(user_id, order_id);
              clearModal();
            break;

            case 'getUserSidesOk':
              this.setState({
                usersides: data
              });
              console.log(data);
            break;

            case 'getDietProductsOk':
              this.setState({
                products: data,
              });
            break;

            case 'getPauseOk':
                if (typeof(data.end_date) != 'undefined') {
                  this.setState({
                      pauses: true,
                      pause_manager: data.manager_id,
                      pause_start: data.date_set,
                      pause_end: data.end_date,
                  });
                } else {
                  this.setState({
                      pauses: false,
                      pause_manager: null,
                      pause_start: null,
                      pause_end: null,
                  });
                }
            break;

            case 'getNotesOk':
              this.setState({
                all_notes: data,
              });
            break;

            case 'getAlternativeMealsOk':
              this.setState({
                altmeals: data,
              });
            break;
            case 'getAlternativeMealSidesOk':
              this.setState({
                altmealSides: data,
              });
            break;
            case 'getOk':
              this.setState({
                userData: data.userData,
                firstName: data.userData.firstName,
                lastName: data.userData.lastName,
              });
            break;

        		case 'getErr':
        			console.log('websocket error:'+data.message);
        		break;
      		}
    	//}
  	};
    setUnsavedVal=(action,index)=>{
      var {editdaylist}=this.state;
      if(action=='pop'){
        delete editdaylist[index]  //delete a key
      }else{
        editdaylist[index]='test';
      }
      var isshow=(Object.keys(editdaylist).length>0)?true:false;
      this.setState({ editdaylist: editdaylist,showDialog:isshow });
    }
	render() {
    const {products, sidesList,showDialog ,openDialog ,openDialogContent} = this.state;
		const {params, dataArray} = this.props;
		const {planList, dietList, typeList, ingredientList, dishLabels,dishGroups} = dataArray;
		const {order_id, user_id} = params; 
		const {mealplan, altmeals, userData, firstName, lastName, all_notes, pauses, pause_start, pause_end, usersides, altmealSides} = this.state;
		if (mealplan && products) {
				const days = [];
        const ParentTypeId=[];
        Object.keys(typeList).map((acc,idx)=>{
          if(typeList[acc]['parent_id']!=0){
            ParentTypeId[typeList[acc]['parent_id']]=acc;
          }
      },[]);
      let diet_id='';
				for (var k in mealplan) {
          if(mealplan[k][0]!=undefined && mealplan[k][0].diet_id!=diet_id){
            (diet_id!='' && days.push(<div className='diet-change'>Plan Changed from <b className='diet-name-edit'>{dietList[diet_id]}</b> to <b className='diet-name-edit'>{dietList[mealplan[k][0].diet_id]}</b> on {k && moment.unix(k).format('Do MMM YYYY')}</div>));
            diet_id=mealplan[k][0].diet_id;
          }
          days.push(<div><Days usersides={usersides} editSides={this.editSides} timestamp={k} pause_start={pause_start} pause_end={pause_end} updateNotes={this.updateNotes} mealplan={mealplan} all_notes={all_notes} ingredientList={ingredientList} products={products} userData={userData} typeList={typeList} altmeals={altmeals} altmealSides={altmealSides} dishLabels={dishLabels} user_id={user_id} order_id={order_id} socket={this.props.socket} dishGroups={dishGroups} setUnsavedVal={this.setUnsavedVal} showSideModal={this.showSideModal} ParentTypeId={ParentTypeId} diet_id={diet_id}  /></div>);
				}
				return(
          <div>
            {showDialog&&<Alert bsStyle="unsaved" ><ErrorOutlineIcon style={{ verticalAlign: "middle" }} /><strong>Please click on "Finish" button before moving to the next day. Highlighted items are the selected ones.</strong></Alert>}
					<div className="mealrow-area">
						<h1 className="mealrow-h5">Edit Mealplan of {firstName} {lastName}</h1>
						{days}
            <ModalWrapper open={openDialog} children={openDialogContent} handleClose={this.handleClose} />
					</div>
          </div>
				);
		} else {
			return(<h1 className="mealrow-h5">No Active Mealplans</h1>)
		}
	}
}
 
EditPlan.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify()),
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
});

export default socketConnect(connect(props, actions)(EditPlan));