import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';

class MobileCheckoutActive extends Component {
  
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      planParams: [],
      sides: [],
      mealPlan: [],
      isToggleOn:false
    };
    this.getPending(user_id); 
  }
  
  getPending = user_id => this.props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });

  listener = action => {
    const { type, data } = action;
    if (type === 'getPendingOk') {
      this.setState({planParams:data.planParams,mealPlan:data.mealPlan});
      console.log(this.state);
    } else if (type === 'getPending') {
      console.err(data.message);
    }
  };

  componentWillMount() {
    this.props.socket.on('mobile_checkout', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('mobile_checkout', this.listener);
  }

  componentWillReceiveProps(next) {
    if ( next.user_id !== this.props.user_id) {
      this.getPending(next.user_id);
    }
  }
   isJsonString =(str) =>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  render() {
    const { dataArray, plan } = this.props;
    const { planList, typeList, dietList, slotsList, products } = dataArray;
    const { planParams, mealPlan} = this.state;
    var logs = [];
    const SidesCardNew=({side,products})=>{
      var title='\n';
      var prefix='';
      const { dataArray } = this.props;
      const { sidesTypes } = dataArray;
      if(side!=undefined){
        // console.log(Object.keys(side).length);
        Object.keys(side).map((side1, ind) =>{
          // console.log(ind);
          if(Object.keys(side).length-1==ind){
            prefix="";
          }else{
            prefix=' , ';
          }
          title+=sidesTypes[side1]+':'+products[side[side1]].title+prefix;    
        });
      }
      return title;
    }
    const DailyCard = ({ stamp, dailyMenu, delivered, cooked, slot_id}) => {
      const date = moment.unix(stamp);
      const today = moment().utc().startOf('day').unix();
      const diff = stamp - today;
      const classKey = `${diff < 0 ? '-' : ''}${+!!diff}`;
      const { planParams,usersides } = this.state; 
     
      var day_action = null;
      var moved_by = null;
      var reason = null;
      var order_id_for_buttons = false;
      var cslot_id=slot_id;
      if (planParams) {
        order_id_for_buttons = planParams.id;
      }
    
     
    
      let sides=[];           
      if(typeof(usersides[stamp]) != 'undefined'){
        sides=usersides[stamp];
      }  

      var dailyMenuData = Object.keys(dailyMenu).map((el, idx) =>{
            const { parent_id , children, title} =typeList[el];
            if(parent_id==null || parent_id==0){
                return <p key={`${dailyMenu[el]}_${idx}`}>
                    {title} : {((dailyMenu[el]!=undefined && Object.keys(dailyMenu[el]).length>0)?'':((products || {})[dailyMenu[el]] || { title: 'deleted' }).title)} {children!=null&& dailyMenu[children.id]!=undefined && Object.keys(dailyMenu[children.id]).length>0&&<SidesCardNew  side={dailyMenu[children.id]} products={products} bagname='InitialBag' meal_type={el} />}
                </p>;
            } 
            return '';
      });
      return (
          <div className={`day ${classes[classKey].day} ${paused_day}`}>
          <div className='status'>
            <span>{date.format('DD MMM')} - {stamp}</span>
            <span>{date.format('ddd')}</span><br/>
            {paused_day && <span className="pausedday">{paused_day}</span>}
            {day_action && <span className="movedcopyday">{day_action}</span>}
            {reason && <span className="movedreason">{reason}</span>}
            {moved_by && <span className="movedby">{moved_by}</span>}
          </div>
          <div className='day-dishes'>
            
            {dailyMenuData}
            
          </div> 

          <div className={`actions ${classes[classKey].actions}`}>
            <p>{delivered ? 'delivered' : (cooked ? 'cooked' : '')}</p>
            <p>{cslot_id && slotsList ? (slotsList || []).find(({ id }) => +id === +cslot_id).name : 'not set'}</p>
          </div>
          </div>
      );
    };
    
    const PlanDetails = () => {
        return mealPlan && planParams && products ? [
              <div key='-1' className='day'><div className='group-title'>Active order deliveries</div></div>,
              ...Object.keys(mealPlan).map((stamp, key) => {
                const {  ...dailyMenu } = mealPlan[stamp];
                return <DailyCard {...{ key, stamp, dailyMenu, slot_id}} />;
              })
        ] : [];
    };
    
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getTypes = typesString => typesString && typeList ? typesString.map(el => typeList[el].title).join(', ') : 'No data';
    const getExcludeWeek =(exclude_week_day)=>(exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const PlanInfo = ({ plan, planList, dietList ,preparedby,lasteditdby}) => {

      const getDate = date => moment.unix(date).format('DD.MM.YYYY');
      const { exclude_week_day,days_count } = plan || {};
      var setPaidBy = '';
      var discountSetBy = '';
    
      const { payment_data } = planParams;
      var paystatus = '';
     
        if(planParams && planParams.paymentMethod=='not_paid'){
          paystatus = 'Not Paid';
        }else if(planParams && payment_data){
          paystatus = 'Paid by '+ payment_data.name;
        }else{
          paystatus = 'Paid by '+ planParams.paymentMethod;
        }
    
     
      const whitecolor = {
        color: '#fff'
      };
      var setTime = false;
      console.log(typeof plan.mealTypes === 'string' || plan.mealTypes instanceof String )
      return plan && planList && dietList ? (
        <div>
        <Fragment>
          <p className='h-p1'>
            <span className='plan bold'>{(days_count<=0)?planList[plan.id].title:days_count+' Days - '}{(days_count>0)?getExcludeWeek(exclude_week_day):''}</span>: <span className='types'>{`${getDate(plan.start_date)} - ${getDate(plan.end_date)}`}</span>
          </p> 
          <p className='h-p2'>
            <span className='type bold'>{dietList[plan.diet_id]}</span>: <span className='types'>{getTypes(this.isJsonString(plan.mealTypes)?JSON.parse(plan.mealTypes):plan.mealTypes)}</span>
          </p>
       
          <p className="paystatus-info-tab">{paystatus} {setPaidBy && <span>, set by {setPaidBy}</span>} {setTime && <span>at {setTime}</span>}</p>
        </Fragment>
        <Fragment>
        {/* { discountstats && discountstats.discount_text && <div><p className={`reason bold green`}>{discountstats.discount_text} - {discountstats.discount_comment}, set by {discountSetBy}</p></div>} */}
       
        </Fragment>
        <Fragment>
        
          {preparedby&&<p  className={`red`}>Menu prepared By: {preparedby.name} {moment.unix(preparedby.created_date).format('DD.MM.YYYY')}</p>}
          {lasteditdby&&<p className={`red`}>Last Edited: {lasteditdby.name} {moment.unix(lasteditdby.created_date).format('DD.MM.YYYY')}</p>}
          
              </Fragment>
              </div>
            ) : (
              <Fragment>
               <p className='h-p1'>No active plan</p>
              </Fragment>
            ); 
    };

  
    
    

    return (
      <div className='customer-plan'>
          <div className='plan-head'>
            <div className='info-part'><PlanInfo {...{ plan:planParams, planList, dietList  }} /></div>
          </div>
          <div className='plan-body scrollable-h'>
             {plan && <PlanDetails/>}
          </div>
      </div>
    );
  }
}

MobileCheckoutActive.propTypes = {
  socket: PropTypes.object,
  orderlogs: PropTypes.object,
  user_id: PropTypes.number.isRequired
};
const props = state => ({
  products: state.dataArray.products
});
export default socketConnect(connect(props, null)(MobileCheckoutActive));
