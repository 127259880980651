import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Paginator from 'components/Paginator';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';

class List extends Component {
  
  getRowsByMeal(newdata,mealType_id) {
    // console.log(newdata);
    const { list, dictionaries, date, notes,dishCostPrice } = this.props;
    const { productsFull } = dictionaries;
    let foodcostpert=0;
    let foodcostto =0;
    let totalcost=0;
    let totalprice=0;
    var i=0;
    return Object.keys(newdata).reduce((acc, cur) => {
      //console.log(productsFull[cur]);
      const { title, nickname } = productsFull[cur] || {};
      const { count,saleprice } = newdata[cur];
      let foodcost = dishCostPrice[cur] || 0;
      foodcost =(foodcost>0)?foodcost.toFixed(2):0;
      let persale  = (saleprice>0)?((saleprice/100)/count).toFixed(2):0;
      let foodcostper  = (foodcost>0&&persale>0)?Math.floor((foodcost/persale) * 100):0;
      foodcostto+=parseFloat(foodcost);
      foodcostpert+=foodcostper;
      totalcost+=(foodcost*count);
      totalprice+=(persale*count);
      const data = {
        title: <Text customColor='#0068c1' inline bold>{nickname || title} - {cur}</Text>,
        foodcost: <Text color='warning' inline bold>{foodcost}</Text>,
        sellingprice: <Text color='warning' inline bold>{persale}</Text>,
        foodcostper: <Text color='warning' inline bold>{foodcostper}%</Text>,
        amount: <Text color='warning' inline bold>{count}</Text>,
        costperall: <Text color='warning' inline bold>{(foodcost*count).toFixed(2)}</Text>,
        sellingserve: <Text color='warning' inline bold>{(persale*count).toFixed(2)}</Text>,
        mealType_id:mealType_id,
        foodcostpertotal:foodcostpert,
        foodcosttotal:foodcostto,
        totalcost:totalcost,
        totalprice:totalprice,
        date
      };
      return { ...acc, [cur]: data };
    }, {});
  }
  
  renderContent() {
    const { list,dictionaries, splitter } = this.props;
    const { type, values } = splitter;
    const { typeList } = dictionaries;
    
    let content = null;
    let fcontent = [];
    let cols = {};
    let rows = {};
    let foodcostpertotal = 0;
    let foodcosttotal = 0;
    let foodcostprice = 0;
    const _type = values && values.length ? type : null;
        var i=0;
        content=Object.keys(list).map((el, key) => {
         
          if(list[el]!=undefined){
            let name =el;
            if(i==0){
              cols = {
                title: 'Dish for '+name,
                foodcost: 'Cost Price p/unit',
                sellingprice: 'Selling Price p/unit',
                foodcostper: 'Food Cost %',
                amount: 'Serving',
                costperall: 'Cost Price (All)',
                sellingserve: 'Selling Price (All)'
              };
              i=1;
             }else{
              cols = {
                title:'Dish for '+name,
                foodcost: 'Cost Price p/unit',
                sellingprice: 'Selling Price p/unit',
                foodcostper: 'Food Cost %',
                amount: 'Serving',
                costperall: 'Cost Price (All)',
                sellingserve: 'Selling Price (All)'
              };
             }
            //  console.log(list[el]);
            rows = this.getRowsByMeal(list[el],el);
            // console.log(rows);
            var total =Object.keys(rows).length?rows[Object.keys(rows)[Object.keys(rows).length - 1]]:{};
            // foodcostpertotal=(total['foodcostpertotal']!=undefined)?foodcostpertotal+(total['foodcostpertotal']/Object.keys(rows).length):foodcostpertotal;
            foodcosttotal=(total['totalcost']!=undefined)?foodcosttotal+total['totalcost']:foodcosttotal;
            foodcostprice=(total['totalprice']!=undefined)?foodcostprice+total['totalprice']:foodcostprice;;
            return Object.keys(rows).length ? <Table {...{ cols, rows, key,name }} /> : null;
          }
         
        });
        fcontent.push(<div className='date-date'>Average Food Cost {Math.floor((foodcosttotal/foodcostprice) * 100)}% of the day</div>);
        fcontent.push(<div className='date-date'>Total Food Cost ({foodcosttotal.toFixed(2)}) of the day</div>);
        fcontent.push(content);   
    return fcontent;
  }
  renderSideContent() {
    const { dictionaries,usersidesList } = this.props;
    const {  typeList } = dictionaries;
    let content = null;
    let cols = {};
    let rows = {};
    var i=0;
    console.log(usersidesList);
    
    if(usersidesList!=undefined){
      let name ='Sides';
        cols = {
            title: 'Sides',
            // type: 'Meal Type',
            // symbol: 'Symbol',
            foodcost: 'Food Cost',
            sellingprice: 'Selling Price',
            foodcostper: 'Food Cost %',
            amount: 'Serving',
            costperall: 'Cost Price (All)',
            sellingserve: 'Selling Price (All)'
          };
          var key=0;
          rows = this.getRowsByMeal(usersidesList,0);
          return Object.keys(rows).length ? <Table {...{ cols, rows, key,name }} /> : null;
        }
    return content;
  }

  render() {
    const { page, maxPages, goTo, date } = this.props;
    return (
      <div>
        <div className='date-date'>{moment.unix(date).format('DD MMM')}</div>
        {this.renderContent()}
        {this.renderSideContent()}
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

List.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  splitter: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.string
};

List.defaultProps = {
  limit: 20,
  page: 1,
  maxPages: 1,
  goTo: () => {},
  splitter: {},
  date: ''
};
export default List;
