import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

//require('moment-weekday-calc');

class ReasonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: null,
      comment: null,
    };
  }

  render() {
    const { discount, comment } = this.state;
    const { save, orderId, vouchers, isFuture } = this.props;

    var vouchersList = [];
    vouchersList[0] = 'None';
    Object.keys(vouchers).map( (el, idx) => {
      if (vouchers[el].active) {
        var thetype = '%';
        if ( vouchers[el].type != 'percent') {
          thetype = 'AED';
        }
        vouchersList[vouchers[el].id] = vouchers[el].code + ' ('+vouchers[el].value+' '+thetype+')';
      }
    });

    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Select Discount</Text>
            </Grid>
            <Grid item md={12}>
                  <SelectInput
                    labelText='Select Voucher'
                    items={vouchersList || {}}
                    value={discount || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({discount: e.target.value})
                    }}
                  />
            </Grid>
            <Grid item md={12} style={{ textAlign: 'left', padding: '10px 0 0' }}>
              <Text bold color={'muted'} inline style={{ fontSize: 'large' }}>Add comment if need</Text>
            </Grid>
            <Grid item md={12} style={{ border: '1px solid #bebebe', padding: '5px 15px 10px' }}>
              <CustomInput
                labelText='Comment'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: comment || '',
                  onChange: e => this.setState({ comment: e.target.value.length < 1023 ? e.target.value : comment }),
                  multiline: true,
                  rows: 9
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(orderId, discount, comment, isFuture)}>Save</Button>
        </div>
      </div>
    );
  }
}


ReasonModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default ReasonModal;
