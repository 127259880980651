import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import Snackbar from '../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons/index';
import CustomTabs from 'components/CustomTabs';
import NumberInput from '../../components/NumberInput';
import Button from '../../components/CustomButtons/Button';
import SelectInput from 'components/SelectInput';
import moment from 'moment/moment';
import Text from 'components/Typography/Text';

import { getArrays } from 'utils';

class DiscountPrices extends Component {
  constructor(props) {
    super(props);
    this.inputs = {};
    this.active = 0;
    this.pricesForSave = {};
    this.state = {
      message: '',
      prices: {},
      note: false,
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getPrices();
  }

  componentWillMount() {
    this.props.socket.on('prices', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('prices', this.listener);
  }

  listener = action => {
    if (this.DiscountPricesRef) {
      switch (action.type) {
        case 'getMealTypeCombineDiscountOk':
          this.setState({ ...action.data });
          break;
        case 'setMealTypeCombineDiscountOk':
          // const prices = this.mergePrices();
          this.setState({ note : true, message: action.data.message });
          setTimeout(() => this.setState({ note : false, message: '' }), 5000);
          break;
        default:
          if (['getErr', 'setErr'].includes(action.type)) {
            // this.showNotification(action.data.message);
          }
          break;
      }
    }
  };

  mergePrices = () => {
    let prices = { ...this.state.prices };
    Object.keys(this.pricesForSave).forEach(typeId => {
      const typePrices = this.pricesForSave[typeId];
      Object.keys(typePrices).forEach(planId => {
        const planPrices = typePrices[planId];
        Object.keys(planPrices).forEach(dietId => {
          (prices[typeId] || { [planId]: {} })[planId][dietId] = planPrices[dietId];
        });
      });
    });
    return prices;
  };

  showNotification(message) {
    if (this.DiscountPricesRef) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  getPrices() {
    this.props.socket.emit('prices', {
      type: 'getMealTypeCombineDiscount'
    });
  }

  collectPrices = active => {
    const { prices } = this.state;
    const oldPrices = { ...prices[active] };
    let pricesForSave = { ...this.pricesForSave };
    if (!pricesForSave[active]) {
      pricesForSave = { ...pricesForSave, [active]: {} };
    }
    // Object.keys(this.inputs).forEach(key => {
    //   const keys = key.split('_');
    //   const newPrice = this.inputs[key].state.value * 100;
    //   if ((oldPrices[keys[0]] || {})[keys[1]] !== newPrice) {
    //     pricesForSave[active] = { ...pricesForSave[active], [keys[0]]: { ...pricesForSave[active][keys[0]], [keys[1]]: newPrice } };
    //   }
    // });
    this.pricesForSave = pricesForSave;
  };

  submit = () => {
    const { prices } = this.state;
    // console.log(this.active);
    // console.log(prices);
    // this.collectPrices(this.active);
    const pricesData = prices[this.active];
    console.log(pricesData);
    if (Object.keys(pricesData).length) {
      this.props.socket.emit('prices', {
        type: 'setMealTypeCombineDiscount',
        data: {
          typeId: this.active,
          prices
        }
      });
    }
  };

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['commonVouchers', 'mealtypeCombinations', 'dietListMobile','daysRange'].filter(el => !(el in dataArray));
    getArrays(socket, types);
  }
  changePrice = (cur,combid,dayId,value) =>{
    let { prices } = this.state;
    if (!prices[cur]) {
      prices = { ...prices, [cur]: {} };
    }
    this.setState({ prices: { ...prices, [cur]: { ...prices[cur], [combid]:{  ...prices[cur][combid], [dayId]:value } } } });
  }
  render() {
    const { message, note, closeNote, prices } = this.state;
    const { dietListMobile,mealtypeCombinations,commonVouchers,daysRange } = this.props.dataArray;
    const vouchers = {};
    const now = moment().utc().unix();
    const vouchersList = [...(vouchers || []), ...(commonVouchers || [])].reduce((acc, { id, type, value, code, start_date, end_date, active }) => {
      const format = {
        fixed: (val) => `AED ${val / 100}`,
        percent: val => `${val}%`
      };
      const title = `${format[type](value)} (${code})`;
      if (start_date && end_date) {
        if (now > start_date && now < end_date && active) {
          return { ...acc, [`${id}`]: `Common: ${title}` };
        } else {
          return acc;
        }
      } else {
        return { ...acc, [`${id}`]: `Personal: ${title}` };
      }
    }, { '': 'None' });
    // console.log(dietListMobile);
    // console.log(mealtypeCombinations);
    console.log(prices);
    const tabsData =  dietListMobile ? Object.keys(dietListMobile).reduce((acc, cur) => {
      // console.log(cur);
      const mealTypePrices = prices[cur] || {};//prices[cur] || {};
      
      const tabData = {
        icon: '',
        label: dietListMobile ? dietListMobile[cur] : '',
        renderTabContent: () => {
          const thead = daysRange ? [<td key={0} />, ...Object.keys(daysRange).map(dayId => <td key={dayId}>{daysRange[dayId].title}</td>)] : [];
          const table = mealtypeCombinations && dietListMobile && Object.keys(mealtypeCombinations).map(planId => {
            const planPrices = mealTypePrices[mealtypeCombinations[planId].id];
            return (
              <tr key={planId+1}>
                <td className='first-col'>{mealtypeCombinations[planId].title}</td>
                {Object.keys(daysRange).map(dayId => (

                    <td key={dayId+1}>
                      <SelectInput
                        labelText='Vouchers'
                        items={vouchersList}
                        value={planPrices && planPrices[daysRange[dayId].id] ? planPrices[daysRange[dayId].id].toString():''}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          title: (vouchers || []).length ? '' : 'This user have no any vouchers',
                          onChange: e => this.changePrice(cur,mealtypeCombinations[planId].id,daysRange[dayId].id,e.target.value)
                        }}
                        ref={el => {
                          this.active = +cur;
                        }}
                      />
                    </td>
                    ))}
                
              </tr>
            );
          });
          return (
            <Fragment>
              <table className='prices-table'>
              <thead>
                  <tr>
                    {thead}
                  </tr>
                </thead>
                <tbody>
                  {table}
                </tbody>
              </table>
              <Button style={{ float: 'right' }} color='success' onClick={this.submit}>Save</Button>
            </Fragment>
          );
        }
      };
      return { ...acc, [cur]: tabData };
    }, {}) : {};
    return (
      <div ref={el => (this.DiscountPricesRef = el)}>
        {!!(tabsData && Object.keys(tabsData).length) && (
          <CustomTabs
            data={tabsData}
            customClasses={'tabs-outher prices-tabs'}
            headerTitle={'Meal Types:'}
            headerColor='darkBlue'
            // onChangeTab={() => this.collectPrices(this.active)}
            defaultTab={Object.keys(dietListMobile || {})[0] || 0}
          />
        )}
        <Snackbar
          place='tc'
          color='info'
          icon={AddAlert}
          message={message}
          open={note}
          closeNotification={closeNote}
          close
        />
      </div>);
  }
}

DiscountPrices.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(DiscountPrices));
