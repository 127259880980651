export const SET_MODAL_STACK = 'SET_MODAL_STACK';
export const CLEAR_MODAL_STACK = 'CLEAR_MODAL_STACK';
export const PUSH_MODAL = 'PUSH_MODAL';
export const POP_MODAL = 'POP_MODAL';
export const UNSET_MODAL = 'UNSET_MODAL';

class ModalData {
  constructor({ content, onClose, size, position }) {
    this._content = content || null;
    this._onClose = onClose;
    this._size = size || 'auto';
    this._position = position;
    this._display = false;
  }

  update(content) {
    this._content = content;
    return this;
  }

  resize(size) {
    this._size = size;
    return this;
  }

  show() {
    this._display = true;
    return this;
  }

  hide() {
    this._display = false;
    return this;
  }

  get props() {
    const { _content: children, _onClose: onClose, _size, _position: position, _display: display } = this;
    const { width, height } = _size;
    return { children, onClose, width, height, position, display };
  }
}

const initialState = [];

export function setModalStack(array) {
  return {
    type    : SET_MODAL_STACK,
    payload : array
  };
}

export function pushModal(obj) {
  return {
    type    : PUSH_MODAL,
    payload : obj
  };
}

export function popModal() {
  return {
    type    : POP_MODAL
  };
}

export function unsetModal(index) {
  return {
    type    : UNSET_MODAL,
    payload : index
  };
}

export function clearModalStack() {
  return {
    type    : CLEAR_MODAL_STACK
  };
}

export default function modalStackReducer(state = initialState, action) {
  if (action.type === SET_MODAL_STACK) {
    return action.payload.map(data => new ModalData(data));
  }
  if (action.type === PUSH_MODAL) {
    return [...state, new ModalData(action.payload)];
  }
  if (action.type === POP_MODAL) {
    return state.slice(0, -1);
  }
  if (action.type === UNSET_MODAL) {
    // state.splice(action.payload, 1);
    return state.filter((el, idx) => idx !== action.payload);
  }
  if (action.type === CLEAR_MODAL_STACK) {
    return initialState;
  }
  return state;
}
