import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import Customers from './Customers';
import Details from './Details';
import Button from 'components/CustomButtons/Button';
import { getArrays } from 'utils';

class Available extends Component {
  constructor(props) {
    super(props);
    this.types = ['binded', 'customers', 'routes', 'drivers', 'notes'];
    this.state = {
      customers: null,
      binded: null,
      routes: null,
      drivers: null,
      priorities: [],
      notes: [],
      windows: [],
      activeUser: null,
      activeWindow: null,
      activeGroup: 'all',
      today: moment().utc().startOf('d').unix()
    };
  }

  componentWillMount() {
    this.props.socket.on('packaging', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('packaging', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['deliveryTime', 'typeList', 'dishPacks', 'cutleryList', 'hygieneList', 'transportTypes', 'productsFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    socket.emit('packaging', { type: 'get', data: { types: this.types, special: false, date: this.state.today  } });
  }

  listener = ({ type, status, subtype, data }) => {
    if (this.PackagingAvailableRef) {
      if (status === 'ok') {
        switch (type) {
          case 'get':
            if (['binded', 'customers', 'routes', 'drivers', 'notes'].includes(subtype)) {
              const { windows } = data;
              this.setState({ ...data, activeWindow: windows ? windows[0] : this.state.activeWindow });
            }
            break;
          case 'set':
            this.setState(data);
            break;
          case 'del':
            this.setState(data);
            break;
          case 'packed':
            const { today } = this.state;
            const { customer_id, customers } = data;
            this.props.showPrintModal(((customers || {})[today] || {})[customer_id]);
            this.setState(data);
            break;
        }
      } else if (status === 'err') {
        const { message, errors } = data;
        console.error(message);
        errors && this.setState({ errors });
      } else {
        console.log({ type, status, subtype, data });
      }
      return null;
    }
  };

  grabPackage = (owner, commonData, details, deliveryType = 'order') => {
    const type = owner ? 'del' : 'set';
    this.props.socket.emit('packaging', { type, data: { commonData, details, deliveryType } });
  };

  markPacked = (packager_id, details, deliveryType = 'order', customer_id) => {
    this.props.socket.emit('packaging', { type: 'packed', data: { packager_id, details, deliveryType, customer_id } });
  };

  closeUser = () => {
    this.setState({ activeUser: null });
  };

  render() {
    const { customers, binded, routes, windows, activeWindow, activeGroup, priorities, activeUser, today, drivers } = this.state;
    const todayCustomers = (customers || {})[today] || {};
    const { dataArray, user } = this.props;
    const { deliveryTime } = dataArray;
    console.log(deliveryTime);
    (deliveryTime || {})[0] = 'Not planned';
    const { type: userType, user_id: userId } = user;
    const getColor = (val1, val2) => ['white', 'darkBlue'][+(val1 === val2)];
    const winTabs = () => windows.map(key => {
      const onClick = () => this.setState({ activeWindow: +key, activeUser: null });
      const color = getColor(activeWindow, +key);
      return <Button {...{ key, color, onClick }}>{(deliveryTime || {})[key] || ''}</Button>;
    });
    const freeCustomers = Object.keys(todayCustomers || {}).reduce((acc, uid) => {
      const { menu } = todayCustomers[uid];
      const notInBinded = Object.values(menu || {}).filter(({ detailsId }) => {
        return !binded.find(({ orderDetails_id, delivery_id, customer_id, date, packager_id }) => {
          return +detailsId === +(orderDetails_id || delivery_id) && +customer_id === +uid && +date === +today && +packager_id !== +userId;
        });
      }).length;
      return notInBinded ? [...acc, +uid] : acc;
    }, []);
    const usersPackaging = (binded || []).filter(el => +el['packager_id'] === +userId);
    const commonProps = { customers: todayCustomers, today, usersPackaging, routes, dataArray, priorities, activeWindow, freeCustomers };
    const renderCustomers = !['deliveryTime', 'typeList'].filter(el => !(el in dataArray)).length;
    const renderDetails = !['deliveryTime', 'dishPacks', 'cutleryList', 'hygieneList', 'transportTypes', 'productsFull'].filter(el => !(el in dataArray)).length;
    const groups = { all: 'All', my: 'My' };
    const setActive = (name, val) => this.setState({ [name]: val });
    return (
      <div className='package-board can-be' ref={el => (this.PackagingAvailableRef = el)}>
        <div className='top-position styled'>
          <div className='filters-block'>
            <div className={'filters-package'}>
              {false && <Button color={'warning'}>Priority</Button>}
              {false && <Button color={'darkBlue'}>Vehicle</Button>}
              {userType === 'packager' && Object.keys(groups).map((el, idx) => {
                return <Button color={getColor(el, activeGroup)} key={idx} onClick={() => setActive('activeGroup', el)}>{groups[el]}</Button>;
              })}
            </div>
            <div className={'filters-package'}>{winTabs()}</div>
          </div>
        </div>
        {renderCustomers && <Customers
          {...{ ...commonProps, activeGroup }}
          onClick={activeUser => this.setState({ activeUser })}
        />} 
        {renderDetails && <Details
          {...{ ...commonProps, activeUser, userType, userId, drivers, binded }}
          grabPackage={this.grabPackage}
          markPacked={this.markPacked}
          closeUser={this.closeUser}
          notes={this.state.notes}
        />}
      </div> 
    );
  }
}

Available.propTypes = {
  showPrintModal: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  user: state.user
});

export default socketConnect(connect(props)(Available));
