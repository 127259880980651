export const SET_CUSTOM_PLAN_SIDES = 'SET_CUSTOM_PLAN_SIDES';
export const CLEAR_CUSTOM_PLAN_SIDES = 'CLEAR_CUSTOM_PLAN_SIDES';

const initialState = [];

export function setCustomPlanSides(arr) {
  return {
    type    : SET_CUSTOM_PLAN_SIDES,
    payload : arr
  };
}

export function clearCustomPlanSides() {
  return {
    type    : CLEAR_CUSTOM_PLAN_SIDES
  };
}

export default function customPlanSidesReducer(state = initialState, action) {
  if (action.type === SET_CUSTOM_PLAN_SIDES) {
    return action.payload;
  }
  if (action.type === CLEAR_CUSTOM_PLAN_SIDES) {
    return initialState;
  }
  return state;
}
