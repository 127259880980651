import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Grid } from 'material-ui';
import { ErrorOutline } from '@material-ui/icons/index';
import Dropzone from 'react-dropzone';
import sendImage from 'handler/sendImage';
import sendImageAws from 'handler/sendImageAws';
import Ingredients from './Ingredients';
import Parts from './Parts';
import CompositeIngred from './CompositeIngred';
import Sides from './Sides';
import SidesMatrix from './SidesMatrix';
import CaloriesData from './Calories';
import { setNotify, clearNotify } from 'store/notify';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ItemGrid from 'components/Grid/ItemGrid';
import SelectInput from 'components/SelectInput';
import ModalWrapper from 'components/Modal/Modal2';
import { getArrays, unsetErrors } from 'utils';
import { spin, stop } from 'store/spinner';

class Form extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    const { id } = params || {};
    this.state = {
      id,
      errors: {},
      sidesdata: {},
      ingredientPrices: {},
      sidesList: false,
      fixedsidesList: false,
      formData: {
        active: '1'
      },
      image: {},
      pack_image: {},
      needImageUpload: '',
      needPackImageUpload: '',
    };
    id && this.getData(id);
    id && this.getSidesData(id);
    this.getSidesList();
    this.getIngredientPrices();
  }

  componentWillReceiveProps(next) {
    if (this.state.id !== next.id && this.FormRef) {
      this.setState({ id: next.id });
    }
  }

  showNotification(message, color = 'success') {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      place: 'tc',
      color,
      icon: ErrorOutline,
      message: message,
      open: true
    });
    setTimeout(() => clearNotify(), 5000);
  }

  listener = action => {

    if (this.FormRef) {
      switch (action.type) {
        case 'getSidesDataOk':
          this.setState({
            sidesdata: action.data, 
          });
        break;
        case 'getIngredientPricesOk':
          this.setState({
            ingredientPrices: action.data.supplierPrices, 
          });
        break;
        case 'getSidesListOk':
          this.setState({
            sidesList: action.data, 
            fixedsidesList: action.fixedoption, 
          });
        break;
        case 'saveSidesOk':
          this.showNotification('Side Saved');
          this.getSidesData(this.props.params.id);
        break;
        case 'delSidesOk':
          this.showNotification('Side Saved');
          this.getSidesData(this.props.params.id);
        break;
        case 'getOk':
          this.setState(action.data);
          break;
        case 'setOk':
          setTimeout(() => (this.submitting = false), 1000);
          const { needImageUpload,needPackImageUpload, image,pack_image } = this.state;
          // console.log(needImageUpload);

          /* Start Store the Nutritional Info Photo */
          var simpleDishImage=(this.CalRef!=undefined)?this.CalRef.state.images:[];
          if(action.data.simpleDishId){
             var objref=this;
              Object.keys(action.data.simpleDishId).map(function(key, index) {
                if(simpleDishImage&&simpleDishImage[key]!=undefined){
                  // console.log(simpleDishImage[key]);
                  objref.saveImage('Dish', 'image', action.data.simpleDishId[key], simpleDishImage[key]['image'],() => { }) 
                }
              });
          }
        /* End Store the Nutritional Info Photo */
          this.props.stop();
          const saved = (id, message) => {
            this.setState({ id });
            browserHistory.push(`/admin/menu/edit/${id}`);
            this.showNotification(message);
          };
          if (needImageUpload||needPackImageUpload) {

            if(needImageUpload){
                this.saveImage('Dish', 'image', action.data.id, image, () => {
                  saved(action.data.id, action.data.message);
                });
            }

            if(needPackImageUpload){
              this.saveImage('Dish', 'pack_image', action.data.id, pack_image, () => {
                saved(action.data.id, action.data.message);
              });
            }
          } else {
            saved(action.data.id, action.data.message);
          }
          break;
        case 'setErr':
          this.props.stop();
          setTimeout(() => (this.submitting = false), 1000);
          const { errors } = action.data;
          if (errors && ('components' in errors || 'ingredients' in errors)) {
            this.showNotification('Please, fill in components of dish', 'warning');
          }
          this.setState({ errors: errors || {} });
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  getData = (id) => this.props.socket.emit('menu_builder', { type: 'get', data: { id } });
  getSidesData = (id) => this.props.socket.emit('menu_builder', { type: 'getSidesData', data: { productId: id } });
  getIngredientPrices = () => this.props.socket.emit('menu_builder', { type: 'getIngredientPrices' });
  getSidesList = () => this.props.socket.emit('menu_builder', { type: 'getSidesList', data: { diet: 'all',is_select2:true } });
  submit = () => {
    this.props.spin();
    const { id, formData } = this.state;
    let data = { ...formData };
    const { image,pack_image } = formData;
    delete data.image;
    delete data.pack_image;
    if (data.type === 'composite') {
        if(this.CompositeIngRef){
            data.ingredients = this.CompositeIngRef.state.ingredients;
        }

        if(this.SidesRef){
          data.diet_ids = this.SidesRef.state.diet;
          data.sides=this.SidesRef.state.sides;
        }

        if(this.CalRef){
          data.calories = this.CalRef.state.totalcal;
          data.caloriesdata=this.CalRef.state.calories;
        }
    } else  if (['simple', 'ready'].includes(data.type)) {
      data.ingredients = this.IngredientsRef.state.ingredients;
    }
    if (pack_image && pack_image.preview) {
      this.setState({ needPackImageUpload: true, 'pack_image': pack_image });
    }

    if (image && image.preview) {
      this.setState({ needImageUpload: true, 'image': image });
    } else if (!id) {
      // this.setState({ errors: { ...errors, image: 'Please, select an image' } });
      // return false;
    }
    if (!this.submitting) {
      setTimeout(() => this.sendData(data, id || this.props.params.id), 20);
    }
    this.submitting = true;
  };
  sendImageData = (data, id) => {
    // console.log(data);
    // console.log(id);
    if(data.imagedata!=undefined){
      this.props.socket.emit('menu_builder', {
        type: 'setAWSFile',
        data: { data, id }
      }); 
    }
  };
  sendData = (data, id) => {
    this.props.socket.emit('menu_builder', {
      type: 'set',
      data: { data, id }
    }); 
  };

  saveImage = (modelName, attribute, id, image, callback) => {
    const dataForm = new FormData();
    dataForm.append('access_token', this.props.user.token);
    dataForm.append('type', 'modelImage');
    dataForm.append('modelName', modelName);
    dataForm.append('id', id);
    dataForm.append('attribute', attribute);
    dataForm.append(`${modelName}[${attribute}]`, image);
    // sendImage.call(this, dataForm, callback);
    var  dishinfo =  new Object();
    dishinfo['id']=id;
    dishinfo['attribute']=attribute;
    dishinfo['modelName']=modelName;
    dishinfo['path']='photos';
    sendImageAws.call(this, image,dishinfo, (info,id)=>{this.sendImageData(info,id,callback)});
    console.log('save_image');
    console.log(dataForm);
  };

  changeForm = (name, value) => {
    const { formData, errors: oldErrors } = this.state;
    const errors = name in oldErrors ? unsetErrors(oldErrors, [name]) : oldErrors;
    this.setState({ formData: { ...formData, [name]: value }, errors });
  };

  onDrop = (files) => {
    const { formData } = this.state;
    this.setState({ formData: { ...formData, image: files[0] } });
  };
  onDropPackImage = (files) => {
    const { formData } = this.state;
    this.setState({ formData: { ...formData, pack_image: files[0] } });
  };
  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['dietList', 'dishGroups', 'sideGroups', 'dishLabels', 'dishTypes', 'allergensList', 'typeList', 'cutleryList', 'hygieneList',
      'ingredientList', 'ingredientListFull', 'products', 'dishPacks','heatingInstructionsList','portionSizeList'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  renderCalories = (dataArray) => {
    let content = null;
    const { dietList,portionSizeList,heatingInstructionsList,dishPacks } = dataArray;
    const {formData} = this.state;
    const {id,caloriesdata,nutritional_images}=formData;
    content = <CaloriesData dietList={dietList} nutritional_images={nutritional_images} caloriesdata={caloriesdata} portionSizeList={portionSizeList} dishPacks={dishPacks} heatingInstructionsList={heatingInstructionsList} product_id={id} socket={this.props.socket}  ref={el => (this.CalRef = el)}/>;
    return content;
  };
 
  
  renderSides = (dataArray, type) => {
    let content = null;
    const { products, dietList,typeList,portionSizeList } = dataArray;
    const {id, sidesdata, sidesList,fixedsidesList,formData} = this.state;
    const {mealTypes,mealType_ids,diet_ids,sides}=formData;
    // console.log(diet_ids);
    if(sidesList){
      content = <SidesMatrix product_id={id} sides={sides} diet={diet_ids} portionSizeList={portionSizeList} typeList={typeList} mealTypes={mealTypes||mealType_ids} sidesList={sidesList} sidesdata={sidesdata} dietList={dietList} fixedsidesList={fixedsidesList} products={products} socket={this.props.socket} ref={el => (this.SidesRef = el)}/>;
    }
    return content;
  };

  renderComponents = (type, dataArray) => {
    let content = null;
    const { ingredientList, ingredientListFull, products, dishPacks, cutleryList, hygieneList,portionSizeList } = dataArray;
    const { ingredientPrices } = this.state;
    const { ingredients, components } = this.state.formData;
    if (ingredientList && ingredientListFull && products && dishPacks && cutleryList && hygieneList) {
     if (type === 'composite') {
        // const props = { ingredientListFull, products, components, dishPacks, cutleryList, hygieneList,portionSizeList,ingredients,ingredientList };
        // console.log(ingredients);
        const props = { ingredientListFull, ingredientList, type, ingredients,portionSizeList};
        content = <CompositeIngred {...props} ref={el => (this.CompositeIngRef = el)}  />;
      } else {
       // if (['simple', 'ready'].includes(type)) {
          const props = { ingredientListFull, ingredientList, type, ingredients,portionSizeList,ingredientPrices };
          content = <Ingredients {...props} ref={el => (this.IngredientsRef = el)} />;
        //}
     }
    }
    return content;
  };

  renderPackaging = (dataArray) => {
    const { dishPacks, cutleryList, hygieneList } = dataArray;
    const { formData } = this.state;
    const { pack_id, cutlery_id, hygiene_id } = formData;
    const propChange = (e, name) => {
      this.setState({ formData: { ...formData, [name]: e.target.value } });
    };
    return dishPacks && cutleryList && hygieneList ? (
      <Grid container>
        <ItemGrid md={3}>
          <SelectInput
            labelText={'Package'}
            multiple={false}
            items={dishPacks}
            empty={''}
            value={pack_id || ''}
            name={'pack_id'}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e => propChange(e, 'pack_id')
            }}
          />
        </ItemGrid>
        <ItemGrid md={3}>
          <SelectInput
            labelText={'Cutlery'}
            multiple={false}
            items={cutleryList}
            empty={''}
            value={cutlery_id || ''}
            name={'cutlery_id'}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e => propChange(e, 'cutlery_id')
            }}
          />
        </ItemGrid>
        <ItemGrid md={3}>
          <SelectInput
            labelText={'Hygiene'}
            multiple={false} 
            items={hygieneList}
            empty={''}
            value={hygiene_id || ''}
            name={'hygiene_id'}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e => propChange(e, 'hygiene_id')
            }}
          />
        </ItemGrid>
      </Grid>
    ) : null;
  };

  render() {
    const { formData, errors } = this.state;
    const { dataArray } = this.props;
    const { dietList, dishGroups, sideGroups, dishTypes, dishLabels, allergensList, typeList: originTypeList } = dataArray;
    const { name, image, description, pungency, active, fat, protein, carb, calories, side_group_id, dish_group_id, type, allergen_ids, diet_ids, mealType_ids, mealTypes, nickname, price, label_id, name_arabic,is_heatable,pack_image,prepare_method,dish_group_ids, kitchen_notes,fibre } = formData;
    const onChange = (e, name) => {
      this.changeForm(name || e.target.name, e.target.value);
    }
    const onNumberChange = (e, mul = 1, min = -Infinity, max = Infinity, name, value) => {
      const val = (+e.target.value || +value) * mul;
      this.changeForm(name || e.target.name, val < min ? min : (val > max ? max : val));
    };
    const typeList = originTypeList && Object.keys(originTypeList).reduce((acc, cur) => ({ ...acc, [+cur]: originTypeList[+cur].title }), {});
    const imageUrl = (image && image.preview) || image;
    const packImageUrl = (pack_image && pack_image.preview) || pack_image;
    const rFlag = !!(dietList && dishGroups && dishTypes && allergensList && originTypeList);
    return (
      <ModalWrapper open handleClose={() => browserHistory.goBack()} ref={el => (this.FormRef = el)}>
        {rFlag && <div className='scroll-wrapper max-v100 h-80'>
          <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle='Dish'
                cardSubtitle='New dish info'
                headerColor='ldarkBlue'
                titleColor='white'
                content={
                  <div className='dishForm'>
                    <Grid container>
                      <ItemGrid md={6} container>
                        <ItemGrid md={type !== 'composite'?6:12} container>
                          <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Title'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'name' in errors}
                              inputProps={{
                                value: name || '',
                                type: 'text',
                                name: 'name',
                                onChange
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Title (arabic)'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'name_arabic' in errors}
                              inputProps={{
                                value: name_arabic || '',
                                type: 'text',
                                name: 'name_arabic',
                                onChange
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Nickname'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'nickname' in errors}
                              inputProps={{
                                value: nickname || '',
                                type: 'text',
                                name: 'nickname',
                                onChange
                              }}
                            />
                          </ItemGrid>
                          {type !== 'composite' && <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Price'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'price' in errors}
                              inputProps={{
                                value: price || '',
                                type: 'number',
                                name: 'price',
                                onChange
                              }}
                            />
                          </ItemGrid>}
                          <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Description'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'description' in errors}
                              inputProps={{
                                value: description || '',
                                type: 'text',
                                name: 'description',
                                multiline: true,
                                rows: 6, 
                                onChange
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={12}>
                            <SelectInput
                              labelText={'Allergen tags'}
                              multiple
                              items={allergensList}
                              error={'allergen_ids' in errors}
                              value={allergen_ids || []}
                              name={'allergen_ids'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'allergen_ids')
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={12}>
                            <CustomInput
                              labelText={'Kitchen notes'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'kitchen_notes' in errors}
                              inputProps={{
                                value: kitchen_notes || '',
                                type: 'text',
                                name: 'kitchen_notes',
                                multiline: true,
                                rows: 6, 
                                onChange
                              }}
                            />
                          </ItemGrid>
                        </ItemGrid>
                        {type !== 'composite' && <ItemGrid md={6} container>
                          <ItemGrid md={12}>
                            <SelectInput
                              labelText={'Diets'}
                              multiple
                              items={dietList}
                              error={'diet_ids' in errors}
                              value={diet_ids || []}
                              name={'diet_ids'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'diet_ids')
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <CustomInput
                              labelText={'Calories'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'calories' in errors}
                              inputProps={{
                                value: (calories || 0) / 1000,
                                type: 'number',
                                name: 'calories',
                                onChange: e => onNumberChange(e, 1000, 0, 100000000)
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <CustomInput
                              labelText={'Fat'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'fat' in errors}
                              inputProps={{
                                value: (fat || 0) / 1000,
                                type: 'number',
                                name: 'fat',
                                onChange: e => onNumberChange(e, 1000, 0, 100000000)
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <CustomInput
                              labelText={'Protein'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'protein' in errors}
                              inputProps={{
                                value: (protein || 0) / 1000,
                                type: 'number',
                                name: 'protein',
                                onChange: e => onNumberChange(e, 1000, 0, 100000000)
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <CustomInput
                              labelText={'Carbohydrate'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'carb' in errors}
                              inputProps={{
                                value: (carb || 0) / 1000,
                                type: 'number',
                                name: 'carb',
                                onChange: e => onNumberChange(e, 1000, 0, 100000000)
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <CustomInput
                              labelText={'Fibre'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={'fibre' in errors}
                              inputProps={{
                                value: (fibre || 0) / 1000,
                                type: 'number',
                                name: 'fibre',
                                onChange: e => onNumberChange(e, 1000, 0, 100000000)
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <SelectInput
                              labelText={'Spicy'}
                              multiple={false}
                              items={['No', 'Yes']}
                              error={'pungency' in errors}
                              value={pungency || '0'}
                              name={'pungency'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'pungency')
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <SelectInput
                              labelText={'Active'}
                              multiple={false}
                              items={['No', 'Yes']}
                              error={'active' in errors}
                              value={active || '1'}
                              name={'active'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'active')
                              }}
                            />
                          </ItemGrid>
                          <ItemGrid md={6}>
                            <SelectInput
                              labelText={'Is heatable?'}
                              multiple={false}
                              items={['No', 'Yes']}
                              error={'is_heatable' in errors}
                              value={is_heatable || '1'}
                              name={'is_heatable'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'is_heatable')
                              }}
                            />
                          </ItemGrid>
                        </ItemGrid>}
                      </ItemGrid>
                      <ItemGrid md={6} container>
                        <ItemGrid md={6} container>
                          <ItemGrid md={12}>
                            <SelectInput
                              labelText={'Type'}
                              multiple={false}
                              items={dishTypes}
                              error={'type' in errors}
                              empty={''}
                              value={type || []}
                              name={'type'} 
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'type')
                              }}
                            />
                            <p className='descOnDishForm'>
                              <span>Simple dish</span> - consists mainly of one ingredient
                            </p>
                            <p className='descOnDishForm'>
                              <span>Ready meals</span>  - e. g. snacks or soy sauce in mini packs
                            </p>
                            <p className='descOnDishForm'>
                              <span>Composite meal</span>  - consists of several simple dishes e. g. chicken and rice
                            </p>
                          </ItemGrid>
                          <ItemGrid md={12}>
                          <SelectInput
                              labelText={'Groups'}
                              multiple
                              items={dishGroups}
                              error={'dish_group_ids' in errors}
                              empty={''}
                              value={dish_group_ids || dish_group_id || []}
                              name={'dish_group_ids'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'dish_group_ids') 
                              }}
                            />
                            <p className='descOnDishForm'>
                              <span>Indicate the type of dish, if it mainly consists of one ingredient, for example risotto or pasta</span>
                            </p>
                          </ItemGrid>
                          {(dish_group_id == 13) && 
                             <ItemGrid md={12}>
                              <SelectInput
                                labelText={'Side Group'}
                                multiple={false}
                                items={sideGroups}
                                error={'side_group_id' in errors}
                                empty={''}
                                value={side_group_id || ''}
                                name={'side_group_id'}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  onChange: e => onChange(e, 'side_group_id') 
                                }}
                              />
                              <p className='descOnDishForm'>
                                <span>Set the category of sides</span>
                              </p>
                            </ItemGrid>
                          }
                          <ItemGrid md={12}>
                            <SelectInput
                              labelText={'Label'}
                              multiple={false}
                              items={dishLabels}
                              error={'label_id' in errors}
                              empty={''}
                              value={label_id || ''}
                              name={'label_id'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'label_id')
                              }}
                            />
                           
                          </ItemGrid>
                          <ItemGrid md={12}>
                            <SelectInput
                              labelText={'Meal types'}
                              multiple
                              items={typeList}
                              error={'mealType_ids' in errors}
                              empty={''}
                              value={mealType_ids || mealTypes || []}
                              name={'mealType_ids'}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: e => onChange(e, 'mealType_ids')
                              }}
                            />
                            <p className='descOnDishForm'>
                              <span>Specify the meal type, only if the dish can be considered as a full meal</span>
                            </p>
                          </ItemGrid>
                        </ItemGrid>
                        <ItemGrid md={6}>
                          <Dropzone
                            onDrop={files => this.onDrop(files)}
                            className='drop-zone'
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              borderColor: 'image' in errors ? 'red' : '#a7a7a7'
                            }}
                          >
                            {!image && <div className='drop-zone-label'><p>Click to upload or drop image here</p></div>}
                          </Dropzone>
                          <Dropzone
                            onDrop={files => this.onDropPackImage(files)}
                            className='drop-zone'
                            style={{
                              backgroundImage: `url(${packImageUrl})`,
                              borderColor: 'pack_image' in errors ? 'red' : '#a7a7a7'
                            }}
                          >
                            {!pack_image && <div className='drop-zone-label'><p>Click to upload or drop packing image here</p></div>}
                          </Dropzone>
                        </ItemGrid>
                      </ItemGrid>
                    </Grid>
                  </div>
                }
                footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
              />
            </ItemGrid>
            {(type === 'simple') && <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle={'Pack structure'} 
                cardSubtitle=''
                headerColor='darkBlue'
                content={this.renderPackaging(dataArray)}
                footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
              />
            </ItemGrid>}
            <ItemGrid md={12}>
              <RegularCard
                  cardTitle='Preparation Method'
                  cardSubtitle=''
                  headerColor='darkBlue'
                  content={<CustomInput
                    labelText={'Preparation Method'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={'prepare_method' in errors}
                    inputProps={{
                      value: prepare_method || '',
                      type: 'text',
                      name: 'prepare_method',
                      multiline: true,
                      rows: 6, 
                      onChange
                    }}
                  />}
                  footer=''
                />
             </ItemGrid>    
             {type && type === 'composite' && <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle='Main Info'
                cardSubtitle=''
                headerColor='darkBlue'
                content={this.renderCalories(dataArray)}
                footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
              />
            </ItemGrid>}

             {type && type === 'composite' && <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle='Sides' 
                cardSubtitle=''
                headerColor='darkBlue'
                content={this.renderSides(dataArray, 'basic')}
                footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
              />
            </ItemGrid>}

            {(type) && <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle={type!='composite'?"Ingredients":'Size & Macros'}
                cardSubtitle=''
                headerColor='darkBlue'
                content={this.renderComponents(type, dataArray)}
                footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
              />
            </ItemGrid>}
          </Grid>
        </div>}
      </ModalWrapper>
    );
  }
}

Form.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  user: state.user,
  dataArray: state.dataArray
});

const actions = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions)(Form));
