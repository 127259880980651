import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import CustomCard from 'components/Cards/CustomCard';
import ItemGrid from 'components/Grid/ItemGrid';
import moment from 'moment';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';

class UserTile extends Component {
  constructor(props) {
    super(props);
    const { active } = props;
    this.state = {
      active
    };
  }

  render() {
    const { active } = this.state;
    const { uid, userInfo, dataArray, clickOnUser, setRef, renewList, notshow } = this.props;
    const { addressFields, emirate, areasList, planList, typeList } = dataArray;
    const { firstName, lastName, phone, gender, birthday, plan, address,account_status } = userInfo;
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const to = `/admin/customers/details/${uid}`;
    var left = false;
    var colorstatus = null;
    var paycolorstatus = null;
    var futurepaycolorstatus = null;
    var activepaid = null;
    var futurepaid = null;
    const planInfo = () => {
      if (plan) {
        const { start, end, payed, price, renew_status, renew_status_date, paymentMethod, renew_reason } = plan;
        const startDate = moment.unix(start).format('DD.MM');
        const endDate = moment.unix(end).format('DD.MM');
        var now = Date.now() / 1000;
        var diff = parseInt( (end - parseInt(now)) / 60 / 60 / 24 ) + 1;
        if (diff <= 3 ) {
          if (diff < 0) {
            diff = 0;
          }
          left = diff + ' days left';
        }
        

        if (renew_status !== null) {
          var diff = parseInt( (parseInt(now) - renew_status_date) / 60 / 60 / 24 ) + 1;
            if (diff > 0 && renew_status === 1) {
              left = 'updated';
              colorstatus = 'green';

              if (typeof(renewList[uid]) != 'undefined') { 
                  if (renewList[uid] != 'not_paid') {
                    futurepaid = 'Future: Paid by '+renewList[uid];
                    futurepaycolorstatus = 'green';
                  } else {
                     futurepaid = 'Future: Not Paid';
                     futurepaycolorstatus = 'red';
                  }
              }
            } else if (diff <=3 && diff > 0 && renew_status === 0) {
              left = 'not renewed';
              colorstatus = 'yellow';
            }

            if (renew_status === 0 && renew_reason) {
              left = 'not renewed';
              colorstatus = 'yellow';
            }
        }

        if (payed && paymentMethod && paymentMethod != 'not_paid') {
          activepaid = 'Active: Paid by '+paymentMethod;
          paycolorstatus = 'green';
        } else {

          if (paymentMethod && paymentMethod != 'not_paid') {
            activepaid = 'Active: Paid by '+paymentMethod;
            paycolorstatus = 'green';
          } else {
            activepaid = 'Active: Not Paid';
            paycolorstatus = 'red';
          }
        }

        if (renew_status === 1) {
          left = 'renewed';
          colorstatus = 'green';
        }

        if (plan.status == 'cancelled') {
          left = 'cancelled';
          colorstatus = 'green';
          activepaid = '';
        }

        return (
          <Fragment>
            <p className='duration'>{`${startDate} - ${endDate}`}</p>
            {/*<p className='price bold'>{`AED ${price / 100}`}</p>
            <p className='status paid'>{payed ? 'already payed' : `Collection ${startDate}`}</p>*/}
          </Fragment>
        ); 
      } else { 
        return <p>No plan</p>;
      }
    };
    const age = birthday ? `${parseInt(moment.duration(moment().diff(moment.unix(birthday))).humanize()) - 1} old` : 'no data';
    const makeAddress = address => {
      return addressFields && address && emirate && areasList ? Object.keys(addressFields).reduce((acc, cur, idx) => {
        if (address[cur] && cur !== 'default') {
          const curVal = address[cur];
          const value = cur === 'emirate' ? emirate[curVal] : (cur === 'area' ? areasList[curVal] : curVal);
          return `${acc}${idx ? ', ' : ''}${value || ''}`;
        } else {
          return acc;
        }
      }, '') : 'Address is not set';
    };
    const getExcludeWeek =(exclude_week_day)=>(exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const getTypes = typesString => typesString && typeList ? typesString.split(',').map(el => typeList[el].title).join(', ') : 'No data';
    const customclass=(account_status!='banned')?'regular':'banned';
    if ( (plan && notshow) || (!plan && !notshow) ) {
    return <ItemGrid sm={12}>
      <Link
        to={to}
        onClick={e => clickOnUser(e, to, uid, { title: (lastName && lastName) ? `${firstName} ${lastName}` : 'First and last name are not set' })}
        key={uid}
        onlyActiveOnIndex={false}
      >
        <CustomCard noPadding marginOnePx>
          <div className={`${active ? 'active-customer-item' : customclass}`} ref={setRef}>
            <div className='customer-owerview'>
              <p className='id bold'>{`id: ${uid}`}</p>
              {planInfo()}
            </div>
            <div className='customer-desc'>
              <p>
                <span className='name bold'>{(lastName && lastName) ? `${firstName} ${lastName}` : 'First and last name are not set'}</span>
                <span className='phone bold'>
                  <span className='age-gender'>{`${age}${gender ? ', '.concat(gender) : ''}`}</span>
                  {phone || 'phone is not set'}
                </span>
                {/* <div className="tileStatuses">
                <span className={`daysleft bold ${colorstatus}`}>
                  {left}
                </span>
                <span className={`paystatus mainstat bold ${paycolorstatus}`}>
                  {activepaid}
                </span>
                {<span className={`paystatus bold ${futurepaycolorstatus}`}>
                  {futurepaid} 
                </span>}
                </div> */}
              </p>
              {/* {plan && <p className='pinfo'>
                <span className='plan bold'>{(plan.days_count<=0)?planList[plan.id].title:plan.days_count+' Days - '}{(plan.days_count>0)?getExcludeWeek(plan.exclude_week_day):''}{}</span>: <span className='types'>{getTypes(plan.types)}</span>
              </p>} */}
              <p className='location'>{makeAddress(address)}</p>
            </div>
          </div>
        </CustomCard>
      </Link>
    </ItemGrid>;
    } else {
      return <div></div>
    }
  }
}

UserTile.propTypes = {
  uid: PropTypes.number.isRequired,
  dataArray: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  clickOnUser: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  active: PropTypes.bool,
  socket: PropTypes.object.isRequired,
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify())
});

export default socketConnect(connect(props, actions)(UserTile));