import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid, Checkbox } from 'material-ui';
import { Close, Check } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';
import Snackbar from 'components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons/index';
import { Text } from 'components/Typography';
import RegularCard from 'components/Cards/RegularCard';
import { getArrays } from 'utils';

class EmirateSlots extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      emirateId:id,
      slots: {},
      order: [],
      note: false,
      message: ''
    }; 
    this.getSlots(id);
  }
  listener = ({ data, type }) => {
    if (this.IngRef) {
      switch (type) {
        case 'getEmirateSlotsOk':
          this.setState({slots:data.slots,order:Object.keys(data.slots)});
          break;
        case 'saveEmirateSlotsOk':
          // console.log(data)
         this.showNotification(data.message);
         break;
        case 'getEmirateSlotsErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };
  componentWillMount() {
    this.props.socket.on('delivery_schedule', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('delivery_schedule', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['slotsListFull','emirate'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }
  showNotification(message) {
    this.setState({ note : true, message });
    setTimeout(() => this.setState({ note : false, message: '' }), 5000);
  }
  getSlots = emirateId => this.props.socket.emit('delivery_schedule', { type: 'getEmirateSlots', data: { emirateId } });
  
  saveSlotData = () =>{
    const { slots, emirateId} = this.state;
    this.props.socket.emit('delivery_schedule', { type: 'saveEmirateSlots', data: {slots,emirateId } })
  }
  renderIngredientFields = (id) => {
    const { dataArray } = this.props;
    const { slotsListFull } = dataArray;
    const { slots, order: oldOrder } = this.state;
    const list = slotsListFull && Object.keys(slotsListFull).reduce((acc, cur) => (!slots[cur] || cur === id) ? { ...acc, [cur]: slotsListFull[cur].name } : acc, {});
    const onChange = val => {
      let slotList = { ...slots };
      let order = [...oldOrder];
      if (id && id in slotList) {
        slotList = { ...slotList, [val]: slotList[id] };
        delete slotList[id];
        order[order.indexOf(id)] = val;
      } else {
        slotList = { ...slotList, [val]: {slot_id:val} };
        order = [...order, val];
      }
      this.setState({ slots: slotList, order });
    };
    const { slot_id,sort_order } = slots[id] || {};
    
    const qtyChange = (e, min = -Infinity, max = Infinity) => {
      const { name, value } = e.target;
      id && this.setState({ slots: { ...slots, [id]: { ...slots[id], [name]: value < min ? min : (value > max ? max : value) } } });
    };
    const remove = () => {
      if (id in slots) {
        let slotList = { ...slots };
        delete slotList[id];
        this.setState({ slots: slotList, order: oldOrder.filter(el => el !== id) });
      }
    };
    return (
      <ItemGrid md={12} key={id} container>
        <ItemGrid md={4}>
          <Select
            placeholder={'Slots'}
            list={list}
            value={slot_id || ''}
            name={'slot_id'}
            onChange={(name, value) => onChange(value)}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <CustomInput
                    labelText={'Sort Order'}
                    formControlProps={{
                    fullWidth: false
                    }}
                    error={false}
                    inputProps={{
                    value: (sort_order!=undefined)?sort_order:'',
                    type: 'number',
                    name: 'sort_order',
                    onChange: e => qtyChange(e, 0, 10000)
                    }}
                />
        </ItemGrid>
        <ItemGrid md={1}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>
      </ItemGrid>
    );
  };

  getRenderer = () => {
    let renderer = null;
    renderer = 'renderIngredientFields';
    return renderer;
  };
  renderPrices = () =>{
    const  { order } = this.state;
    const renderer = this.getRenderer();
    return <div>
      {order.map(this[renderer])}
        {this[renderer]()}
    </div>;
  }
  render() {
    const  { dataArray } =this.props;
    const  { emirate } = dataArray;
    const  { emirateId,message,note} = this.state;
    var emiratename= emirate[emirateId] || '' ;  
    return (
      <Grid container ref={el => (this.IngRef = el)}>
        <RegularCard
              cardTitle={emiratename+' Slot List'} 
              cardSubtitle=''
              headerColor='darkBlue'
              content={this.renderPrices()}
              footer={<Button color='darkBlue' onClick={this.saveSlotData}>Save</Button>}
            />
            <Snackbar
              place='tc'
              color='info'
              icon={AddAlert}
              message={message}
              open={note}
              closeNotification={() => this.setState({ note: false, message: '' })}
              close
            />
      </Grid>
      
    );
  }
}

EmirateSlots.propTypes = {
    socket: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired, 
    dataArray: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    dataArray: state.dataArray
  });
  
  export default socketConnect(connect(mapStateToProps, null)(EmirateSlots));
