import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from 'material-ui';

import ItemGrid from 'components/Grid/ItemGrid';
import DataGrid from './Grid';
import Paginator from 'components/Paginator';

class List extends Component {
  render() {
    const { page, maxPages, actions, list, goTo, limit, dictionaries } = this.props;
    const attributes = { list, actions, offset: limit * (page - 1), dictionaries };
    return (
      <div className={'list-wrap'}>
        <DataGrid {...attributes} />
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

List.propTypes = {
  page: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  dictionaries: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired
};

List.defaultProps = {
  limit: 20
};
export default List;
