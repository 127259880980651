import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/SelectInput';
import Select from 'components/Select';
// import { Select2 } from "select2-react-component";
import CustomInput from 'components/CustomInput/CustomInput';
import { Close, Check } from '@material-ui/icons/index';
import Select2 from 'react-select2-wrapper';
class SidesMatrix extends Component { 
  constructor(props) {
    super(props);
    const {diet, sides} = props;
    // console.log(diet);
    const sidesdata=sides||{};
    this.state = {
        diet: diet,
        sides:sidesdata
    };
  }
  onPortionChange(dietId, value) {
    var {sides} = this.state;
    if(sides[dietId]==undefined){
      sides[dietId]={};
    }
    sides[dietId]['portion_size_id']=value;
    this.setState({
      sides: sides
    });
  };
  onChange(name,value,dietId,mealId) {
    
    switch(name) {
      case 'side':
        var {sides} = this.state;
       console.log(mealId);
        if(sides[dietId]!=undefined){
          sides[dietId][mealId]=value;
          this.setState({
            sides: sides
          });
        }
      break;
      default:
      this.setState({
        [name]: value,
      })
      break;
    }

  }

  saveSides() {
    const {side, product_id, diet, is_default} = this.state;
    var data = { productId: product_id, sideId: side, diet: diet, isDefault: is_default }
    
    this.props.socket.emit('menu_builder', {
      type: 'saveSides',
      data: data
    }); 
  }

  delSides(id) {
    var data = { dataId: id }
    
    this.props.socket.emit('menu_builder', {
      type: 'delSides',
      data: data
    }); 
  }

  renderList = () => {
    const {sidesdata, dietList, products} = this.props;

    var rows = [];
    Object.keys(sidesdata).map( (el, idx) => {
      var item = sidesdata[el];
      // console.log(products[item.side_id]);
      rows.push(
        <ItemGrid md={12} container>
          <ItemGrid md={2}>
            {dietList[item.diet_id] || 'no diet'}
          </ItemGrid>
          <ItemGrid md={3}>
            {products[item.side_id].title || 'no product'}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].fat/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].protein/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].carb/1000 || 0}
          </ItemGrid>
          <ItemGrid md={1}>
            {products[item.side_id].calories/1000 || 0}
          </ItemGrid>
          <ItemGrid md={2}>
            {item.is_default ? <span className="defaultDish">default</span> : ''}
          </ItemGrid>
          <ItemGrid md={1}>
            <Button size='small' color='white' onClick={(e) => {this.delSides(item.id)}}><Close color='error' /></Button>
          </ItemGrid>
        </ItemGrid>
      )
    });
    
    return rows;
  }
  renderListNew=()=>{
    const {sidesdata, dietList,typeList, products,mealTypes,sidesList} = this.props;
    // console.log(mealTypes);
    // console.log(dietList);
    // console.log(sidesdata);
    // var datas=[];
    Object.keys(dietList).map( (el, idx) => {
      return (
        <tr key={el}>
          <td className='first-col'>{dietList[el]}</td>
          {Object.keys(mealTypes).map( (meal_id, idx) => {
              <td><Select2 
                  placeholder='Side'
                  data={sidesList} 
                  >
                </Select2></td>
          })}
          </tr>
      );
    });
    // return datas;
  }
  renderComponentFields = (id) => {
    const {diet} = this.state;
    const { dietList} = this.props;
  

    return (
      <ItemGrid md={12} container>
        <ItemGrid md={6}>
          <Select
            placeholder={'Add Diet Type'}
            list={dietList}
            multiple={true}
            value={diet || ''}
            name={'diet'}
            onChange={(name, value) => this.onChange(name, value)}
          />
        </ItemGrid>
      </ItemGrid>
    );
  };
  renderHead=()=>{
    const {mealTypes,typeList} = this.props;
    var rows=[];
    mealTypes.map((el,idx)=>{
        rows.push(<td key={el}>{typeList[el].title}</td>);
    });
    return rows;
  }
  renderOtherSidesFields=(dietId)=>{
    const {sidesList} = this.props;
    const {sides} = this.state;
    const sidesdata=sides||{};
    return (<Select2 
      options={{
        placeholder: 'Sides',
        name:dietId+'other_side'
      }}
      data={sidesList} 
      
      value={(sidesdata[dietId]!=undefined&&sidesdata[dietId]['other_side']!=undefined)?sidesdata[dietId]['other_side']:''}
      onChange={() => console.log('onChange')}
      // onChange={ 
      //   (e) => {console.log(e);}
      // }
      > 
    </Select2>);
  }
  renderCalories=(products,product_id)=>{
    var calories=0;
    var carbs=0;
    var protein=0;
    var fat=0;
    if(products[product_id]!=undefined&&!Array.isArray(product_id)){
      // console.log(products[product_id]);
      calories = products[product_id].calories/1000;
      carbs = products[product_id].carb/1000;
      protein = products[product_id].protein/1000;
      fat = products[product_id].fat/1000;
    return (<div> Kcal {calories}, PRO {protein}g, CHO {carbs}g, F {fat}g </div>);
    }else if(Array.isArray(product_id)&&product_id.length>0){
      product_id.map((el,index)=>{
        if(products[el]!=undefined){
          calories = calories+products[el].calories;
          carbs = carbs+products[el].carb;
          protein = protein+products[el].protein;
          fat = fat+products[el].fat;
        }
      })
      return (<div> Kcal {calories/1000}, PRO {protein/1000}g, CHO {carbs/1000}g, F {fat/1000}g </div>);
    }
    return '';
  }
render() {
  const {dietList,typeList, products,mealTypes,sidesList,portionSizeList,fixedsidesList} = this.props;
  const {diet,sides} = this.state;
  const sidesdata=sides||{};
  
  // console.log(products);
  // console.log(mealTypes);

  const thead = mealTypes ? [<th  />,<th />, ...mealTypes.map((el,index) => <th >{typeList[el].title}</th>),<th>Other Options</th>,<th>Fixed Sides</th>] : [];
  const thead1 = mealTypes ? [<th />,<th >Portion Size</th>, ...mealTypes.map((el,index) => <th  />),<tdth />] : [];
 /* const table1 = diet && mealTypes && mealTypes.map((mealId,index) => {
   

        return (
          <tr >
            <td className='first-col'>{typeList[mealId].title}</td>
            {diet.map((dietId,index1) => (
              
              <td className='first-col'>{<SelectInput                    
                    multiple={false}
                    items={portionSizeList}
                    empty={''}
                    value={sidesdata[dietId].portion_size_id || {}||''}
                    name={'portion_size_id'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onPortionChange(dietId, e.target.value)
                    }}
                  />}</td>,
                  <td>{<Select2 
                    placeholder='Side'
                    data={sidesList} 
                    value={(sidesdata[dietId]!=undefined&&sidesdata[dietId][mealId]!=undefined)?sidesdata[dietId][mealId]:''}
                    update={ 
                      (value) => this.onChange('side',value,dietId,mealId)
                    }
                    > 
                  </Select2>}</td>
            ))}
          </tr>
        );
      });  
      const otheroptions=<tr>
        <td className='first-col'>Other Options</td>
        {diet.map((dietId,index1) => (
         <td>{<Select2 
                placeholder='Side'
                data={sidesList} 
                multiple={true}
                value={(sidesdata[dietId]!=undefined&&sidesdata[dietId]['other_side']!=undefined)?sidesdata[dietId]['other_side']:''}
                update={ 
                  (value) => this.onChange('side',value,dietId,'other_side')
                }
                > 
              </Select2>}</td>
        ))}</tr>;
        const fixeoptions=<tr>
        <td className='first-col'>Fixed Sides</td>
        {diet.map((dietId,index1) => (
         <td>{<Select2 
                placeholder='Side'
                data={sidesList} 
                multiple={true}
                value={(sidesdata[dietId]!=undefined&&sidesdata[dietId]['other_side']!=undefined)?sidesdata[dietId]['other_side']:''}
                update={ 
                  (value) => this.onChange('side',value,dietId,'other_side')
                }
                > 
              </Select2>}</td>
        ))}</tr>;
         */
  
  const table = diet && mealTypes && diet.map((dietId,index) => {
    const { portion_size_id } = sidesdata[dietId] || {};
    let refName = dietId+'otherSideRef';
    let reffixedName = dietId+'fixedSideRef';
        return (
          <tr >
            <th className='first-col headcol'>{dietList[dietId]}</th>
            <td className='first-col'>{
                  <SelectInput
                      
                      multiple={false}
                      items={portionSizeList}
                      empty={''}
                      value={portion_size_id||''}
                      name={'portion_size_id'}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onPortionChange(dietId, e.target.value)
                      }}
                    />}</td>
            {mealTypes.map((mealId,index1) => (
              <td >
                <Select2 
                    options={{
                      placeholder: 'Sides',
                      name:'side'+dietId+mealId
                    }}
                    data={sidesList} 
                    value={(sidesdata[dietId]!=undefined&&sidesdata[dietId][mealId]!=undefined)?sidesdata[dietId][mealId]:''}
                    onChange={(e) =>{this.onChange('side',e.target.value,dietId,mealId)}}
                    />    
                    {this.renderCalories(products,(sidesdata[dietId]!=undefined&&sidesdata[dietId][mealId]!=undefined)?sidesdata[dietId][mealId]:'')}               
              </td>
            ))}

         <td><Select2 
                   options={{
                    placeholder: 'Sides',
                  }}
                    multiple
                    data={sidesList} 
                    value={(sidesdata[dietId]!=undefined&&sidesdata[dietId]['other_side']!=undefined)?sidesdata[dietId]['other_side']:[]}
                    // onChange={ 
                    //   (e) => this.onChange('side',e.target.value,dietId,'other_side')
                    // }
                    ref={refName}
                    onOpen={() => this.setState({isSelectOpen: true})}
                    onClose={() => this.setState({isSelectOpen: false})}
                    onChange={e => {
                          if (this.state.isSelectOpen) {
                                  if(sidesdata[dietId]!=undefined){
                                    sidesdata[dietId]['other_side']=this.refs[refName].el.val();
                                  }
                                  this.setState({isSelectOpen: false,sides: sidesdata})
                           }
                       }}
                      //  onUnselect={({target: {value}}) => {
                      //   console.log(value);
                      //    var indexval=sidesdata[dietId]['other_side'].indexOf(value);
                      //    console.log(indexval);
                      //    console.log(sidesdata[dietId]['other_side']);
                      //    console.log(this.refs[refName].el.val());
                      //   // if(indexval>-1){
                      //   //      sidesdata[dietId]['other_side'].splice(indexval, 1);
                      //   //  }
                      //   // this.setState({sides: sidesdata});
                      // }}
                    > 
                  </Select2>
                  
                  </td>
        <td><Select2 
                   options={{
                    placeholder: 'Sides',
                  }}
                  
                    data={fixedsidesList} 
                    value={(sidesdata[dietId]!=undefined&&sidesdata[dietId]['fixed_side']!=undefined)?sidesdata[dietId]['fixed_side']:''}
                    ref={reffixedName}
                    onOpen={() => this.setState({isSelectOpen: true})}
                    onClose={() => this.setState({isSelectOpen: false})}
                    onChange={e => {
                          if (this.state.isSelectOpen) {
                                  if(sidesdata[dietId]!=undefined){
                                    sidesdata[dietId]['fixed_side']=this.refs[reffixedName].el.val();
                                  }
                                  this.setState({isSelectOpen: false,sides: sidesdata})
                           }
                       }}
                    // onChange={ 
                    //   (e) => this.onChange('side',e.target.value,dietId,'fixed_side')
                    // }
                    > 
                  </Select2>
                  {this.renderCalories(products,(sidesdata[dietId]!=undefined&&sidesdata[dietId]['fixed_side']!=undefined)?sidesdata[dietId]['fixed_side']:'')}
                  </td>
          </tr>
        );
      });    
      
  return (
    <div ref={el => (this.SidesRef = el)}>
    {/* <Grid container>
      
      <Button color='darkBlue' onClick={ (e) => this.saveSides()}>Save</Button>
    </Grid> */}
    <h4>Select the Diet</h4>
      <div className="sides-text">
      {/* {sidesdata && <Grid container>{this.renderList()}</Grid>} */}
      {this.renderComponentFields()}
      
      <div className="sides-inner-holder">{mealTypes&&<table className='prices-table sides-holder'>
              <thead>
                <tr>
                  {thead}
                </tr>
                <tr>{thead1}</tr>
              </thead>
              <tbody>
                {table}
              </tbody>
      </table>}
      </div>   
      </div>
    </div>
  );
}
}
SidesMatrix.propTypes = {
  products: PropTypes.object.isRequired,
};

export default SidesMatrix;