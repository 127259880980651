import React from 'react';
import { withStyles, Modal } from 'material-ui';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4
  }
});

class ModalWrapper extends React.Component {
  /* getModalStyle() {
    const { width, height } = this.props;
    return {
      top: '2%',
      left: '1%',
      right: '1%',
      bottom: '2%',
      width: width,
      height: height
    };
  } */

  render({ ...props }) {
    const { classes, children, open, handleClose } = this.props;
    return (
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}
      >
        <div className={`modal-wrapper-custom ${classes.paper}`}>
          <div className={'modal-body'}>
            <div className='modal-close-button'>
              <Close onClick={handleClose} />
            </div>
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string
};

ModalWrapper.defaultProps = {
  width: 'auto',
  height: '90vh'
};

export default withStyles(styles)(ModalWrapper);
