import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      comment: null,
    };
  }

  render() {
    const {src} = this.props;
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Image Preview</Text>
            </Grid>
            <img src={src} />
          </Grid>
        </div>
      </div>
    );
  }
}

PreviewModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default PreviewModal;