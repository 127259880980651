import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid
} from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import { socketConnect } from 'socket.io-react';
import Button from 'components/CustomButtons/Button';
import ProfileLog from './ProfileLog';
import OrderLog from './OrderLog';
class TabLogs extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      current:'orders'
    };
    
  }
  changeTab(slug) {
    this.setState({current:slug});
  };
  render() {
    const {current } = this.state;
    const tabs = {
      'orders': { title: 'Active Order Log', slug: 'orders'},
      'profile': { title: 'Profile Log', slug: 'profile'},
      
    }
    const { userData,dataArray } = this.props;
    const props = { userData: userData || {}, user_id: +userData.user_id,dataArray: dataArray || {} };
    const buttons = Object.keys(tabs).map((key, idx) => {
      const { title, slug} = tabs[key];
      return <Button key={idx} color={(slug === current) ? 'blue' : 'white'} onClick={() => this.changeTab(slug)}>{title}</Button>;
    });
    let tabsData;
    // console.log(tabsData);
    if (current=='profile') {      
      tabsData = <ProfileLog {...props}/>;    
    } else {     
       tabsData = <OrderLog {...props} />; 
    }
    return (
      <div  className='progress scrollable-h'>
        <Grid container>
      <div className='tabsCustomLog'>
        <ItemGrid md={12}>
          {buttons}
        </ItemGrid>
      </div>
      <div className='afterTabsCustom'>
        <div className={'full-w'}>
          {tabsData}
        </div>
      </div>
    </Grid>
    </div>
    );
  }
}

TabLogs.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object
};
const props = state => ({
  dataArray: state.dataArray
});

export default connect(props, null)(socketConnect(TabLogs));
