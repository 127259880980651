import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import ItemGrid from 'components/Grid/ItemGrid';
import DayEdit from './DayEdit';
import DishList from './DishList';
import { setPlanParams, clearPlanParams } from 'store/planBuilder';
import { getArrays } from 'utils';

class ProgramDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDiet: 3,
      activeType: 3,
      activeDay: null,
      includedDishes: [],
      customPlans: {},
      defaultDishes: {},
      defaults: {},
      altproducts: {},
    };
    this.getAltProducts(3);
  }

  checkCustom = (plans, typeId, dietId) => plans.find(el => +el.mealType_id === +typeId && +el.diet_id === +dietId) || {};

  listener = ({ type, data }) => {
    console.log(type);
    const { setPlanParams, clearPlanParams, dataArray } = this.props;
    const { activeType, activeDiet, customPlans: oldCustomPlans } = this.state;
    switch (type) {
      case 'setBageOk':
        this.getDefaultDishes(activeDiet, activeType);
      break;
      case 'setOk':
        const { diet_id, mealType_id, data: planData } = data.planParams || {};
        if (+diet_id === +activeDiet && +mealType_id === +activeType) {
          setPlanParams({ ...planData });
        }
        this.setState({ customPlans: { ...oldCustomPlans, [+diet_id]: { ...oldCustomPlans[+diet_id], [+mealType_id]: planData } } });
        break;
      case 'listOk':
        const { dietList, typeList } = dataArray;
        const { customPlans: plansList } = data;
        const customPlans = Object.keys(dietList || {}).reduce((acc, dietId) => {
          const types = Object.keys(typeList || {})
            .reduce((acc, typeId) => ({ ...acc, [+typeId]: this.checkCustom(plansList, typeId, dietId).data }), {});
          return { ...acc, [+dietId]: types };
        }, {});
        setPlanParams({ ...(customPlans[activeDiet] || {})[activeType] || {} });
        this.setState({ customPlans });
        break;
      case 'delOk':
        const oldPlans = { ...this.state.customPlans };
        const { dietId, mealTypeId } = data;
        (oldPlans[dietId] || {})[mealTypeId] && delete (oldPlans[dietId][mealTypeId]);
        clearPlanParams();
        break;
      case 'setErr':
        console.error(data);
        break;
    }
  };

  defDishesListener = ({ type, data }) => {
    if (type === 'getOk') {
      const defaultDishes = { ...data.defaultDishes };
      this.setState({ defaultDishes, defaults: {} });
    } else if (type == 'getAltProductsOk') {
        this.setState({
          altproducts: data,
        });
    } else if (type === 'setOk') {
      const { defaultDishes, defaults } = this.state;
      this.setState({ defaultDishes: { ...defaultDishes, ...defaults }, defaults: {} });
    } else if (['getErr', 'setErr'].includes(type)) {
      console.error(data);
    }
  };

  componentWillMount() {
    this.props.socket.on('custom_plan', this.listener);
    this.props.socket.on('default_dishes', this.defDishesListener); 
  }

  componentWillUnmount() {
    this.props.socket.removeEventListener('custom_plan', this.listener);
    this.props.socket.removeEventListener('default_dishes', this.defDishesListener);
  }

  componentDidMount() {
    const { socket, dataArray, clearPlanParams } = this.props;
    const types = ['typeList', 'dietList', 'productsFull', 'dishLabels','sidesTypes','dietListFull'].filter(type => !(type in dataArray));
    types.length && getArrays(socket, types);
    this.props.socket.emit('custom_plan', { type: 'list' });
    clearPlanParams();
    const { activeDiet, activeType } = this.state;
    activeDiet && activeType && this.getDefaultDishes(activeDiet, activeType) && this.getAltProducts(activeDiet,activeType);
  }

  change = ({ activeDay, activeDiet: newActiveDiet, activeType: newActiveType, includedDishes } = { activeDay: null }) => {
    const { setPlanParams, planParams,dataArray } = this.props;
    const { customPlans, activeType: oldActiveType, activeDiet: oldActiveDiet } = this.state;
    const activeDiet = newActiveDiet || oldActiveDiet;
    const activeType = newActiveType || oldActiveType;
    if (activeDay !== null) {
      setPlanParams({ ...planParams, [+activeDay]: planParams[activeDay] || includedDishes });
    } else {
      setPlanParams({ ...(customPlans[activeDiet] || {})[activeType] || {} });
      const { typeList,sidesTypes } = dataArray;
      const { parent_id:typeParentId }=typeList[activeType];
      var sideId=false;
      if(typeParentId && sidesTypes ){
        sideId=Object.keys(sidesTypes)[0];
      }
      this.getDefaultDishes(activeDiet, activeType, sideId);
      this.getAltProducts(activeDiet,activeType, sideId);
    }
    this.setState({ includedDishes, activeDay, activeDiet, activeType });
  };

  save = data => {
    const { activeDiet: diet_id, activeType: mealType_id } = this.state;
    this.props.socket.emit('custom_plan', {
      type: 'set',
      data: {
        diet_id,
        mealType_id,
        data
      }
    });
    this.setState({ activeDay: null, includedDishes: [] });
  };

  getDefaultDishes = (dietId, typeId, sideTypeId=false) => {
    this.props.socket.emit('default_dishes', { type: 'get', data: { dietId, typeId,sideTypeId } });
  };

  getAltProducts = (dietId,typeId=null, sideTypeId=false) => {
    this.props.socket.emit('default_dishes', { type: 'getAltProducts', data: { dietId, withsides: true, withsidenames: true,typeId,sideTypeId } });
  };

  setDefaultDishes = (dietId, typeId, defaults, sideTypeId=false) => {
    this.props.socket.emit('default_dishes', { type: 'set', data: { dietId, typeId, defaults,sideTypeId } });
  };

  render() {
    const { activeDiet, activeType, activeDay, includedDishes, defaultDishes, defaults, altproducts } = this.state;
    const { planParams, dataArray } = this.props;
    const { typeList, dietList, productsFull: products, dishGroups, dishLabels,sidesTypes,dietListFull } = dataArray;
    console.log(sidesTypes);
    console.log(typeList);
    const { parent_id:typeParentId }=typeList[activeType];

    console.log(typeParentId);
    const buttons = (list, active, type) => list && Object.keys(list).sort((a, b) => list[a].order - list[b].order).filter(function (typeId) {
      if(type=='activeType'){ // Filter the sides meal types based on diet sides list 
        return ((typeList[typeId].parent_id==0||typeList[typeId].parent_id==null))?true:false;
      }else{
        return true;
      }
  }).map((id, idx) => {
      const { title } = list[id];
      const props = { activeDiet: { color: 'primary', defColor: 'success' }, activeType: { color: 'warning', defColor: 'gray' } };
      return <Button key={idx} color={props[type][+id === +active ? 'color' : 'defColor']}
        onClick={() => this.change({ [type]: +id, activeDay: null })}>{title || list[id]}
      </Button>;
    });
    const days = [];
    for (let dayNum = 0; dayNum < 31; dayNum++) {
      days[dayNum] = (<DishList
        key={dayNum}
        includedDishes={planParams[dayNum] || []}
        products={altproducts}
        activeDay={activeDay}
        dishGroups={dishGroups || {}}
        dishLabels={dishLabels || {}}
        thediet={activeDiet}
        activeType={activeType}
        typeList={typeList}
        sidesTypes={sidesTypes}
        typeParentId={typeParentId}
        day={dayNum}
        clickEdit={() => this.change({ activeDay: dayNum, includedDishes })}
        save={() => this.save(planParams)}
        defaultDishes={defaultDishes || {}}
        defaults={defaults}
        changeDefaults={(dishId,activeSide) =>{ 
          if(typeParentId){
            const newArr = { ...defaults };
            if(newArr[dayNum]==undefined){
              newArr[dayNum]=defaultDishes[dayNum] || {};
            }
            newArr[dayNum][activeSide] = dishId;
            this.setState({ defaults: newArr });
          }else{
            this.setState({ defaults: { ...defaults, [dayNum]: dishId } });
          }
        }}
        saveDefaults={() => this.setDefaultDishes(activeDiet, activeType, { ...defaultDishes, ...defaults },typeParentId)}
      />);
    }
    return (
      <div className='programsBody'>
        <div className='programsHeading'>
          <div className='head sub'>
            <div className={'diets'}>{buttons(dietList, activeDiet, 'activeDiet')}</div>
            <div className={'day-dishes'}>{buttons(typeList, activeType, 'activeType')}</div>
          </div>
        </div>
        <Grid container>
          <ItemGrid md={8}>
            {days}
          </ItemGrid>
        </Grid>
        <DayEdit
          activeDiet={activeDiet}
          dishGroups={dishGroups || {}}
          dishLabels={dishLabels || {}}
          activeType={activeType}
          activeDay={activeDay}
          includedDishes={planParams[activeDay] || includedDishes || []}
          products={altproducts}
        />
      </div>
    );
  }
}

ProgramDetails.propTypes = {
  planParams: PropTypes.object,
  socket: PropTypes.object,
  dataArray: PropTypes.object,
  setPlanParams: PropTypes.func,
  clearPlanParams: PropTypes.func
};

const props = state => {
  return {
    dataArray: state.dataArray,
    planParams: state.planParams
  };
};

const actions = dispatch => ({
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  clearPlanParams: () => dispatch(clearPlanParams())
});

export default socketConnect(connect(props, actions)(ProgramDetails));
