import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Grid } from 'material-ui';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import Text from 'components/Typography/Text';

class PassRestore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
      message: '',
      modalMessage: ''
    };
  }

  listener = ({ type, message }) => {
    if (this.PassRestoreRef) {
      switch (type) {
        case 'checkOk':
          break;
        case 'checkErr':
          this.setState({ message });
          break;
        case 'setOk':
          setTimeout(() => {
            browserHistory.push('/');
          }, 1000);
          break;
        case 'setErr':
          console.error(message);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('restore_password', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('restore_password', this.listener);
  }

  componentDidMount() {
    const { type, token } = this.props.params;
    if (['set', 'restore'].includes(type)) {
      const data = { token };
      this.props.socket.emit('restore_password', { type: 'check', data });
    } else {
      this.setState({ message: 'This link is not valid.' });
    }
  }

  passwordChange = e => this.setState({ password: e.target.value });

  setNewPass = (e) => {
    e.preventDefault();
    const data = {
      token: this.props.params.token,
      password: this.state.password
    };
    this.props.socket.emit('restore_password', { type: 'set', data: data });
  };

  render() {
    const { message, password, modalMessage } = this.state;
    return (
      <div ref={el => (this.PassRestoreRef = el)}>
        <form onSubmit={this.setNewPass} onKeyPress={e => e.key === 'Enter' && this.setNewPass(e)}>
          <Grid container>
            <Grid item md={4} />
            <Grid item xs={12} sm={12} md={4}>
              {message ? <Text>{message}</Text> : <RegularCard
                cardTitle='Set your password'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                          ref={'Pass'}
                          labelText='Password'
                          id='password'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: 'password',
                            value: password,
                            onChange: this.passwordChange
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
                footer={<Button color='success' onClick={this.setNewPass}>Save password</Button>}
              />}
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

PassRestore.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.any.isRequired
};

export default socketConnect(PassRestore);
