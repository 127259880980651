import React, { Component, Children, cloneElement, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getMenuLinks, groups } from 'variables/menu';
import { getArrays } from 'utils';
import {
    Grid,
    Table as MUITable,
    TableBody,
    TableCell,
    TableRow,
    Checkbox
  } from 'material-ui';
import { Close, Check } from '@material-ui/icons/index';
  import TableHead from 'material-ui/Table/TableHead';
import RegularCard from 'components/Cards/RegularCard';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import { AddAlert } from '@material-ui/icons/index';
import Snackbar from 'components/Snackbar/Snackbar';

class UserSettings extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            usertypes :['admin','manager','accounts','nutritionist', 'delivery', 'kitchen', 'logistic', 'packager','guest_nutritionist'],
            cats:getMenuLinks('admin'),
            errors:[],
            usersPrivileges:{},
            message:'',
            note:false
        }
        this.getUserPrivilege();
    }
    getUserPrivilege() {
     
      this.props.socket.emit('profile', {
        type: 'getUserPrivilege'
      });
    }
    socketUsers = ({ type, data }) => {
          switch (type) {
            case 'saveUserPrivilegeOk':
              this.showNotification(data.message);
              break;
              case 'getUserPrivilegeOk':
                const types = this.fillDefaultUserTypes();
                const menulinks = this.fillDefaultMenus();
                const privileges = this.fillDefaultPrivilege(types,menulinks,data.list);
                this.setState({usersPrivileges:privileges});
              break;
            default:
              this.setState({ errors: data.errors });
              this.showNotification(data.errors);
              break;
          }
      };
    fillDefaultUserTypes() {
      const { usertypes } = this.state;
      let types = {};
      usertypes.map((usertype, type) => {
        types[usertype] = {};
      });
     return types;
    }
    fillDefaultMenus () {
      const { cats } = this.state;
      let types = {};
      types['-admin']=false;
      cats.map((acc1, type) => {
        let mainmenu = acc1.path.replaceAll("/",'-');
        if(acc1.path=='/'&&acc1['sublinks']!=undefined){
          acc1['sublinks'].map((sub,index) => {
            let menupath= sub.path.replaceAll("/",'-');
            types[menupath] = false;
          });
        }else{
          types[mainmenu] = false;
        }
        
      });
     return types;
    }
    fillDefaultPrivilege(defaultPrivilege,menulinks,oldprivilege) {
        return Object.keys(defaultPrivilege).reduce((acc, usertype) => {
          let oldusertype =oldprivilege[usertype] || {};
           const menulinks1 = Object.keys(menulinks).reduce((privileges, menupath) => {
             return { ...privileges, [menupath]: (oldusertype[menupath]!=undefined&&(oldusertype[menupath]) || usertype=='admin')?true:false };
          }, {});
          return { ...acc, [usertype]: menulinks1 };
        }, {});
    }
    componentWillMount() {
        this.props.socket.on('profile', this.socketUsers);
      }
    
      componentWillUnmount() {
        this.props.socket.removeListener('profile', this.socketUsers);
      }
    
      componentDidMount() {
        const { socket, dataArray } = this.props;
        const types = ['gender', 'usersPrivilege'].filter(el => !(el in dataArray));
        types.length && getArrays(socket, types);
      }
    saveUserPrivilege() {
      const { usersPrivileges } =this.state;
      this.props.socket.emit('profile', {
        type: 'saveUserPrivilege',
        data:{
          usersPrivileges
        }
      });
    }
    showNotification(message) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
    render(){
        const { usertypes , cats,usersPrivileges, message, note } = this.state;
        const thead = usertypes.map((usertype,index) => {
              return <TableCell>{(usertype=='delivery')?'CALL CENTER':usertype.toUpperCase()}</TableCell>;
        });
        const tbody = Object.keys(usersPrivileges).length >0 && cats && cats.map((cat,index) => {
          let mainmenus =[];
          let sublinks =[];
          let menupath= cat.path.replaceAll("/",'-');
          if(cat.path!='/'){
             if(index==0){
                    let trows=usertypes.map((usertype,index) => {
                      return <TableCell><div className='checkHolder'>
                      <Checkbox
                        checked='false'
                        checkedIcon={<Check className={`${(usersPrivileges[usertype]!=undefined && usersPrivileges[usertype]['-admin'] !=undefined && usersPrivileges[usertype]['-admin'])  ? 'checked' : 'unChecked'} `} />}
                        icon={<Check />}
                        classes={{
                          checked: 'unChecked'
                        }}
                        onClick={() => usertype && this.setState({ usersPrivileges: { ...usersPrivileges, [usertype]: { ...usersPrivileges[usertype], ['-admin']: !usersPrivileges[usertype]['-admin'] } } })}
                      />
                    
                    </div></TableCell>;
                });
                mainmenus.push(<TableRow><TableCell>Home</TableCell>{trows}</TableRow>);
              }
              let trows=usertypes.map((usertype,index) => {
                    return <TableCell><div className='checkHolder'>
                    <Checkbox
                      checked='false'
                      checkedIcon={<Check className={`${(usersPrivileges[usertype]!=undefined && usersPrivileges[usertype][menupath] !=undefined && usersPrivileges[usertype][menupath])  ? 'checked' : 'unChecked'} `} />}
                      icon={<Check />}
                      classes={{
                        checked: 'unChecked'
                      }}
                      onClick={() => usertype && this.setState({ usersPrivileges: { ...usersPrivileges, [usertype]: { ...usersPrivileges[usertype], [menupath]: !usersPrivileges[usertype][menupath] } } })}
                    />
                   
                  </div></TableCell>;
              });
             
              mainmenus.push(<TableRow><TableCell>{cat.navbarName}</TableCell>{trows}</TableRow>);
            }
              if(cat['sublinks']!=undefined){
                sublinks=cat['sublinks'].map((sub,index) => {
                  let menupathnew= sub.path.replaceAll("/",'-');
                  let trowsub=usertypes.map((usertype,index) => {
                        return <TableCell> <div className='checkHolder'>
                        <Checkbox
                          checked='false'
                          checkedIcon={<Check className={`${(usersPrivileges[usertype]!=undefined && usersPrivileges[usertype][menupathnew] !=undefined && usersPrivileges[usertype][menupathnew])  ? 'checked' : 'unChecked'} `} />}
                          icon={<Check />}
                         onClick={() => usertype && this.setState({ usersPrivileges: { ...usersPrivileges, [usertype]: { ...usersPrivileges[usertype], [menupathnew]: !usersPrivileges[usertype][menupathnew] } } })}
                          classes={{
                            checked: 'unChecked'
                          }}
                        />
                       
                      </div></TableCell>;
                  });
                  return <TableRow><TableCell>{cat.navbarName} - {sub.navbarName}</TableCell>{trowsub}</TableRow>
                });
              }
              mainmenus.push(sublinks);
              return mainmenus;
        });
        return <div className='user-settings'>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={'User Privileges'}
              cardSubtitle='Manage privileges setting'
              content={
                <div>
                  <MUITable aria-label="simple table" key={0}>
                        <TableHead>
                            <TableRow><TableCell>Roles / Sections</TableCell>{thead}</TableRow>
                        </TableHead>
                        <TableBody>
                        {tbody}
                        <TableRow><TableCell colSpan={9} align="center"><Button color='success' onClick={e => this.saveUserPrivilege()}>Save</Button></TableCell></TableRow>
                        </TableBody>
                        
                    </MUITable>
                </div>
              }
            />
          </ItemGrid>
        </Grid>
        <Snackbar
            place='tc'
            color='info'
            icon={AddAlert}
            message={message}
            open={note}
            closeNotification={() => this.setState({ note: false, message: '' })}
            close
          />
        </div>;
    }
}   
const props = state => ({
    dataArray: state.dataArray,
    userType: state.user.type
  });
  
  export default socketConnect(connect(props, null)(UserSettings));