import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import { Check, Close } from '@material-ui/icons/index';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from 'material-ui';
import './style.css';
import { Link, browserHistory } from 'react-router';
import { setModal, clearModal } from 'store/modal';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';

class UserRow extends Component {
  render() {
    const { rows, header } = this.props;
    return (
      <div className='UserRow'>
      	  <h3 className="userrow-header">{header}</h3>
	      <table className="table table-user">
	      <thead className="success TableHeader">
	      	<tr className="table-head"><td>id</td><td>Customer name</td><td>Details</td></tr>
	      </thead>
	      <tbody className="table-body">
	      	{rows.map((prop, key) => {
            return (
              <TableRow key={key}>
                {prop.map((prop, key) => {
                  return (
                    <TableCell className="td" key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
                <TableCell className="td" key={key}>
                      <a className="td-user-url" onClick={() => { 
                      	this.props.clearModal(); 
                      	browserHistory.push('/admin/customers/details/'+rows[key][0]);
                      }}>go to customer's details</a>
                </TableCell>
              </TableRow>
            );
          	})}
	      </tbody>
	      </table>
      </div>
    );
  }
}

UserRow.propTypes = {
  header: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
};

const actions = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal())
});

const props = state => ({
  userType: (state.user || {}).type || '',
  dataArray: state.dataArray
});

export default socketConnect(connect(props, actions)(UserRow));
