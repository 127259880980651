import React from 'react';
import PropTypes from 'prop-types';
import { Select, InputLabel, MenuItem, FormControl, withStyles } from 'material-ui';
import { Clear, Check } from '@material-ui/icons';

import customInputStyle from '../../assets/jss/material-dashboard-react/customInputStyle';
import cx from 'classnames';

function SelectInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    value,
    empty,
    items,
    multiple
  } = props;

  const labelClasses = cx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });
  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl + ' inputHolder'}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        multiple={multiple && Array.isArray(value)}
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        value={value}
        id={id}
        onOpen={e => {
          window.selectInput = document.getElementById(id);
        }}
        {...inputProps}
      >
        {empty && <MenuItem key='empty' value=''>
          <em>{empty}</em>
        </MenuItem>}
        {Object.keys(items).map((el, idx) => <MenuItem key={idx} value={el}>{props.items[el]}</MenuItem>)}
      </Select>
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

SelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  items: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  empty: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool
};

SelectInput.defaultProps = {
  id: 'custom-select',
  empty: '',
  multiple: false
};

export default withStyles(customInputStyle)(SelectInput);
