import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Check, ChevronRight, ChevronLeft } from '@material-ui/icons/index';
import { Text } from 'components/Typography';
import noImage from 'assets/no_img.svg';
import { intersect } from 'lib';
import { Select2 } from "select2-react-component";
import ModalWrapper from 'components/ModalWrapper';
require('moment-weekday-calc');

class DishesInfo extends Component {
  constructor(props) {
    super(props);
    const { errors, mealPlan, planParams } = props;
    const dates = Object.keys(mealPlan);
    const currentDate = dates[0] || planParams.date;
    const currentType = planParams.mealType[0];
    this.state = {
      errors,
      planParams,
      currentDate,
      currentType,
      dates,
      sidemodal:false,
      sideModalContent:''
    };
  }
  showSideModal = (prodKeys,productsFull,dishId,sideId,currentType) =>{
    // console.log(prodKeys);
   const { changePlan,userData } = this.props;
   const { currentDate } = this.state;
   const {dishdislikes,eliminate_groups}=userData;
   
    const sidelist =prodKeys.filter(function (dishId1) {
      const { group,spicy } = productsFull[dishId1] || {};
      let isEliminate = true;
      if(eliminate_groups.length>0){ 
        // isEliminate = !(eliminate_groups.indexOf(group)>-1);
        isEliminate = !intersect(group,eliminate_groups).length;   
        if(isEliminate){
            isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
        }
      }
      if(dishdislikes.length>0 && isEliminate){
        isEliminate = !(dishdislikes.indexOf(+dishId1)>-1); 
      }
      return isEliminate;
  }).map((dishId1, idx) => {
      const { title } = productsFull[dishId1] || {}; 
      return {'value':dishId1,'label':title};
    });
    // const { title } = productsFull[dishId] || {}; 
    // const selectDish={'value':dishId,'label':title};
    // console.log(dishId);
    const sidemodalcontent = <Select2 
    placeholder='Change Sides'
    data={sidelist} 
    value={dishId.toString()}
    update={ 
      (value) => changePlan(+currentDate, +currentType, +value,sideId)
    }
    >
  </Select2>;
  // console.log(sidelist);
    this.setState({sidemodal:true,sideModalContent:sidemodalcontent});
    
  }
  handleClose = () =>{
    this.setState({sidemodal:false});
  }
  DishList = ({ productsFull, prodKeys, todayDishes ,ParentTypeId,dietId }) => {
    const { currentDate, currentType, dates } = this.state;
    const { changePlan,userData, customPlanSides,dataArray,mealPlanSides } = this.props;
    const { sidesTypes,dietListFull } = dataArray;
    const { allergies,dislikes,dishdislikes,eliminate_groups}=userData;
    let nextDishid=0;
    const { sides_id  } = dietListFull[dietId] || null;
    // console.log(todayDishes);
    const RenderdishList =({prodKeys, productsFull,todayDishes,currentType,sideId=''})=>{
      prodKeys=productsFull ? prodKeys.filter(function (dishId) {
          const { group,spicy } = productsFull[dishId] || {};
          let isEliminate = true;
          if(eliminate_groups.length>0){ 
            // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
            isEliminate =(group.length>0)?!intersect(group,eliminate_groups).length:false;   
            if(isEliminate){
                isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
            }
          }
          if(dishdislikes.length>0 && isEliminate){
            isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
          }
          return isEliminate;
      }): []; 
      return productsFull ? prodKeys.map((dishId, idx) => {
        const { title, image, calories, rating,ingredients,group,spicy } = productsFull[dishId] || {};
        const imgUrl = image ? `${image}` : noImage;
        /* Start Disable the dislike/allergies dish */
        let is_allergies=false;
        if(ingredients!=undefined&&ingredients.length>0){
          if (allergies.length>0) {
            is_allergies = intersect(ingredients,allergies).length;
          }
          if(dislikes.length>0&&!is_allergies){
            is_allergies = intersect(ingredients,dislikes).length;   
          }
       }
       if(!is_allergies&&eliminate_groups!=undefined&&eliminate_groups.length>0){
            is_allergies =eliminate_groups.includes(group);
            if(is_allergies){
              is_allergies=(eliminate_groups.includes(14)&& spicy==1); // Spicy Group and dish set as spicy
            }
        }
       
        if((todayDishes[+currentType] === +dishId||nextDishid== +dishId)&&is_allergies){
          nextDishid=((prodKeys[idx+1])!=undefined)?prodKeys[idx+1]:'';
        }
        // console.log(sideId);
        /* End Disable the dislike/allergies dish */
        return (
          <div className='dish' key={idx}>
            <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
            {dates.includes(currentDate) && <div className='meal-check-holder'>
              <Checkbox
                checked={(todayDishes[+currentType] == +dishId||+nextDishid==+dishId)&&!is_allergies}
                checkedIcon={<Check className='checked' />}
                icon={<Check className='unChecked' />} 
                onClick={() => changePlan(+currentDate, (sideId)?+sideId:+currentType, +dishId,(sideId)?+currentType:'')}
              />
            </div>}
            {/* {+nextDishid==+dishId&&changePlan(+currentDate, +currentType, +dishId)} */}
            {(!dates.includes(currentDate) && (todayDishes[+currentType] === +dishId||+nextDishid==+dishId)) && <div className='meal-check-holder'>
              <Checkbox
                checked='false'
                icon={<Check className='unChecked' />}
              /> 
            </div>}
            <p className='title'>{title}</p>
            <p>Add Note</p>
            <p className='cal'>{ calories / 1000 } kCal</p>
            <p className='rating'><this.Rating rating={rating} /></p>
          </div>
        );
      }) : []; 
    }
   
    if(ParentTypeId[currentType]!=undefined&&sides_id!=null){
      const dayNum = moment.unix(currentDate).date() - 1;
      let todaySideDishes=(mealPlanSides[currentDate]!=undefined)?mealPlanSides[currentDate][+ParentTypeId[currentType]]:{};
      const sidesContent = todaySideDishes!=undefined&&sides_id!=null&&Object.keys(todaySideDishes).length>0&&sidesTypes? Object.keys(sidesTypes).filter(function (sideId) {
        return (sides_id.indexOf(sideId)>-1);
    }).map((sideId, idx) => {
            const plan = customPlanSides.find(el => +el.mealType_id === +ParentTypeId[currentType] && +el.side_type_id == +sideId) || {};
            let prodKeys1 = (plan.data || {})[dayNum] || [];
           const dishId=todaySideDishes[sideId] || null;
           const { title, image, calories, rating,ingredients,group } = productsFull[dishId] || {};
           const imgUrl = image ? `${image}` : noImage;
          return (
            <div className='sidedish' key={idx}>
              <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
              <p className='title'>{title}</p>
              <p className="link" onClick={() =>this.showSideModal(prodKeys1,productsFull, dishId, sideId,+ParentTypeId[currentType])}>Change</p>
            </div>
          );
       
        }) : [];
 
      return <div>
              {/* <SidesInfo  sidesTypes={sidesTypes} currentType={+ParentTypeId[currentType]} productsFull={productsFull} todaySideDishes={todaySideDishes} prodKeys={prodKeys1} RenderdishList={RenderdishList} /> */}
              {todaySideDishes!=undefined&&sidesContent.length>0?<div className="sidedishcontainer">{sidesContent}</div> :'Please map default side dish on program section.'}
              <RenderdishList {...{prodKeys,productsFull,todayDishes,currentType}} />
           </div>;
    }else{
      return <RenderdishList {...{prodKeys,productsFull,todayDishes,currentType}} />
    }
    
  };

  Rating = ({ rating }) => {
    return Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
  };

  render() {
    const { planParams, currentDate, currentType, dates,sidemodal, sideModalContent } = this.state;
    const { dataArray, userData, mealPlan, customPlans } = this.props;
    const dayNum = moment.unix(currentDate).date() - 1;
    const plan = customPlans.find(el => +el.mealType_id === +currentType) || {};
    let prodKeys = (plan.data || {})[dayNum] || [];
    const { dietList, typeList, productsFull } = dataArray;
    const { diet: dietId, mealType: types } = planParams || {};
    const { firstName, lastName } = userData;
    const userName = `${firstName} ${lastName}`;
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const todayDishes = mealPlan[currentDate];
    const ParentTypeId=[];
    const tabsButtons = types && typeList ? types.map((typeId, idx) => {
      const type = typeList[typeId];
      const { parent_id:typeParentId } = type;
      if(typeParentId==0){ 
        return (
          <Button key={idx} color={+typeId === +currentType ? 'darkBlue' : 'white'} onClick={() => this.setState({ currentType: typeId })}>{type.title}</Button>
        );
      }else{
        ParentTypeId[typeParentId]=typeId;
      }
    }) : [];
    const curDateIdx = dates.indexOf(currentDate);
    const prevDate = curDateIdx > -1 ? dates[curDateIdx - 1] : false;
    const nextDate = curDateIdx > -1 ? dates[curDateIdx + 1] : false;
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
              <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
            </Grid>
            <Grid item md={4} style={{ textAlign: 'left', paddingTop: '20px' }}>
              {prevDate && <Text className='link-butt prev-date' onClick={() => this.setState({ currentDate: prevDate })}>
                <ChevronLeft style={{ verticalAlign: 'bottom' }} /> Prev: {moment.unix(prevDate).format('MMM DD')}
              </Text>}
            </Grid>
            <Grid item md={4} style={{ textAlign: 'center', paddingTop: '20px' }}>
              <div className='day-editing'>Menu editing for <span>{moment.unix(currentDate).format('MMM DD')}</span></div>
            </Grid>
            <Grid item md={4} style={{ textAlign: 'right', paddingTop: '20px' }}>
              {nextDate && <Text className='link-butt next-date' onClick={() => this.setState({ currentDate: nextDate })}>
                Next: {moment.unix(nextDate).format('MMM DD')} <ChevronRight style={{ verticalAlign: 'bottom' }} />
              </Text>}
            </Grid>
            <Grid item md={12}>
              {tabsButtons}
            </Grid>
            <Grid item md={12}>
              <div className='holder'>
                <div className='day'>
                  <this.DishList {...{ productsFull, todayDishes, prodKeys, ParentTypeId, dietId }} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <ModalWrapper  close={() => this.setState({ sidemodal: false })} show={sidemodal} className='sidesmodal'>
                <div style={{minWidth:"360px"}}>
                    <h6>Change Side Dish</h6>
                   {sideModalContent}
                </div>
            </ModalWrapper>
      </div>
    );
  }
}

DishesInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  customPlans: PropTypes.array.isRequired,
  mealPlan: PropTypes.object.isRequired,
  mealPlanSides: PropTypes.object.isRequired,
  customPlanSides: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,  
  dataArray: PropTypes.object.isRequired,
  changePlan: PropTypes.func.isRequired
};

export default DishesInfo;
