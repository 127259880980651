import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import ToolTip from 'components/ToolTip';
import SelectInput from 'components/SelectInput';
import { Checkbox } from 'material-ui';
import { Check } from '@material-ui/icons/index';
import { Map, Marker } from 'components/GoogleMap';
import { ABU_CENTER } from 'variables/constants';

import marker_img from 'assets/img/hexagonal_marker.png';

class Route extends Component {
  constructor(props) {
    super(props);
    const { activeRoute, notUsed, priorities, activeDay, activeWindow, activeWindowPartner } = props;
    const priority = priorities.findIndex(el => el.date === +activeDay && el.window === +activeWindow && el.driver === +activeWindowPartner) !== -1;
    this.markers = {};
    this.state = {
      isEdit: false,
      changePriority: false,
      priority,
      mapIsLoaded: false,
      hideDialog: false,
      route: activeRoute || [],
      notUsed: notUsed || [],
      activeArea: '0'
    };
  }

  componentWillReceiveProps(next) {
    const { activeRoute: nextRoute, activeWindowPartner: nextDriver, notUsed: nextNotUsed, priorities, activeDay, activeWindow } = next;
    const { activeWindowPartner: thisDriver } = this.state;
    if ((nextDriver !== thisDriver) && this.RouteRef) {
      const priority = priorities.findIndex(el => el.date === +activeDay && el.window === +activeWindow && el.driver === +nextDriver) !== -1;
      this.setState({ route: nextRoute, notUsed: nextNotUsed, isEdit: false, changePriority: false, priority, activeArea: '0' });
    }
  }

  markAsVisit = userId => {
    const { route, notUsed } = this.state;
    const includes = route.includes(userId);
    this.setState({
      route: includes ? route.filter(el => el !== userId) : [...route, userId],
      notUsed: includes ? [userId, ...notUsed] : notUsed.filter(el => el !== userId)
    });
  };

  save = () => {
    const { route, notUsed, priority } = this.state;
    const { setRoute, activeWindowPartner } = this.props;
    setRoute(activeWindowPartner, route, notUsed, priority);
  };

  move = (idx, dir) => {
    const route = [ ...this.state.route ];
    route.splice(idx + dir, 0, route.splice(idx, 1)[0]);
    this.setState({ route });
  };

  addClientsToMap(clients = []) {
    const { map, maps, props, state } = this;
    const { clientsList, activeWindowPartner, bagVisitsInit, activeSlot, activeDay } = props;
    const { route } = state;
    let counter = 1;
    return (map && maps && clientsList) ? clients.map((clientId, idx) => {
      return clientsList[clientId][activeDay][activeSlot] != undefined && Object.keys(clientsList[clientId][activeDay][activeSlot]).length>0 ? Object.keys(clientsList[clientId][activeDay][activeSlot]).map((area,idx1)=>{
      const { address, firstName, lastName } = clientsList[clientId][activeDay][activeSlot][area];
      const { lat, lng } = address.location || {};
      const hint = `${firstName} ${lastName}`;
      this.markers = [...this.markers, clientId];
      const index = route.findIndex(el => +el === +clientId);
      const isBagVisit = bagVisitsInit.includes(+clientId);
      return (
        <Marker
          key={idx}
          lat={lat}
          lng={lng}
          title={activeWindowPartner && index !== -1 ? `${index + 1}` : `${route.length + counter++}`}
          hint={(
            <div className='tooltip-yn tooltip-edit my-tt' style={{ width: '200px' }}>
              {hint}
            </div>
          )}
          image={marker_img}
          style={{ filter: index !== -1 ? (isBagVisit ? 'hue-rotate(270deg)' : 'none') : 'grayscale(100%)' }}
        />
      );
    }) : [];
    }) : [];
  }

  changeArea = e => {
    const activeArea = e.target.value;
    const geocoder = new this.maps.Geocoder();
    const { emirates, areas } = this.props;
    const address = `${emirates[1]}, ${areas[activeArea]}`;
    geocoder.geocode({ address }, res => {
      if (res && res.length) {
        this.map.setCenter(res[0].geometry.location);
        this.map.fitBounds(res[0].geometry.bounds);
      }
    });
    this.setState({ activeArea });
  };

  getAreasList = (list = []) => {
    const { areas, clientsList,activeDay} = this.props;
    return list.reduce((acc, clientId) => {
     
      if(typeof clientsList[clientId] != undefined){
       const areaData = Object.keys(clientsList[clientId][activeDay]).reduce((acc1,area)=>{
          if (area in areas) {
            return { ...acc, [area]: areas[area] };
          }
        },{});
        return areaData;
      }
    
      return acc;
    }, {});
  };

  changePriority(priority) {
    const { setPriority, activeWindowPartner, activeWindow, activeDay } = this.props;
    this.state.isEdit ? this.setState({ priority }) : setPriority(priority, activeDay, activeWindowPartner, activeWindow);
  }

  render() {
    const { activeWindowPartner, emirates, clientsList, deliveryVisits, getDelivered, bagVisitsInit, activeSlot, activeWindow, used,activeDay } = this.props;
    const { isEdit, route, changePriority, hideDialog, activeArea, priority, notUsed } = this.state;
    const deliveredList = getDelivered([ ...notUsed, ...used]);
    // let mergedClients = activeWindowPartner ? [...route, ...notUsed.filter(el => !route.includes(el))].filter(el => +(clientsList[el] || {}).slot === +activeSlot) : [];
    console.log(activeWindowPartner);
    // console.log(clientsList);
    // let mergedClients = (clientsList && activeWindowPartner != undefined && clientsList[activeWindowPartner]!=undefined) ? clientsList[activeWindowPartner][activeDay][activeSlot] : [];
    let mergedClients = {...((clientsList[activeWindowPartner] || {})[activeDay] || {})[activeSlot] || {} };
    // console.log("route");
    // console.log(route);
    // console.log("notUsed");
    // console.log(notUsed);
    // console.log("clientsList");
    // console.log(clientsList);
    // console.log("mergedClients");
    // console.log(mergedClients);
    // console.log(clientsList[3142][activeDay][activeSlot]);
    
  
    const areas = { 0: 'All areas' };
    
    mergedClients = +activeArea ? mergedClients.filter(el => clientsList[el][activeDay][+activeSlot][+activeArea]!= undefined ) : mergedClients;
    const clientsListForDriver = () => {
      // console.log(activeWindowPartner);
      // console.log(activeWindow);
      if (Object.keys(mergedClients).length && activeWindowPartner && activeWindow) {
        // console.log(clientsList[3142][+activeSlot]);~
        var index = 0;
        return Object.keys(mergedClients).map((clientId, idx) => {
          // console.log(mergedClients[clientId]);
            const { address, firstName, lastName, deliveryInstructions, notes, payed, phone } = mergedClients[clientId];
            const { emirate, area, street, landmark, apartments } = address;
            const delivered = deliveredList.includes(clientId);
            const isBagVisit = bagVisitsInit.includes(+clientId);
            const text = deliveryVisits.includes(+clientId) ? 'Delivery' : (isBagVisit ? 'Bag Visit' : '');
            const visit = <span className={delivered ? 'delivered' : 'not'}>{text || <span style={{ color: 'red' }}>canceled</span>}</span>;
            const deliveringStatus = <p className='text-right delivery'>
              {visit}
              {(route.includes(+clientId) && !isEdit && !changePriority) && <Checkbox
                checked='false'
                checkedIcon={<Check className={'checked-noborder'} />}
                icon={<Check />}
              />}
              {isEdit && <Checkbox
                checked={route.includes(clientId)}
                checkedIcon={<Check className={route.includes(clientId) ? (delivered ? 'checked-checked' : 'checked') : 'unChecked'} />}
                icon={<Check />}
                onClick={() => this.markAsVisit(clientId)}
                disabled={delivered}
              />}
              {(route.includes(+clientId) && changePriority && !delivered) && <div className='order-arrows'>
                <p onClick={() => idx > 0 && this.move(idx, -1)}>➧</p>
                <p onClick={() => idx < route.length - 1 && this.move(idx, 1)}>➧</p>
              </div>}
            </p>;
            const divClassEditable = isEdit ? 'editable' : 'not-editable';
            const divClassPlanned = notUsed.includes(clientId) ? 'not-planned' : 'planned';
            index++;
            return <div className={`visit ${divClassEditable} ${divClassPlanned}`} key={idx}>
              <div className='part'>
                <p className='name'>{firstName} {lastName}
                  {deliveryInstructions && <ToolTip
                    tooltipText={<div className='tooltip-yn my-tt' style={{ width: '300px' }}>
                      <p className='instructions'>
                        <span>Instructions: </span><br />{deliveryInstructions}
                      </p>
                      {Object.values((notes || {})).map((note, idx) => <p key={idx}><span>Note #{idx + 1}.</span>{note}</p>)}
                    </div>}
                    tooltipDirection={'right'}
                    trigger={['click']}
                  >
                    <span className='info-icon'>i</span>
                  </ToolTip>}
                </p>
                <p className='address'><span>{index}.</span>
                  <span> {emirates[emirate]}, </span>
                  <span>{areas[area]}, </span>
                  <span>{street || 'street not specified'}, </span>
                  <span>{landmark || 'landmark not specified'}, </span>
                  <span>apartments: {apartments || 'not specified'} </span>
                </p>
                {(!+payed && !isBagVisit) && <p className='collection'>Collection</p>}
              </div>
              <div className='part'>
                <p className='text-right phone'>{phone}</p>
                {deliveringStatus}
              </div>
            </div>;
         
         
          
        });
      } else {
        return <span className='chhose'>Choose driver's window  </span>;
      }
    };
    return <Fragment>
      <div className='visits-holder rounded-shadowed' ref={el => (this.RouteRef = el)}>
        <div className='head'>
          {!isEdit
            ? <span>Route list:</span>
            : <SelectInput
              items={areas}
              value={activeArea}
              formControlProps={{
                fullWidth: false
              }}
              inputProps={{
                name: 'area',
                onChange: this.changeArea
              }}
            />
          }
          {/* {!!(activeWindowPartner && activeWindow) &&
          <p className='buttons'>
            <Button color={priority ? 'success' : 'white'} onClick={() => this.changePriority(!priority)}>High priority</Button>
            {!(isEdit || changePriority)
              ? <ToolTip
                tooltipText={<div className='tooltip-yn tooltip-edit my-tt' style={{ width: '230px' }}>
                  <Button
                    color={'success'}
                    disabled={!route.length}
                    onClick={() => {
                      this.setState({ hideDialog: true, isEdit: false, changePriority: true });
                      setTimeout(() => this.setState({ hideDialog: false }));
                    }}
                  >
                    Edit visits order
                  </Button>
                  <Button
                    color={'primary'}
                    onClick={() => {
                      this.setState({ hideDialog: true, isEdit: true, changePriority: false, activeArea: '0' });
                      setTimeout(() => this.setState({ hideDialog: false }));
                    }}
                  >
                    Edit the route
                  </Button>
                </div>}
                tooltipDirection={'bottom'}
                trigger={['click']}
                hide={hideDialog}
              >
                <Button color={'gray'}>Edit</Button>
              </ToolTip>
              : <Button color={'success'} onClick={() => { this.save(); }}>Save</Button>
            }
          </p>
          } */}
        </div>
        <div className='body visit-list scrollable-h'>
          {clientsListForDriver()}
        </div>
      </div>
      <div className='map-holder rounded-shadowed'>
        <div className='head'>Clients on the map</div>
        <div style={{ height: '95%' }}>
          <Map
            center={ABU_CENTER}
            onLoad={({ map, maps }) => {
              this.map = map;
              window.map = map;
              this.maps = maps;
              window.maps = maps;
              this.setState({ mapIsLoaded: true });
            }}
          >
            {/* {this.addClientsToMap(mergedClients)} */}
          </Map>
        </div>
      </div>
    </Fragment>;
  }
}

Route.propTypes = {
  activeSlot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeWindow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeWindowPartner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  notUsed: PropTypes.array,
  used: PropTypes.array,
  bagVisitsInit: PropTypes.array,
  deliveryVisits: PropTypes.array,
  activeRoute: PropTypes.array,
  clientsList: PropTypes.object,
  areas: PropTypes.object,
  emirates: PropTypes.object,
  priorities: PropTypes.array,
  setRoute: PropTypes.func,
  setPriority: PropTypes.func,
  getDelivered: PropTypes.func
};

Route.defaultProps = {
  areas: {},
  emirates: {},
  clientsList: {},
  notUsed: []
};

export default Route;
