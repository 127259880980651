import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Text from 'components/Typography/Text';
import { Check, RadioButtonChecked, RadioButtonUnchecked, ErrorOutline } from '@material-ui/icons/index';
import SelectInput from 'components/SelectInput';
import Calendar from 'components/InputCalendar';
import CalendarDate from 'components/Calendar';
import Tooltip from 'components/ToolTip';

class PlanInfo extends Component {
  constructor(props) {
    super(props);
    const {planParams} = this.props;
    const {deliverySlot} = planParams;
    var is_evening = false;
    if (deliverySlot == 4) {
      is_evening = true;
    }
    this.state = { 
      step: 1,
      is_evening: is_evening,
    };
  }

  renderDay = (props, curd, seld, exWeekdays, exDates, minDate) => {
    const {is_evening} = this.state;
    var day = false;
    if (is_evening) {
      day = moment(curd._d).format('dddd');  
    }

    /*if ( (minDate && curd.isBefore(minDate)) || (day == 'Friday' || day == 'Thursday') ) {
      props.className = `${props.className} disabled`;
      delete props.onChange;
      delete props.onClick;
    }*/
    return <td {...props}>{ curd.date() }</td>;
  };

  render() {
    const { dataArray, userData, planParams, changeParams, errors, excludedDates, build, minDate: minRenewDate, bagPrice, changeBag,prevPlanParams } = this.props;
    
    
    const { vouchers } = userData || {};
    const { voucher,price:oldprice } = prevPlanParams;
    const { diet, mealPlan, date, price } = planParams;
    const {  dietList, commonVouchers } = dataArray;
    const now = moment().utc().unix();
    const vouchersList = [...(vouchers || []), ...(commonVouchers || [])].reduce((acc, { id, type, value, code, start_date, end_date, active }) => {
      const format = {
        fixed: (val) => `AED ${val / 100}`,
        percent: val => `${val}%`
      };
      const title = `${format[type](value)} (${code})`;
      if (start_date && end_date) {
        if (now > start_date && now < end_date && active) {
          return { ...acc, [`c_${id}`]: `Common: ${title}` };
        } else {
          return acc;
        }
      } else {
        return { ...acc, [`p_${id}`]: `Personal: ${title}` };
      }
    }, { '': 'None' });
    const selectvoucher='c_'+voucher;
    
    //const minDate = useMinRenewDate ? moment.unix(minRenewDate).utc() : null;
 
    const DateSelectionTooltip = () => {
      return <div className='tooltip-yn my-tt' style={{ width: '100%', textAlign: 'center', fontSize: '16px', marginTop: '5px', fontWeight: 600 }}>
        Select diet and plan first
      </div>;
    };
    const today = moment().utc().startOf('d');
    
    const vouchersAreDisabled = Object.keys(vouchersList).length < 2;
    const editaredisabled=build==='editplan';
    var displayPrice = 'N\A';
    if (typeof(price) != 'undefined'||typeof(oldprice) != 'undefined') {
      displayPrice = 'AED ' + (price?price/100:0);
    }
    return (
      <div>
        <div className='scroll-wrapper' style={{ paddingBottom: '40px' }}>
          <Grid container>
            
            
            <Grid item md={3}>
              <Text bold style={{ fontSize: 'large' }} customColor='#555'>Select diet</Text>
              {'diet' in errors && <ErrorOutline color='error' />}
              {dietList && Object.keys(dietList).map((dietId, idx) => {
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={+diet === +dietId}
                      disabled={editaredisabled}
                      icon={<RadioButtonUnchecked className='unchecked-radio' />}
                      checkedIcon={<RadioButtonChecked className='checked-radio' />}
                      onClick={() => changeParams('diet', +dietId)}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text>{dietList[dietId]}</Text>
                  </Grid>
                );
              })}
            </Grid>
            
            <Grid item md={9}>
              <Grid container>
                <Grid item md={12}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} customColor='#555'>Select start date</Text>
                  {'date' in errors && <ErrorOutline color='error' />}
                  <Tooltip
                    tooltipText={<DateSelectionTooltip />}
                    tooltipDirection={'top'}
                    trigger={['hover']}
                    hide={!!(diet && mealPlan)}
                  >
                    <div>
                      {/* <Calendar
                        controlled
                        value={date || today.unix()}
                        placeholder={'Date'}
                        minDate={planParams.date}
                        maxDate={planParams.end_date}
                        disable={!(diet && mealPlan)|| editaredisabled}
                        locale={'GB'}
                        onChange={date => changeParams('date', date)}
                        onViewModeChange={() => false}
                        renderDay={(a, b, c) => this.renderDay(a, b, c, excluded, excludedDates, minDate)}
                        onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(1, 'year'))}
                      /> */}
                      <CalendarDate
                          date={date || today.unix()}
                          name={'start_date'}
                          title={'Start Date'}
                          minDate={today.unix()}
                          maxDate={prevPlanParams.end_date}
                          onChange={date => changeParams('date', date)}
                          controlled
                          // error={'periodFrom' in errors}
                          formControlProps={{
                            fullWidth: false,
                            style: {
                              margin: '0 10px 0 4px',
                              minWidth: '13%'
                            }
                          }}
                        />
                    </div>
                  </Tooltip>
                </Grid>
                
                {!editaredisabled&&<Grid item md={12}> 
                  <Text bold style={{ fontSize: 'large' }} customColor='#555'>
                    Price: <Text bold style={{ fontSize: 'large' }} color='success'>{displayPrice}</Text> <br/>
                    Bag Price: <Text bold style={{ fontSize: 'large' }} color='success'>AED {bagPrice}</Text> <br/>
                    Total Price: <Text bold style={{ fontSize: 'large' }} color='success'>{ `AED ${(price?price/100:0) + parseFloat(bagPrice)}` }</Text>
                  </Text>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

PlanInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  changeParams: PropTypes.func.isRequired,
  excludedDates: PropTypes.array,
  build: PropTypes.string,
  minDate: PropTypes.number
};

export default PlanInfo;
