import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import SelectInput from 'components/SelectInput';
import Calendar from 'components/Calendar';
import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';
import moment from 'moment/moment';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().clone().add(2, 'd').utc().startOf('day');
    this.defFilters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(7, 'd').unix()
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today,
      offset: props.offset,
      mealtype: 0,
    };
  }

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['ingredientTypes'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  componentWillReceiveProps(next) {
    if (this.FilterRef && next.offset !== this.state.offset) {
      this.setState({ offset: next.offset });
    }
  }

  pdfButton(periodFrom, periodTo,ingrTypes=false) {
    window.open(
      'https://basiligo:basiligo2019!@healthyme.losewight.ae/admin/site/marketlistpdf?date_from='+periodFrom+'&date_to='+periodTo+'&ingrTypes='+ingrTypes,
      '_blank'
    );
  }

  render() {
    const { filters, offset, today } = this.state;
    const { dataArray, errors, applyFilters, setSplitter, splitter } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value }});
    };
    const {  ingredientTypes } = dataArray;
    console.log(ingredientTypes);
    const { periodFrom, periodTo,ingrTypes } = filters;
   
    return (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters, offset);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters, offset);
            }
          }}
        > 
          <Calendar
            date={periodFrom || today}
            name={'periodFrom'}
            title={'From date'}
            minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              onChange(undefined, date.isSameOrAfter(today) ? date.unix() : today.unix(), name);
              if (date.isAfter(moment.unix(periodTo))) {
                setTimeout(() => onChange({}, date.unix(), 'periodTo'), 10);
              }
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '13%'
              }
            }}
          />
          <Calendar
            date={periodTo || today}
            name={'periodTo'}
            title={'To date'}
            minDate={moment.unix(periodFrom || today).utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              const from = filters.periodFrom ? moment.unix(filters.periodFrom) : today;
              onChange(undefined, date.isSameOrAfter(today) && date.isAfter(from) ? date.unix() : today.unix(), name);
            }}
            controlled
            error={'periodTo' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '13%'
              }
            }}
          />
          
          <SelectInput
            labelText='Filter by Ingredient type'
            items={ingredientTypes || {}}
            value={ingrTypes || []}
            multiple
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'ingrTypes',
              onChange
            }}
          />
          
          <div className=''>
            <Button color='danger' size='large' type='submit' onClick={() => {this.pdfButton(periodFrom, periodTo,ingrTypes)}}>Export PDF</Button>
          </div>
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters(this.defFilters);
              this.setState({ filters: this.defFilters });
              setSplitter({ type: 'ingrTypes', values: [] });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  errors: PropTypes.object,
  applyFilters: PropTypes.func.isRequired,
  setSplitter: PropTypes.func.isRequired,
  splitter: PropTypes.object.isRequired,
  ingrTypes: PropTypes.array.isRequired
};

Filters.defaultProps = {
  offset: 0,
  errors: {}
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props, null)(Filters));
