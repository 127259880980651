import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
// import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory, History } from 'history';
import { setNotify, clearNotify } from 'store/notify';
import { Grid } from 'material-ui';
import CustomCard from 'components/Cards/CustomCard';
import Pins from 'components/Pins';
import Filters from './Filters';
import UserTile from './UserTile';
import Form from './Details/Form';
import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';
import { ErrorOutline } from '@material-ui/icons';
import Calendar from 'components/Calendar';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.tabs = {
      'allfull': {
        title: 'All',
        callback: () => this.getUsersList('allfull', (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: true } })
      },
      /*'allorders': {
        title: 'All with Orders',
        callback: () => this.getUsersList(this.types[0], (this.state.page - 1) * this.state.limit, this.state.limit)
      },*/
      'banned': {
        title: 'Banned',
        callback: () => this.getUsersList('banned', (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: true } })
      },
      'hold': {
        title: 'Hold/Paused',
        callback: () => this.getUsersList('hold', (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: true } })
      },
      'noorder': {
        title: 'Has no plan',
        callback: () => this.getUsersList('noorder', (this.state.page - 1) * this.state.limit, this.state.limit)
      },
      'hasActive': {
        title: 'Active Plans',
        callback: () => this.getUsersList(this.types[0], (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: true } })
      },
      'mobileCheckout': {
        title: 'Checkouts',
        callback: () => this.getUsersList('checkouts', (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: false } })
      },
      'noActive': {
        title: 'Has no active plan',
        callback: () => this.getUsersList(this.types[0], (this.state.page - 1) * this.state.limit, this.state.limit, { activePlan: { value: false } })
      },
      'add': {
        title: 'Add new +',
        callback: () => browserHistory.push('/admin/customers/add')
      }
    };
    this.activeTab = 'hasActive';
    this.UserListRefs = {};
    this.types = ['customer', 'admin', 'delivery', 'kitchen', 'logistic'];
    const limit = 10000;
    const date_value = moment().utc().startOf('day').unix();
    this.state = {
      xyz: false,
      users: false,
      limit,
      page: 1,
      formData: false,
      showForm: false,
      adminUsers: false,
      kitchenUsers: false,
      renewList: [],
      errors: {},
      date_value: date_value,
      usertab: 'customer',
    };

    this.getUsersList(this.types[0], 0, limit);
    this.getUsersList('admin', 0, 999);
    this.getUsersList('kitchen', 0, 999);
    this.getRenewList();
  }

  componentWillMount() {
    this.props.socket.on('profile', this.socketProfile);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.socketProfile);
  }

  listener = ({ type, data }) => {
    if (this.CustomersRef) {
      switch (type) {
        case 'getRenewListOk':
          this.setState({
            renewList: data.list, 
          });
        break;
        case 'listOk':
          const {userType} = data;
          switch(userType) {
            case 'admin':
              this.setState({
                adminUsers: data.users
              });
            break;
            
            case 'kitchen':
              this.setState({
                kitchenUsers: data.users
              });
            break;

            default:
              this.setState(data);
            break;
          }
          break;
        case 'setAdminOk':
        case 'editProfDataOk':
        case 'addressUpdateOk':
          const { message, user_id, errors } = data;
          this.getUserData(user_id); 
          if(errors!=undefined && Object.keys(errors).length>0){
            let errormessage='';
            Object.keys(errors).map((key)=>{
                  let m=(errors[key]!=undefined && errors[key].length>0) && errors[key].map((val,indx)=>{
                      errormessage+=(val+'\n');
                  });
                    
            });
            if(errormessage!=''){
              this.showNotification(errormessage,'warning',8000);
            }else{
              this.showNotification(message);
            }
          }else{
            this.showNotification(message);
          }
          browserHistory.push(`/admin/customers/details/${user_id}`);
          setTimeout(() => {
           this.props.socket.emit('profile', {
              type: 'list',
              data: {
                userType: 'customer',
                offset: 0,
                limit: 10000,
                filters: {activePlan: {value: true}}
              }
            });
            this.setState({showForm: false});
          }, 100);
          break;
        case 'listErr':
          this.setState(data);
          break;
        case 'setAdminErr':
          if (typeof(data) != 'undefined') {
            let { errors } = data;
            const addressFields = this.props.dataArray['addressFields'] || {};
            const addresses = ['workAddress', 'homeAddress', 'publicAddress'];
            if (errors) {
              addresses.forEach(address => {
                if (address in errors && errors[address].length) {
                  if (!(errors[address][0] instanceof Object)) {
                    const fieldsErrors = Object.keys(addressFields).reduce((acc, cur) => ({ ...acc, [cur]: 'This field is required' }), {});
                    errors = { ...errors, [address]: fieldsErrors };
                  } else {
                    errors = { ...errors, [address]: errors[address][0] };
                  }
                }
              });
            } else {
              errors = {};
            }
            this.setState({ formData: { ...this.state.formData, errors } });
          }
          break;
      }
    }
  };


  
  showNotification = (message, color = 'success', timeout = 5000) => {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      message,
      color,
      place: 'tc',
      icon: ErrorOutline,
      open: true
    });
    setTimeout(() => clearNotify(), timeout);
  };

  submitForm = (userData, company) => {
    userData['company'] = company;
    this.props.socket.emit('profile', { type: 'editProfData', data: { userData } });
  }

  changeManagers = (nutritionist, manager, user_id, notes) => { 
    this.props.socket.emit('profile', { type: 'setManagers', data: { nutritionist: nutritionist, manager: manager, 'user_id': user_id, 'notes': notes } });
  }
  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  getRenewList() {
    this.props.socket.emit('profile', {
      type: 'getRenewList',
      data: {}
    });
  }
  getUsersList(userType, offset, limit, _filters) {
    console.log('beforesocket: '+userType);
    let filters = { ..._filters };
    if (['hasActive', 'noActive'].includes(this.activeTab)) {
      filters = { ...filters, activePlan: { value: this.activeTab === 'hasActive' || !(this.activeTab === 'noActive') } };
    }

    if (userType == 'admin' || userType == 'kitchen') {
      filters = null;
    }
    this.props.socket.emit('profile', {
      type: 'list',
      data: {
        userType,
        offset,
        limit,
        filters
      }
    });
  }

  clickOnUser = (e, to, id) => {
    e.preventDefault();
    this.getUserData(id);
    if (window.location.pathname === '/admin/customers') {
      browserHistory.push(to);
    } else {
      const history = createBrowserHistory();
      history.push(to);
    }
    this.active = id;
    this.PinsRef.getWrappedInstance().setState({ active: +id});
    const _this = this.UserListRefs[id];
    if (this.UserListRefs[id]) {
      const oldActive = this.ListContainerRef.querySelector('.active-customer-item');
      if (oldActive) {
        oldActive.className = 'regular';
      }
      _this.className = 'active-customer-item';
    }
  };

  allDailyMenu(type) {
      const {date_value} = this.state;
      
      if (type) {
        window.open(
          'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/allmenupdf?date='+date_value,
          '_blank' // <- This is what makes it open in a new window.
        );
      } else {
        window.open(
          'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/allmenu?date='+date_value,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
  }
  allDailyMenuNew(type) {
    const {date_value} = this.state;
    
    if (type) {
      window.open(
        'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/allmenunewpdf?date='+date_value,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      window.open(
        'https://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/allmenunew?date='+date_value,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  closeDetails = (id) => {
    this.active = false;
    this.PinsRef.getWrappedInstance().setState({ active: false });
    const _this = this.UserListRefs[id];
    if (_this) {
      const oldActive = this.ListContainerRef.querySelector('.active-customer-item');
      oldActive && (oldActive.className = 'regular');
      _this.className = 'regular';
    }
    this.active = false;
    this.forceUpdate();
    browserHistory.push('/admin/customers');
  };

  getUserData = (userId) => {
    userId && this.props.socket.emit('profile', {
      type: 'get',
      data: {
        userId
      }
    });
  };

  componentDidMount() {
    this.CustomersRef && setTimeout(() => this.setState({ xyz: true }), 100);
    const { dataArray, socket } = this.props;
    const types = ['ingredientList', 'emirate', 'addressFields', 'dietList', 'paymentMethods',
      'accStatuses', 'areasList', 'areasListFull', 'planList', 'typeList', 'slotsList', 'products', 'companies', 'dishLabels','nutritionistlist','wellnessManagerlist','countryList','dishGroups','sidesTypes','deliveryPartner','deliveryPartnerFull','dietListFull','medicalConditions','nutritionistActivelist','wellnessManagerActivelist'].filter(el => !(el in dataArray));
    getArrays(socket, types);
  }

  getArrays = types => {
    const { socket } = this.props;
    getArrays(socket, types);
  };

  getButtons = () => {
    return Object.keys(this.tabs).map((el, idx) => {
      const { title, callback } = this.tabs[el];
      const onClickHandler = () => {
        this.activeTab = el === 'add' ? this.activeTab : el;
        var settab = el;
        if ( (el == 'noActive') || (el == 'hasActive') ) {
          settab = 'customer';
        }
        this.setState({usertab: settab});
        callback();
      };
      return <Button key={idx} size="medium" color={this.activeTab === el ? 'success' : 'darkBlue'} onClick={onClickHandler}>{title}</Button>;
    });
  };

  render() {
    const { params, dataArray,userType } = this.props; 
    const { users, xyz, page, limit, formData, showForm, notshow, renewList, usertab } = this.state;
    console.log(usertab);
    const { planList } = dataArray;
    const usersList = planList ? (users || []).map(({ id: uid, ...userInfo }) => {
      const props = { active: +this.active === +uid, dataArray, notshow, userInfo, clickOnUser: this.clickOnUser, setRef: el => (this.UserListRefs[uid] = el) };
      return <UserTile renewList={renewList} key={uid} uid={+uid} {...props} />; 
    }) : [<div key={0} className={'customer-item-empty on-list'}>No results</div>];
    const children = this.props.children && Children.map(this.props.children, child => cloneElement(child, {
      id: +params.id, 
      getUserData: this.getUserData,
      showForm: formData => this.setState({ showForm: true, formData }),
      setCurrent: uid => (this.active = uid),
      closeUser: uid => this.closeDetails(uid)
    }));
    return <div ref={el => (this.CustomersRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
      <div className='customer-wrap customers-page'>
        <Grid container>
          <Grid item md={12}>
            <div className='top-position'>
              <div className='tabsCustom'>
                <div className='top-buttons-inner'>
                <div className='top-left'>
                  {this.getButtons()} 
                  </div>
                  <div className='top-right'>
                  {userType!='guest_nutritionist'&&<div id="mealplan-calendar-side">
                    <Calendar
                        date={this.state.date_value}
                        name={'menu-day'}
                        title={'Daily menu of all users'}
                        minDate={moment().utc().startOf('day').unix()}
                        onChange={(value, name) => {
                          const date_value = moment.unix(value).utc().unix();
                          this.setState({
                            date_value: date_value,
                          });
                        }}
                        controlled
                        formControlProps={{
                          fullWidth: false,
                          style: {
                            margin: '0 10px 0 4px',
                            minWidth: '13%'
                          }
                        }}
                      />
                      <Button color='warning' onClick={ () => this.allDailyMenu(false)}>Daily Menu Web</Button>
                      <Button color='danger' onClick={ () => this.allDailyMenu(true)}>Daily Menu PDF</Button>
                      <Button color='warning' onClick={ () => this.allDailyMenuNew(false)}>Daily Menu Web</Button>
                      <Button color='danger' onClick={ () => this.allDailyMenuNew(true)}>Daily Menu PDF</Button>
                  </div>}
                  </div>
                </div>
                <Pins
                  ref={el => (this.PinsRef = el)}
                  active={+this.active || +params.id}
                  group={'customers'}
                  clickPin={(e, id) => this.clickOnUser(e, `/admin/customers/details/${id}`, id)}
                  title='Pinned:'
                />
              </div>
              <CustomCard marginOnePx cardCustomStyle={{ padding: '5px 10px 5px 0', borderRadius: '3px', marginBottom: '5px', background: '#eee' }}>
                <div className='finances'>
                  <Filters
                    applyFilters={filters => this.getUsersList(this.state.usertab, (page - 1) * limit, limit, filters)}
                    errors={{}}
                    getArrays={this.getArrays}
                  />
                </div>
              </CustomCard>
            </div>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={5} sm={12}>
                <div ref={el => (this.ListContainerRef = el)} className='customers-inner' style={{ marginLeft: '-15px', marginRight: '-15px', paddingRight: '5px' }}>
                  <CustomCard noPadding plainCard>
                    <Grid container>
                      {usersList}
                    </Grid>
                  </CustomCard>
                </div>
              </Grid>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {(showForm && formData) && <Form
        close={() => this.setState({ showForm: false, formData: false })}
        submit={this.submitForm}
        changeManagers={this.changeManagers}
        adminUsers={this.state.adminUsers}
        kitchenUsers={this.state.kitchenUsers}
        {...formData}
      />}
    </div>;
  }
}

Customers.propTypes = {
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify())
});

export default socketConnect(connect(props, actions)(Customers));
