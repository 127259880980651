import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import { Check } from '@material-ui/icons';
import { iterator } from 'utils';
import noImage from 'assets/no_img.svg';

class Details extends Component {
  constructor(props) {
    const {notes} = props;
    super(props);
    this.hygiens = {};
    this.cutleries = {}; 
    this.checkboxes = {};
    this.state = {
      ready: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { freeCustomers: nextFreeCustomers, activeUser: nextUser, closeUser } = nextProps;
    const { freeCustomers, activeUser } = this.props;
    if (+activeUser === +nextUser && freeCustomers.includes(+activeUser) && !nextFreeCustomers.includes(+activeUser)) {
      closeUser();
    } else if (+activeUser !== +nextUser) {
      this.uncheck();
      this.setState({ ready: false });
    }
  }

  uncheck = () => {
    for (const boxId in this.checkboxes) {
      this.checkboxes[boxId] = false;
    }
  };

  checkCheckboxes = (id) => {
    const checked = !this.checkboxes[id];
    this.checkboxes[id] = checked;
    const ref = document.getElementById(id);
    if (ref) {
      const classes = ['checked', 'unChecked'];
      const from = classes[+checked];
      const to = classes[+!checked];
      ref.classList.replace(from, to);
    }
    const { ready } = this.state;
    let nextReady = true;
    for (const boxId in this.checkboxes) {
      nextReady = nextReady && this.checkboxes[boxId];
    }
    if ((!ready && nextReady) || (ready && !nextReady)) {
      this.setState({ ready: nextReady });
    }
  };

  Header = ({ driver, username, grab, finish, transportTypes, userType, closeUser }) => {
    const { firstName, lastName, transport_type, transport_id } = driver;
    const driverName = `${firstName || ''} ${lastName || ''}`;
    const vehicle = `${(transportTypes || {})[transport_type] || ''}: #${transport_id || ''}`;
    const flag = firstName && lastName && transport_type && transport_id;
    return <div className='head'>
      <p className='tilte'>
        <span className={'close'} style={{ fontSize: '32px', fontWeight: 'bold', lineHeight: '24px', cursor: 'pointer' }} onClick={closeUser}>×</span>
        {flag && <span className='title'>{vehicle}</span>}
        <span className='delivery-for'><span>Delivery for:</span><span className={'client'}>{username}</span></span>
        {flag && <span className='driver'><span>Driver:</span>{driverName}</span>}
      </p>
      {userType === 'packager' && <p className='button-group'>
        <Button color={'darkBlue'} disabled={grab.disabled} onClick={grab.callback}>{grab.title}</Button>
        <Button color={'success'} disabled={finish.disabled} onClick={finish.callback}>{finish.title}</Button>
      </p>}
    </div>;
  };

  addCnH = (cut, hyg) => {
    hyg && (hyg in this.hygiens ? this.hygiens[hyg]++ : this.hygiens[hyg] = 1);
    cut && (cut in this.cutleries ? this.cutleries[cut]++ : this.cutleries[cut] = 1);
  };

  DishInfo = ({ dishInfo, needsCutlery, dataArray, idx, productsFull }) => {
    const { dishPacks, cutleryList, hygieneList } = dataArray;
    const { id, preview, title, nickname, pack, cutlery, hygiene, components, dishType, customPackage } = dishInfo;
    const {notes, activeUser, product_id, key} = this.props;
    var note = '';
    if (typeof(notes[activeUser]) != 'undefined') {
      if (typeof(notes[activeUser][id]) != 'undefined') {
        note = (<p class="add-note-text">{notes[activeUser][id]}</p>);
      }
    }
    this.addCnH(cutlery, hygiene);
    const leftCounter = iterator();
    const rightCounter = iterator();
    const cbxCounter = iterator();
    const Left = ({ text }) => <p className='item-group'>
      <span className='item-name'>{text}</span>
    </p>;
    const Right = ({ text }) => <p className='item'>
      <span className='item-name'>{text}</span>
      <span className='checkHolder'>{this.getCheckbox(`menu_${idx}_${cbxCounter()}`)}</span>
    </p>;
    const leftRows = [<Left text={`${nickname || title} (${dishType})`} key={`l_${leftCounter()}_${idx}`} />];
    let rightRows = [<Right text={dishPacks[pack]} key={`r_${rightCounter()}_${idx}`} />];
    if (dishType === 'composite') {
      for (const subDish of components) {
        const { title, nickname, pack: defPack, cutlery: defCutlery, hygiene: defHygiene } = productsFull[subDish];
        const { cutlery_id, hygiene_id, pack_id } = customPackage[subDish] || {};
        const cutlery = cutlery_id || defCutlery;
        const hygiene = hygiene_id || defHygiene;
        const pack = pack_id || defPack;
        this.addCnH(cutlery, hygiene);
        leftRows.push(<Left text={nickname || title} key={`l_${leftCounter()}_${idx}`} />);
        rightRows.push(<Right text={dishPacks[pack]} key={`r_${rightCounter()}_${idx}`} />);
        if (needsCutlery && cutlery) {
          leftRows.push(<Left text={''} key={`r_${leftCounter()}_${idx}`} />);
          rightRows.push(<Right text={cutleryList[cutlery]} key={`r_${rightCounter()}_${idx}`} />);
        }
        if (hygiene) {
          leftRows.push(<Left text={''} key={`r_${leftCounter()}_${idx}`} />);
          rightRows.push(<Right text={hygieneList[hygiene]} key={`r_${rightCounter()}_${idx}`} />);
        }
      }
    }
    needsCutlery && cutlery && rightRows.push(<Right text={cutleryList[cutlery]} key={`r_${rightCounter()}_${idx}`} />);
    hygiene && rightRows.push(<Right text={hygieneList[hygiene]} key={`r_${rightCounter()}_${idx}`} />);
    while (leftRows.length < rightRows.length) {
      leftRows.push(<p className='item-group' key={`l_${leftCounter()}_${idx}`} />);
    }

    return <div className='package' key={idx}>
      <div className='topack'>
        <div className='photo' style={{ backgroundImage: `url(${preview || noImage})` }} />
      </div>
      <div className='list'>
        <div className='head'>
          <p>Dish</p>
          <p>Pack list</p>
        </div>
        <div className='body'>
          {rightRows.map((el, idx) => <div className='imems-row' key={idx}>
            {leftRows[idx]}
            {note}
            {el}
          </div>)}
        </div>
      </div>
    </div>;
  };

  TotalInfo = ({ hygiens, cutleries, dataArray }) => {
    const { cutleryList, hygieneList } = dataArray;
    const getInfo = (info, dict) => Object.keys(info).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${dict[cur]}${info[cur] > 1 ? ` x ${info[cur]}` : ''}`, '');
    return <div className='package general'>
      <div className='list'>
        <div className='head'>
          <p>General</p>
          <p>Pack list</p>
        </div>
        <div className='body'>
          <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>General packaging</span>
            </p>
            <p className='item'>
              <span className='item-name'>Bag</span>
              <span className='checkHolder'>{this.getCheckbox('bag_total')}</span>
            </p>
          </div>
          <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>Cutlery</span>
            </p>
            <p className='item'>
              <span className='item-name'>{getInfo(cutleries, cutleryList)}</span>
              <span className='checkHolder'>{this.getCheckbox('cutleries_total')}</span>
            </p>
          </div>
          {!!Object.keys(hygiens || {}).length && <div className='imems-row'>
            <p className='item-group'>
              <span className='item-name'>Hygiene products</span>
            </p>
            <p className='item'>
              <span className='item-name'>{getInfo(hygiens, hygieneList)}</span>
              <span className='checkHolder'>{this.getCheckbox('hygiens_total')}</span>
            </p>
          </div>}
        </div>
      </div>
    </div>;
  };

  getCheckbox = (id) => {
    return <span ref={() => {
      if (!(id in this.checkboxes)) {
        this.checkboxes[id] = false;
      }
    }}>
      <Check
        style={{ cursor: 'pointer' }}
        className={this.checkboxes[id] ? 'checked' : 'unChecked'}
        onClick={() => this.checkCheckboxes(id)}
        id={id}
      />
    </span>;
  };

  Menu = ({ menu, needsCutlery, dataArray }) => {
    const { productsFull } = dataArray;
    return Object.values(menu).map((typeInfo, idx) => {
      const { dish } = typeInfo;
      return <this.DishInfo {...{ id: dish, dishInfo: productsFull[dish], needsCutlery, dataArray, idx, productsFull }} key={idx} />;
    });
  };

  render() {
    this.cutleries = {};
    this.hygiens = {};
    const { activeUser, customers, userType, dataArray, drivers, routes, today, activeWindow, usersPackaging, binded, userId, grabPackage, markPacked, closeUser } = this.props;
    const { transportTypes, cutleryList } = dataArray;
    const { ready } = this.state;
    const todayRoutes = (routes || {})[today] || {};
    const driverId = +Object.keys(todayRoutes).find(driverId => (todayRoutes[driverId][activeWindow] || []).includes(activeUser));
    const driver = (drivers || {})[driverId] || {};
    const { username, menu, needsCutlery, window, type } = customers[activeUser] || {};
    needsCutlery && this.addCnH(Math.min(Object.keys(cutleryList || {})));
    const owner = !!usersPackaging.find(({ customer_id, date, deliveryTime_id }) => +date === today && +deliveryTime_id === +window && +customer_id === activeUser);
    const notUsed = !(binded || []).find(({ packager_id, customer_id, deliveryTime_id, driver_id }) => {
      return +packager_id !== userId && +customer_id === activeUser && +deliveryTime_id === activeWindow && +driver_id === driverId;
    });
    const commonData = {
      packager_id: userId,
      deliveryTime_id: activeWindow,
      customer_id: activeUser,
      driver_id: driverId
    };
    const details = Object.values(menu || {}).map(el => el['detailsId']);
    const grab = {
      title: owner ? 'Not my' : 'My package',
      disabled: !(owner || userType === 'packager' || notUsed),
      callback: () => grabPackage(owner, commonData, details, type)
    };
    const finish = {
      title: 'Mark as Packed',
      disabled: !(ready && userType === 'packager' && owner),
      callback: () => markPacked(userId, details, type, +activeUser)
    };
    return <Fragment>
      {activeUser && <div className='statistics'>
        <this.Header {...{ driver, username, grab, finish, transportTypes, userType, closeUser }} />
        <div className='packList scrollable-h'>
          <this.Menu {...{ menu, needsCutlery, dataArray }} />
          <this.TotalInfo {...{ hygiens: this.hygiens, cutleries: this.cutleries, needsCutlery, dataArray }} />
        </div>
      </div>}
    </Fragment>;
  }
}

Details.propTypes = {
  activeUser: PropTypes.number,
  userType: PropTypes.string,
  userId: PropTypes.number,
  customers: PropTypes.object,
  today: PropTypes.number,
  activeWindow: PropTypes.number,
  routes: PropTypes.object,
  drivers: PropTypes.object,
  usersPackaging: PropTypes.array,
  dataArray: PropTypes.object,
  binded: PropTypes.array,
  grabPackage: PropTypes.func,
  markPacked: PropTypes.func,
  freeCustomers: PropTypes.array,
  closeUser: PropTypes.func
};

export default Details;
