import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Filters from './filters';
import Button from 'components/CustomButtons/Button';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import moment from 'moment/moment';
import CustomInput from 'components/CustomInput/CustomInput';
class BagCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activedatas: [],
      users: [],
      thetype:''
    };
    this.getBagCollection('');
  }

  orderListener = ({ type, data }) => {
      switch (type) {
        case 'getBagCollectionOk':
          this.setState({
            activedatas : data.datas,
            users : data.users,
          });
        break;
        case 'updateBagCollectionOk':
        var {activedatas}=this.state;
        activedatas[data.userId][data.date]['received']=data.received;
        // console.log(activedatas[data.userId]);
        this.setState({
          activedatas : activedatas,
        });
      break;
      case 'updateBagStatusOk':
        this.getBagCollection(this.state.thetype);
      break;
        
      }
  };

  componentWillMount() {
    this.props.socket.on('order', this.orderListener);
    this.props.socket.on('profile', this.orderListener);

    const { dataArray, socket } = this.props;
    // const types = ['planList', 'typeList', 'dietList', 'slotsList', 'productsFull', 'products', 'priceList', 'commonVouchers', 'couponTypes', 'paymentMethods'].filter(el => !(el in dataArray));
    // getArrays(socket, types);
    //clearDefaultDishes();
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.orderListener);
    this.props.socket.removeListener('profile', this.orderListener);
  }

  

  getBagCollection = (type) => {
    this.props.socket.emit('order', { type: 'getBagCollection', data: { activetype:type , dateFrom: null, dateTo: null } });
  };

  
  getUserData = (userId) => {
    // console.log('get user query');
    userId && this.props.socket.emit('profile', {
      type: 'get',
      data: {
        userId
      }
    });
  };

  updateBagstatus = (el,status) => {
    const {activedatas,thetype} = this.state;    
    this.props.socket.emit('order', { type: 'updateBagStatus', data: {userId:el, orderId:activedatas[el].order_id ,status:status,thetype:thetype } });
  }
  
  qtyChange = (e,el,date)=> {
    const {activedatas,thetype} = this.state;
    console.log(thetype);
    console.log(date);
    this.props.socket.emit('order', { type: 'updateBagCollection', data: {userId:el, orderId:activedatas[el][date].order_id , date: date, delivered: activedatas[el][date].delivered, received: e.target.value,thetype:thetype } });
  };

  render() {    
    const {activedatas,users,thetype} = this.state;
    
    var rows = [];
    var rowshead = [];
    var rowbody = [];
    var startdate=moment().startOf('month');
  
    const RenderRowData = (rowdata,el,cdate1) => {
      rowdata.push(<React.Fragment><td scope="col">{activedatas[el][cdate1]['delivered']}</td><td scope="col">{<CustomInput
        labelText={'Received'}
        formControlProps={{
          fullWidth: true
        }}
        error={false}
        inputProps={{
          value: activedatas[el][cdate1]['received'],
          type: 'number',
          name: 'received',
          min: 0, 
          max: activedatas[el][cdate1]['delivered'],
          onChange: e => this.qtyChange(e,el,cdate1)
        }}
  />}</td></React.Fragment>);
        return rowdata;
    }
    switch (thetype) {
      case 'refund':
      case 'paused':
      case 'noresponse':
      case 'finishorcancelled':
      default:
        rowshead.push(<td></td>);
        rowshead.push(<React.Fragment><td>Delivered</td><td>Received</td></React.Fragment>);
          var user_id=0;
          Object.keys(activedatas).map((el, idx) => {
            var start=moment.unix(activedatas[el]['start_date']);
             startdate=moment(startdate.format('YYY-MM-DD')+' '+start.format('HH:mm'),'YYY-MM-DD HH:mm:ss');
            // console.log(activedatas[el]);
             var rowdata=[];
             var totalreceived=0;
             var totaldelivered=0;
             var is_pasue=false;
             var { order_id,bag_collected_status }=activedatas[el];
             if(activedatas[el][order_id]!=undefined&&activedatas[el][order_id].pasue_start_date!=''){
                is_pasue=true;
             }
             for(var i=0;i<moment().format('D');i++){
   
               var cdate=startdate.clone().add(i, 'day');
               
               if(user_id==0){
                rows.push(
                  <th scope="col" colSpan="2">{cdate.format('Do MMM')} </th>
                );
               
                rowshead.push(<React.Fragment><td>Delivered</td><td>Received</td></React.Fragment>);
               }
             
               var cdate1=cdate.unix();
               if(typeof(activedatas[el][cdate1])!='undefined'){
                totalreceived=totalreceived+parseInt(activedatas[el][cdate1]['received']);
                totaldelivered=totaldelivered+parseInt(activedatas[el][cdate1]['delivered']);
                rowdata=RenderRowData(rowdata,el,cdate1);
               }else{
                rowdata.push(<React.Fragment><td scope="col"></td><td scope="col"></td></React.Fragment>)
               }
            }
            var predelivered=typeof(activedatas[el]['prev'])!='undefined'?activedatas[el]['prev']['delivered']:0;
            var prereceived=typeof(activedatas[el]['prev'])!='undefined'?activedatas[el]['prev']['received']:0;
            totaldelivered=totaldelivered+predelivered;
            totalreceived=totalreceived+parseInt(prereceived);
            var bagdiff=totaldelivered-totalreceived;
            var ccolor='';
            if(bagdiff>=3&&bagdiff<5){
              ccolor='prof-yellow';
            }else if(bagdiff>=5){
              ccolor='prof-red';
            }
            if(thetype=='refund'||thetype=='finishorcancelled'){
              rowbody.push(<tr>
                <td scope="col" className={ccolor}>{users[el]}</td>
                <td scope="col">{totaldelivered}</td>
                <td scope="col">{totalreceived}</td>
                <td scope="col">{bagdiff}</td> 
                <td scope="col">{thetype=='refund'&&bag_collected_status!='finished'&&<Button color='success' size='small' onClick={() => {
                         
                          this.updateBagstatus(el, 'finished');
                        }}>Refund done</Button>}
                         {thetype=='finishorcancelled'&&<Button color='warning' size='small' onClick={() => {
                         
                          this.updateBagstatus(el, 'paused');
                        }}>Moved to Paused, not Collected</Button>}
                        {thetype=='finishorcancelled'&&<Button color='error' size='small' onClick={() => {
                          
                          this.updateBagstatus(el, 'noresponse');
                        }}>Moved to No-response</Button>}
                        {thetype=='finishorcancelled'&&<Button color='success' size='small' onClick={() => {
                          this.updateBagstatus(el, 'refund');
                        }}>Move to Refund</Button>}
                        
                        </td> 
              </tr>);
            }else{
              rowbody.push(<tr>
                <td scope="col" className={ccolor}>{users[el]}</td>
                <td scope="col">{predelivered}</td>
                <td scope="col">{<CustomInput 
                                labelText={'Prev Received'}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={false}
                                inputProps={{
                                  value: prereceived,
                                  type: 'number',
                                  name: 'received',
                                  min: 0, 
                                  max: predelivered,
                                  onChange: e => this.qtyChange(e,el,'prev')
                                }}
                          />}</td> 
                {rowdata}
                <td scope="col">{totaldelivered}</td>
                <td scope="col">{totalreceived}</td> 
                <td scope="col">{is_pasue&&'Paused'}</td> 
                <td scope="col">{bagdiff}</td> 
                <td scope="col"><div> 
                        {thetype!='paused'&&<Button color='warning' size='small' onClick={() => {
                         
                          this.updateBagstatus(el, 'paused');
                        }}>Moved to Paused, not Collected</Button>}
                        {thetype!='noresponse'&&<Button color='error' size='small' onClick={() => {
                         
                          this.updateBagstatus(el, 'noresponse');
                        }}>Moved to No-response</Button>}
                        <Button color='success' size='small' onClick={() => {
                          this.updateBagstatus(el, 'refund');
                        }}>Move to Refund</Button>
                      </div></td> 
                
            </tr>);
            }
           
            user_id=el;
          });
          
        break;
    }
    // console.log(rowbody);
    return (
      <Grid container>
        <Grid item md={12} className="whiteArea">
            <h1>Bag Collection</h1>
            
            <Button color={!thetype ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: false,    
              });
              this.getBagCollection('');
            }}>ACTIVE CUSTOMERS</Button>

            <Button color={thetype == 'finishorcancelled' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype:'finishorcancelled',    
              });
              this.getBagCollection('finishorcancelled');
            }}>FINISHED / CANCELLED CUSTOMERS</Button>

            <Button color={thetype == 'paused' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype:'paused',    
              });
              this.getBagCollection('paused');
            }}>PAUSED, NOT COLLECTED</Button>

            <Button color={thetype == 'noresponse' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'noresponse', 
              });
              this.getBagCollection('noresponse');
            }}>NO RESPONSE</Button>

            <Button color={thetype == 'refund' ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'refund',    
              });
              this.getBagCollection('refund');
            }}>Refund</Button>

           
            <div className="bagcollect-inner-holder">
             <table className="Tabled">
              <thead className="roundedHeader">
                {thetype!='refund'&&thetype!='finishorcancelled'&&<tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col" colSpan="2">Carry forward from Prev Month</th>
                  {rows}
                  <th scope="col" colSpan="2">TOTAL</th>
                  <th scope="col">Other Actions</th> 
                  <th scope="col">Bag Difference </th> 
                  <th scope="col">Action</th> 
                </tr>}
                {(thetype=='refund'||thetype=='finishorcancelled')&&<tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Total delivered</th>
                  <th scope="col">Total received</th>
                  <th scope="col">Bag Difference </th> 
                  <th scope="col">Action</th> 
                </tr>}
                {thetype!='refund'&&thetype!='finishorcancelled'&&rowshead}
                
              </thead>
              <tbody>
               {rowbody}
              </tbody>
            </table>
            </div>
        </Grid>
      </Grid>
    );
  }
}

BagCollection.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
}; 

const props = state => ({
  dataArray: state.dataArray,
  pinsList: Object.keys(state.pins.customers),
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions, null, { withRefs: true })(BagCollection));