import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Grid } from 'material-ui';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import { Add, ErrorOutline, CheckCircle } from '@material-ui/icons/index';
import { clearNotify, setNotify } from 'store/notify';
import { pushModal, clearModalStack } from 'store/modalStack';
import YesNo from 'components/Dialog/YesNo';

import './style.css';

class StaticPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      actions: {
        update: {
          tooltip: 'Edit',
          button: 'Edit',
          callback: id => browserHistory.push(`/admin/blog/edit/${id}`)
        }
      }
    };
  }

  listener = ({ type, status, data }) => {
    if (this.ThisRef) {
      if (status === 'ok') {
        if (type === 'list') {
          this.setState(data);
        } else if (type === 'del') {
          this.notify(data.message);
        }
      } else {
        const { message, ...rest } = data;
        this.notify(message, status);
        console.error(message, rest);
      }
      this.props.clearModalStack();
    }
  };

  notify = (message, type = 'ok', time = 5000) => {
    const { setNotify, clearNotify } = this.props;
    const types = {
      ok: {
        color: 'success',
        icon: CheckCircle
      },
      err: {
        color: 'warning',
        icon: ErrorOutline
      }
    };
    setNotify({
      open: true,
      place: 'tc',
      message,
      ...types[type]
    });
    setTimeout(clearNotify, time);
  };

  getPages = () => this.props.socket.emit('static_pages', { type: 'list', data: {} });

  componentWillMount() {
    this.getPages();
    this.props.socket.on('static_pages', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('static_pages', this.listener);
  }

  showDeleteModal = id => {
    const { pushModal, clearModalStack } = this.props;
    const deletePage = () => this.props.socket.emit('static_pages', { type: 'del', data: { id } });
    pushModal({
      content: <YesNo actionYes={deletePage} actionNo={clearModalStack} message='Are you sure?' />
    });
  };

  List = ({ list }) => {
    const edit = id => browserHistory.push(`/admin/static-pages/edit/${id}`);
    const Item = ({ id, name, description, alias, url, published }) => <Grid item sm={12} md={6} lg={4} style={{ padding: '5px' }}>
      <CustomCard noPadding marginOnePx>
        <div className={'card-wrapper'}>
          <div className='fields-part'>
            <div className='page-name'><span>Name:</span> {name}</div>
            <div className='page-alias'><span>Alias:</span> {alias}</div>
            <div className='page-status'><span>Published:</span> {['No', 'Yes'][published]}</div>
            <div className='page-description'><span>Description:</span> {(d => d.length > 30 ? d.substr(0, 30) + '...' : d)(description || '')}</div>
            <div className='page-url'><span>Alternative URL:</span> {url || 'not set'}</div>
          </div>
          <div className='actions-part'>
            <div className='button'>
              <Button size={'medium'} color={'success'} fullWidth onClick={() => edit(id)}>Edit</Button>
            </div>
            <div className='button'>
              <Button size={'medium'} color={'danger'} fullWidth onClick={() => this.showDeleteModal(id)}>Delete</Button>
            </div>
          </div>
        </div>
      </CustomCard>
    </Grid>;
    return <Grid container>
      {list.map((el, idx) => <Item key={idx} {...el} />)}
    </Grid>;
  };

  render() {
    const { list } = this.state;
    const { List } = this;
    return (<div ref={el => (this.ThisRef = el)}>
      <Button fullWidth={false} color='success' size='medium' onClick={() => browserHistory.push('/admin/static-pages/add')}><Add /></Button>
      <List {...{ list }} />
    </div>);
  }
}

StaticPages.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired,
  pushModal: PropTypes.func.isRequired,
  clearModalStack: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props)),
  pushModal: props => dispatch(pushModal(props)),
  clearModalStack: () => dispatch(clearModalStack())
});

export default socketConnect(connect(props, actions)(StaticPages));
