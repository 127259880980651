import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class EditAdditional extends Component {
  constructor(props) {
    super(props);
    const {dish_id, data} = props;
    const {stamp, orderId, type} = data;
    this.state = {
      id: dish_id,
      real_id: null,
      alternative: null,
    };

    this.getAlternativeMeals(stamp, orderId, type);
  }

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  listener = ({ type, data }) => {
    const {res} = data;
    switch (type) {
      case 'getAdditionalAlternativeMealsOk':
        console.log('here');
        console.log(data);
        this.setState({
          alternative: res
        });
      break;
    }
  };

  getAlternativeMeals = (stamp, orderId, type) => {
    this.props.socket.emit('order', { type: 'getAdditionalAlternativeMeals', data: {stamp: stamp, orderId: orderId, dishType: type} });
  }

  render() {
    const {data, save} = this.props;
    const {alternative} = this.state;
    const {stamp, name, dishName, type, dish_id, products, orderId} = data;
    console.log(alternative);
    //console.log(products);
    const changeParams = (name, value, sorted, productsList) => {
        var obj = {}; 
        obj[name] = value;
        this.setState(obj); 
        var name = sorted[value];
        Object.keys(productsList).map( (idxel, el) => {
          if (productsList[idxel] == name) {
            this.setState({real_id: idxel})
          }
        });
    };

    var productsList = {};
    /*
    All Meals By Selected type:
    Object.keys(products).map( (idx, el) => {
      Object.keys(products[idx].type).map( (type_id, el) => {
        if (products[idx].type[type_id] == type) {
          productsList[products[idx].id] = products[idx].title;
        }
      }); 
    });*/

    // Alternative meals only:
   
    if (alternative) {
      Object.keys(alternative).map( (idx, el) => {
        if (typeof (alternative[idx]) != 'undefined') {
          var theid = alternative[idx];
          if (typeof(products[theid]) != 'undefined') {
            productsList[products[theid].id] = products[theid].title; 
          }
        } 
      });
    }


    var sorted = [];
    for (var i in productsList) {
      sorted.push(productsList[i]);
    }
    sorted = sorted.sort();
    
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
              <div className='day-editing t2'>Edit additional Meals for {name}</div>
            </Grid>
            <p>Select a meal from the list to set it instead of <br/> <b>{dishName}</b>:</p>
            <SelectInput 
                labelText='Select Meal'
                items={sorted || {}}
                value={'' + this.state.id}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => changeParams('id', e.target.value, sorted, productsList)
                }} 
              />
          </Grid>
          <div className='modal-button-handler right'>
            <Button color='darkBlue' onClick={() => save(stamp, name, type, dish_id, this.state.real_id, orderId)}>
              Save
            </Button>
          </div>
        </div>
       
      </div>
    );
  }
}

EditAdditional.propTypes = {
  //date: PropTypes.number.isRequired,
  //save: PropTypes.func.isRequired
};

export default EditAdditional;
