import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Check } from '@material-ui/icons';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';

class SpecialList extends Component {
  getRows(date) {
    const { list, dictionaries, users } = this.props;
    const { productsFull, ingredients, dietList, slotsList, typeList } = dictionaries;
    const Info = ({ userData }) => {
      const { username, diet, slot, preferences, dislikes, allergies } = userData;
      const InfoList = ({ color, list, title, ...rest }) => <Grid item md={12} className='component-title' {...rest}>
        <Text color={'muted'} inline bold>{title}: </Text>
        {(list || []).length ? list.map((el, idx) => (<Text key={idx} color={color} style={{ paddingLeft: '3px' }} inline>{ingredients[el]}</Text>)) : 'none'}
      </Grid>;
      return <Grid className='component-row' container>
        <Grid item md={12} className='component-title'>
          <Text color='darkBlue' inline style={{ fontSize: 'larger' }} bold>{username}, </Text>
          <Text color='primary' inline>{dietList[diet]}, {slotsList.find(({ id }) => +id === +slot).name}</Text>
        </Grid>
        <InfoList list={allergies} color={'danger'} title={'Allergies'} />
        <InfoList list={preferences} color={'info'} title={'Preferences'} />
        <InfoList list={dislikes} color={'warning'} title={'Dislikes'} />
      </Grid>;
    };
    const Notes = ({ notes }) => <Grid className='component-row' container>
      {Object.values(notes).map((note, idx) => <Grid key={idx} item md={12} className='component-title'>
        <Text color='success' inline>{note.text}</Text>
      </Grid>)}
    </Grid>;
    const Dish = ({ id, mealType, cooked }) => {
      const { title, nickname } = productsFull[id];
      return <Grid key={id} className='component-row dish-info' container>
        <Grid item md={4} className='component-title'><Text color='info' inline>{typeList[mealType]['title']}</Text></Grid>
        <Grid item md={7} className={'component-amount dish-name'}>
          <Text color='success' inline bold>{nickname || title}</Text>
        </Grid>
        <Grid item md={1} title={cooked ? 'Dish is cooked' : ''}>{cooked && <Check color={'primary'} className={'cooked'} />}</Grid>
      </Grid>;
    };
    const rows = list.reduce((acc, { id, user_id, mealType, cooked, packed }) => {
      const { notes, ...userData } = users[user_id];
      const dish = <Dish key={id} {...{ id, mealType, cooked }} />;
      if (user_id in acc) {
        const dishes = [...(acc[user_id] || {})['dishes'] || [], dish];
        return {
          ...acc,
          [user_id]: {
            ...acc[user_id],
            dishes,
            cookedAmount: acc[user_id]['cookedAmount'] + cooked,
            packedAmount: acc[user_id]['packedAmount'] + packed,
            totalAmount: acc[user_id]['totalAmount'] + 1
          }
        };
      } else {
        return {
          ...acc,
          [user_id]: {
            info: <Info {...{ userData }} />,
            notes: <Notes {...{ notes }} />,
            dishes: [dish],
            actionsData: { date, user_id },
            cookedAmount: +cooked,
            packedAmount: +packed,
            totalAmount: 1
          }
        };
      }
    }, {});
    for (const row in rows) {
      rows[row].dishes = <div className={'dishes-list'}>{rows[row].dishes}</div>;
    }
    return rows;
  }

  renderContent(date) {
    const { actions } = this.props;
    const props = {
      cols: {
        info: 'User Info',
        dishes: 'Dishes',
        notes: 'Notes',
        actions: 'Actions'
      },
      rows: this.getRows(date),
      actions
    };
    return <Table {...props} />;
  }

  render() {
    const { date } = this.props;
    return (
      <div>
        <div className='date-date'>{moment.unix(date).format('DD MMM')}</div>
        {this.renderContent(date)}
      </div>
    );
  }
}

SpecialList.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  users: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.number
};

export default SpecialList;
