import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import Snackbar from '../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons/index';
import CustomTabs from 'components/CustomTabs';
import NumberInput from '../../components/NumberInput';
import Button from '../../components/CustomButtons/Button';

import { getArrays } from 'utils';

class Prices extends Component {
  constructor(props) {
    super(props);
    this.inputs = {};
    this.active = 0;
    this.pricesForSave = {};
    this.state = {
      message: '',
      prices: {},
      note: false,
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getPrices();
  }

  componentWillMount() {
    this.props.socket.on('prices', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('prices', this.listener);
  }

  listener = action => {
    if (this.PricesRef) {
      switch (action.type) {
        case 'getOk':
        console.log(action);
          this.setState({ ...action.data });
          break;
        case 'setOk':
          const prices = this.mergePrices();
          this.setState({ note : true, message: action.data.message, prices });
          setTimeout(() => this.setState({ note : false, message: '' }), 5000);
          break;
        default:
          if (['getErr', 'setErr'].includes(action.type)) {
            this.showNotification(action.data.message);
          }
          break;
      }
    }
  };

  mergePrices = () => {
    let prices = { ...this.state.prices };
    Object.keys(this.pricesForSave).forEach(typeId => {
      const typePrices = this.pricesForSave[typeId];
      Object.keys(typePrices).forEach(planId => {
        const planPrices = typePrices[planId];
        Object.keys(planPrices).forEach(dietId => {
          (prices[typeId] || { [planId]: {} })[planId][dietId] = planPrices[dietId];
        });
      });
    });
    return prices;
  };

  showNotification(message) {
    if (this.PricesRef) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  getPrices() {
    this.props.socket.emit('prices', {
      type: 'get',
      data: {}
    });
  }

  collectPrices = active => {
    const { prices } = this.state;
    const oldPrices = { ...prices[active] };
    let pricesForSave = { ...this.pricesForSave };
    if (!pricesForSave[active]) {
      pricesForSave = { ...pricesForSave, [active]: {} };
    }
    Object.keys(this.inputs).forEach(key => {
      const keys = key.split('_');
      const newPrice = this.inputs[key].state.value * 100;
      if ((oldPrices[keys[0]] || {})[keys[1]] !== newPrice) {
        pricesForSave[active] = { ...pricesForSave[active], [keys[0]]: { ...pricesForSave[active][keys[0]], [keys[1]]: newPrice } };
      }
    });
    this.pricesForSave = pricesForSave;
  };

  submit = () => {
    this.collectPrices(this.active);
    const prices = this.pricesForSave;
    if (Object.keys(prices).length) {
      this.props.socket.emit('prices', {
        type: 'set',
        data: {
          typeId: this.active,
          prices
        }
      });
    }
  };

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['typeList', 'planList', 'dietList'].filter(el => !(el in dataArray));
    getArrays(socket, types);
  }

  render() {
    const { message, note, closeNote, prices } = this.state;
    const { typeList } = this.props.dataArray;
    const tabsData = prices && typeList ? Object.keys(typeList).sort((a, b) => typeList[a].order - typeList[b].order).reduce((acc, cur) => {
      console.log(cur);
      const mealTypePrices = prices[cur] || {};//prices[cur] || {};

      const tabData = {
        icon: '',
        label: typeList ? typeList[cur].title : '',
        renderTabContent: () => {
          const { planList, dietList } = this.props.dataArray;
          const thead = dietList ? [<td key={0} />, ...Object.keys(dietList).map(dietId => <td key={dietId}>{dietList[dietId]}</td>)] : [];
          const table = planList && dietList && Object.keys(planList).map(planId => {
            const planPrices = mealTypePrices[planId];
            if(planList[planId].active==1){
            return (
              <tr key={planId}>
                <td className='first-col'>{planList[planId].title}</td>
                {Object.keys(dietList).map(dietId => (

                  <td key={dietId}>
                    {<NumberInput
                      value={planPrices && planPrices[dietId] ? planPrices[dietId] / 100 : 0}
                      inputProps={{ name: `${cur}_${planId}_${dietId}`, min: 0, step: '1' }}
                      ref={el => {
                        this.inputs[`${planId}_${dietId}`] = el;
                        this.active = +cur;
                      }}
                    />}
                    {false && <input type='number' name={`${cur}_${planId}_${dietId}`} min={0} value={mealTypePrices[planId][dietId] / 100} />}
                  </td>
                ))}
              </tr>
            );
           }
          });
          return (
            <Fragment>
              <table className='prices-table'>
                <thead>
                  <tr>
                    {thead}
                  </tr>
                </thead>
                <tbody>
                  {table}
                </tbody>
              </table>
              <Button style={{ float: 'right' }} color='success' onClick={this.submit}>Save</Button>
            </Fragment>
          );
        }
      };
      return { ...acc, [cur]: tabData };
    }, {}) : {};
    return (
      <div ref={el => (this.PricesRef = el)}>
        {!!(tabsData && Object.keys(tabsData).length) && (
          <CustomTabs
            data={tabsData}
            customClasses={'tabs-outher prices-tabs'}
            headerTitle={'Meal Types:'}
            headerColor='darkBlue'
            onChangeTab={() => this.collectPrices(this.active)}
            defaultTab={Object.keys(typeList || {})[0] || 0}
          />
        )}
        <Snackbar
          place='tc'
          color='info'
          icon={AddAlert}
          message={message}
          open={note}
          closeNotification={closeNote}
          close
        />
      </div>);
  }
}

Prices.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(Prices));
