import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Edit } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import moment from 'moment';
import { Text } from 'components/Typography';
import Button from 'components/CustomButtons/Button';
import NoAvatar from 'assets/img/noimg.svg';

const buttonEditStyle = {
  padding: '0',
  height: '30px',
  minWidth: '30px',
  backgroundColor: '#e2e2e2',
  color: '#4a4a4a',
  right: '20px',
  position: 'absolute',
  margin: '0'
};


class TabInfo extends Component {

  render() {
    const { dataArray, userData, errors, showForm, adminUsers, kitchenUsers, sendMailReset } = this.props;
    var {avatar} = this.props;
    if (!avatar) {
      avatar = NoAvatar;
    }

    /*const nutritionistList = {
      76: 'Rawda',
      129: 'Rayana',
      85: 'Justine',
      34: 'Sarath',
      2260: 'Lany',
      2240: 'Bayan',
    }

    const managerList = {
      145: 'Edward',
      146: 'John',
      34: 'Sarath',
      458: 'Paolo',
    }
    console.log(dataArray);*/
    const { ingredientList, products, emirate, addressFields, accStatuses, dietList, areasList, companies,nutritionistlist,wellnessManagerlist,countryList,dishGroups,deliveryPartnerFull,medicalConditions  } = dataArray;
    const { birthday, language, email, phone, weight, height, homeAddress, workAddress,
      publicAddress, deliveryInstructions, medicalCondition, allergies, dislikes, dishlikes, dishdislikes, preferences, plan, notes, manager, nutritionist, company_id,customer_type,nationality,eliminate_groups, delivery_partner_id,cutlery, whatsUpnumber, PC_CODE, WPC_CODE } = userData;
      const birth = moment.unix(birthday);
      var today = Date.now()/1000;
        var ageval = null;
        if (birthday) {
          var birthYear = birth.format('YYYY');
          var todayYear = moment.unix(today).format('YYYY');
          var birthMonth = birth.format('MM');
          var todayMonth = moment.unix(today).format('MM');
  
          var ageval = todayYear - birthYear;
          if (birthMonth > todayMonth) {
            ageval = ageval - 1;
          }
        }
      const bmih=(height/100);
      const bmi=(weight!=''&&height!='')?((weight/1000)/(bmih*bmih)):'No data';  
      var bmi_color='warning';
      if(bmi>=18.5&&bmi<=24.9){
        bmi_color='success';
      }  
    // console.log(products);
    const customer_types = {'normal':'Normal','vvip':'VVIP','care':'Extra Careful'};
    const sefProps = arr => ingredientList && (Array.isArray(arr) ? arr : JSON.parse(arr) || []).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${ingredientList[+cur]}`, '');
    const sefDishgroupProps = arr => dishGroups && (Array.isArray(arr) ? arr : JSON.parse(arr) || []).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${dishGroups[+cur]}`, '');
    const sefProdProps = arr => products && (Array.isArray(arr) ? arr : JSON.parse(arr) || []).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${(typeof(products[+cur])!='undefined')?products[+cur].title:''}`, '');
    const sefMedProps = arr => medicalConditions!=undefined && medicalConditions && (Array.isArray(arr) ? arr : JSON.parse(arr) || []).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${(typeof(medicalConditions[+cur])!='undefined')?medicalConditions[+cur]:''}`, '');
    const makeAddress = address => {
      return Object.keys(addressFields).reduce((acc, cur, idx) => {
        if (address[cur] && cur !== 'default') {
          const curVal = address[cur];
          const value = cur === 'emirate' ? emirate[curVal] : (cur === 'area' ? areasList[curVal] : curVal);
          return `${acc}${idx ? ', ' : ''}${value || ''}`;
        } else {
          return acc;
        }
      }, '') || 'No data';
    };
    const margin = (val, dir = null, units = 'px') => ({ [`margin${dir ? dir[0].toUpperCase() : ''}${dir.substr(1)}`]: dir ? `${val}${units}` : val });
    const sef = {
      allergies: sefProps(allergies),
      dislikes: sefProps(dislikes),
      dishlikes: sefProdProps(dishlikes),
      dishdislikes: sefProdProps(dishdislikes),
      eliminate_groups: sefDishgroupProps(eliminate_groups),
      preferences: sefProps(preferences),
      medicalCondition: sefMedProps(medicalCondition),
      notes: notes,
    };
    const butt = (callback = () => {}, filters) => <Button size={'small'} style={buttonEditStyle} onClick={() => callback(filters)}><Edit style={{ fontSize: '20px' }} /></Button>;
    const callback = filterFields => {
      showForm({
        errors: errors || {},
        userData: userData,
        dataArray: dataArray,
        filterFields
      });
      this.setState({ filterFields });
    };

    var company = null;
    if (company_id!=null&&typeof(companies[company_id]) != 'undefined') {
      company = companies[company_id];
    } 

    return (ingredientList && emirate && addressFields && accStatuses && dietList) ? (
      <Fragment>
        <div className='customer-info scrollable-h cast-cast'>
          <Grid container>
            <Grid container>
            <ItemGrid md={2} xs={2}>
                   {avatar && (<img src={avatar} className="userPic" />)}
            </ItemGrid>
            <ItemGrid md={10} sm={10}>
              <Grid container>
                {butt(callback, ['common'])}
                <ItemGrid md={6} sm={6}>
                  <Text bold>Birthday: </Text><Text color='info'>{birthday ? moment.unix(birthday).format('DD/MM/YYYY') : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Language preference: </Text><Text color='info'>{language}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Email: </Text><Text color='info'>{email} <br/><a className="mail-restore-link" onClick={ e => {sendMailReset(email)} }>Send restore password link</a></Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                <Text bold>Phone: </Text><Text color='info'>{(PC_CODE!=null?PC_CODE+phone.replace(PC_CODE,''):phone) || 'No data'}</Text><br/>
                  <Text bold>WhatsApp: </Text><Text color='info'>{(WPC_CODE!=null?WPC_CODE+whatsUpnumber.replace(WPC_CODE,''):whatsUpnumber) || 'No data'}</Text><br/>
                  <Text bold>Company: </Text><Text color='info'>{company || 'No data'} </Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}> 
                  <Text bold>Weight: </Text><Text color='info'>{weight ? `${weight / 1000} kg` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Height: </Text><Text color='info'>{height ? `${height} cm` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Age: </Text><Text color='info'>{ageval ? `${ageval}` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>BMI: </Text><Text color={bmi_color}>{bmi ? `${bmi.toFixed(1)}` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Customer Type: </Text><Text color='info'>{customer_type ? `${customer_types[customer_type]}` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Nationality: </Text><Text color='info'>{nationality ? `${countryList[nationality]}` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Delivery Partner: </Text><Text color='info'>{delivery_partner_id && deliveryPartnerFull ? `${deliveryPartnerFull[delivery_partner_id].name}` : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  <Text bold>Helpline: </Text><Text color='info'>{delivery_partner_id && deliveryPartnerFull ? `${deliveryPartnerFull[delivery_partner_id].helpline}` : 'No data'}</Text>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            </Grid>
            <ItemGrid md={12} sm={12}>
              <Grid container>
                <ItemGrid md={6} sm={6}>
                  {butt(callback, ['addresses', 'home'])}
                  <Text bold >Main address:</Text>
                  {(homeAddress || {}).default && <span className='default-ad'>Default</span>}
                  <Text paragraph inline={false} style={margin(0, 'top')}>{homeAddress ? makeAddress(homeAddress) : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  {butt(callback, ['addresses', 'work'])}
                  <Text bold>2nd address:</Text>
                  {(workAddress || {}).default && <span className='default-ad'>Default</span>}
                  <Text paragraph inline={false} style={margin(0, 'top')}>{workAddress ? makeAddress(workAddress) : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  {butt(callback, ['addresses', 'public'])}
                  <Text bold>3rd address:</Text>
                  {(publicAddress || {}).default && <span className='default-ad'>Default on weekends and holidays</span>}
                  <Text paragraph inline={false} style={margin(0, 'top')}>{publicAddress ? makeAddress(publicAddress) : 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={6} sm={6}>
                  {butt(callback, ['deliveryInstructions'])}
                  <Text bold>Delivery Instructions:</Text>
                  <Text paragraph inline={false} style={margin(0, 'top')}>{(deliveryInstructions!=null && deliveryInstructions.replace(/&amp;/g, '&')) || 'No data'}</Text>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            <ItemGrid md={12} sm={12}>
              <Grid container>
                {butt(callback, ['medical'])}
                <ItemGrid md={12} sm={12}>
                  <Text inline={false} bold>Medical condition:</Text>
                  <Text paragraph inline={false} style={margin(0, 'top')}>{sef.medicalCondition || 'No data'}</Text>
                </ItemGrid>
              </Grid>
            </ItemGrid>
            <ItemGrid md={12} sm={12}>
              {butt(callback, ['other'])}
              <Grid container>
                <ItemGrid md={12} sm={12}>
                  <Text color='success' bold>Notes & Likes:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.notes || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                   <Text color='info' bold>Cutlery:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{(cutlery!=undefined && cutlery==1)? 'Required' : 'Not Required'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='info' bold>Allergies:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.allergies || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='warning' bold>Eliminate Groups:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.eliminate_groups || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='warning' bold>Dislikes:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.dislikes || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='success' bold>Dish Likes:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.dishlikes || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='danger' bold>Dish Dislikes:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{sef.dishdislikes || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='success' bold>Nutritionist:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{nutritionistlist[nutritionist] || 'No data'}</Text>
                </ItemGrid>
                <ItemGrid md={12} sm={12}>
                  <Text color='info' bold>Wellness manager:</Text>
                  <Text inline={false} paragraph style={margin(0, 'top')}>{wellnessManagerlist[manager] || 'No data'}</Text>
                </ItemGrid>
              </Grid>
            </ItemGrid>
          </Grid>
        </div>
      </Fragment>
    ) : null;
  }
}

TabInfo.propTypes = {
  dataArray: PropTypes.object,
  userData: PropTypes.object,
  errors: PropTypes.object,
  showForm: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray
});

export default connect(props, null)(TabInfo);
