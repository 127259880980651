import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ABU_CENTER } from 'variables/constants';
import { Map as GoogleMap, Marker } from 'components/GoogleMap';
import marker_img from 'assets/img/hexagonal_marker.png';
import Button from 'components/CustomButtons/Button';
import { Close } from '@material-ui/icons/index';

const addresses = [
  {
    param: 'homeAddress',
    title: 'Home'
  },
  {
    param: 'workAddress',
    title: 'Work'
  },
  {
    param: 'publicAddress',
    title: 'Public'
  }
];

class Map extends Component {
  constructor(props) {
    super(props);
    const { userData } = props;
    const locations = this.getLocations(userData) || {};
    this.state = {
      edit: false,
      type: null,
      locations
    };
  }

  getLocations = userData => addresses.reduce((acc, { param }) => ({ ...acc, [param]: (userData[param] || {}).location }), {});

  componentWillReceiveProps(nextProps, nextContext) {
    const { userData } = nextProps;
    this.setState({ locations: this.getLocations(userData), edit: false, type: null });
  }

  save = locations => {
    const { userData, saveLocations, user_id } = this.props;
    const data = Object.keys(locations).reduce((acc, cur) => locations[cur] ? { ...acc, [cur]: locations[cur] } : acc, {});
    saveLocations(user_id, data);
    this.closeEdit(userData);
  };

  closeEdit = userData => this.setState({ edit: false, type: null, locations: this.getLocations(userData) });

  getMarkers = (userData, locations, type) => {
    return addresses.map((el, idx) => {
      const { param, title } = el;
      const { location } = userData[param] || {};
      const newLoc = locations[param];
      return ((newLoc || location) ? <Marker
        key={idx}
        lat={(newLoc || location).lat}
        lng={(newLoc || location).lng}
        title={title[0].toUpperCase()}
        image={marker_img}
        style={{ filter: type === param ? 'hue-rotate(270deg)' : 'none' }}
      /> : null);
    });
  };

  changeLocation = ({ lat, lng }, edit, type) => {
    if (edit) {
      this.setState({ locations: { ...this.state.locations, [type]: { lat, lng } } });
      // const geo = new this.maps.Geocoder();
      // const location = new this.maps.LatLng(lat, lng);
      // geo.geocode({ location }, (res, status) => {
      //   if (status === 'OK') {
      //     console.log(res);
      //   }
      // });
    }
  };

  render() {
    const { userData } = this.props;
    const { edit, type, locations } = this.state;
    const getButtons = type => {
      return addresses.map((el, idx) => {
        const { param, title } = el;
        return <Button key={idx} color={param === type ? 'darkBlue' : 'white'} onClick={() => this.setState({ type: param })}>{title}</Button>;
      });
    };
    return (
      <div className='customer-info map-holder'>
        <div className={'map-edit-holder'}>
          {edit && getButtons(type)}
          {edit && <div className={'separator'} />}
          <Button
            color={edit ? 'success' : 'darkBlue'}
            onClick={() => edit ? this.save(locations) : this.setState({ edit: true, type: 'homeAddress' })}
          >
            {edit ? 'Save' : 'Edit'}
          </Button>
          {edit && <Button
            round
            color={'transparent'}
            style={{ padding: 0, minWidth: 'unset', marginRight: 0 }}
            onClick={() => this.closeEdit(userData)}
          >
            <Close />
          </Button>}
        </div>
        <div className={'map-holder'}>
          <GoogleMap
            center={ABU_CENTER}
            onLoad={({ map, maps }) => {
              this.map = map;
              window.map = map;
              this.maps = maps;
              window.maps = maps;
              this.setState({ mapIsLoaded: true });
            }}
            onClick={e => this.changeLocation(e, edit, type)}
          >
            {this.getMarkers(userData, locations, type)}
          </GoogleMap>
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  saveLocations: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired
};

export default Map;
