import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class TouchSpin extends Component {
  constructor(props) {
    super(props);
    const { value, initialValue } = props;
    this.value = value;
    this.state = {
      value: value || initialValue || 0
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { value } = nextProps;
    if (this.props.value !== value) {
      this.setState({ value });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.value = this.state.value;
  }

  render() {
    const { useInput, min, max, step, controlled, onChange, style, fractionDigits } = this.props;
    const { value } = this.state;
    const mul = step < 1 ? 1 / step : 1;
    const add = (a, b) => ((+a * mul) + (+b * mul)) / mul;
    const change = value => controlled ? onChange(value) : this.setState({ value });
    const checkValue = value => value > min ? (value < max ? value : max) : min;
    const tick = ({ ctrlKey, shiftKey }, val) => {
      const mul1 = ctrlKey ? 100 : 1;
      const mul2 = shiftKey ? 10 : 1;
      change(checkValue(add(value, +val * mul1 * mul2)));
    };
    const formatValue = value => value.toFixed(fractionDigits || 0);
    return (
      <div className={'touch-spin'} style={style}>
        <div className={`butt down${+value === +min ? ' disabled' : ''}`} onClick={e => tick(e, -step)}><span>&#x268A;</span></div>
        <div className={'value-handler'}>
          {useInput
            ? <input
              type={'text'}
              onChange={e => {
                e.preventDefault();
                const newVal = e.target.value;
                if (isNaN(+newVal)) {
                  change(value);
                } else {
                  change(~~(checkValue(newVal) / step) * step);
                }
              }}
              value={formatValue(+value)}
            />
            : <span>{formatValue(+value)}</span>}
        </div>
        <div className={`butt up${+value === +max ? ' disabled' : ''}`} onClick={e => tick(e, step)}>
          <span>&#x271A;</span>
        </div>
      </div>
    );
  }
}

TouchSpin.propTypes = {
  onChange: PropTypes.func,
  useInput: PropTypes.bool,
  controlled: PropTypes.bool,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialValue: PropTypes.number,
  style: PropTypes.object,
  fractionDigits: PropTypes.number
};

TouchSpin.defaultProps = {
  useInput: false,
  controlled: false,
  min: -Infinity,
  max: Infinity,
  step: 1,
  onChange: () => {},
  style: {}
};

export default TouchSpin;
