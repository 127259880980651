import React, { Component, Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table as MUITable,
  TableBody,
  TableCell,
  TableRow
} from 'material-ui';
import TableHead from 'material-ui/Table/TableHead';
import CustomCard from 'components/Cards/CustomCard';
import Text from 'components/Typography/Text';
import moment from 'moment';
import Days from 'components/DeliverySchedule';
import SelectInput from 'components/SelectInput';

class TabDeliverySchedule extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      user_id,
      datas: [],
      plans: []
    };
    user_id && this.getDatas(user_id);
  }

  getDatas = user => {
    const user_id = user || this.state.user_id;
    this.props.socket.emit('delivery_schedule', { type: 'get', data: { user_id } });
  };

  removeNote = id => {
    this.props.socket.emit('delivery_schedule', { type: 'del', data: { id } });
  };

  listener = action => {
    const { type, data } = action;
    switch (type) {
      case 'getOk':
        this.setState(data);
        break;
      case 'delOk':
        const datas = this.state.datas.filter(el => +el.id !== +data.id);
        this.setState({ datas });
        break;
      default:
        if (['getErr', 'delErr'].includes(type)) {
          console.error(data);
        } else {
          console.log(action);
        }
        break;
    }
  };

  componentWillMount() {
    this.props.socket.on('delivery_schedule', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('delivery_schedule', this.listener);
  }

  componentWillReceiveProps(next) {
    if (next.user_id !== this.props.user_id) {
      this.getDatas(next.user_id);
    }
  }
  makeAddress = address => {
    const { dataArray } = this.props;
    const { emirate,areasList,addressFields } = dataArray;
    return Object.keys(addressFields).reduce((acc, cur, idx) => {
      if (address[cur] && cur !== 'default') {
        const curVal = address[cur];
        const value = cur === 'emirate' ? emirate[curVal] : (cur === 'area' ? areasList[curVal] : curVal);
        return `${acc}${idx ? ', ' : ''}${value || ''}`;
      } else {
        return acc;
      }
    }, '') || 'No data';
  };
  saveSchedule = datas => {
      this.props.socket.emit('delivery_schedule', { type: 'save', data: datas });
  }
  renderList = (datas,slots,addressTypes ) => {
    const { dataArray,userData } = this.props;
    const { deliveryPartner } = dataArray;
    
    return Object.keys(datas).map((bagname,index) => {
          return (<Days datas={ datas[bagname]} 
                        slots={slots} 
                        deliveryPartner={deliveryPartner} 
                        makeAddress={this.makeAddress}  
                        index={index}  
                        bagname={bagname}  
                        userData={userData}  
                        saveSchedule= {this.saveSchedule}
                        addressTypes={addressTypes}  
                        />);
    });
  }
  
  render() {
    const { plans } = this.state;
    const { dataArray } = this.props;
    const { planList } = dataArray;
    const PlanInfo = ({ data}) =>{
            const { order_id,mealPlan_id,start_date,end_date,status } = data;
            return (<Grid item md={12} key={order_id.toString()}>
              <CustomCard marginOnePx>
                <Text bold inline color='darkBlue'>{planList[mealPlan_id].title} : </Text>
                <Text bold inline color='darkBlue'>{moment.unix(start_date).format('DD.MM.YYYY')} -{moment.unix(end_date).format('DD.MM.YYYY')} </Text>
                <Text bold inline color='darkBlue'>| {status.toUpperCase()} | </Text>
                <Text bold inline color='darkBlue'>Id :{order_id} </Text>
              </CustomCard>
            </Grid>);
    };
    const List = ({ list }) => {
      const { dataArray, userData } = this.props;
      const { slotsList } = dataArray;
      var slots = {};
      Object.keys(slotsList).map( (id, el) => {
        slots[ slotsList[id].id] = slotsList[id].name;
      });
      var addressTypes = {'homeAddress':'Main address'};
      if(userData.workAddress){
        addressTypes['workAddress'] = '2nd address';
      }
      if(userData.publicAddress){
        addressTypes['publicAddress'] = '3rd address';
      }
      
      return Object.keys(list).map(( date ,id) => {
        return this.renderList(list[date],slots,addressTypes);
      });
    };

    const RenderData = ({ plans }) =>{
        const { datas } = this.state;
        return Object.keys(plans).map((orderId, id) => {
              const data = plans[orderId];
              // console.log(datas[orderId]);
              return ( <Fragment>
                <PlanInfo data={data} />
                <MUITable aria-label="simple table" key={id}>
                    <TableHead>
                      <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Day </TableCell>
                          <TableCell>Bag</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Delivery Partner</TableCell>
                          <TableCell>Timings</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                        <List list={datas[orderId]} /> 
                      </TableBody>
              </MUITable>
              </Fragment>);
          });
    };
    
    return (
      <div className='notes deliveryschedule'>
        <Grid container>
          <Grid item md={12}>
            <Grid container>
              <div className='innre scrollable-h'>
                {plans && <RenderData plans={plans} /> }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TabDeliverySchedule.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired
};

export default socketConnect(TabDeliverySchedule);
