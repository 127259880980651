import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';

class ModalForm extends Component {
  constructor(props) {
    super(props);
    const { dishes } = props;
    this.state = {
      dishes
    };
  }

  componentWillMount() {
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  listener = ({ data, type, status }) => {
    if (this.Ref) {
      if (status === 'ok') {
        if (type === 'setSpecial') {
          const dishes = [...this.state.dishes];
          const { dish_id, mealType_id } = data;
          const idx = dishes.findIndex(({ id, mealType }) => +id === +dish_id && +mealType === +mealType_id);
          dishes[idx]['cooked'] = true;
          this.setState({ dishes });
        }
      } else if (status === 'err') {
        console.error(type, data);
      }
    }
  };

  setCooked = (dish_id, mealType_id) => {
    const { user_id, date, socket } = this.props;
    socket.emit('kitchen', { type: 'setSpecial', data: { user_id, date, dish_id, mealType_id } });
  };

  setPacked = () => {
    const { user_id, date, socket } = this.props;
    socket.emit('kitchen', { type: 'packedSpecial', data: { user_id, date } });
  };

  render() {
    const { productsFull, typeList } = this.props;
    const { dishes } = this.state;
    let cookedAmount = 0;
    const menu = (dishes || []).map(({ id, mealType, cooked }) => {
      const { title, nickname } = productsFull[id];
      cookedAmount += +cooked;
      return <Grid key={id} className='component-row' container>
        <Grid item md={3} className='component-title'><Text color='info' inline>{typeList[mealType]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='success' inline bold>{nickname || title}</Text></Grid>
        <Grid item md={3} className='component-amount'>
          {!cooked && <Button size={'small'} color={'success'} pullRight onClick={() => this.setCooked(id, mealType)}>Set Cooked</Button>}
        </Grid>
      </Grid>;
    });
    const canBePacked = dishes && dishes.length === cookedAmount;
    return <Grid container style={{ userSelect: 'none' }} ref={el => (this.Ref = el)}>
      <Grid item md={12} style={{ textAlign: 'center', marginBottom: '15px' }}>
        <Text customColor={'#4e4e4e'} bold style={{ fontSize: 'x-large' }}>Menu</Text>
      </Grid>
      <Grid item md={12} className={'cooked-menu'} style={{ width: '40vw' }}>
        {menu}
      </Grid>
      <Grid item md={12} >
        <Button disabled={!canBePacked} size={'medium'} color={'darkBlue'} pullRight onClick={this.setPacked}>Set Packed</Button>
      </Grid>
    </Grid>;
  }
}

ModalForm.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  date: PropTypes.number.isRequired,
  dishes: PropTypes.array.isRequired,
  productsFull: PropTypes.object.isRequired,
  typeList: PropTypes.object.isRequired
};

export default socketConnect(ModalForm);
