import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Delete } from '@material-ui/icons';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import moment from 'moment';

const buttStyle = {
  padding: '0',
  height: '30px',
  minWidth: '30px',
  backgroundColor: '#e2e2e2',
  color: '#4a4a4a',
  right: '10px',
  position: 'absolute',
  margin: '0'
};

class TabDeliveryNotes extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      user_id,
      notes: []
    };
    user_id && this.getNotes(user_id);
  }

  getNotes = user => {
    const user_id = user || this.state.user_id;
    this.props.socket.emit('delivery_note', { type: 'get', data: { user_id } });
  };

  removeNote = id => {
    this.props.socket.emit('delivery_note', { type: 'del', data: { id } });
  };

  listener = action => {
    const { type, data } = action;
    switch (type) {
      case 'getOk':
        this.setState(data);
        break;
      case 'delOk':
        const notes = this.state.notes.filter(el => +el.id !== +data.id);
        this.setState({ notes });
        break;
      default:
        if (['getErr', 'delErr'].includes(type)) {
          console.error(data);
        } else {
          console.log(action);
        }
        break;
    }
  };

  componentWillMount() {
    this.props.socket.on('delivery_note', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('delivery_note', this.listener);
  }

  componentWillReceiveProps(next) {
    if (next.user_id !== this.props.user_id) {
      this.getNotes(next.user_id);
    }
  }

  render() {
    const { notes } = this.state;
    const List = ({ notes }) => {
      console.log(notes);
      return notes.map(({ id, driver, note, created_at }) => {
        return (
          <Grid item md={12} key={id}>
            <CustomCard marginOnePx>
              <Button size='small' style={buttStyle} onClick={() => this.removeNote(id)}>
                <Delete style={{ fontSize: '20px' }} />
              </Button>
              <Text bold inline color='darkBlue'>{driver}</Text>
              <Text paragraph inline={false} >{note}</Text>
              <Text paragraph inline={false} >{moment.unix(created_at).format('LLL')}</Text>
            </CustomCard>
          </Grid>
        );
      });
    };
    return (
      <div className='notes'>
        <Grid container>
          <Grid item md={12}>
            <Grid container>
              <div className='innre scrollable-h'>
                <List notes={notes} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TabDeliveryNotes.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired
};

export default socketConnect(TabDeliveryNotes);
