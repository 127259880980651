export const SET_SPINNER = 'SET_SPINNER';
export const STOP_SPINNER = 'STOP_SPINNER';

const initialState = false;

export function spin() {
  return {
    type    : SET_SPINNER
  };
}

export function stop() {
  return {
    type    : STOP_SPINNER
  };
}

export default function spinnerReducer(state = initialState, action) {
  if (action.type === SET_SPINNER) {
    return true;
  }
  if (action.type === STOP_SPINNER) {
    return false;
  }
  return state;
}
