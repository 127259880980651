import React, { Component, Children, cloneElement, Fragment } from 'react';
import { connect } from 'react-redux';
// import createBrowserHistory from 'history/createBrowserHistory';
import { getMenuLinks } from 'variables/menu';
import { createBrowserHistory, History } from 'history';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import GridView from 'components/GridView';
import PassChange from 'components/PassChange';
import DelUser from 'components/DelUser';
import Button from 'components/CustomButtons/Button';
import CustomTabs from 'components/CustomTabs';
import { Add, CheckCircle, HighlightOff } from '@material-ui/icons/index';
import { getArrays } from 'utils';
import { pushModal, popModal, clearModalStack } from 'store/modalStack';
import { Grid } from 'material-ui';
import Text from 'components/Typography/Text';
import { spin, stop } from 'store/spinner';

class Users extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    const { type, page } = params;
    const limit = 20;
    this.state = {
      users: {},
      limit,
      page: +page || 1,
      maxPages: 1,
      errors: {},
      menulist:getMenuLinks('admin'),
      tableHead: {
        index: '#',
        firstName: 'First Name',
        lastName: 'Last Name',
        accesslevel:{label:'Access Level','middleware' : 'usersPrivilege','datas':[]},
        email: 'Email',
        phone: 'Phone',
        'account_status': 'Account Status',
        actions: 'Actions'
      },
      actions: {
        update: {
          tooltip: 'Edit',
          button: 'Edit'
        },
        profile: {
          tooltip: 'Go to Profile',
          button: 'delete',
          callback: this.goToProfile,
          icon: 'delete'
        },
        pass: {
          tooltip: 'Change password',
          button: 'Edit',
          callback: this.showChangePassForm
        },
        editaccess:{
          tooltip: 'Change Access Level',
          button: 'Edit',
          callback: this.showUserTypeForm
        }
      },
      defFilters: {
        firstName: {
          type: 'text',
          value: '',
          label: 'First name'
        },
        email: {
          type: 'text',
          value: '',
          label: 'Email'
        },
        phone: {
          type: 'text',
          value: '',
          label: 'Phone'
        },
        lastName: {
          type: 'text',
          value: '',
          label: 'Last name'
        },
        gender: {
          type: 'select',
          items: {
            '': '',
            male: 'Male',
            female: 'Female'
          },
          value: '',
          label: 'Gender'
        },
        'account_status': {
          type: 'select',
          items: {}, 
          value: '',
          label: 'Account status'
        }
      }
    };
    this.types = ['admin', 'delivery', 'kitchen', 'logistic', 'customer', 'packager','manager','accounts','nutritionist','guest_nutritionist','no_access'];

    this.usertypes = {'admin':'Admin', 'delivery':'Delivery', 'kitchen':'Kitchen', 'logistic':'Logistic', 'customer':'Customer', 'packager':'Packager','manager':'Manager','accounts':'Accounts','nutritionist':'Nutritionist','guest_nutritionist':'Guest Nutritionist','no_access':'No Access'};

    this.getUsers(type || this.types[0], (this.state.page - 1) * limit, limit);
  } 


  goToProfile = id => {
    browserHistory.push(`/admin/customers/details/${id}`);
  }

  showChangePassForm = userId => {
    const { users } = this.state;
    const { firstName, lastName, email, status } = (users || []).find(({ id }) => +id === +userId) || {};
    this.props.pushModal({
      content: <PassChange username={`${firstName} ${lastName}`} save={pass => this.savePass(userId, pass)} email={email} formtype='password'/>
    });
  };

  showUserTypeForm = userId => { 
    const { users } = this.state;
    const { firstName, lastName, email, type } = (users || []).find(({ id }) => +id === +userId) || {};
    this.props.pushModal({
      content: <PassChange username={`${firstName} ${lastName}`} types={this.usertypes} save={usertype => this.saveUserType(userId, usertype)} email={email} formtype='usertype' type={type} />
    });
  };
  
  fillDefaultMenus () {
    const { menulist,tableHead } = this.state;
    let types = {};
    types['-admin']='Home';
    menulist.map((acc1, type) => {
      let mainmenu = acc1.path.replaceAll("/",'-');
      if(acc1.path=='/'&&acc1['sublinks']!=undefined){
        acc1['sublinks'].map((sub,index) => {
          let menupath= sub.path.replaceAll("/",'-');
          types[menupath] = sub.navbarName;
        });
      }else{
        types[mainmenu] = acc1.navbarName;
      }
      
    });
    this.setState({ tableHead: { ...tableHead, ['accesslevel']: { ...tableHead['accesslevel'], ['datas']: types } } });
  }

  changeUserStatus = (user_id, type) => {
    this.props.socket.emit('profile', { type, data: { user_id } });
  };

  showDeleteUserForm = userId => {
    const { users } = this.state;
    const { pushModal, popModal } = this.props;
    const { firstName, lastName, email, status } = (users || []).find(({ id }) => +id === +userId) || {};
    console.log({ firstName, lastName, email, status });
    const setConfirmModal = type => {
      pushModal({
        content: <div>
          <Grid container>
            <Grid item md={12} style={{ textAlign: 'center', marginBottom: '50px' }}>
              <Text paragraph color={'darkBlue'} bold style={{ fontSize: 'larger' }}>Are you really sure?</Text>
            </Grid>
            <Grid item md={12}>
              <Button onClick={() => this.changeUserStatus(userId, type)} color={type === 'activate' ? 'success' : 'danger'}>{type}</Button>
              <Button onClick={popModal} pullRight color={'darkBlue'}>Cancel</Button>
            </Grid>
          </Grid>
        </div>
      });
    };
    +status === 10 ? pushModal({
      content: <DelUser
        username={`${firstName} ${lastName}`}
        confirm={setConfirmModal}
        cancel={popModal}
        email={email}
      />
    }) : setConfirmModal('activate');
  };

  savePass = (user_id, password) => {
    this.props.spin();
    this.props.socket.emit('profile', { type: 'setPass', data: { user_id, password } });
  };

  saveUserType = (user_id, usertype) => {
    this.props.socket.emit('profile', { type: 'setUserType', data: { user_id, usertype } });
  };

  socketUsers = ({ type, data }) => {
    if (this.UsersRef) {
      this.props.stop();
      switch (type) {
        case 'listOk':
          this.fillDefaultMenus();
          this.setState(data);
          break;
        case 'setPassOk':
          this.props.clearModalStack();
          break;
        case 'deleteOk':
        case 'deactivateOk':
        case 'activateOk':
        case 'setUserTypeOk':
          this.props.clearModalStack();
          const { limit, page, filters } = this.state;
          this.getUsers(this.props.params.type || this.types[0], (page - 1) * limit, limit, filters);
          break;
        case 'listErr':
          this.setState({ errors: data.errors });
          break;
      }
    }
  };

  getUsers(userType, offset, limit, filters) {
    this.props.socket.emit('profile', {
      type: 'list',
      data: {
        userType,
        offset,
        limit,
        filters,
        listType: 'extended'
      }
    });
  }

  componentWillMount() {
    this.props.socket.on('profile', this.socketUsers);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.socketUsers);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['gender', 'accStatuses','usersPrivileges','medicalConditions'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  goTo = (page, type) => {
    const { limit, defFilters } = this.state;
    const history = createBrowserHistory();
    history.push(`/${this.props.userType}/users/${type}/page/${page}`);
    this.setState({ page });
    this.getUsers(type, (page - 1) * limit, limit, defFilters);
  };

  filters = () => {
    const { defFilters } = this.state;
    const { gender, accStatuses } = this.props.dataArray;
    const items = {
      gender: gender || {},
      'account_status': accStatuses || {}
    };
    return Object.keys(defFilters).reduce((acc, cur) => ({ ...acc, [cur]: { ...defFilters[cur], items: items[cur] || {} } }), {});
  };

  addNewOne = type => browserHistory.push(`/${this.props.userType}/users/${type}/add`);

  submitFilters = (defFilters, type) => {
    const { page, limit } = this.state;
    this.setState({ defFilters });
    this.getUsers(type, (page - 1) * limit, limit, defFilters);
  };

  render() {
    const { submitFilters, filters, goTo, state, props } = this;
    const { page, maxPages, users, limit, tableHead, actions: _actions } = state;
    const { children, params, userType } = props;
    const tabsData = this.types.reduce((acc, cur) => {
      const callback = id => browserHistory.push(`/${userType}/users/${cur}/edit/${id}`);
      const actions = {
        ..._actions,
        update: { ..._actions.update, callback },
        profile: { ..._actions.profile},
        custom: {
          tooltip: row => +(row || {}).status === 10 ? 'Delete' : 'Activate',
          callback: this.showDeleteUserForm,
          icon: (row, getClasses) => +(row || {}).status === 10 ? <HighlightOff className={getClasses('close')} /> : <CheckCircle className={getClasses('success')} />
        }
      };

      const tabData = {
        icon: '',
        label: (cur=='delivery')?'Call Center':cur,
        renderTabContent: () => {
          const props = {
            ...{ page, maxPages, limit, tableHead, actions },
            rows: users,
            goTo: page => goTo(page, cur),
            filters: filters(cur),
            submitFilters: (f) => submitFilters(f, cur)
          };
          return (
            <Fragment>
              <Button fullWidth={false} color='success' size='medium' onClick={() => this.addNewOne(cur)}><Add /></Button>
              <GridView {...props} />
            </Fragment>
          );
        }
      };
      return { ...acc, [cur]: tabData };
    }, {});
    let child = children && Children.map(
      children,
      child => cloneElement(
        child,
        {}
      )
    );
    return (<div ref={el => (this.UsersRef = el)}>
      {!!(tabsData && Object.keys(tabsData).length) && (
        <CustomTabs
          data={tabsData}
          headerColor='darkBlue'
          defaultTab={params.type}
          onChangeTab={active => this.goTo(1, active)}
        />
      )}
      {child}
    </div>);
  }
}

Users.propTypes = {
  clearModalStack: PropTypes.func.isRequired,
  pushModal: PropTypes.func.isRequired,
  popModal: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  children: PropTypes.object,
  params: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  dataArray: PropTypes.object.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  clearModalStack: () => dispatch(clearModalStack()),
  pushModal: obj => dispatch(pushModal(obj)),
  popModal: () => dispatch(popModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions)(Users));
