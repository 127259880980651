export const SET_DATA_ARRAY = 'SET_DATA_ARRAY';
export const UPDATE_DATA_ARRAY = 'UPDATE_DATA_ARRAY';
export const CLEAR_DATA_ARRAY = 'CLEAR_DATA_ARRAY';

const initialState = {};

export function setDataArray(array) {
  return {
    type    : SET_DATA_ARRAY,
    payload : array
  };
}

export function updateDataArray(array) {
  return {
    type    : UPDATE_DATA_ARRAY,
    payload : array
  };
}

export function clearDataArray() {
  return {
    type    : CLEAR_DATA_ARRAY
  };
}

export default function dataArrayReducer(state = initialState, action) {
  if (action.type === SET_DATA_ARRAY) {
    return action.payload;
  }
  if (action.type === UPDATE_DATA_ARRAY) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_DATA_ARRAY) {
    return initialState;
  }
  return state;
}
