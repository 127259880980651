import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import LogFilter from './LogFilter';
import CustomCard from 'components/Cards/CustomCard';
import { getArrays } from 'utils';
class Logs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters:{
              dietId: '',
              typeId: '',
              sideTypeId: '',
              logtype: '',
            },
            loglist:{}
        };
        this.getList(this.state.filters);
      }
      getList(filters) {
        // const { filters } =this.state;
        console.log(filters);
        this.props.socket.emit('menu_builder', {
          type: 'getprogramlog',
          data: filters
        });
      }
    listener = action => {
        switch (action.type) {
            case 'getprogramlogOk':
              this.setState(action.data);
            break;
            default:
              if (action.type.includes('Err')) {
                const { errors } = (action.data!=undefined)?action.data:'';
                this.setState({ errors });
              }
              break;
          }
    }
    componentWillMount() {
        this.props.socket.on('menu_builder', this.listener);
      }
    componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
    }
    componentDidMount() {
        const { socket, dataArray } = this.props;
        const types = ['typeList', 'dietList', 'productsFull', 'dishLabels','sidesTypes','dietListFull'].filter(type => !(type in dataArray));
        types.length && getArrays(socket, types);
      }
    render() {
    const { filters, loglist} = this.state;
    const { dietId, typeId, sideTypeId } = filters;
    const { dataArray } = this.props;
   
    const { typeList, dietList, productsFull: products, sidesTypes,dietListFull } = dataArray;
    let logTypes = {
    1: {'title':'Dish List','id':'custom_plan'},
    2:{'title':'Default Dish','id':'custom_plan'},
    3:{'title':'SideDish List','id':'custom_plan'},
    4: {'title':'Default SideDish List','id':'custom_plan'}
    };
    var dietListFulldata = (dietListFull)?dietListFull[dietId] || {}:{};

    const { sides_id:sidesId  } = dietListFulldata ;

    const { parent_id:typeParentId }=typeList?typeList[typeId] || 0:0;

    const buttons = (list, active, type) => list && Object.keys(list).sort((a, b) => list[a].order - list[b].order).filter(function (typeId) {
      if(type=='typeId'){ // Filter the sides meal types based on diet sides list 
        return ((typeList[typeId].parent_id==0 || typeList[typeId].parent_id==null))?false:(typeof sidesId == undefined || sidesId==null)?false:(sidesId.length>0);
      }else if(type=='sideTypeId'){
        return (typeof sidesId != undefined && sidesId!=null)?(sidesId.indexOf(typeId)>-1):true;
      }else{
        return true;
      }
    }).map((id, idx) => {
        const { title } = list[id];
        const props = { dietId: { color: 'primary', defColor: 'success' }, typeId: { color: 'warning', defColor: 'gray' }, sideTypeId: { color: 'info', defColor: 'gray' }  };
        return <Button key={idx} color={props[type][+id === +active ? 'color' : 'defColor']}
        onClick={() => this.change({ [type]: +id, activeDay: null })}>{title || list[id]}
        </Button>;
    });
    var logs = [];
    if (loglist) {
      Object.keys(loglist).map( (el, idx) => {
        el=loglist[el];
        // loglist[el].map( (el1, idx) => {
          // message='';
        logs.push( 
          <li>
          <a target="_blank" href="javascript:void(0);">{el.type} by {moment.unix(el.created_date).format('DD MMM, YYYY')} : {el.firstName} {el.lastName}</a>
        
          <a href="javascript:void(0);" className="float-right">{moment.unix(el.created_date).format('DD MMM, YYYY h:mm:ss A')}</a>
         
          <div dangerouslySetInnerHTML={{__html: el.description}} />
        </li>
            )
            // });
      });
    }
    return (
       <Fragment>
         <div className='filtesHolder finances'>
         <CustomCard marginOnePx>
        <LogFilter 
          applyFilters={(filterVal) => {
            this.setState({filters: filterVal});
            this.getList(filterVal);
          }}
          
        />
      </CustomCard>
    </div>
    <div class="dish-wrapper xyz-fin"><div class="list-wrap">
    <ul className="timeline">
                    {logs}
                  </ul>
          </div>
          </div>
       </Fragment>
       );
}
}   
const props = state => {
return {
    dataArray: state.dataArray,
};
};
const actions = dispatch => ({
   
});
  export default socketConnect(connect(props, actions)(Logs));