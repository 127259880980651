import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import CustomCard from 'components/Cards/CustomCard';
import { Table, TableBody, TableCell, TableRow } from 'material-ui';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import TableHead from 'material-ui/Table/TableHead';
import moment from 'moment';
import Filters from './Filters';
import Text from 'components/Typography/Text';
 
class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      xyz: false,
      orderBy: 'username',
      orderDir: '+',
      onlyActive: false
    };
    this.getSales();
  }

  componentWillMount() { 
    this.props.socket.on('finances', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('finances', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 50);
    this.props.setExport({ type: 'sales' });
  }

  listener = ({ type, status, data }) => {
    if (this.SalesRef) {
      if (status === 'ok') {
        if (type === 'getSales') {
          this.setState(data);
        }
      } else {
        console.error(data);
      }
    }
  };

  getSales = () => this.props.socket.emit('finances', { type: 'getSales', data: {} });

  sort = (a, b) => {
    const results = { true: 1, false: -1 };
    const { orderBy, orderDir } = this.state;
    const getItemData = (item, prefix) => {
      const { username, price, days_total, days_delivered: daysReceived } = item;
      const dailyPrice = price / days_total;
      const daysPending = days_total - daysReceived;
      const moneyReceived = dailyPrice * daysReceived;
      const moneyPending = daysPending * dailyPrice;
      const prop = name => `${prefix}${name}`;
      return {
        [prop('username')]: username,
        [prop('price')]: price,
        [prop('bagPrice')]: 123,
        [prop('dailyPrice')]: dailyPrice,
        [prop('daysReceived')]: daysReceived,
        [prop('moneyReceived')]: moneyReceived,
        [prop('daysPending')]: daysPending,
        [prop('moneyPending')]: moneyPending
      };
    };
    const obj = { ...getItemData(a, 'a_'), ...getItemData(b, 'b_') };
    return results[orderDir === '+' ? obj[`a_${orderBy}`] > obj[`b_${orderBy}`] : obj[`b_${orderBy}`] > obj[`a_${orderBy}`]];
  };

  applyFilters = ({ order, nameOrPhone, onlyActive }) => {
    const orderArr = [...order];
    const orderDir = orderArr.splice(-1).join('');
    const orderBy = orderArr.join('');
    const { orderDir: oldOrderDir, orderBy: oldOrderBy, nameOrPhone: oldNameOrPhone, onlyActive: oldOnlyActive } = this.state;
    let newState = {};
    if (orderDir !== oldOrderDir || orderBy !== oldOrderBy) {
      newState = { orderBy, orderDir };
    }
    if (nameOrPhone !== oldNameOrPhone) {
      newState.nameOrPhone = nameOrPhone;
    }
    if (onlyActive !== oldOnlyActive) {
      newState.onlyActive = onlyActive;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
    this.props.setExport({ type: 'sales', filters: newState });
  };

  renderContent = (sales = []) => {
    const totals = {
      price: 0,
      bagPrice: 0,
      dailyPrice: 0,
      moneysReceived: 0,
      moneysLeft: 0,
      start: { date: -Infinity, count: 0 },
      end: { date: Infinity, count: 0 },
      update({ price, dailyPrice, moneysReceived, moneysLeft, last_delivery, first_delivery, next_delivery, bagPrice }) {
        this.price += +price;
        this.bagPrice += bagPrice;
        this.dailyPrice += +dailyPrice;
        this.moneysReceived += +moneysReceived;
        this.moneysLeft += +moneysLeft;
        if (last_delivery < this.end.date) {
          this.end = { date: last_delivery, count: 1 };
        } else if (last_delivery === this.end.date) {
          this.end.count++;
        }
        if (first_delivery > ~~(+(new Date()) / 1000) && first_delivery === next_delivery && first_delivery > this.start.date) {
          this.start = { date: first_delivery, count: 1 };
        } else if (first_delivery === this.start.date) {
          this.start.count++;
        }
      }
    };
    const { nameOrPhone, onlyActive } = this.state;
    const filteredSales = sales.filter(({ username, phone, first_delivery: first, next_delivery: next }) => {
      const lower = (nameOrPhone || '').toLowerCase();
      const matchByNameOrPhone = nameOrPhone ? (username || '').toLowerCase().includes(lower) || (phone || '').toLowerCase().includes(lower) : true;
      const matchByOnlyActive = onlyActive ? first !== next : true;
      return matchByNameOrPhone && matchByOnlyActive;
    });
    const rows = filteredSales.sort(this.sort).map((item, idx) => {
      const { username, price, days_total, days_delivered, last_delivery, first_delivery, next_delivery, phone, bagPrice } = item;
      const dailyPrice = price / days_total;
      const daysLeft = days_total - days_delivered;
      const moneysReceived = dailyPrice * days_delivered;
      const moneysLeft = daysLeft * dailyPrice;
      totals.update({ price, dailyPrice, moneysReceived, moneysLeft, last_delivery, first_delivery, next_delivery, bagPrice });
      return <TableRow key={idx}>
        <TableCell>
          <Text inline={false} bold>{`${idx + 1}. ${username}`}</Text>
          <Text inline={false} style={{ fontSize: 'smaller' }}>{phone}</Text>
        </TableCell>
        <TableCell>{price / 100}</TableCell>
        <TableCell>{bagPrice}</TableCell>
        <TableCell>{dailyPrice / 100}</TableCell>
        <TableCell>{days_delivered}</TableCell>
        <TableCell>{moneysReceived / 100}</TableCell>
        <TableCell>{daysLeft}</TableCell>
        <TableCell>{moneysLeft / 100}</TableCell>
      </TableRow>;
    });
    const plusDays = moment.unix(totals.start.date).diff(moment().utc().startOf('d'), 'days');
    const minusDays = moment.unix(totals.end.date).diff(moment().utc().startOf('d'), 'days');
    return <Table>
      <TableHead className='primaryTableHeader roundedHeader not-align-right'>
        <TableRow className='totals-row'>
          <TableCell>Totals</TableCell>
          <TableCell>{totals.price / 100}</TableCell>
          <TableCell>{totals.bagPrice}</TableCell>
          <TableCell>{totals.dailyPrice / 100}</TableCell>
          <TableCell>
            {totals.start.count > 0 ? <Text>
              <ArrowUpward color='primary' style={{ verticalAlign: 'bottom' }} />
              {`+${totals.start.count} delivering in ${plusDays} day${plusDays > 1 ? 's' : ''}`}
            </Text> : '-'}
          </TableCell>
          <TableCell>{totals.moneysReceived / 100}</TableCell>
          <TableCell>
            {totals.end.count > 0 ? <Text>
              <ArrowDownward color='error' style={{ verticalAlign: 'bottom' }} />
              {`-${totals.end.count} delivering in ${minusDays} day${minusDays > 1 ? 's' : ''}`}
            </Text> : '-'}
          </TableCell>
          <TableCell>{totals.moneysLeft / 100}</TableCell>
        </TableRow>
        <TableRow id='header-row'>
          <TableCell>Client</TableCell>
          <TableCell>Total Plan Amount</TableCell>
          <TableCell>Bag Deposit</TableCell>
          <TableCell>Daily Sales</TableCell>
          <TableCell>Total Days Received</TableCell>
          <TableCell>Total Money Received</TableCell>
          <TableCell>Total Days Pending</TableCell>
          <TableCell>Total Money Pending</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
      </TableBody>
    </Table>;
  };

  render() {
    const { xyz, sales } = this.state;
    console.log(sales);
    return (
      <div ref={el => (this.SalesRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
        <div className='filtesHolder finances'>
          <Filters applyFilters={this.applyFilters} />
        </div>
        <CustomCard marginOnePx>
          <div>
            {this.renderContent(sales)}
          </div>
        </CustomCard>
      </div>
    );
  }
}

Sales.propTypes = {
  socket: PropTypes.object,
  setExport: PropTypes.func
};

export default socketConnect(Sales);
