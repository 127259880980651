import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid, Checkbox } from 'material-ui';
import { Close, Check } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';
import Snackbar from 'components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons/index';
import { Text } from 'components/Typography';
import RegularCard from 'components/Cards/RegularCard';
import { getArrays } from 'utils';

class IngredientPrice extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      ingredientId:id,
      supplierPrices: {},
      order: [],
      note: false,
      message: ''
    }; 
    this.getSupplierPrices(id);
  }
  listener = ({ data, type }) => {
    if (this.IngRef) {
      switch (type) {
        case 'getIngredientOk':
        console.log(data);
          this.setState({supplierPrices:data.supplierPrices,order:Object.keys(data.supplierPrices)});
          break;
        case 'saveIngredientPriceOk':
          console.log(data)
          this.showNotification(data.message);
         break;
        case 'getIngredientErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };
  showNotification(message) {
    this.setState({ note : true, message });
    setTimeout(() => this.setState({ note : false, message: '' }), 5000);
  }
  componentWillMount() {
    this.props.socket.on('prices', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('prices', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['suppliers','ingredientList','ingredientListFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  getSupplierPrices = ingredientId => this.props.socket.emit('prices', { type: 'getIngredient', data: { ingredientId } });
  
  savePriceData = () =>{
    const { supplierPrices, ingredientId} = this.state;
    this.props.socket.emit('prices', { type: 'saveIngredientPrice', data: {supplierPrices,ingredientId } })
  }
  renderIngredientFields = (id) => {
    const { dataArray } = this.props;
    const { suppliers, ingredientListFull } = dataArray;
    const { supplierPrices, order: oldOrder,ingredientId } = this.state;
    const { unit_name } = ingredientListFull[ingredientId] || {};
    const list = suppliers && Object.keys(suppliers).reduce((acc, cur) => (!supplierPrices[cur] || cur === id) ? { ...acc, [cur]: suppliers[cur] } : acc, {});
    const onChange = val => {
      let ings = { ...supplierPrices };
      let order = [...oldOrder];
      if (id && id in ings) {
        ings = { ...ings, [val]: ings[id] };
        delete ings[id];
        order[order.indexOf(id)] = val;
      } else {
        ings = { ...ings, [val]: {supplier_id:val} };
        order = [...order, val];
      }
      this.setState({ supplierPrices: ings, order });
    };
    const { supplier_id,is_default,price,quantity,wastage } = supplierPrices[id] || {};
    const tquantity = (wastage>0&&quantity>0)?(quantity-(quantity*(wastage/100))):quantity;
    const perprice=(tquantity>0&&price>0) ?Math.round( (price/tquantity) * 1000 ) / 1000  :0;
    const qtyChange = (e, min = -Infinity, max = Infinity) => {
      const { name, value } = e.target;
      id && this.setState({ supplierPrices: { ...supplierPrices, [id]: { ...supplierPrices[id], [name]: value < min ? min : (value > max ? max : value) } } });
    };
    const remove = () => {
      if (id in supplierPrices) {
        let ings = { ...supplierPrices };
        delete ings[id];
        this.setState({ supplierPrices: ings, order: oldOrder.filter(el => el !== id) });
      }
    };
    return (
      <ItemGrid md={12} key={id} container>
        <ItemGrid md={2}>
          <Select
            placeholder={'Suppliers'}
            list={list}
            value={supplier_id || ''}
            name={'supplier_id'}
            onChange={(name, value) => onChange(value)}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <CustomInput
                    labelText={'Quantity'}
                    formControlProps={{
                    fullWidth: false
                    }}
                    error={false}
                    inputProps={{
                    value: (quantity!=undefined)?quantity:'',
                    type: 'number',
                    name: 'quantity',
                    onChange: e => qtyChange(e, 0, 10000)
                    }}
                />&nbsp;{unit_name} &nbsp;
        </ItemGrid>
        <ItemGrid md={2}>
        <CustomInput
                    labelText={'Price'}
                    formControlProps={{
                    fullWidth: false
                    }}
                    error={false}
                    inputProps={{
                    value: (price!=undefined)?price:0,
                    type: 'text',
                    name: 'price',
                    onChange: e => qtyChange(e, 0, 10000)
                    }}
                />&nbsp;AED
        </ItemGrid>
        <ItemGrid md={1}>
        <CustomInput
                    labelText={`Per ${unit_name} `}
                    formControlProps={{
                    fullWidth: false
                    }}
                    error={false}
                    inputProps={{
                    value: (perprice!=undefined)?perprice:'',
                    type: 'text',
                    name: 'perprice',
                    onChange: e => qtyChange(e, 0, 10000)
                    }}
                />&nbsp;
        </ItemGrid>
        <ItemGrid md={2}>
        <CustomInput
                    labelText={'Wastage %'}
                    formControlProps={{
                    fullWidth: false
                    }}
                    error={false}
                    inputProps={{
                    value: (wastage!=undefined)?wastage:0,
                    type: 'number',
                    name: 'wastage',
                    onChange: e => qtyChange(e, 0, 10000)
                    }}
                />&nbsp;%
        </ItemGrid>
        <ItemGrid md={2}>
          <div className='checkHolder'>
            <Checkbox
              checked='false'
              checkedIcon={<Check className={`${(is_default==1 || is_default==true) ? 'checked' : 'unChecked'} `} />}
              icon={<Check />}
              onClick={() => id && this.setState({ supplierPrices: { ...supplierPrices, [id]: { ...supplierPrices[id], is_default: !is_default } } })}
              classes={{
                checked: 'unChecked'
              }}
            />
            Is Default?
          </div>
        </ItemGrid>
        <ItemGrid md={1}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>
      </ItemGrid>
    );
  };

  getRenderer = () => {
    let renderer = null;
    renderer = 'renderIngredientFields';
    return renderer;
  };
  renderPrices = () =>{
    const  { order } = this.state;
    const renderer = this.getRenderer();
    console.log(order);
    return <div>
      {order.map(this[renderer])}
        {this[renderer]()}
    </div>;
  }
  render() {
    const  { dataArray } =this.props;
    const  { ingredientList } = dataArray;
    const  { ingredientId,message,note} = this.state;
    var ingredientname= ingredientList[ingredientId] || '' ;  
    return (
      <Grid container ref={el => (this.IngRef = el)}>
        <RegularCard
              cardTitle={ingredientname+' Supplier Price List'} 
              cardSubtitle=''
              headerColor='darkBlue'
              content={this.renderPrices()}
              footer={<Button color='darkBlue' onClick={this.savePriceData}>Save</Button>}
            />
            <Snackbar
              place='tc'
              color='info'
              icon={AddAlert}
              message={message}
              open={note}
              closeNotification={() => this.setState({ note: false, message: '' })}
              close
            />
      </Grid>
      
    );
  }
}

IngredientPrice.propTypes = {
    socket: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired, 
    dataArray: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    dataArray: state.dataArray
  });
  
  export default socketConnect(connect(mapStateToProps, null)(IngredientPrice));
