import React, { Component,Fragment} from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';
class OrderLog extends Component {
  
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      orderlogs: [],
      isToggleOn:false
    };
    this.getActiveOrderLogs(user_id); 
  }
  
  getActiveOrderLogs = user_id => this.props.socket.emit('order', { type: 'getActiveOrderLogs', data: { user_id } });

  listener = action => {
    const { type, data } = action;
    if (type === 'getActiveOrderLogsOk') {
      this.setState({orderlogs:data.orderlogs});
      console.log(this.state);
    } else if (type === 'getActiveOrderLogs') {
      console.err(data.message);
    }
  };

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.OrderLogRef && next.user_id !== this.props.user_id) {
      this.getActiveOrderLogs(next.user_id);
    }
  }
  renderDishModify = (newData,oldData) =>{
    const { dataArray} = this.props;
    const {products,typeList,slotsList}=dataArray;
    const message= newData.map((nd, ni) => {
      if(oldData[ni]!=undefined&&oldData[ni].dish_id!=undefined){
        if(nd.date!=undefined){
            var title_name = '';
            var old_title_name = '';
            var new_titile_name = '';
            
            if(typeList && typeList[oldData[ni].mealType_id].hasOwnProperty('title')){
               title_name = typeList[oldData[ni].mealType_id].title;
            }
            if(products[oldData[ni].dish_id] && products[oldData[ni].dish_id].hasOwnProperty('title')){
               old_title_name = products[oldData[ni].dish_id].title;
            }
            if(products[nd.dish_id] && products[nd.dish_id].hasOwnProperty('title')){
               new_titile_name = products[nd.dish_id].title;
            }
            return <li>Date:{moment.unix(nd.date).format('DD MMM, YYYY')} ,Meal Type:"{title_name}", Old dish:"{old_title_name}", New Dish:"{new_titile_name}".</li>;
         }else if(nd.dish_id!=undefined){
          return <li>From dish "{products[oldData[ni].dish_id].title}" to "{products[nd.dish_id].title}".</li>;
         }
      }else if(nd.cutlery!=undefined){
        if(nd.cutlery!=oldData[ni].cutlery){
          return <li>Changed the cutlery from {oldData[ni].cutlery} to {nd.cutlery}</li>;
        }
        if(nd.slot_id!=oldData[ni].slot_id){
          return <li>Changed the slot from {slotsList[oldData[ni].slot_id]} to {slotsList[nd.cutlery.slot_id]}</li>;
       }
      }
    });
    return <ol>{message}</ol>;
  }
  
  render() {
    const { orderlogs,isToggleOn } = this.state;
    
    var logs = [];
    //console.log(orderlogs);
   
    var message='';
    
    if (orderlogs) {
      Object.keys(orderlogs).map( (el, idx) => {
        orderlogs[el].map( (el1, idx) => {
          message='';
          var data=(el1.data!=null)?JSON.parse(el1.data):[];
          switch(el1.action){
            case 'saveEditPlan':
            case 'modifyPlan':
              if(data.new_data!=undefined&&data.new_data.length>0){
                message =this.renderDishModify(data.new_data,data.old_data);
              }
            break;
          }
        logs.push(
          <li>
          <a href="javascript:void(0);" >{el1.action} by {el1.modified_by} : {el1.user_type} </a>
        
          <a href="#" className="float-right">{moment.unix(el1.created_date).format('DD MMM, YYYY h:mm:ss A')}</a>
          <p>{el1.description}</p>
          <Fragment>
           {message}
          </Fragment>
          
        </li>
            )
            });
      });
    }

    return (
      <div>
      <ul className="timeline">
        {logs}
      </ul>
      
      </div>
    );
  }
}

OrderLog.propTypes = {
  socket: PropTypes.object,
  orderlogs: PropTypes.object,
  user_id: PropTypes.number.isRequired
};
const props = state => ({
  products: state.dataArray.products
});
export default socketConnect(connect(props, null)(OrderLog));
