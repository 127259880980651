export const SET = 'SET';
export const UPDATE = 'UPDATE';
export const CLEAR = 'CLEAR';

const initialState = {};

export function setPlanParams(obj) {
  return {
    type    : SET,
    payload : obj
  };
}

export function updatePlanParams(obj) {
  return {
    type    : UPDATE,
    payload : obj
  };
}

export function clearPlanParams() {
  return {
    type    : CLEAR
  };
}

export default function planParamsReducer(state = initialState, action) {
  if (action.type === SET) {
    return action.payload;
  }
  if (action.type === UPDATE) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR) {
    return initialState;
  }
  return state;
}
