import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
  }

  render() {
    const { value, placeholder, className, classError, name, hasError, list, multiple, itself, disabled } = this.props;
    const { value: _value } = this.state;
    const options = Object.keys(list).map(value => {
      return ({ value, label: list[value] });
    });
    const onChange = itself ? (name, value) => this.setState({ value: value }) : this.props.onChange;
    return (
      <div className={`${className} ${hasError ? classError : ''}`} ref={el => (this.SelectContainerRef = el)}>
        <ReactSelect
          options={options}
          disabled={disabled}
          multi={multiple}
          removeSelected
          closeOnSelect
          placeholder={placeholder}
          value={itself ? _value : value}
          onChange={val => onChange(name, multiple ? val.map(el => el.value) : val ? val.value : null)}
          onOpen={() => this.SelectContainerRef.classList.add('opened')}
          onClose={() => this.SelectContainerRef.classList.remove('opened')}
        />
        {hasError.length && <span className={classError}>{hasError}</span>}
      </div>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  itself: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  list: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  classError: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

Select.defaultProps = {
  placeholder: '',
  multiple: false,
  disabled: false,
  itself: false,
  list: {},
  className: 'form-input',
  classError: 'has-error letter-xs red-color',
  value: '',
  name: '',
  onChange: () => {},
  hasError: false
};

export default Select;
