import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import moment from 'moment';
import { Grid, Checkbox,InputLabel } from 'material-ui';
import CustomInput from 'components/CustomInput/CustomInput';
import MaskInput from 'components/CustomInput/MaskInput';
import PhoneNumberInput from 'components/CustomInput/PhoneInput';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import SelectInput from 'components/SelectInput';
import ModalWrapper from 'components/ModalWrapper';
import Calendar from 'components/Calendar';
import ToolTip from 'components/ToolTip';
import Text from 'components/Typography/Text';
import { spin, stop } from 'store/spinner';
import { clearModal, setModal } from 'store/modal';
import { Close, CardGiftcard, MonetizationOn, Check } from '@material-ui/icons';
import { Select2 } from "select2-react-component";
import { AutoComplete } from "select2-react-component";
import { parsePhoneNumber } from 'react-phone-number-input';


class Form extends Component {
  constructor(props) {
    super(props);
    const { userData, errors } = props;
    const {user_id, notes, nutritionist, manager} = userData;  

    this.state = {
      userData,
      'nutritionist': nutritionist,
      'manager' : manager,
      'user_id' : user_id,
      'notes' : notes,
      'age' : '',
      'bannedClose': false,
      'hideDialog': false,
      'message': null,
      errors
    };
  }

  renderInput = (name, value, info, change, hasError, fieldName) => {
    let input = '';
    let onChange = e => {
      change(name, e.target.value);
    }
    const { type, label, props } = info;
    const { areasListFull: areas } = this.props.dataArray;
    switch (type) {
      case 'text':
        const max = props.max || Infinity;
        onChange = e => {
          const { name, value } = e.target;
          change(name, value.length > max ? value.substr(0, max) : value);
        };
        input = (<CustomInput
          labelText={label}
          formControlProps={{
            fullWidth: true
          }}
          error={hasError}
          inputProps={{
            value: value || '',
            type,
            name,
            onChange
          }}
        />);
        break;
      case 'select':
        if (name == 'area') {
          var curEmirate = +(this.state.userData[fieldName] || {})['emirate'] || 0;
          var data_em = [];
          var test = (areas || []).reduce(
            (acc, { id, name, emirate_id: eid }) => {
              if (curEmirate === +eid) { 
                data_em.push({label: name, value: id}); 
              } 
          });
          input = (
            <Select2 data={data_em} value={(this.state.userData[fieldName] || {})['area']} update={ val => {
             change(name, val);
            }}></Select2>
          );
        } else {
          var curEmirate = +(this.state.userData[fieldName] || {})['emirate'] || 0;
          var list = props.items;
          input = (<SelectInput
            labelText={label}
            items={list}
            value={('' + value) || ''}
            name={name}
            error={hasError}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange,
              disabled: name === 'area' && !curEmirate,
              title: name === 'area' && !curEmirate ? 'Select emirate first' : ''
            }}
          />);
        }
        break;
    }
    return input;
  };

  unsetError(errors = {}, names = []) {
    if (names.length === 1) {
      delete errors[names[0]];
    } else if (names.length > 1) {
      errors = { ...errors, [names[0]]: this.unsetError(errors[names[0]], names.splice(1)) };
    }
    return errors;
  }

  renderAddressFields = (data, fieldName, changeUser, errors) => {
    const addressFields = this.props.dataArray['addressFields'] || {};
    const onchange = (name, value) => {
      const result = { ...data, [name]: value };
      changeUser(fieldName, result, [fieldName, name]);
    };
    return Object.keys(addressFields).map((el, idx) => {
      const value = (data && data[el]) || '';
      const input = this.renderInput(el, value, addressFields[el], onchange, !!(errors && errors[el]), fieldName);
      return (
        <Grid item xs={12} sm={12} md={12} key={idx}>
          {input}
        </Grid>
      );
    }); 
  }; 

  listener = ({ type, data }) => {
    this.props.stop();
    const { orderId, planParams, message, errors, dish_notes, is_future, mealPlan} = data;
    switch (type) {
    
      case 'cancelOk':
       /* this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
          </div>
        });*/
        this.setState({
          'message': message,
          'hideDialog': true,
        });
      break;
     
      default:
        if (errors) {
          this.setState({ errors });
        }
        if (type.includes('Err')) {
          console.error({ type, data });
        }
      break;
    }
  };
  
  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ errors: nextProps.errors });
  }

  btnClick(userData) {
    const {submit, changeManagers} = this.props
    const {nutritionist, manager, user_id, notes} = this.state;
    if(userData['whatsUpnumber']!='' && userData['whatsUpnumber']!=null){
      let phcode = parsePhoneNumber(userData['whatsUpnumber']);
      if(!phcode){
        userData['whatsUpnumber']=userData['WPC_CODE']+userData['whatsUpnumber'];
      }
      userData['WPC_CODE']= (phcode)?'+'+phcode.countryCallingCode:userData['WPC_CODE'];
    }
    if(userData['phone']!='' && userData['phone']!=null ){
      let phcode =parsePhoneNumber(userData['phone'] );
      if(!phcode){
        userData['phone']=userData['PC_CODE']+userData['phone']; 
      }
      userData['PC_CODE']= (phcode)?'+'+phcode.countryCallingCode:userData['PC_CODE'];
    }
    //console.log(userData);
    submit(userData);  
    changeManagers(nutritionist, manager, user_id, notes);
  }

  addressSave(userData, filterFields) {
    const { user_id } = userData;
    var address = false;
    var adrtype = false;

    if (filterFields.includes('home')) {
      address = userData['homeAddress'];
      adrtype = 'home';
    }

    if (filterFields.includes('work')) {
      address = userData['workAddress'];
      adrtype = 'work';
    }

    if (filterFields.includes('public')) {
      address = userData['publicAddress'];
      adrtype = 'public';
    }

    var data = {
      userId: user_id,
      address: address, 
      adrtype: adrtype, 
    }

    console.log('==================');
    console.log(data);
    console.log('==================');

    this.props.socket.emit('profile', { type: 'addressUpdate', data: data });
  }

  cancelPlan = (orderId, makeCoupon) => {
    const { userId } = this.state.userData;
    this.props.socket.emit('order', { type: 'cancel', data: { orderId, makeCoupon, userId } });
  };

  removePlan = (orderId) => {
    this.props.socket.emit('order', { type: 'removePlan', data: { order_id: orderId } });
  };

  render() { 
    const { userData, errors, bannedClose, hideDialog,age } = this.state;
    const { dataArray, close, submit, filterFields, adminUsers, kitchenUsers } = this.props;
    const genders = dataArray.gender || {};
    const accStatuses = dataArray['accStatuses'] || {};
    const customer_types = {'normal':'Normal','vvip':'VVIP','care':'Extra Careful'};
    const { language: languages, ingredientList, slotsList, products, companies,nutritionistlist,wellnessManagerlist,countryList,dishGroups, deliveryPartner,medicalConditions,nutritionistActivelist,wellnessManagerActivelist } = dataArray;
    var productsList = [];
    Object.keys(products).map( (el, idx) => {
      productsList[el] = products[el].title;
    });

    /*const nutritionistList = {
      76: 'Rawda',
      129: 'Rayana',
      85: 'Justine',
      34: 'Sarath',
      2260: 'Lany',
      2240: 'Bayan',
    }

    const managerList = {
      145: 'Edward',
      146: 'John',
      34: 'Sarath',
      458: 'Paolo',
    }*/

    const slots = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const { birthday, email, firstName, gender, height, language, lastName, slot_id, account_status, company_id,customer_type,nationality,delivery_partner_id } = userData;
    const { medicalCondition, phone, weight, allergies, dislikes, dishdislikes, dishlikes, preferences, deliveryInstructions,eliminate_groups, whatsUpnumber,PC_CODE,WPC_CODE } = userData;

    const birth = moment.unix(birthday);
    var today = Date.now()/1000;
	    var ageval = null;
	    if (birthday&&age=='') {
	    	var birthYear = birth.format('YYYY');
	    	var todayYear = moment.unix(today).format('YYYY');
	    	var birthMonth = birth.format('MM');
	    	var todayMonth = moment.unix(today).format('MM');

	    	var ageval = todayYear - birthYear;
	    	if (birthMonth > todayMonth) {
	    		ageval = ageval - 1;
	    	}
	    }else{
        ageval =age;
      }
    const changeUser = (name, value, errorsToUnset) => 
    {
      if (name == 'account_status' && value == 'banned') {
        this.setState({
          bannedClose: true,
        });
      } else if (name == 'account_status') {
        this.setState({
          bannedClose: false,
        });
      } else if (name == 'age') {
        var year = moment().subtract(value, 'year').format('YYYY');
        const isoDate = `${year}-01-01T00:00:00.000Z`;
        const birth = moment(isoDate).unix();
        const birname='birthday';
          this.setState({
            age: value,
            userData: { ...userData, [birname]: birth, [name]: value }, // doesn't work for companies, so I use another way
            errors: errorsToUnset ? this.unsetError(errors, errorsToUnset) : this.unsetError(errors, [birname])
          });
      }else if(name == 'birthday'){
        this.setState({
              age: ''
            });
      }

      if (this.FormRef&&name != 'age') {
        this.setState({
          userData: { ...userData, [name]: value }, // doesn't work for companies, so I use another way
          errors: errorsToUnset ? this.unsetError(errors, errorsToUnset) : this.unsetError(errors, [name])
        });
      }
    }
    const changeData = (name, value, errorsToUnset) => {
      this.setState({
        [name]: value,
        errors: errorsToUnset ? this.unsetError(errors, errorsToUnset) : this.unsetError(errors, [name])
      });
    }

    const setDefAddress = address => {
      const { workAddress, homeAddress } = userData;
      const addressesData = { workAddress: { ...workAddress, default: address === 'workAddress' }, homeAddress: { ...homeAddress, default: address === 'homeAddress' } };
      this.setState({ userData: { ...userData, ...addressesData } });
    };
    const pubIsDef = userData['publicAddress'] ? userData['publicAddress'].default : false;
    const cellStyle = { margin: '10px 0', padding: '0 10px' };
    return (
      <ModalWrapper show close={close} ref={el => (this.FormRef = el)} md={6}>
        <Grid container >
          {filterFields.includes('addresses') && <Grid item md={12}>
            
            {filterFields.includes('home') && <RegularCard
              cardTitle='Main address'
              cardSubtitle='' 
              content={
                <div>
                  <Grid container>
                    {this.renderAddressFields(userData['homeAddress'], 'homeAddress', changeUser, errors['homeAddress'])}
                    {/* <Grid item md={12} style={cellStyle}>
                      <div className='checkHolder'>
                        <Checkbox
                          checked={(userData['homeAddress'] || {}).default}
                          icon={<Check className={'unChecked'} />}
                          checkedIcon={<Check className={'checked'} />}
                          onClick={() => setDefAddress('homeAddress')}
                        />
                        Use as default
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
              }
            />}
            {filterFields.includes('work') && <RegularCard
              cardTitle='2nd address'
              cardSubtitle=''
              content={
                <div>
                  <Grid container>
                    {this.renderAddressFields(userData['workAddress'], 'workAddress', changeUser, errors['workAddress'])}
                    {/* <Grid item md={12} style={cellStyle}>
                      <div className='checkHolder'>
                        <Checkbox
                          checked={(userData['workAddress'] || {}).default}
                          icon={<Check className={'unChecked'} />}
                          checkedIcon={<Check className={'checked'} />}
                          onClick={() => setDefAddress('workAddress')}
                          classes={{
                            checked: 'unChecked'
                          }}
                        />
                        Use as default
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
              }
            />}
            {filterFields.includes('public') && <RegularCard
              cardTitle='3rd address'
              cardSubtitle=''
              content={
                <div>
                  <Grid container>
                    {this.renderAddressFields(userData['publicAddress'], 'publicAddress', changeUser, errors['publicAddress'])}
                    {/* <Grid item md={12} style={cellStyle}>
                      <div className='checkHolder'>
                        <Checkbox
                          checked={pubIsDef}
                          icon={<Check className={'unChecked'} />}
                          checkedIcon={<Check className={'checked'} />}
                          onClick={() => this.setState({ userData: { ...userData, publicAddress: { ...(userData['publicAddress'] || {}), default: !pubIsDef } } })}
                          classes={{
                            checked: 'unChecked'
                          }}
                        />
                        Use as default
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
              }
            />}
          </Grid>}
          {filterFields.includes('common') && <Grid item xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle='Profile info'
              cardSubtitle='Complete profile'
              content={
                <div> 
                  <Grid container>
                    <Grid item md={6} style={cellStyle}>
                      <CustomInput
                        labelText='Email'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'email' in errors}
                        inputProps={{
                          value: email || '',
                          onChange: e => changeUser('email', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                      <SelectInput
                        labelText='Account Status'
                        items={accStatuses}
                        value={account_status || ''}
                        name='account_status'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('account_status', e.target.value)
                        }}
                      />

                      {bannedClose && userData.plan &&
                        <ToolTip
            tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
              <div className='text-center' style={{ fontSize: 'large' }}>
                <span>Are you sure?</span>
              </div>
              {(({ order, payed }) => {
                if (payed) {
                  return <Fragment> 
                    <Button  fullWidth color='success' size='medium' onClick={() => this.cancelPlan(order, true)} >
                      <CardGiftcard className='icon-yes' /> <span className='button-text yes'>Yes, Make Coupon</span>
                    </Button>
                    <Button  fullWidth color='success' size='medium' onClick={() => this.cancelPlan(order, false)} >
                      <MonetizationOn className='icon-yes' /> <span className='button-text yes'>Yes, Refund Money</span>
                    </Button>
                    <Button  fullWidth color='warning' size='medium' onClick={() => this.removePlan(order)} >
                      <span className='button-text yes'>Created By Mistake (Remove Fully)</span>
                    </Button>
                  </Fragment>;
                } else {
                  return <Button  fullWidth color='success' size='medium' onClick={() => this.cancelPlan(order, false)}>
                    <Check className='icon-yes' /> <span className='button-text yes'>Yes</span>
                  </Button>;
                }
              })(userData.plan)}
              <Button fullWidth color='danger' size='medium' onClick={() => {
                this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));
              }}>
                <Close className='icon-no' /> <span className='button-text no'>No</span>
              </Button>
            </div>}
            tooltipDirection={'topRight'}
            trigger={['click']}
            hide={hideDialog}
          > 
                        <div className="subform-text">Customer has an active Meal Plan
                          <div className="button subform-btn bg-danger danger-shadow">Close Active Mealplan</div>
                          <br/><p className="subform-success-text">{this.state.message}</p>
                        </div> 
          </ToolTip>
                      }
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                      <SelectInput
                        labelText='Language'
                        items={languages || {}}
                        value={language || ''}
                        name='language'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('language', e.target.value)
                        }}
                        error={'language' in errors}
                      />
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                      <CustomInput
                        labelText='First Name'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'firstName' in errors}
                        inputProps={{
                          value: firstName || '',
                          onChange: e => {
                            const val = e.target.value;
                            changeUser('firstName', val.length <= 50 ? val : firstName);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                      <CustomInput
                        labelText='Last Name'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'lastName' in errors}
                        inputProps={{
                          value: lastName || '',
                          onChange: e => {
                            const val = e.target.value;
                            changeUser('lastName', val.length <= 50 ? val : lastName);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                      {/* <MaskInput
                        labelText='Mobile Number'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'phone' in errors}
                        inputProps={{}}
                        maskProps={{
                          value: phone || '',
                          onChange: e => changeUser('phone', e.target.value),
                          mask: '+\\9\\7\\1(99)999 99 99 99',
                          permanents: [0, 1, 2, 3, 4, 7, 11, 14, 17],
                          alwaysShowMask: false,
                          maskChar: ' '
                        }}
                      /> */}
                      <PhoneNumberInput
                           labelText='Calling Number'
                           formControlProps={{
                             fullWidth: true
                           }}
                           error={'phone' in errors}
                          phoneProps={{ 
                            value:(PC_CODE!=null && PC_CODE!=undefined && phone!=undefined)?PC_CODE+(phone.replace(PC_CODE,'')):phone,
                          defaultCountry:"AE",
                          international:true,
                          countryCallingCodeEditable:false,
                          onChange:(value) => changeUser('phone', value)
                    }}
                    inputProps={{
                      
                    }}
                          />
                    </Grid>
                    <Grid item md={6} style={cellStyle}>
                    <PhoneNumberInput
                          labelText='WhatsApp Number'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'whatsUpnumber' in errors}
                          phoneProps={{ 
                            value:(WPC_CODE!=null && WPC_CODE!=undefined && whatsUpnumber!=undefined)?WPC_CODE+(whatsUpnumber.replace(WPC_CODE,'')):whatsUpnumber,
                              defaultCountry:"AE",
                              international:true,
                              countryCallingCodeEditable:false,
                              onChange:(value) => changeUser('whatsUpnumber', value)
                          }}
                          />
                    </Grid>
                    <Grid item md={3} style={cellStyle}>
                      <CustomInput
                        labelText='Weight (kg)'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'weight' in errors}
                        inputProps={{
                          value: (weight / 1000) || '',
                          type: 'number',
                          onChange: e => changeUser('weight', (e.target.value < 0 ? 0 : (e.target.value > 500 ? 500 : e.target.value) * 1000))
                        }}
                      />
                    </Grid>
                    <Grid item md={3} style={cellStyle}>
                      <CustomInput
                        labelText='Height (cm)'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'height' in errors}
                        inputProps={{
                          value: height || '',
                          type: 'number',
                          onChange: e => changeUser('height', e.target.value < 0 ? 0 : (e.target.value > 300 ? 300 : e.target.value))
                        }}
                      />
                    </Grid>
                    <Grid item md={3} style={cellStyle}>
                      <Calendar
                        title={'Date of Birth'}
                        date={birthday}
                        name={'birthday'}
                        view={'decade'}
                        minDate={moment().utc().startOf('day').subtract(100, 'y').unix()}
                        maxDate={moment().utc().startOf('day').subtract(18, 'y').unix()}
                        onChange={(value, name) => changeUser(name, value)}
                        error={'birthday' in errors}
                        controlled
                      />
                      {/* <CustomInput
                        labelText=''
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'birthday' in errors}
                        inputProps={{
                          value: birthday ? moment.unix(birthday).format('DD/MM/YYYY') : '',
                          type: 'date',
                          onChange: e => changeUser('birthday', moment(e.target.value).unix())
                        }}
                      /> */}
                    </Grid>
                    <Grid item md={3} style={cellStyle}>
                      <CustomInput
                          labelText='Age'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'age' in errors}
                          inputProps={{
                            value: ageval ? ageval: '',
                            type: 'number',
                            onChange: e => changeUser('age', e.target.value)
                          }}
                        />
                    </Grid> 
                    <Grid item md={6} style={cellStyle}>
                      <SelectInput
                        labelText='Gender'
                        items={genders}
                        value={gender || ''}
                        name='gender'
                        error={'gender' in errors}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('gender', e.target.value)
                        }}
                      />
                    </Grid>

                    {companies && <Grid item md={3} style={cellStyle}>
                      <SelectInput
                        labelText='company'
                        items={companies}
                        value={company_id || ''}
                        name='company_id'
                        error={'company_id' in errors}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('company_id', e.target.value)
                        }}
                      />
                    </Grid>}

                    {/*<Grid item md={6} style={cellStyle}>
                      <SelectInput
                        labelText='Delivery Slot'
                        items={slots}
                        value={slot_id || ''}
                        name='slot_id'
                        error={'slot_id' in errors}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('slot_id', e.target.value)
                        }}
                      />
                    </Grid>*/}
                    <Grid item md={6} style={cellStyle}>
                      <SelectInput
                          labelText='Customer Type'
                          items={customer_types}
                          value={customer_type || ''}
                          name='customer_type'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeUser('customer_type', e.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item md={6} style={cellStyle}>
                      <Select
                        placeholder='Nationality'
                        name='nationality'
                        value={nationality || ''}
                        list={countryList || {}}
                        onChange={changeUser}
                      />
                      </Grid>
                      <Grid item md={6} style={cellStyle}>
                        <SelectInput
                          labelText='Delivery Partner'
                          name='delivery_partner_id'
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={delivery_partner_id || ''}
                          items={deliveryPartner || {}}
                          inputProps={{
                            onChange: e => changeUser('delivery_partner_id', e.target.value)
                          }}
                        />
                      </Grid>
                  </Grid>
                </div>
              }
            /> 
          </Grid>}
          {filterFields.includes('medical') && <Grid item xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle='Medical condition'
              cardSubtitle='Complete medical info'
              content={
                <div>
                  <Grid container>
                    <Grid item md={12} style={cellStyle}>
                      {/* <CustomInput
                        labelText='Please type here...'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'medicalCondition' in errors}
                        inputProps={{
                          multiline: true,
                          rows: 6,
                          value: medicalCondition || '',
                          onChange: e => changeUser('medicalCondition', e.target.value)
                        }}
                      /> */}
                       <Select
                        placeholder='Medical Condition'
                        name='medicalCondition'
                        value={medicalCondition || []}
                        hasError={medicalCondition in errors}
                        list={medicalConditions || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </Grid>}
          {filterFields.includes('deliveryInstructions') && <Grid item xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle='Delivery Instructions'
              content={
                <div>
                  <Grid container>
                    <Grid item md={12} style={cellStyle}>
                      <CustomInput
                        labelText='Please type here...'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'deliveryInstructions' in errors}
                        inputProps={{
                          multiline: true,
                          rows: 6,
                          value: (deliveryInstructions!=null && deliveryInstructions.replace(/&amp;/g, '&')) || '',
                          onChange: e => changeUser('deliveryInstructions', e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </Grid>}
          {filterFields.includes('other') && <Grid item xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle='Other info'
              cardSubtitle='Dietary preferences, allergies and dislikes'
              content={
                <div>
                  <Grid container>
                    <Grid item md={12} style={{ ...cellStyle, marginBottom: '25px' }}>
                      <CustomInput
                        labelText='Notes & Likes'
                        formControlProps={{
                          fullWidth: true
                        }}
                        error={'preferences' in errors}
                        inputProps={{
                          multiline: true,
                          rows: 6,
                          value: this.state.notes || '',
                          onChange: e =>  changeData('notes', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ ...cellStyle, marginBottom: '25px' }}>
                    <InputLabel
                        >{'Allergies'} </InputLabel>
                      <Select
                        placeholder='Allergies'
                        name='allergies'
                        value={allergies || []}
                        hasError={allergies in errors}
                        list={ingredientList || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>
                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Dislikes'} </InputLabel>
                      <Select
                        placeholder='Dislikes'
                        name='dislikes'
                        value={dislikes || []}
                        hasError={dislikes in errors}
                        list={ingredientList || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>
                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Eliminate Group'} </InputLabel>
                      <Select
                        placeholder='Eliminate Group'
                        name='eliminate_groups'
                        value={eliminate_groups || []}
                        hasError={eliminate_groups in errors}
                        list={dishGroups || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>    
                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Dish Likes'} </InputLabel>
                      <Select
                        placeholder='Dish Likes'
                        name='dishlikes'
                        value={dishlikes || []}
                        hasError={dishlikes in errors}
                        list={productsList || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>

                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Dish DisLikes'} </InputLabel>
                      <Select
                        placeholder='Dish DisLikes'
                        name='dishdislikes'
                        value={dishdislikes || []}
                        hasError={dishdislikes in errors}
                        list={productsList || {}}
                        onChange={changeUser}
                        multiple
                      />
                    </Grid>

                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Nutritionist'} </InputLabel>
                      <Select
                        placeholder='Nutritionist'
                        name='nutritionist'
                        value={this.state.nutritionist || null}
                        list={nutritionistActivelist || {}}
                        onChange={changeData}
                      />
                     
                    </Grid>

                    <Grid item md={12} style={cellStyle}>
                    <InputLabel
                        >{'Wellness manager'} </InputLabel>
                      <Select
                        placeholder='Wellness manager'
                        name='manager'
                        value={this.state.manager || null}
                        list={wellnessManagerActivelist || {}}
                        onChange={changeData}
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </Grid>}
        </Grid>
        <div className='modal-button-handler right'>
        {filterFields.includes('addresses') ? 
          <Button color='darkBlue' onClick={() => {
            this.addressSave(userData, filterFields);
          }}>Update Address</Button>
        :
          <Button color='darkBlue' onClick={() => {
            this.btnClick(userData);
          }}>Update Profile</Button> }
        </div>
      </ModalWrapper>
    );
  }
}

Form.propTypes = {
  socket: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  filterFields: PropTypes.array
};

const props = state => ({
  dataArray: state.dataArray,
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions)(Form));
