import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';


class CartModal extends Component {
  constructor(props) {
    super(props);
  }

  cartDelete = (cart_id) => {
    this.props.socket.emit('order', { type: 'removeFromCart', data: { cartId: cart_id} });
    
  };

  cartPurchased = (cart_id) => {
    this.props.socket.emit('order', { type: 'cartPurchased', data: { cartId: cart_id} });
  };

  render() {
    const { cart, products, typeList} = this.props;

    var rows = [];
    Object.keys(cart).map( (el, idx) => {
      rows.push(
                  <tr id="{idx}">
                      <td>#</td>
                      <td>{products[cart[el].dish_id].title}</td>
                      <td>{typeList[cart[el].to_type].title}, {products[cart[el].to_dish_id].title}</td>
                      <td>{moment.unix(cart[el].date).format('DD MMM, YYYY')}</td>
                      <td>
                        <Button color='danger' size='small' onClick={() => {
                            this.cartDelete(cart[el].id);
                          }}>Delete</Button>
                      </td>
                      <td>
                          <Button color='success' size='small' onClick={() => {
                            this.cartPurchased(cart[el].id);
                          }}>Set Purchased</Button>
                      </td>
                    </tr>
      )
    });
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Customer's Cart</Text>
            </Grid>
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Side / Dish</th>
                  <th scope="col">Added To</th>
                  <th scope="col">Date</th>
                  <th scope="col">Delete</th> 
                  <th scope="col">Set Purchased</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
              </table>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

CartModal.propTypes = {
  //dataArray: PropTypes.object.isRequired,
};

export default CartModal;