import React, { Component } from 'react';
import {
    Grid,
    Table as MUITable,
    TableBody,
    TableCell,
    TableRow
  } from 'material-ui';
import moment from 'moment';
import SelectInput from 'components/SelectInput';
class Days extends Component {

    constructor(props) {
        super(props);
        const { datas } = this.props;
        this.state = {
            datas :datas
        }
    }
    changeData = (name,value) => {
      const { datas } = this.state;
      const { userData,saveSchedule } = this.props;
      var ndatas = datas;
      ndatas = { ...ndatas, [name]: value };
      if(name=='delivery_address_type'){
        var nname = "delivery_address";
        ndatas = { ...ndatas, [nname]: userData[value] };
      }
      this.setState({ datas: ndatas });
      saveSchedule(ndatas);
    }
    render() {

      const { datas } = this.state;
      const { index, makeAddress, slots , deliveryPartner, bagname, addressTypes } = this.props;
     
      const { delivery_date, delivery_address, delivery_partner_id, delivery_slot_id, delivery_address_type } = datas;
      // const addressTypes = {'homeAddress':'Main address','publicAddress':'2nd address','workAddress':'3rd address'}
       return( <TableRow key={index}>
                <TableCell classes={{body:'cell-body'}}>{bagname=='InitBag'&&moment.unix(delivery_date).format('DD.MM.YYYY')}</TableCell>
                <TableCell classes={{body:'cell-body'}}>{bagname=='InitBag'&&moment.unix(delivery_date).format('ddd')} </TableCell>
                <TableCell classes={{body:'cell-body'}}>{bagname}</TableCell>
                <TableCell classes={{body:'cell-body'}}>{delivery_address ? makeAddress(delivery_address) : 'No data'}
                  <SelectInput
                      labelText=''
                      items={addressTypes || {}}
                      value={delivery_address_type || ''}
                      formControlProps={{
                          fullWidth: true,
                          className:'delivery-contain'
                      }}
                      classes={{select:'select-input'}}
                      inputProps={{
                        onChange: e => this.changeData('delivery_address_type',e.target.value)
                      }}
                  />
                </TableCell>
                <TableCell classes={{body:'cell-body'}}>
                <SelectInput
                    labelText=''
                    items={deliveryPartner || {}}
                    value={(delivery_partner_id!=null)?delivery_partner_id.toString():''}
                    formControlProps={{
                        fullWidth: true,
                        className:'delivery-contain'
                    }}
                    classes={{select:'select-input'}}
                    inputProps={{
                      onChange: e => this.changeData('delivery_partner_id',e.target.value)
                    }}
                />
                </TableCell>
                <TableCell classes={{body:'cell-body'}}>
                <SelectInput
                    labelText=''
                    items={slots || {}}
                    value={delivery_slot_id || ''}
                    formControlProps={{
                        fullWidth: true,
                        className:'delivery-contain timing'
                    }}
                    classes={{select:'select-input'}}
                    inputProps={{
                      onChange: e => this.changeData('delivery_slot_id',e.target.value)
                    }}
                />
                </TableCell>
                
            </TableRow>);
    }
}

export default Days;