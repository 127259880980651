import React from 'react';
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions
} from 'material-ui';
import PropTypes from 'prop-types';
import cx from 'classnames';

import customCardStyle from 'assets/jss/material-dashboard-react/customCardStyle';

function CustomCard({ ...props }) {
  const {
    classes,
    header,
    plainCard,
    children,
    marginOnePx,
    noPadding,
    paddingPxFilters,
    backgroundColor,
    cardCustomStyle,
    footer
  } = props;
  const { color: headerColor, title, subtitle } = header || {};
  const plainCardClasses = cx({ [`${classes.cardPlain}`]: plainCard });
  const cardPlainHeaderClasses = cx({ [` ${classes.cardPlainHeader}`]: plainCard });
  const marginOnePxClass = cx({ [` ${classes.marginOnePx}`]: marginOnePx });
  const noPaddingClass = cx({ [` ${classes.noPadding}`]: noPadding });
  const paddingPxFiltersClass = cx({ [` ${classes.paddingPxFilters}`]: paddingPxFilters });
  const backgroundColorClass = backgroundColor ? classes[`${backgroundColor}Background`] : '';
  return (
    <Card className={`${classes.card} ${marginOnePxClass} ${noPaddingClass} ${paddingPxFiltersClass} ${plainCardClasses} ${backgroundColorClass}`}>
      {header && <CardHeader
        classes={{
          root: `${classes.cardHeader} ${classes[`${headerColor}CardHeader`]}${cardPlainHeaderClasses}`,
          title: classes.cardTitle,
          subheader: classes.cardSubtitle
        }}
        title={title}
        subheader={subtitle}
      />}
      <CardContent style={noPadding ? { padding: 0, ...cardCustomStyle } : cardCustomStyle}>{children}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  );
}

CustomCard.defaultProps = {
  headerColor: 'purple'
};

CustomCard.propTypes = {
  plainCard: PropTypes.bool,
  marginOnePx: PropTypes.bool,
  noPadding: PropTypes.bool,
  paddingPxFilters: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  cardCustomStyle: PropTypes.object,
  header: PropTypes.shape({
    title: PropTypes.node,
    subtitle: PropTypes.node,
    color: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple', 'darkBlue', 'darkGreen'])
  }),
  backgroundColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple', 'darkBlue', 'darkGreen']),
  children: PropTypes.any,
  footer: PropTypes.node
};

CustomCard.defaultProps = {
  headerColor: 'darkBlue',
  cardCustomStyle: {}
};

export default withStyles(customCardStyle)(CustomCard);
