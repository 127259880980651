import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';
import Button from 'components/CustomButtons/Button';
import CustomCard from 'components/Cards/CustomCard';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      order: 'default+',
      nameOrPhone: '',
      status: 'all',
      reason: 'all'
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today
    };
  }

  render() {
    const { filters, today } = this.state;
    const { errors, applyFilters } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value } });
    };
    const { nameOrPhone, order, status, reason } = filters;
    const amountSorters = {
      'default+': 'Default',
      'username+': 'Username ASC',
      'username-': 'Username DESC',
      'debit+': 'Debit ASC',
      'debit-': 'Debit DESC',
      'credit+': 'Credit ASC',
      'credit-': 'Credit DESC',
      'date+': 'Date ASC',
      'date-': 'Date DESC'
    };
    return (
      <CustomCard marginOnePx>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
          <CustomInput
            labelText='Name or Phone'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'nameOrPhone' in errors}
            inputProps={{
              value: nameOrPhone,
              min: today.format('DD/MM/YYYY'),
              type: 'text',
              name: 'nameOrPhone',
              onChange
            }}
            labelProps={{
              shrink: true
            }}
          />
          <SelectInput
            labelText='Sort by amount'
            items={amountSorters}
            value={order}
            error={'order' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'order',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by status'
            items={{ all: 'All', 0: 'Pending', 1: 'Done' }}
            value={status}
            error={'status' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'status',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by reason'
            items={{ all: 'All', sales: 'Sales', refund: 'Refund' }}
            value={reason}
            error={'reason' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'reason',
              onChange
            }}
          />
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              this.setState({ filters: this.defFilters });
              applyFilters(this.defFilters);
            }}>Clear</Button>
            <Button color='darkBlue' size='large' type='submit' style={{ position: 'relative', marginLeft: '5px' }}>Apply</Button>
          </div>
        </form>
      </CustomCard>
    );
  }
}

Filters.propTypes = {
  errors: PropTypes.object,
  applyFilters: PropTypes.func.isRequired
};

Filters.defaultProps = {
  errors: {}
};

export default Filters;
