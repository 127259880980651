// ##############################
// // // RegularCard styles
// #############################

import {
  card,
  cardHeader,
  defaultFont,
  orangeCardHeader,
  greenCardHeader,
  darkGreenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  darkBlueCardHeader,
  orangeBackground,
  greenBackground,
  darkGreenBackground,
  redBackground,
  blueBackground,
  purpleBackground,
  darkBlueBackground
} from 'assets/jss/material-dashboard-react.jsx';

const customCardStyle = {
  card,
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none'
  },
  cardHeader: {
    ...cardHeader,
    ...defaultFont
  },
  cardPlainHeader: {
    marginLeft: 0,
    marginRight: 0
  },
  orangeCardHeader,
  greenCardHeader,
  darkGreenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  darkBlueCardHeader,
  orangeBackground,
  greenBackground,
  redBackground,
  blueBackground,
  purpleBackground,
  darkBlueBackground,
  darkGreenBackground,
  cardTitle: {
    color: '#FFFFFF',
    marginTop: '0',
    marginBottom: '5px',
    ...defaultFont,
    fontSize: '1.125em'
  },
  cardSubtitle: {
    ...defaultFont,
    marginBottom: '0',
    color: 'rgba(255, 255, 255, 0.62)',
    margin: '0 0 10px'
  },
  cardActions: {
    padding: '14px',
    display: 'block',
    height: 'auto'
  },
  marginOnePx: {
    margin: '1px 0'
  },
  noPadding: {
    padding: '0'
  },
  paddingPxFilters: {
    padding: '15px'
  }
};

export default customCardStyle;
