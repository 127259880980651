import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from 'material-ui';
import { Close, Check } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';
import { Text } from 'components/Typography';

class Ingredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: props.ingredients || {},
      order: (props.ingredients && Object.keys(props.ingredients)) || [],
      totalCost: 0
    }; 
  }
  renderTotalCost = () =>{
    const { ingredients,order } = this.state;
    const { ingredientPrices } = this.props;
     let totalcost =  order.reduce(function(previous, current) {
      const { perprice } = ingredientPrices[current] || {};
      const { raw_quantity } = ingredients[current] || {};
      const igrprice = (raw_quantity>0&&perprice>0)?(perprice/1000)*raw_quantity:0; 
      const returns = previous + igrprice;
      return returns;
    },0);
    return <ItemGrid md={12} container>
    <ItemGrid md={3}></ItemGrid>
    <ItemGrid md={2}></ItemGrid>
    <ItemGrid md={2}></ItemGrid>
    <ItemGrid md={2}></ItemGrid>
    <ItemGrid md={1}><stong>Total Cost</stong></ItemGrid>
    <ItemGrid md={1}>{totalcost.toFixed(2)}</ItemGrid>
    <ItemGrid md={1}></ItemGrid>
  </ItemGrid>;
  }
  renderIngredientFields = (id) => {
    const { ingredientList, ingredientListFull,ingredientPrices } = this.props;
    const { ingredients, order: oldOrder } = this.state;
    const list = Object.keys(ingredientList).reduce((acc, cur) => (!ingredients[cur] || cur === id) ? { ...acc, [cur]: ingredientList[cur] } : acc, {});
    const onChange = val => {
      let ings = { ...ingredients };
      let order = [...oldOrder];
      if (id && id in ings) {
        ings = { ...ings, [val]: ings[id] };
        delete ings[id];
        order[order.indexOf(id)] = val;
      } else {
        ings = { ...ings, [val]: {} };
        order = [...order, val];
      }
      this.setState({ ingredients: ings, order });
    };
    const { raw_quantity, quantity, allergen } = ingredients[id] || {};
    const { perprice } = ingredientPrices[id] || {};
    const igrprice = (raw_quantity>0&&perprice>0)?((perprice/1000)*raw_quantity).toFixed(2):0; 
    const qtyChange = (e, min = -Infinity, max = Infinity) => {
      const { name, value } = e.target;
      id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id], [name]: value < min ? min : (value > max ? max : value) } } });
    };
    const remove = () => {
      if (id in ingredients) {
        let ings = { ...ingredients };
        delete ings[id];
        this.setState({ ingredients: ings, order: oldOrder.filter(el => el !== id) });
      }
    };
    return (
      <ItemGrid md={12} key={id} container>
        <ItemGrid md={3}>
          <Select
            placeholder={'Ingredient'}
            list={list}
            value={id || ''}
            name={'ingredients'}
            onChange={(name, value) => onChange(value)}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <div className='qtyHolder'>
            <span className='label'>UOM</span>
            <Text paragraph bold inline={false}>{id && ingredientListFull[id]['unit_name']}</Text>
          </div>
        </ItemGrid>
        <ItemGrid md={2}>
          <CustomInput
            labelText={'Quantity (Raw)'}
            formControlProps={{
              fullWidth: true
            }}
            error={false}
            inputProps={{
              value: raw_quantity || 0,
              type: 'text',
              name: 'raw_quantity',
              onChange: e => qtyChange(e, 0, 10000)
            }}
          />
        </ItemGrid>
        <ItemGrid md={2}>
          <CustomInput
            labelText={'Quantity (Prepared)'}
            formControlProps={{
              fullWidth: true
            }}
            error={false}
            inputProps={{
              value: quantity || 0,
              type: 'text',
              name: 'quantity',
              onChange: e => qtyChange(e, 0, 10000)
            }}
          />
        </ItemGrid>
        <ItemGrid md={1}>
          <div className='checkHolder'>
            <Checkbox
              checked='false'
              checkedIcon={<Check className={`${allergen ? 'checked' : 'unChecked'} `} />}
              icon={<Check />}
              onClick={() => id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id], allergen: !allergen } } })}
              classes={{
                checked: 'unChecked'
              }}
            />
            Allergen
          </div>
        </ItemGrid>
        <ItemGrid md={1}>
          <CustomInput
            labelText={'Cost'}
            formControlProps={{
              fullWidth: true
            }}
            error={false}
            inputProps={{
              value: igrprice || 0,
              type: 'number',
              name: 'cost',
              onChange: e => qtyChange(e, 0, 10000)
            }}
          />
        </ItemGrid>
        <ItemGrid md={1}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>
      </ItemGrid>
    );
  };

  renderReadyIngredientFields = (id) => {
    const { ingredientList } = this.props;
    const { ingredients, order: oldOrder } = this.state;
    const list = Object.keys(ingredientList).reduce((acc, cur) => (!ingredients[cur] || cur === id) ? { ...acc, [cur]: ingredientList[cur] } : acc, {});
    const onChange = val => {
      let order = [...oldOrder];
      let ings = { ...ingredients };
      if (id && id in ings) {
        ings = { ...ings, [val]: ings[id] };
        delete ings[id];
        order[order.indexOf(id)] = val;
      } else {
        ings = { ...ings, [val]: {} };
        order = [...order, val];
      }
      this.setState({ ingredients: ings, order });
    };
    const { allergen } = ingredients[id] || {};
    const remove = () => {
      if (id in ingredients) {
        let ings = { ...ingredients };
        delete ings[id];
        this.setState({ ingredients: ings, order: oldOrder.filter(el => el !== id) });
      }
    };
    return (
      <ItemGrid md={6} key={id} container>
        <ItemGrid md={6}>
          <Select
            placeholder={'Ingredient'}
            list={list}
            value={id || ''}
            name={'ingredients'}
            onChange={(name, value) => onChange(value)}
          />
        </ItemGrid>
        <ItemGrid md={3}>
          <div className='checkHolder'>
            <Checkbox
              checked='false'
              checkedIcon={<Check className={`${allergen ? 'checked' : 'unChecked'} `} />}
              icon={<Check />}
              onClick={() => id && this.setState({ ingredients: { ...ingredients, [id]: { ...ingredients[id], allergen: !allergen } } })}
              classes={{
                checked: 'unChecked'
              }}
            />
            Allergen
          </div>
        </ItemGrid>
        <ItemGrid md={3}>{id && <Button size='medium' color='white' onClick={remove}><Close color='error' /></Button>}</ItemGrid>
      </ItemGrid>
    );
  };

  getRenderer = type => {
    let renderer = null;
    switch (type) {
      case 'simple':
        renderer = 'renderIngredientFields';
        break;
      case 'composite':
        renderer = 'renderIngredientFields';
        break;
      case 'ready':
        renderer = 'renderReadyIngredientFields';
        break;
    }
    return renderer;
  };

  componentWillReceiveProps(next) {
    if (this.IngRef && next.type !== this.props.type) {
      this.setState({ ingredients: next.ingredients || {}, order: (next.ingredients && Object.keys(next.ingredients)) || [] });
    }
  }

  render() {
    const { order } = this.state;
    const { type } = this.props;
    const renderer = this.getRenderer(type);
    return (
      <Grid container ref={el => (this.IngRef = el)}>
        {order.map(this[renderer])}
        {this[renderer]()}
        {this.renderTotalCost()}
      </Grid>
    );
  }
}

Ingredients.propTypes = {
  ingredientList: PropTypes.object.isRequired,
  ingredientListFull: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['simple', 'ready', 'composite']),
  ingredients: PropTypes.object
};

Ingredients.defaultProps = {
  type: 'simple'
};

export default Ingredients;
