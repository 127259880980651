import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { ErrorOutline } from '@material-ui/icons/index';

import { setModal, clearModal } from 'store/modal';
import { setNotify, clearNotify } from 'store/notify';
import moment from 'moment/moment';

import YesNo from 'components/Dialog/YesNo';
import List from './List';
import CustomCard from 'components/Cards/CustomCard';
import Filters from './Filters';
import { getArrays } from 'utils';

class Dishes extends Component {
  constructor(props) {
    super(props);
    const limit = 10;
    const page = 1;
    this.state = {
      xyz: false,
      list: false,
      limit,
      page,
      maxPages: 1,
      errors: {},
      activeOrders: {},
      defaultDishes: {},
      defaultDishSides: {},
      labels: false,
      actions: this.getActions(),
      filters: {}
    };
    this.getList((page - 1) * limit, limit);
  }

  getActions(filter) {
    let actions = {
      update: {
        tooltip: 'Edit',
        button: 'Edit',
        callback: id => browserHistory.push(`/admin/menu/edit/${id}`)
      },
      duplicate: {
        tooltip: 'Duplicate',
        button: 'Duplicate',
        callback: id => this.duplicate(id)
      },
      close: {
        tooltip: 'Delete',
        button: 'Delete',
        callback: id => this.deleteConfirm(id)
      },
      viewlog: {
        tooltip: 'View Logs',
        button: 'Logs',
        callback: id => browserHistory.push(`/admin/dishlog/${id}`)
      }
    };
    return filter ? Object.keys(actions).reduce((acc, cur) => filter.includes(cur) ? { ...acc, [cur]: actions[cur] } : acc, {}) : actions;
  }

  getList(offset, limit, filters) {
    this.props.socket.emit('menu_builder', {
      type: 'list',
      data: {
        offset,
        limit,
        filters
      }
    });
  }

  duplicate(id) {
    const { setModal, clearModal, socket } = this.props;
    const del = () => {
      socket.emit('menu_builder', {
        type: 'duplicatedish',
        data: {
          id
        }
      });
      clearModal();
    };
    setModal({
      content: (<YesNo actionYes={del} actionNo={() => clearModal()} message='Are you sure?' />),
      width: '30%',
      height: '20vh'
    });
  }

  deleteConfirm(id){
    const {  socket } = this.props;
    socket.emit('menu_builder', {
      type: 'delconform',
      data: {
        id
      }
    });
  }
  resetConfirmdata(){
    const {  clearModal } = this.props;
    this.setState({ activeOrders: {},
      defaultDishes: {},
      defaultDishSides: {} });
      clearModal();
  }
  delete(id) {
    const { setModal, clearModal, socket,dataArray } = this.props;
    const { dietList , typeList, sidesTypes } =dataArray;
    const { activeOrders,defaultDishes,defaultDishSides } = this.state;
    console.log(id);
    const del = () => {
      console.log(id);
      socket.emit('menu_builder', {
        type: 'del',
        data: {
          id
        }
      });
      clearModal();
    };
    var stations = [];
    stations.push(<h6>Are you sure and confirm?</h6>);
    const activeHtml  = () =>  {
      var list = activeOrders&&activeOrders.map((el, idx) => {
          return <li key={idx}>Meal Date {moment.unix(el.current_date).format('DD.MM.YYYY')} and Customer Name {el.firstName} {el.lastName} - {el.user_id}</li>;
      });
        return (activeOrders.length>0)?<div><h6>Assigned Acive Orders</h6><ul>{list}</ul></div>:'';
    };
    stations.push(activeHtml());
    const defaultHtml  = () =>  {
        var list = defaultDishes&&defaultDishes.map((el, idx) => {
            return <li key={idx}>Day {el.day} diet type {dietList[el.diet_id]} and meal type {typeList[el.type_id].title}</li>;
        });
         return (defaultDishes.length>0)?<div><h6>Assigned Default Dish</h6><ul>{list}</ul></div>:'';
     };
     stations.push(defaultHtml());
     const defaultSidesHtml  = () =>  {
      var list = defaultDishSides&&defaultDishSides.map((el, idx) => {
        return <li key={idx}>Day {el.day} diet type {dietList[el.diet_id]} and meal type {typeList[el.type_id].title} and sides {sidesTypes[el.sides_type]}</li>;
    });
     return (defaultDishSides.length>0)?<div><h6>Assigned Default Side Dish</h6><ul>{list}</ul></div>:'';
    };
    stations.push(defaultSidesHtml());
    setModal({
      content: (<YesNo actionYes={del} actionNo={() => this.resetConfirmdata()} message={stations} />),
      width: '30%',
      height: '20vh'
    });
  }

  listener = action => {
    if (this.DishesRef) {
      const { limit, page, filters } = this.state;
      switch (action.type) {
        case 'listOk':
          this.setState(action.data);
          break;
        case 'delOk':
          this.showNotification(action.data.message);
          setTimeout(() => this.getList((page - 1) * limit, limit, filters), 50);
          break;
        case 'delconformOk':
            const { orders,defaultDishes,defaultDishSides } =action.data;
            this.setState({ activeOrders:orders,defaultDishes:defaultDishes,defaultDishSides:defaultDishSides });
            this.delete(action.data.id);
            break;  
        case 'delOk':
          this.showNotification(action.data.message);
          this.resetConfirmdata();
          setTimeout(() => this.getList((page - 1) * limit, limit, filters), 50);
          break;
          case 'duplicatedishOk':
          this.showNotification(action.data.message);
          setTimeout(() => this.getList((page - 1) * limit, limit, filters), 50);
          break;
        default:
          if (action.type.includes('Err')) {
            const { errors } = action.data;
            this.setState({ errors });
          }
          break;
      }
    }
  };

  goTo = page => {
    const { limit, filters } = this.state;
    this.getList((page - 1) * limit, limit, filters);
    setTimeout(() => this.setState({ page }), 5);
    this.DishesRef && this.DishesRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
  };

  showNotification(message) {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      place: 'tc',
      color: 'success',
      icon: ErrorOutline,
      message: message,
      open: true
    });
    setTimeout(() => clearNotify(), 5000);
  }

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  componentDidMount() {
    if (this.DishesRef) {
      setTimeout(() => this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['dietList', 'dishGroups', 'dishTypes', 'dishLabels'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }

  render() {
    const { xyz, page, maxPages, list, limit, labels, actions, errors } = this.state;
    const { dietList, dishGroups, dishTypes, dishLabels, typeList } = this.props.dataArray;
    const render = list && labels && dietList && dishGroups && dishTypes && dishLabels;
    const content = render ? <List {...{ page, maxPages, limit, list, actions, goTo: this.goTo, dictionaries: { dietList, dishGroups, dishTypes, dishLabels, typeList } }} /> : null;
    return (
      <Fragment>
        <div className='filtesHolder finances'>
          <CustomCard marginOnePx>
            <Filters 
              errors={errors}
              applyFilters={(filters, offset) => {
                let newPage = page;
                if (offset === 0) {
                  newPage = 1;
                }
                this.getList(offset, limit, filters);
                setTimeout(() => this.setState({ page: newPage, filters }), 50);
              }}
              offset={(page - 1) * limit}
            />
          </CustomCard>
        </div>
        <div ref={el => (this.DishesRef = el)} className={`dish-wrapper ${xyz ? 'xyz-fin' : 'xyz'}`}>
          {content}
        </div>
      </Fragment>
    );
  }
}

Dishes.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(Dishes));
