import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class CutleryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cutlery: null,
    };
  }

  render() {
    const { cutlery } = this.state;
    const { save, orderId } = this.props;
    console.log(orderId);
    const cutleryList = {
      0 : 'Not Required',
      1 : 'Required',
    }
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Select Cutlery Value</Text>
            </Grid>
            <Grid item md={12}>
                  <SelectInput
                    labelText='Cutlery'
                    items={cutleryList || {}}
                    value={cutlery || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({cutlery: e.target.value})
                    }}
                  />
            </Grid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(orderId, cutlery)}>Save</Button>
        </div>
      </div>
    );
  }
}


CutleryModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default CutleryModal;