import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from '../Grid/ItemGrid';
import DataGrid from '../DataGrid';
import Paginator from '../Paginator';

class GridView extends Component {
  render() {
    const { page, maxPages, limit, tableHead, actions, rows, goTo, filters, submitFilters, mappedList } = this.props;
    const isArr = !!rows.reduce;
    let resRows = [];
    if ('index' in tableHead) {
      if (isArr) {
        resRows = rows.map((row, idx) => ({ ...row, index: +idx + ((page - 1) * limit) + 1 }));
      } else {
        resRows = Object.keys(rows).map((row, idx) => ({ ...rows[row], index: +idx + ((page - 1) * limit) + 1, id: rows[row].id || row }));
      }
    }
    const attributes = { rows: resRows, cols: tableHead, actions, filters, submitFilters, mappedList };
    return (
      <div>
        <DataGrid {...attributes} />
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

GridView.propTypes = {
  page: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  rows: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tableHead: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  submitFilters: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired
};

GridView.defaultProps = {
  limit: 20,
  filters: {}
};

export default GridView;
