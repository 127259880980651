import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import { Remove, Add } from '@material-ui/icons/index';
import { setModal, clearModal } from 'store/modal';
import { setNotify, clearNotify } from 'store/notify';
import { getArrays } from 'utils';
import moment from 'moment/moment';
import ModalWrapper from 'components/Modal';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import List from './List';
import Filters from './Filters';
import printJS from 'print-js';

class Receipe extends Component {
  constructor(props) {
    super(props);
    const limit = 10000;
    const page = 1;
    const today = moment().utc().startOf('day');
    const filters = {
      periodFrom: today.clone().add(1, 'd').unix(),
      periodTo: today.clone().add(1, 'd').unix()
    };
    this.state = {
      xyz: false,
      list: false,
      usersidesList: false,
      showCookedModal: false,
      limit,
      page,
      maxPages: 1,
      filters,
      splitter: {},
      errors: {},
      cookedData: {},
      mealTypes: [],
      deliveries: [],
      dishGroups: [],
      notes: {},
    };
    this.getList((page - 1) * limit, limit, filters);
  }

  getList(offset, limit, filters) {
    this.props.socket.emit('kitchen', {
      type: 'list',
      data: {
        offset,
        limit,
        filters
      }
    });

    this.props.socket.emit('kitchen', {
      type: 'listNotes',
      data: { filters }
    });
  }

  listener = action => {
    const { page, limit, filters } = this.state;
    if (this.ReceipeRef) {
      switch (action.type) {
        case 'listOk':
          this.setState(action.data);
          break;
        case 'listNotesOk':
          this.setState({notes: action.data.notes});
          break;
        case 'setOk':
          this.getList((page - 1) * limit, limit, filters);
          this.setState({ cookedData: {}, showCookedModal: false });
          break;
        default:
          if (action.type.includes('Err')) {
            const { errors } = action.data;
            this.setState({ errors });
          }
          break;
      }
    }
  };

  goTo = page => {
    const { limit, filters } = this.state;
    this.getList((page - 1) * limit, limit, filters);
    setTimeout(() => this.setState({ page }), 5);
    this.ReceipeRef && this.ReceipeRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
  };

  componentWillMount() {
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  componentDidMount() {
    if (this.ReceipeRef) {
      setTimeout(() => this.ReceipeRef && this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['typeList', 'dishGroups', 'dishTypes', 'deliveryTime', 'productsFull','portionSizeList','dishPacks'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }

  renderCookedModal = ({ dish, date, maxAmount, count }) => {
    const setCooked = () => {
      this.props.socket.emit('kitchen', { type: 'set', data: { dish, date, count } });
    };
    return <Grid container style={{ userSelect: 'none' }}>
      <Grid item md={12} style={{ textAlign: 'center', padding: '35px 0' }}>
        <Text style={{ fontSize: '24px' }} bold paragraph color={'darkBlue'}>How many dishes are cooked?</Text>
      </Grid>
      <Grid item md={4}>
        <Button
          onClick={() => this.setState({ cookedData: { ...this.state.cookedData, count: count > 1 ? count - 1 : 1 } })}
          pullRight
          disabled={count === 1}
          color={'success'}
          size={'medium'}
        >
          <Remove />
        </Button>
      </Grid>
      <Grid item md={4} style={{ textAlign: 'center' }}>
        <Text style={{ fontSize: '42px' }} color={'darkBlue'}>{count || 1}</Text>
      </Grid>
      <Grid item md={4}>
        <Button
          onClick={() => this.setState({ cookedData: { ...this.state.cookedData, count: count < maxAmount ? count + 1 : maxAmount } })}
          disabled={count === maxAmount}
          color={'success'}
          size={'medium'}
        >
          <Add />
        </Button>
      </Grid>
      <Grid item md={12} style={{ paddingTop: '40px' }}>
        <Button
          onClick={setCooked}
          color={'darkBlue'}
          style={{ display: 'block', margin: '0 auto' }}
        >
          OK
        </Button>
      </Grid>
    </Grid>;
  };

  render() {
    const { xyz, page, maxPages, list, limit, errors, splitter, mealTypes, dishGroups: activeDishGroups, deliveries, showCookedModal, cookedData,usersidesList } = this.state;
    const { productsFull, dishGroups, dishTypes, typeList } = this.props.dataArray;
    const render = list && productsFull && dishGroups && dishTypes;
    const actions = {
      cooked: (id, { date, maxAmount }) => this.setState({ showCookedModal: true, cookedData: { dish: id, date, maxAmount, count: 1 } }),
      print: (id, row) => {
        this.props.setModal({
          content: <div>
            {/*<div id={'printable-block'} style={{ width: '100%', height: '100%', padding: '25px' }}>
              
            </div>
            <Button size={'large'} color={'darkBlue'} pullRight onClick={() => printJS('printable-block', 'html')}>Print</Button>*/}
           
          </div>
        });
      }
    };
    const props = { page, maxPages, limit, actions, goTo: this.goTo, dictionaries: { productsFull, dishGroups, dishTypes, typeList }, splitter };
    const dates = Object.keys(list || {});
    const content = render ? dates.map((date, idx) => <List key={idx} {...props} date={date} list={list[date]} usersidesList={usersidesList[date]} notes={this.state.notes} />) : null;
    return (
      <Fragment>
        <div className='filtesHolder finances'>
          <CustomCard marginOnePx>
            <Filters
              errors={errors}
              applyFilters={(filters, offset) => {
                let newPage = page;
                if (offset === 0) {
                  newPage = 1;
                }
                this.getList(offset, limit, filters);
                setTimeout(() => this.setState({ page: newPage, filters }), 50);
              }}
              offset={(page - 1) * limit}
              setSplitter={splitter => this.setState({ splitter })}
              splitter={splitter}
              mealTypes={mealTypes}
              activeDishGroups={activeDishGroups}
              deliveries={deliveries}
            />
          </CustomCard>
        </div>
        <CustomCard marginOnePx>
          <div ref={el => (this.ReceipeRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
            {content}
          </div>
        </CustomCard>
        <ModalWrapper width='30%' height='30vh' handleClose={() => this.setState({ showCookedModal: false })} open={showCookedModal}>
          {this.renderCookedModal(cookedData)}
        </ModalWrapper>
      </Fragment>
    );
  }
}

Receipe.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(Receipe));
