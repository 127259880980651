import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

import './styles.css';

class ModalWrapper extends React.Component {
  /* getModalStyle() {
    const { width, height, position } = this.props;
    return {
      width: width ? `${width}px` : 'fit-content',
      height: height ? `${height}px` : 'fit-content',
      minWidth: '100px',
      minHeight: '50px',
      boxSizing: 'border-box',
      ...this.getPosition(position, width, height)
    };
  }

  getPosition = (pos, width, height) => {
    if (!pos && width && height) {
      const y = ~~((window.innerHeight - height) / 2);
      const x = ~~((window.innerWidth - width) / 2);
      return { top: `${y}px`, left: `${x}px` };
    } else if (Array.isArray(pos)) {
      let [x, y] = pos || [0, 0];
      return { top: `${y}px`, left: `${x}px` };
    } else if (typeof pos === 'object') {
      let { x, y } = pos || {};
      return { top: `${y || 0}px`, left: `${x || 0}px` };
    } else {
      return {};
    }
  }; */

  componentDidMount() {
    const { width, height } = this.props;
    const modal = this.ModalBodyRef;
    if (modal) {
      let { width: modalWidth, height: modalHeight } = modal.getBoundingClientRect();
      if (!width) {
        this.ModalBodyRef.style.left = `${~~((window.innerWidth - modalWidth) / 2)}px`;
      }
      if (!height) {
        this.ModalBodyRef.style.top = `${~~((window.innerHeight - modalHeight) / 2)}px`;
      }
    }
  }

  render() {
    const { children, display, handleClose, classNames } = this.props;
    return <div>
      {display && <div className='modal-back-layer modal-wrapper-custom' onClick={handleClose}>
        <div ref={el => (this.ModalBodyRef = el)} className={`modal-body b-rounded-blue ${classNames}`} onClick={e => e.stopPropagation()}>
          <div className='close-button' onClick={handleClose}>
            <Close />
          </div>
          {children}
        </div>
      </div>}
    </div>;
  }
}

ModalWrapper.propTypes = {
  display: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  classNames: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ModalWrapper;
