import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { 
  Checkbox,
  Card,
  CardContent,
  Typography,
  FormControlLabel
} from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CalendarDate from 'components/Calendar';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

class RefundPlanModal extends Component {
  constructor(props) {
    super(props);
    const { errors, mealPlan, planParams,moveStats,pause_start, pause_end,pauses } = props;
    // const minDate = moment().utc().add(2, 'd').startOf('day').unix();
    const today = moment().utc().startOf('day');
    let cancelFrom=today.unix();
    const { individualprices,end_date } = planParams;
    if(end_date<today.unix()){
      cancelFrom=end_date;
    }
    // console.log(mealPlan);
    const allDates = Object.keys(mealPlan);

    console.log('pause_start');
    console.log(pause_start);
    console.log('pause_end');
    console.log(pause_end);
    var totalprice=0,totalcount=0,compdays=0;
    var removeCompday=true;
    var pauseDetail='';
    var is_pause_day=false;
    var pause_end_date=false;
     Object.keys(allDates).map((timestamp,currentValue)=>{
            var stamp=parseInt(allDates[currentValue]);
            if(pauses[stamp]!=undefined){
              pauseDetail=pauses[stamp];
              // pauseDetail.end_date=(pauseDetail.end_date==null)?Object.keys(mealPlan)[Object.keys(mealPlan).length - 1]:pauseDetail.end_date;
              pause_end_date=(pauseDetail.end_date==null)?Object.keys(allDates)[Object.keys(allDates).length - 1]:pauseDetail.end_date;
            }
            is_pause_day=false;
            if(pause_end_date && stamp <= pause_end_date){
              is_pause_day=true;
            }
            // if(!this.checkIsPause(stamp,pause_start, pause_end)){
              if(!is_pause_day){
                if((typeof(moveStats[stamp]) != 'undefined'&&(moveStats[stamp].log_action=='charged' || (removeCompday && moveStats[stamp].log_action=='complimentary')))){
                  totalcount--;
                  totalprice-=parseInt(individualprices[stamp]);
                }else if((typeof(moveStats[stamp]) != 'undefined'&&(moveStats[stamp].log_action.includes('addmeals')))){
                  totalprice=totalprice+(parseInt(individualprices[stamp])*parseInt(moveStats[stamp].count));
                  // console.log(parseInt(individualprices[stamp])*parseInt(moveStats[stamp].count));
                  totalcount+=parseInt(moveStats[stamp].count);
                }
                if(typeof(moveStats[stamp]) != 'undefined'&& moveStats[stamp].log_action=='complimentary'){
                  compdays++;
                }
                totalprice+=parseInt(individualprices[stamp]);
                totalcount++;
            }
          
     })
    //  console.log(totalprice);
    //  console.log(totalcount);
    this.state = {
      errors,
      mealPlan,
      planParams,
      // dates,
      allDates,
      resumeDate: false,
      startDate: false,
      removeDiscounts: false,
      removeBagFee: false,
      cancelFrom,
      cancellationFee: 20,
      creditCardFee: 2.25,
      refundDayCount:0,
      totalDayCount:totalcount,
      cancellationAmount:0,
      creditCardAmount:0,
      totalAmount:planParams.old_price,
      reasonForCancellation:'',
      cancellationDescription:'',
      removeCompday: true,
      compdays:compdays
    };
  }
  checkIsPause = (stamp,pause_start,pause_end) =>{
     return pause_start!=''&&(stamp >= pause_start) && (stamp <= pause_end || pause_end=='');
  }
  getDateCountNew=(startDate,endDate)=>{ 
    var price=0,totalcount=0;
    const {moveStats,planParams,pause_start,pause_end,pauses}=this.props;
    const { individualprices } = planParams;
    const { allDates ,removeCompday }=this.state;
    // console.log(startDate.unix());
    // console.log(endDate);
    var is_pause_day=false;
    var pause_end_date=false;
    var pauseDetail='';
    Object.keys(allDates).map((timestamp,currentValue)=>{
           // console.log(allDates[currentValue]);
           var stamp=allDates[currentValue];
           if(pauses[stamp]!=undefined){
            pauseDetail=pauses[stamp];
            // pauseDetail.end_date=(pauseDetail.end_date==null)?Object.keys(mealPlan)[Object.keys(mealPlan).length - 1]:pauseDetail.end_date;
            pause_end_date=(pauseDetail.end_date==null)?Object.keys(allDates)[Object.keys(allDates).length - 1]:pauseDetail.end_date;
          }
          is_pause_day=false;
          if(pause_end_date && stamp <= pause_end_date){
            is_pause_day=true;
          }
          //  if(stamp>=startDate.unix()&&stamp<=endDate&&!this.checkIsPause(stamp,pause_start, pause_end)){
            if(stamp>=startDate.unix()&&stamp<=endDate&&!is_pause_day){
              if((typeof(moveStats[stamp]) != 'undefined'&&(removeCompday && moveStats[stamp].log_action=='complimentary'))){
                totalcount--;
                price-=parseInt(individualprices[stamp]);
              }else if((typeof(moveStats[stamp]) != 'undefined'&&(moveStats[stamp].log_action=='charged' || moveStats[stamp].log_action.includes('addmeals')))){
                price=price+(parseInt(individualprices[stamp])*parseInt(moveStats[stamp].count));
                totalcount+=parseInt(moveStats[stamp].count);
              }
              // console.log(currentValue);
              price+=parseInt(individualprices[stamp]);
              totalcount++;
           }
    });
    return {'price':price,'totalcount':totalcount};
  }
  getDateCount=(startDate,endDate,includesDay)=>{
    const {moveStats}=this.props;
    const { allDates,removeCompday}=this.state;
    var count=moment.unix(endDate).diff(startDate, 'days')+1;

    // console.log(count);
    var exculdecount=0;
    var totalcount=0;
     for (let i = 0; i < count; i++) {
       var new_date = moment(startDate, "DD-MM-YY").add(i, 'days');
       var stamp=new_date.unix();
      //  console.log(stamp);
      //  console.log(new_date.format('DD-MM-YYYY'));
       /* Removed weekends,pause date and complimentary days */
      //  if(!includesDay.includes(new_date.weekday())||(pause_start<=stamp&&pause_end>=stamp)||(typeof(moveStats[stamp]) != 'undefined'&&moveStats[stamp].log_action=='complimentary')||(skipDays.indexOf(stamp)!=-1)){
        if(allDates.indexOf(stamp.toString())==-1||(typeof(moveStats[stamp]) != 'undefined'&&(removeCompday && moveStats[stamp].log_action=='complimentary'))){
        // console.log("Disable Date"+new_date.format('DD-MM-YYYY'));
        exculdecount++;
       }
     }
     count=(count-exculdecount);
     return (count>=0)?count:0;

 }
  
  render() {
    const { planParams, refundDayCount,cancelFrom,removeDiscounts,removeBagFee,cancellationFee,creditCardFee,reasonForCancellation,cancellationDescription, totalDayCount, totalAmount,removeCompday,compdays } = this.state;
    
    const { userData, dataArray,saveRefund } = this.props;
    // console.log( this.props);
    const { dietList, planList,commonVouchers,typeList,paymentMethods,bagPrice } = dataArray;
    
    const { firstName, lastName,plan } = userData;
    const reasonList = {
      '':'',
      "Didn't like the food":"Didn't like the food",
      'Delivery service was bad':'Delivery service was bad',
      'Customer Service is bad':'Customer Service is bad',
      'Travelling':'Travelling',
      "Didn't lose Weight":"Didn't lose Weight",
      'Too Many mistakes':'Too Many mistakes',
      'Others':'Others'
    };

    const userName = `${firstName} ${lastName}`;
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getExcludeWeek =(exclude_week_day)=>(exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const { diet: dietId, mealPlan: planId,days_count,exclude_week_day,discount_text } = planParams || {};
    const excluded =(days_count>0 && exclude_week_day!=undefined)?exclude_week_day:(planList && planList[planId] && planList[planId]['excludeWeekDay']) || [];
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excluded.includes(el));
    const vouchersList = commonVouchers.reduce((acc, val) =>{
      acc[val.id] = val;
      return acc;
    }, {});
    // console.log(vouchersList);
    // console.log(planParams);
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const today = moment();
    !today.isUTC() && today.utc();
    today.startOf('day');

    const getTypes = typesString =>typesString!=undefined && typesString && typeList ? typesString.split(',').map(el => typeList[el].title).join('+ ') : 'No data';
    const handleChange = (event) => {
      this.setState({[event.target.name]: event.target.checked });
    };
    const handleCompChange = (event) => {
      this.setState({[event.target.name]: event.target.checked },function(){
        var result=this.getDateCountNew(moment.unix(cancelFrom),planParams.end_date,includesDay);
        this.setState({ refundDayCount:result['totalcount'] });     
      });
    };
    // var totalDay=this.getDateCount(moment.unix(planParams.date),planParams.end_date,includesDay);
    // var totalDayCount=totalDay['totalcount'];
    var totalDayCount1=(!removeCompday)?totalDayCount+compdays:totalDayCount;
    var refundDayCount1=this.getDateCountNew(moment.unix(cancelFrom),planParams.end_date,includesDay);
    var refundDayCountVal=refundDayCount1['totalcount'];
    var paystatus = '';
    // var thenew=false;
    // if (plan && plan.paymentMethod &&typeof(paymentMethods)!= 'undefined'&& (typeof(paymentMethods[plan.paymentMethod]) != 'undefined') ) {
    //     paystatus = 'Paid by '+ paymentMethods[plan.paymentMethod];
    // } else {
    //     if (thenew && typeof(futurePlanParams) != 'undefined') {
    //       if (plan && futurePlanParams.paymentMethod && (typeof(paymentMethods[futurePlanParams.paymentMethod]) != 'undefined') ) {
    //         paystatus = 'Paid by '+ paymentMethods[futurePlanParams.paymentMethod];
    //       }
    //     } else {
    //       paystatus = 'Not Paid';
    //     }

    // }
    const { payment_data } = planParams;
    var paystatus = '';
      if(planParams && planParams.paymentMethod=='not_paid'){
        paystatus = 'Not Paid';
      }else if(planParams && payment_data){
        paystatus = 'Paid by '+ payment_data.name;
      }else{
        paystatus = 'Paid by '+ planParams.paymentMethod;
      }
    // let price=planParams.price/100;
    // console.log(planParams);
    let price=(parseInt(totalAmount))/100;
    // console.log(price);
    if(removeDiscounts){
        //  price=planParams.old_price/100;    
    }else if(planParams.voucher!=0){
      let discountPrice=(vouchersList[planParams.voucher].type=='percent')?price-(price*vouchersList[planParams.voucher].value/100):price-vouchersList[planParams.voucher].value;
      price=discountPrice;
    }
  //   if(!removeBagFee){
  //     price=price+planParams.bag_price;
  //  }
   refundDayCountVal=(refundDayCount>0)?refundDayCount:refundDayCountVal;
  //  console.log("Total Days:"+totalDayCount);
  //  console.log("Refund Days:"+refundDayCountVal);
    let cancelAmount=(price*(cancellationFee/100)).toFixed(2);
    let creditAmount=(price*(creditCardFee/100)).toFixed(2);
    let pricePerDay=(price/totalDayCount1).toFixed(2);
    let total=(refundDayCountVal*pricePerDay)-cancelAmount-creditAmount;
    var bagpriceval=parseFloat(bagPrice);
    if(removeBagFee){
      total=total+parseFloat(bagpriceval);
    }
    let consumeDays = totalDayCount1-refundDayCountVal;
    return (
      <div ref={el => (this.PausePlanModalRef = el)} className='edit-modal-inner'>
        <Card>
        <CardContent>
        <Typography gutterBottom variant="display1" component="h4">
            Info
          </Typography>
            <div>
            <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
            <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
            </div>
            <div>
             <p className='h-p2'>{ moment.unix(planParams.date).format('Do MMM YYYY')} to { moment.unix(planParams.end_date).format('Do MMM YYYY')}</p>
            </div>
            <div>
            <p className='h-p2'>{(days_count<=0)?planList[planId].title:days_count+' Days - '}{(days_count>0 && exclude_week_day!=undefined)?getExcludeWeek(exclude_week_day):''}</p>
            </div>
            <p className='h-p2'>
              <span className='types'>{getTypes(plan.types)}</span>
            </p>
          </CardContent>
        </Card>
        <Card>
        <CardContent>
          <Typography gutterBottom variant="display1" component="h4">
            Payment
          </Typography>
          <div>
            <p className='h-p2'>{` ${(parseInt(totalAmount))/100} AED`}{!removeDiscounts&&planParams.voucher!=0&&vouchersList[planParams.voucher]!=undefined&&<span>-{vouchersList[planParams.voucher].code}{` = ${price} AED`}{` +  ${bagpriceval} AED`}{` =  ${price+bagpriceval} AED`}</span>}</p>
            <p className='h-p2'>{paystatus}</p>
            </div>
        </CardContent>
        </Card>
        <Card>
        <CardContent>
          <Typography gutterBottom variant="display1" component="h4">
            Refund Policy
          </Typography>
          <div>
         <CalendarDate
            date={cancelFrom}
            name={'cancelFrom'}
            title={'Cancel From:'}
            minDate={planParams.date}
            maxDate={planParams.end_date}
            onChange={(value, name) => {
              const date = moment.unix(value);
              var result=this.getDateCountNew(date,planParams.end_date,includesDay);

              this.setState({ cancelFrom: date.unix(),refundDayCount:result['totalcount'] });              
            }}
            controlled
            // error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '13%'
              }
            }}
          />
          <p>Consumed Days: {consumeDays} days</p>
          <p>Refund Days: {refundDayCountVal} days</p>
          </div>
          <div>
            <FormControlLabel
            control={<Checkbox  name="removeDiscounts" />}
            label="Remove discount"
            checked={removeDiscounts}
            onChange={handleChange}
          />        
            </div>
            <div className=''><span><CustomInput
                labelText='Cancellation % '
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  value: cancellationFee || '',
                  type:'text',
                  name:'cancellationFee',
                  onChange: e => this.setState({ cancellationFee: e.target.value })
                }}
               
              />=</span>{cancelAmount}</div>
              <div className=''><span>  <CustomInput
                labelText='Credit Card fees %'
                value={creditCardFee}
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  value: creditCardFee || '',
                  type:'text',
                  name:'creditCardFee',
                  onChange: e => this.setState({ creditCardFee: e.target.value })
                }}
                // onChange={val => this.setState({ creditCardFee: val })}
              />=</span>{creditAmount}</div>
              <div className=''><SelectInput
                    labelText='Reason for cancellation'
                    items={reasonList || {}}
                    value={reasonForCancellation || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({reasonForCancellation: e.target.value})
                    }}
                  /></div> 
              <div className=''><CustomInput
                labelText='Decription for Reason'
                value={cancellationDescription}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: cancellationDescription || '',
                  name:'cancellationDescription',
                  onChange: e => this.setState({ cancellationDescription: e.target.value }),
                  multiline: true,
                  rows: 9
                }}
              /></div>   
           <div>
           <FormControlLabel
            control={<Checkbox  name="removeCompday" />}
            label="Remove Complimentary Days"
            checked={removeCompday}
            onChange={handleCompChange}
          />          
          <FormControlLabel
            control={<Checkbox  name="removeBagFee" />}
            label="Add Bag deposit fees"
            checked={removeBagFee}
            onChange={handleChange}
          />             
            </div>  
            <div>
            {refundDayCountVal&&<p className='h-p2'>
              <span>Refund Days {refundDayCountVal} Days * {pricePerDay}= </span>{(refundDayCountVal*pricePerDay).toFixed(2)}-{cancelAmount}-{creditAmount}{removeBagFee&&'+'+bagpriceval}={` ${total.toFixed(2)} AED`}
            </p>}
           {(total>0)&&<p className='h-p2'><span>Total Refund Amount =</span>{` ${total.toFixed(2)} AED`}</p>}
            </div> 
        </CardContent>
        </Card>
        <div className='modal-button-handler'>
          {total>0&&<Button
            color='darkBlue'
            onClick={() => {
              saveRefund(planParams.id,cancelFrom,refundDayCountVal, cancelAmount,creditAmount,cancellationFee,creditCardFee,removeDiscounts,removeBagFee,total,reasonForCancellation,cancellationDescription,removeCompday,consumeDays,price);
            }}
          >
            Save
          </Button>}
        </div>
      </div>
    );
  }
}

RefundPlanModal.propTypes = {
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  mealPlan: PropTypes.object,
  planParams: PropTypes.object,
  moveStats: PropTypes.array || PropTypes.object,
  dataArray: PropTypes.object.isRequired
};

export default RefundPlanModal;