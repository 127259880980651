export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';

const initialState = {};

export function setSettings(obj) {
  return {
    type    : SET_SETTINGS,
    payload : obj
  };
}

export function updateSettings(obj) {
  return {
    type    : UPDATE_SETTINGS,
    payload : obj
  };
}

export function clearSettings() {
  return {
    type    : CLEAR_SETTINGS
  };
}

export default function settingsReducer(state = initialState, action) {
  if (action.type === SET_SETTINGS) {
    // const payloadObj = { ...action.payload };
    // for (const attr in payloadObj) {
    //   payloadObj[attr]['toString'] = function () {
    //     return this.value;
    //   };
    // }
    return action.payload;
  }
  if (action.type === UPDATE_SETTINGS) {
    return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_SETTINGS) {
    return initialState;
  }
  return state;
}
