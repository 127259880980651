import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomCard from 'components/Cards/CustomCard';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderUserCard = (onClick, data, date, dataArray, relatedVehicle, priority = false, owner = false) => {
    const { typeList, deliveryTime } = dataArray;
    const { id, username, window, menu } = data;
    const types = Object.keys(menu).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${(typeList[cur] || {}).title || ''}`, '');
    return <CustomCard noPadding marginOnePx key={id}>
      <div className='package' onClick={() => onClick(+id)}>
        <div className='package-desc'>
          <p className='name'>{username}</p>
          <p className='date'>{date}</p>
          <p className='time'>{deliveryTime[window] || ''}</p>
          <p className='id'>{`id: ${id}`}</p>
          <p className='package-dishes'>{types}</p>
        </div>
        <div className='status'>
          <span className={`priority${priority ? ' up' : ''}`} />
          {relatedVehicle && <span className='car'>{relatedVehicle}</span>}
          <span className={owner ? 'my' : 'not-my'} />
        </div>
      </div>
    </CustomCard>;
  };

  render() {
    const { customers, routes, usersPackaging, priorities, onClick, today, activeWindow, dataArray, activeGroup, freeCustomers } = this.props;
    const date = moment.unix(today).utc().format('DD MMM, ddd');
    const todayRoutes = (routes || {})[today] || {};
    const plannedClients = Object.values(todayRoutes).reduce((acc, driversWindows) => {
      return Object.keys(driversWindows).reduce((acc, cur) => {
        return +cur === activeWindow ? [...acc, ...driversWindows[cur].filter(el => !acc.includes(+el) && el in customers).map(el => +el)] : acc;
      }, acc);
    }, []);
    const allClients = +activeWindow === 0 ? Object.keys(customers).filter(el => !plannedClients.includes(+el)).map(el => +el) : plannedClients;
    const customersList = routes && priorities && usersPackaging && allClients.filter(id => {
      const { menu } = customers[id];
      const values = Object.values(menu || {});
      return freeCustomers.includes(+id) && values.length !== values.reduce((acc, { packed }) => acc + packed, 0);
    }).map(id => {
      const userData = customers[id];
      if (+userData.window === activeWindow) {
        const relatedVehicle = '';
        const priority = !!priorities.find(({ date, window, driver }) => +date === +today && +window === +userData.window && driver in todayRoutes);
        const owner = !!usersPackaging.find(({ customer_id, date, deliveryTime_id }) => +date === +today && +deliveryTime_id === +userData.window && +customer_id === +id);
        return (activeGroup === 'all' || owner) && this.renderUserCard(onClick, { ...userData, id }, date, dataArray, relatedVehicle, priority, owner);
      }
    });
    return (
      <div className='packs-holder can'>
        {customersList}
      </div>
    );
  }
}

Customers.propTypes = {
  customers: PropTypes.object,
  today: PropTypes.number,
  activeWindow: PropTypes.number,
  routes: PropTypes.object,
  usersPackaging: PropTypes.array,
  freeCustomers: PropTypes.array,
  priorities: PropTypes.array,
  onClick: PropTypes.func,
  dataArray: PropTypes.object,
  activeGroup: PropTypes.string
};

Customers.defaultProps = {
  routes: {},
  usersPackaging: []
};

export default Customers;
