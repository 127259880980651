import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import { HistoryModal } from '../Modals';

class Photos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      beforePic: null,
      afterPic: null,
      history: [],
    };
    this.getHistory();
  }

  getHistory = () => {
     this.props.socket.emit('profile', { type: 'getHistory', data: { 
        'userId': this.props.user_id,
    } });
  }

  listener = ({ type, data }) => {
    this.props.stop()
      switch (type) {
        case 'getHistoryOk':
          this.setState({
            history: data.history
          });
        break;
      }
  };

   showPreviewModal(date, beforePic, afterPic) {
    const {setModal} = this.props;
    const props = {
      width: '55%',
      height: '56vh',
      display: true
    };

    props.content = <HistoryModal beforePic={beforePic} afterPic={afterPic} date={date}  />;
    props.width = '640px';
    props.height = '480px';
    
    setModal(props);
  }

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  render() {
    const {history} = this.state;
    var {beforePic, afterPic} = this.state;
    var rows = [];
    Object.keys(history).map( (time, idx) => {
      var date = moment.unix(time).format('D MMM, YYYY');
      Object.keys(history[time]).map( (type, id) => {
        if (type == '1') {
          beforePic = history[time][type];
        } else {
          afterPic = history[time][type];
        }
      });

      rows.push(
          <div className="progress-block" onClick={() => this.showPreviewModal(date, beforePic, afterPic)} >
            <span className="date">{date}</span>
            <div className="row">
            <div className="before col-md-6">
              <img id="img-{count}" src={beforePic} />
            </div>
            <div className="after col-md-6">
              <img id="img-{count+1}" src={afterPic} />
            </div>
            </div>
          </div>
      );
    });
    return (
      <div>
        <h3 class="progress-block-history">Customer's progress history</h3>
        {rows}
      </div>
    )
  }

}


Photos.propTypes = {
  dataArray: PropTypes.object,
  socket: PropTypes.object.isRequired,
  userData: PropTypes.object,
  errors: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const props = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  clearModal: () => dispatch(clearModal()),
});

export default socketConnect(connect(props, actions)(Photos));
