import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import cx from 'classnames';
import {
  withStyles,
  AppBar
} from 'material-ui';
import { ExitToApp, Home,FolderShared } from '@material-ui/icons/index';
import headerNavigationStyle from 'assets/jss/material-dashboard-react/headerNavigationStyle.jsx';
import { getCatalogs } from 'variables/menu';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.cats = getCatalogs(props.userType);

    this.state = {
      drop: false,
      open: false,
    }

  }

  toggleState(param) {
    var val = this.state[param];
    val = !val;
    this.setState({
      [param]: val
    });
  }
  openDropDown = (event) => {
   
    this.setState({
      open: event.target.innerText,
      drop: !this.state.drop
    });
  }
  render() {
    const { classes, color, links, userType, logout, location } = this.props;
    const {drop,open} = this.state;
    const appBarClasses = cx({
      [' ' + classes[color]]: color
    });
    const isActiveRoute = path => {
      const route = path.split('/').filter((el, idx) => el && idx > 1)[0];
      const loc = location.pathname.split('/').filter((el, idx) => el && idx > 1)[0];
      return path === location.pathname || (route && ((loc && route === loc) || (route === 'catalogs' && this.cats.find(el => location.pathname.includes(el.path)))));
    };
    const LinksList = () => <div className='links-list'>
      <div className='list-container'>
        {/* {links.filter(link => link.roles.includes(userType)).map(({ redirect, type, sublinks, path, sidebarName, navbarName, icon: Icon }, key) => { */}
        {userType!='guest_nutritionist'&&links.map(({ redirect, type, sublinks, path, sidebarName, navbarName, icon: Icon }, key) => {
          if (redirect) return null;
          const itemClassName = isActiveRoute(path) ? 'active-link' : '';
          if (type != 'dropdown') {
            return (
              <Link
                to={path}
                className={'menu-link'}
                key={key}
                onlyActiveOnIndex={false}
              >
                <div className={`link-body blue-theme ${itemClassName}`} title={navbarName}>
                  <div className='link-icon'>
                    <Icon />
                  </div>
                  <div className={'link-text'}>{sidebarName}</div>
                </div>
              </Link>
            );
          } else {
            return (
              <div className={`link-body blue-theme active-link dropdown-link`} title={navbarName}>
                  <div className='link-icon'>
                    <Icon />
                  </div>
                  <div className={'link-text'} onClick={() => this.toggleState('drop'),this.openDropDown}>{sidebarName} *</div>
                  {drop && sidebarName+' *' === open && <div className="dropdown">
                      
                    {sublinks.map(({ redirect, type, path, sidebarName, navbarName, icon: Icon }, keys) => {
                      return (<Link
                        to={path}
                        className={'menu-link'}
                        key={keys}
                        onlyActiveOnIndex={false}
                      >
                        <div className={`link-body blue-theme ${itemClassName}`} title={navbarName}>
                          <div className='link-icon'>
                            <Icon />
                          </div>
                          <div className={'link-text'}>{sidebarName}</div>
                        </div>
                      </Link>)
                    })}

                  </div>}
              </div>
            )
               
          }
        })}
        {userType=='guest_nutritionist'&&<Link
                to={'/admin/customers'}
                className={'menu-link'}
                key={1}
                onlyActiveOnIndex={false}
              >
                <div className={`link-body blue-theme `} title={'Customers'} style={{width:'auto'}}>
                  <div className='link-icon'>
                    <FolderShared />
                  </div>
                  <div className={'link-text'}>{'Customers'}</div>
                </div>
              </Link>}
      </div>
    </div>;
    const LogoutButt = () => <div className='butt-handler'>
      <div className='butt-wrapper'>
        <div className={'menu-button home-butt'} onClick={logout} title={'Log Out'}>
          <ExitToApp />
        </div>
      </div>
    </div>;
    const HomeButt = () => <div className='butt-handler'>
      <div className='butt-wrapper'>
        <div className={'menu-button home-butt'} title={'Home page'} onClick={() => browserHistory.push('/')}>
          <Home />
        </div>
      </div>
    </div>;
    const LogoCon  = () => <div className='logo link-body blue-theme ' onClick={() => browserHistory.push('/')}>
        <div class="link-icon"><Home /></div> <div class="link-text">Lose Weight</div>
    </div>;
    return <AppBar className={classes.appBar + appBarClasses}>
      <div className='main-menu'>
        <div className={'top-menu'}>
          <div className='menu-handler'>
             <LogoCon />
            <LinksList />
            <LogoutButt />
          </div>
          <div className='background' />
        </div>
      </div>
    </AppBar>;
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired
};

export default withStyles(headerNavigationStyle)(Navigation);
