import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';
import Button from 'components/CustomButtons/Button';
import CustomCard from 'components/Cards/CustomCard';
import { Checkbox } from 'material-ui';
import { Check } from '@material-ui/icons';
import Text from 'components/Typography/Text';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      order: 'username+',
      nameOrPhone: '',
      onlyActive: false
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today
    };
  }

  render() {
    const { filters, today } = this.state;
    const { errors, applyFilters } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value } });
    };
    const { nameOrPhone, order, onlyActive } = filters;
    const amountSorters = {
      'username+': 'Username ASC',
      'username-': 'Username DESC',
      'price+': 'Total amount ASC',
      'price-': 'Total amount DESC',
      'dailyPrice+': 'Daily Sales ASC',
      'dailyPrice-': 'Daily Sales DESC',
      'daysReceived+': 'Days Received ASC',
      'daysReceived-': 'Days Received DESC',
      'moneyReceived+': 'Money Received ASC',
      'moneyReceived-': 'Money Received DESC',
      'daysPending+': 'Days Pending ASC',
      'daysPending-': 'Days Pending DESC',
      'moneyPending+': 'Money Pending ASC',
      'moneyPending-': 'Money Pending DESC'
    };
    return (
      <CustomCard marginOnePx>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
          <CustomInput
            labelText='Name or Phone'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'nameOrPhone' in errors}
            inputProps={{
              value: nameOrPhone,
              min: today.format('DD/MM/YYYY'),
              type: 'text',
              name: 'nameOrPhone',
              onChange
            }}
            labelProps={{
              shrink: true
            }}
          />
          <SelectInput
            labelText='Sort by amount'
            items={amountSorters}
            value={order}
            error={'order' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'order',
              onChange
            }}
          />
          <div style={{ display: 'inline-block', maxWidth: 'unset' }}>
            <Checkbox
              checked={onlyActive}
              icon={<Check className='unChecked' />}
              checkedIcon={<Check className='checked' />}
              onChange={e => onChange(e, !onlyActive, 'onlyActive')}
            />
            <Text>Show users with active plan only</Text>
          </div>
          <div className='buttons-on-filters'>
            <Button
              color='danger'
              size='large'
              onClick={() => {
                this.setState({ filters: this.defFilters });
                applyFilters(this.defFilters);
              }}
              pullRight
            >Clear</Button>
            <Button
              color='darkBlue'
              size='large'
              type='submit'
              pullRight
            >Apply</Button>
          </div>
        </form>
      </CustomCard>
    );
  }
}

Filters.propTypes = {
  errors: PropTypes.object,
  applyFilters: PropTypes.func.isRequired
};

Filters.defaultProps = {
  errors: {}
};

export default Filters;
