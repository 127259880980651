import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
//import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class BagpriceModal extends Component {
  constructor(props) {
    super(props);
    const {bagprice} = props;

    this.state = {
      price: bagprice,
    };
  }

  render() {
    const { price } = this.state;
    const { save, orderId } = this.props;
    console.log(orderId);
    console.log(price);
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>Set Bag Price</Text>
            </Grid>
           
            <Grid item md={12} style={{ border: '1px solid #bebebe', padding: '5px 15px 10px' }}>
              <CustomInput
                labelText='Bag Price'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: price || '',
                  onChange: e => this.setState({ price: e.target.value }),
                  multiline: false,
                  rows: 1
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(orderId, price)}>Save</Button>
        </div>
      </div>
    );
  }
}





BagpriceModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default BagpriceModal;
