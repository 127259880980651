import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import ItemGrid from 'components/Grid/ItemGrid';
import DayEdit from './DayEdit';
import DishList from './DishList';
import { setPlanParamSides, clearPlanParamSides } from 'store/planParamSides';
import { getArrays } from 'utils';

class SideDishDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDiet: 3,
      activeType: 3,
      activeSideType: 1,
      activeDay: null,
      includedDishes: [],
      customPlanSides: {},
      defaultDishSides: {},
      defaults: {},
      altproducts: {},
    };
    this.getAltProducts(3);
  }

  checkCustom = (plans, typeId, dietId,sideTypeId) => plans.find(el => +el.mealType_id === +typeId && +el.diet_id === +dietId && +el.side_type_id === +sideTypeId ) || {};

  listener = ({ type, data }) => {
   
    const { setPlanParamSides, clearPlanParamSides, dataArray } = this.props;
    const { activeType, activeDiet, customPlanSides: oldCustomPlanSides,activeSideType } = this.state;
    switch (type) {
      case 'setBageOk':
        this.getdefaultDishSides(activeDiet, activeType);
      break;
      case 'setOk':
        const { diet_id, mealType_id,side_type_id, data: planData } = data.planParams || {};
        if (+diet_id === +activeDiet && +mealType_id === +activeType && +side_type_id === +activeSideType) {
          setPlanParamSides({ ...planData });
        }
        this.setState({ customPlanSides: { ...oldCustomPlanSides, [+diet_id]: { ...oldCustomPlanSides[+diet_id], [+mealType_id]: {...oldCustomPlanSides[+diet_id][+mealType_id],[+side_type_id]:  planData} } } });
        break;
      case 'listOk':
        const { dietList, typeList,sidesTypes } = dataArray;
        const { customPlanSides:plansList } = data;
        const customPlanSides = Object.keys(dietList || {}).reduce((acc, dietId) => {
          const types = Object.keys(typeList || {}).filter(function (typeId) {
              return (typeList[typeId].parent_id>0)
          }).reduce((acc, typeId) => {
            const sidetype = Object.keys(sidesTypes || {}).reduce((acc, sideTypeId) => ({ ...acc, [+sideTypeId]: this.checkCustom(plansList, typeId, dietId,sideTypeId).data || {} }),{});
            return { ...acc, [+typeId]: sidetype };
          },{});
          return { ...acc, [+dietId]: types };
        }, {});
        setPlanParamSides({...((customPlanSides[activeDiet] || {})[activeType] || {})[activeSideType] || {} });
        this.setState({ customPlanSides });
        break;
       case 'delOk':
        const oldPlans = { ...this.state.customPlanSides };
        const { dietId, mealTypeId } = data;
        (oldPlans[dietId] || {})[mealTypeId] && delete (oldPlans[dietId][mealTypeId]);
        clearPlanParamSides();
        break;
      case 'setErr':
        console.error(data);
        break;
    }
  };

  defDishesListener = ({ type, data }) => {
    if (type === 'getOk') {
      const defaultDishSides = { ...data.defaultDishSides };
      this.setState({ defaultDishSides, defaults: {} });
    } else if (type == 'getAltProductsOk') {
        this.setState({
          altproducts: data,
        });
    } else if (type === 'setOk') {
      const { defaultDishSides, defaults } = this.state;
      this.setState({ defaultDishSides: { ...defaultDishSides, ...defaults }, defaults: {} });
    } else if (['getErr', 'setErr'].includes(type)) {
      console.error(data);
    }
  };

  componentWillMount() {
    this.props.socket.on('custom_plan_sides', this.listener);
    this.props.socket.on('default_dish_sides', this.defDishesListener); 
  }

  componentWillUnmount() {
    this.props.socket.removeEventListener('custom_plan_sides', this.listener);
    this.props.socket.removeEventListener('default_dish_sides', this.defDishesListener);
  }

  componentDidMount() {
    const { socket, dataArray, clearPlanParamSides } = this.props;
    const types = ['typeList', 'dietList', 'productsFull', 'dishLabels','sidesTypes','dietListFull'].filter(type => !(type in dataArray));
    types.length && getArrays(socket, types);
    this.props.socket.emit('custom_plan_sides', { type: 'list' });
    clearPlanParamSides();
    const { activeDiet, activeType, activeSideType } = this.state;
    activeDiet && activeType && this.getdefaultDishSides(activeDiet, activeType, activeSideType) && this.getAltProducts(activeDiet,activeType, sideId);
  }

  change = ({ activeDay, activeDiet: newActiveDiet, activeType: newActiveType, activeSideType: newActiveSideType, includedDishes } = { activeDay: null }) => {
    const { setPlanParamSides, planParamSides } = this.props;
    const { customPlanSides, activeType: oldActiveType, activeDiet: oldActiveDiet , activeSideType: oldActiveSideType} = this.state;
    const activeDiet = newActiveDiet || oldActiveDiet;
    const activeType = newActiveType || oldActiveType;
    const activeSideType = newActiveSideType || oldActiveSideType;
    if (activeDay !== null) {
      setPlanParamSides({ ...planParamSides, [+activeDay]: planParamSides[activeDay] || includedDishes });
    } else {
      setPlanParamSides({...((customPlanSides[activeDiet] || {})[activeType] || {})[activeSideType] || {} });
      this.getdefaultDishSides(activeDiet, activeType, activeSideType);
      this.getAltProducts(activeDiet,activeType, activeSideType);
    }
    this.setState({ includedDishes, activeDay, activeDiet, activeType,activeSideType });
  };

  save = data => {
    const { activeDiet: diet_id, activeType: mealType_id,activeSideType:side_type_id } = this.state;
    this.props.socket.emit('custom_plan_sides', {
      type: 'set',
      data: {
        diet_id,
        mealType_id,
        side_type_id,
        data
      }
    });
    this.setState({ activeDay: null, includedDishes: [] });
  };

  getdefaultDishSides = (dietId, typeId, sideTypeId=false) => {
    this.props.socket.emit('default_dish_sides', { type: 'get', data: { dietId, typeId, sideTypeId} });
  };

  getAltProducts = (dietId,typeId=null, sideTypeId=false) => {
    this.props.socket.emit('default_dish_sides', { type: 'getAltProducts', data: { dietId, withsides: true, withsidenames: true,typeId, sideTypeId } });
  };

  setdefaultDishSides = (dietId, typeId, defaults, sideTypeId=false) => {
    this.props.socket.emit('default_dish_sides', { type: 'set', data: { dietId, typeId, defaults,sideTypeId } });
  };

  render() {
    const { activeDiet, activeType, activeDay, includedDishes, defaultDishSides, defaults, altproducts ,activeSideType } = this.state;
    const { planParamSides, dataArray } = this.props;
    const { typeList, dietList, productsFull: products, dishGroups, dishLabels, sidesTypes,dietListFull } = dataArray;

    var dietListFulldata = (dietListFull)?dietListFull[activeDiet] || {}:{};

    const { sides_id:sidesId  } = dietListFulldata ;

    const { parent_id:typeParentId }=typeList?typeList[activeType] || 0:0;

    const buttons = (list, active, type) => list && Object.keys(list).sort((a, b) => list[a].order - list[b].order).filter(function (typeId) {
      if(type=='activeType'){ // Filter the sides meal types based on diet sides list 
        return ((typeList[typeId].parent_id==0 || typeList[typeId].parent_id==null))?false:(typeof sidesId == undefined || sidesId==null)?false:(sidesId.length>0);
      }else if(type=='activeDiet'){
        return (typeof dietListFull != undefined && typeof dietListFull[typeId].sides_id == undefined || dietListFull[typeId].sides_id==null)?false:(dietListFull[typeId].sides_id.length>0);
      }else if(type=='activeSideType'){
        return (typeof sidesId != undefined && sidesId!=null)?(sidesId.indexOf(typeId)>-1):true;
      }else{
        return true;
      }
  }).map((id, idx) => {
      const { title } = list[id];
      const props = { activeDiet: { color: 'primary', defColor: 'success' }, activeType: { color: 'warning', defColor: 'gray' }, activeSideType: { color: 'info', defColor: 'gray' }  };
      return <Button key={idx} color={props[type][+id === +active ? 'color' : 'defColor']}
        onClick={() => this.change({ [type]: +id, activeDay: null })}>{title || list[id]}
      </Button>;
    });
    const days = [];
    for (let dayNum = 0; dayNum < 31; dayNum++) {
      days[dayNum] = (<DishList
        key={dayNum}
        includedDishes={planParamSides[dayNum] || []}
        products={altproducts}
        activeDay={activeDay}
        dishGroups={dishGroups || {}}
        dishLabels={dishLabels || {}}
        thediet={activeDiet}
        activeType={activeType}
        typeList={typeList}
        sidesTypes={sidesTypes}
        typeParentId={typeParentId}
        activeSideType={activeSideType}
        planParamSides={planParamSides}
        sidesId={sidesId}
        day={dayNum}
        clickEdit={() => this.change({ activeDay: dayNum, includedDishes })}
        save={() => this.save(planParamSides)}
        defaultDishSides={defaultDishSides || {}}
        defaults={defaults}
        changeDefaults={(dishId,activeSideType) =>{ 
          if(typeParentId){
            const newArr = { ...defaults };
            if(newArr[dayNum]==undefined){
              newArr[dayNum]=defaultDishSides[dayNum] || {};
            }
            newArr[dayNum][activeSideType] = dishId;
            this.setState({ defaults: newArr });
          }else{
            this.setState({ defaults: { ...defaults, [dayNum]: dishId } });
          }
        }}
        saveDefaults={() => this.setdefaultDishSides(activeDiet, activeType, { ...defaultDishSides, ...defaults },activeSideType)}
      />);
    }
    return (
      <div className='programsBody'>
        <div className='programsHeading'>
          <div className='head sub'>
            <div className={'diets'}>{buttons(dietList, activeDiet, 'activeDiet')}</div>
            <div className={'day-dishes'}>{buttons(typeList, activeType, 'activeType')}</div>
            <div className={'day-sidetypes'}>{buttons(sidesTypes, activeSideType, 'activeSideType')}</div>
          </div>
        </div>
        <Grid container>
          <ItemGrid md={8}>
            {days}
          </ItemGrid>
        </Grid>
        <DayEdit
          activeDiet={activeDiet}
          dishGroups={dishGroups || {}}
          dishLabels={dishLabels || {}}
          activeType={activeType}
          activeDay={activeDay}
          activeSideType={activeSideType}
          includedDishes={planParamSides[activeDay] || includedDishes || []}
          products={altproducts}
        />
      </div>
    );
  }
}

SideDishDetails.propTypes = {
  planParamSides: PropTypes.object,
  socket: PropTypes.object,
  dataArray: PropTypes.object,
  setPlanParamSides: PropTypes.func,
  clearPlanParamSides: PropTypes.func
};

const props = state => {
  return {
    dataArray: state.dataArray,
    planParamSides: state.planParamSides
  };
};

const actions = dispatch => ({
  setPlanParamSides: obj => dispatch(setPlanParamSides(obj)),
  clearPlanParamSides: () => dispatch(clearPlanParamSides())
});

export default socketConnect(connect(props, actions)(SideDishDetails));