import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';

class HistoryModal extends Component {
  render() {
    const {beforePic, afterPic, date, count} = this.props;
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
             <h3>Progress {count} History of {date}</h3>
            </Grid>
            <div className="progress-block-big" >
                <div className="row">
                <div className="before col-md-6">
                  <img src={beforePic} />
                </div>
                <div className="after col-md-6">
                  <img src={afterPic} />
                </div>
                </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default HistoryModal;