import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { pushModal, clearModalStack } from 'store/modalStack';
import { setNotify, clearNotify } from 'store/notify';
import { getArrays } from 'utils';
import moment from 'moment/moment';
import CustomCard from 'components/Cards/CustomCard';
import List from './List';
import Filters from './Filters';
import ModalForm from './ModalForm';

class Special extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    const filters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(1, 'd').unix()
    };
    this.state = {
      xyz: false,
      list: false,
      filters,
      errors: {},
      users: false,
      usedSlots: false
    };
    this.getList(filters);
  }

  getList(filters) {
    this.props.socket.emit('kitchen', {
      type: 'listSpecial',
      data: { filters }
    });
  }

  listener = ({ data, type, status }) => {
    if (this.SpecialRef) {
      if (type === 'listSpecialOk') {
        this.setState(data);
      } else if (type === 'packedSpecial' && status === 'ok') {
        this.props.clearModalStack();
        this.getList(this.state.filters);
      } else {
        if (type.includes('Err')) {
          this.setState(data);
        }
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  componentDidMount() {
    if (this.SpecialRef) {
      setTimeout(() => this.SpecialRef && this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['typeList', 'dishTypes', 'timesList', 'productsFull', 'ingredientList', 'slotsList', 'dietList'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }

  showCookedModal = (user_id, date, dishes) => {
    const { pushModal, dataArray } = this.props;
    const { productsFull, typeList } = dataArray;
    pushModal({
      content: <ModalForm {...{ productsFull, typeList, user_id, date, dishes }} />,
      onClose: () => this.getList(this.state.filters)
    });
  };

  render() {
    const { xyz, list, users, errors, usedSlots } = this.state;
    const { productsFull, dishTypes, typeList, ingredientList: ingredients, slotsList, timesList, dietList } = this.props.dataArray;
    const render = list && users && usedSlots && productsFull && slotsList && typeList && ingredients && dietList;
    const listByDates = (list || []).reduce((acc, cur) => {
      const { date } = cur;
      return { ...acc, [date]: [...(acc[date] || {}), cur] };
    }, {});
    const actions = {
      cooked: ({ user_id, date }) => this.showCookedModal(user_id, date, listByDates[date].filter(({ user_id: uid }) => +uid === +user_id))
    };
    const props = { actions, users, dictionaries: { typeList, dishTypes, timesList, productsFull, ingredients, slotsList, dietList } };
    const dates = Object.keys(listByDates);
    const content = render ? dates.map((date, idx) => <List {...props} key={idx} date={+date} list={listByDates[date]} />) : null;
    return (
      <Fragment>
        <div className='filtesHolder finances'>
          <CustomCard marginOnePx>
            <Filters
              errors={errors}
              applyFilters={filters => {
                this.getList(filters);
                setTimeout(() => this.setState({ filters }), 50);
              }}
              usedSlots={usedSlots || []}
              slotsList={slotsList || []}
            />
          </CustomCard>
        </div>
        <CustomCard marginOnePx>
          <div ref={el => (this.SpecialRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
            {content}
          </div>
        </CustomCard>
      </Fragment>
    );
  }
}

Special.propTypes = {
  socket: PropTypes.object.isRequired,
  pushModal: PropTypes.func.isRequired,
  clearModalStack: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  pushModal: object => dispatch(pushModal(object)),
  clearModalStack: () => dispatch(clearModalStack()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(Special));
