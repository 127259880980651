import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Paginator extends Component {
  render() {
    const { goToPage, classes, visibleRange, maxPage, current } = this.props;
    let buttonsList = [];
    const start = current - visibleRange > 0 ? current - visibleRange : 1;
    const end = current + visibleRange <= maxPage ? current + visibleRange : maxPage;
    for (let i = start; i <= end; i++) {
      const isCur = i === current;
      const butt = (
        <li
          key={i}
          className={`${isCur ? classes.active : classes.clickable} ${classes.digit}  ${classes.item}`}
          onClick={() => {
            if (!isCur) {
              goToPage(i);
            }
          }}
        >
          <span className='letter-xs'>{i}</span>
        </li>
      );
      buttonsList = [...buttonsList, butt];
    }
    const isFirst = current === 1;
    const isLast = current === maxPage;
    return (
      <ul className={classes.paginator}>
        {!isFirst && <Fragment>
          <li
            className={`${classes.clickable} ${classes.arrow} ${classes.item}`}
            onClick={() => goToPage(1)}
          >
            <span>{'‹‹'}</span>
          </li>
          <li
            className={`${classes.clickable} ${classes.arrow} ${classes.item}`}
            onClick={() => goToPage(current - 1)}
          >
            <span>{'‹'}</span>
          </li>
        </Fragment>}
        {buttonsList}
        {!isLast && <Fragment>
          <li
            className={`${classes.clickable} ${classes.arrow} ${classes.item}`}
            onClick={() => goToPage(current + 1)}
          >
            <span>{'›'}</span>
          </li>
          <li
            className={`${classes.clickable} ${classes.arrow} ${classes.item}`}
            onClick={() => goToPage(maxPage)}
          >
            <span>{'››'}</span>
          </li>
        </Fragment>}
      </ul>
    );
  }
}

Paginator.propTypes = {
  goToPage: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    paginator: PropTypes.string,
    item: PropTypes.string,
    active: PropTypes.string,
    clickable: PropTypes.string,
    inactive: PropTypes.string,
    digit: PropTypes.string,
    arrow: PropTypes.string
  }),
  visibleRange: PropTypes.number,
  maxPage: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired
};

Paginator.defaultProps = {
  classes: {
    paginator: 'paginator',
    item: 'item',
    active: 'active',
    clickable: 'clickable',
    inactive: 'inactive',
    digit: 'digit',
    arrow: 'arrow'
  },
  visibleRange: 1
};

export default Paginator;
