import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import { Check, Close } from '@material-ui/icons/index';

import './style.css';

class YesNo extends Component {
  render() {
    const { actionYes, actionNo, message } = this.props;
    return (
      <div className='yes-no-wrapper'>
        <p className='message'>{message}</p>
        <div className='buttons-handler'>
          <div>
            <Button fullWidth={false} color='success' size='medium' onClick={actionYes}><Check className='icon-yes' /><span className='button-text yes'>Yes</span></Button>
          </div>
          <div>
            <Button fullWidth={false} color='danger' size='medium' onClick={actionNo}><Close className='icon-no' /><span className='button-text no'>No</span></Button>
          </div>
        </div>
      </div>
    );
  }
}

YesNo.propTypes = {
  actionYes: PropTypes.func.isRequired,
  actionNo: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default YesNo;
