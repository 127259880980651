import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { userLogin } from 'store/user';
import { Grid } from 'material-ui';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import { getArrays } from 'utils';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      smscode: '',
      showsms: false,
      showPassword: false,
      errors: {}
    };
  }

  socketLogin = ({ type, data }) => {
    if (this.LoginRef) {
      switch (type) {
        case 'smsOk':
          this.setState({
            showsms: true,
          });
          alert ('Enter SMS code to login (use 2020 for now as a code)');
        break;
        case 'smsErr':
          alert ('The code is wrong');
        break;
        case 'setOk':
          this.props.login(data);
          const {  socket } = this.props;
          const { type: userType } = data;
          const tail = {
            packager: 'packaging',
            admin: ''
          };
          const types = ['usersPrivilege'];
          getArrays(socket, types);
          // browserHistory.push(`/${userType}/${tail[userType] || ''}`);
          browserHistory.push(`/admin/${tail[userType] || ''}`);
          break;
        case 'setErr':
          this.setState(data);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('login', this.socketLogin);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('login', this.socketLogin);
  }

  submit(e) {
    e.preventDefault();
    const { email, password, smscode} = this.state;
    const { browserId } = this.props;
    this.props.socket.emit('login', { type: 'set', data: { email, password, app: 'admin', browserId, smscode: smscode } });
  }

  render() {
    const onChange = (name, value) => this.LoginRef && this.setState({ [name]: value });
    const { errors, email, password, smscode, showsms } = this.state;
    return (
      <div ref={el => (this.LoginRef = el)}>
        <form onSubmit={this.submit} onKeyPress={e => e.key === 'Enter' && this.submit(e)}>
          <Grid container>
            <Grid item md={4} />
            <Grid item xs={12} sm={12} md={4}>
              <RegularCard
                cardTitle='Sign In'
                cardSubtitle='Authorise to use dashboard'
                headerColor='ldarkBlue'
                titleColor='white'
                content={
                  <div>
                    {!showsms && <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                          ref={'Email'}
                          labelText='Email address'
                          id='email'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'email' in errors}
                          inputProps={{
                            type: 'email',
                            value: email,
                            onChange: e => onChange('email', e.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>}
                    {!showsms && <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                          ref={'Pass'}
                          labelText='Password'
                          id='password'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'password' in errors}
                          inputProps={{
                            type: 'password',
                            value: password,
                            onChange: e => onChange('password', e.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>}
                    {showsms && <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                          ref={'smscode'}
                          labelText='Sms code'
                          id='smscode'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'smscode' in errors}
                          inputProps={{
                            type: 'smscode',
                            value: smscode,
                            onChange: e => onChange('smscode', e.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>}
                  </div>
                }
                footer={<Button color='success' onClick={e => this.submit(e)}>Sign In</Button>}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

SignIn.propTypes = {
  socket: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  browserId: PropTypes.string
};

SignIn.defaultProps = {
};

const props = state => ({
  browserId: state.user.browserId
});

const actions = dispatch => ({
  login: user => dispatch(userLogin(user))
});

export default socketConnect(connect(props, actions)(SignIn));
