import { Menu, Person, ImportContacts, Settings, LocalDining, Archive, FolderShared, Map, AccountBalance, RoomService, AddShoppingCart } from '@material-ui/icons/index';

const getMenuLinks = type => [
  {
    path: `/${type}/menu`,
    sidebarName: 'Menu Programming',
    navbarName: 'Menu Programming',
    icon: RoomService,
    roles: ['admin']
  },
  {
    path: `/${type}/catalogs`,
    sidebarName: 'Settings',
    navbarName: 'Settings',
    icon: Settings,
    roles: ['admin']
  },
  {
    path: '/kitchen/cooking',
    sidebarName: 'Cooking',
    navbarName: 'Cooking',
    icon: LocalDining,
    roles: ['admin']
  },
  {
    path: `/${type}/customers`,
    sidebarName: 'Customers',
    navbarName: 'Customers',
    icon: FolderShared,
    roles: ['admin']
  },
  {
    path: `/${type}/packaging`,
    sidebarName: 'Package',
    navbarName: 'Package',
    icon: Archive,
    roles: ['admin']
  },
  {
    path: '/logistic',
    sidebarName: 'Logistic',
    navbarName: 'Logistic',
    icon: Map,
    roles: ['admin']
  },
  { 
    path: '/',
    type: 'dropdown',
    sidebarName: 'Other',
    navbarName: 'Other',
    icon: ImportContacts,
    roles: ['admin'],
    sublinks: [
      {
        path: `/${type}/finances`,
        sidebarName: 'Finances',
        navbarName: 'Finances',
        icon: AccountBalance,
        roles: ['admin']
      },
      {
        path: `/${type}/blog`,
        sidebarName: 'Blog',
        navbarName: 'Blog Management',
        icon: ImportContacts,
        roles: ['admin']
      },
      {
        path: `/${type}/users/admin`,
        sidebarName: 'Users',
        navbarName: 'Users List',
        icon: Person,
        roles: ['admin']
      },
       {
        path: `/reports`,
        sidebarName: 'Reports',
        navbarName: 'Reports',
        icon: Map,
        roles: ['admin']
      },
    ]
  },
  {
    path: '/',
    sidebarName: 'Action Center',
    navbarName: 'Action Center',
    type: 'dropdown',
    icon: Person,
    roles: ['admin'],
    sublinks: [
      {
        path: `/notifications`,
        sidebarName: 'Notifications',
        navbarName: 'Notifications',
        icon: Person,
        roles: ['admin']
      },
      {
        path: `/bagcollection`,
        sidebarName: 'Bag Collection',
        navbarName: 'Bag Collection',
        icon: AddShoppingCart,
        roles: ['admin']
      }
    ]
    
  },
  /* {
    path: `/${type}/prices`,
    sidebarName: 'Prices',
    navbarName: 'Manage Prices',
    icon: MonetizationOn,
    roles: ['admin']
  }
  {
    path: `/${type}/model/order`,
    sidebarName: 'Orders',
    navbarName: 'Orders',
    icon: Assignment,
    roles: ['admin']
  },
  {
    path: `/${type}/model/coupon`,
    sidebarName: 'Coupons',
    navbarName: 'Coupons',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/delivery-time`,
    sidebarName: 'Delivery Times',
    navbarName: 'Delivery Times',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/diet`,
    sidebarName: 'Diets',
    navbarName: 'Diets',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/dish`,
    sidebarName: 'Dishes',
    navbarName: 'Dishes',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/emirate`,
    sidebarName: 'Emirates',
    navbarName: 'Emirates',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/ingredient`,
    sidebarName: 'Ingredients',
    navbarName: 'Ingredients',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/meal-plan`,
    sidebarName: 'Meal Plans',
    navbarName: 'Meal Plans',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/meal-type`,
    sidebarName: 'Meal Types',
    navbarName: 'Meal Types',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/blog-category`,
    sidebarName: 'Blog Categories',
    navbarName: 'Blog Categories',
    icon: Menu,
    roles: ['admin']
  },
  {
    path: `/${type}/model/forum-category`,
    sidebarName: 'Forum Categories',
    navbarName: 'Forum Categories',
    icon: Menu,
    roles: ['admin']
  } */
];

const getCatalogs = (type = 'admin') => [
  {
    path: `/${type}/model/delivery-time`,
    title: 'Delivery Times',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/model/slot`,
    title: 'Delivery Slots',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/model/delivery-time`,
    title: 'Delivery Times',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/user-setting`,
    title: 'User Settings',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/diet-groups`,
    title: 'Diet Groups',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/diet`,
    title: 'Diets',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/ingredient`,
    title: 'Ingredients',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/ingredient-types`,
    title: 'Ingredient Types',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/meal-plan`,
    title: 'Meal Plans',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/meal-type`,
    title: 'Meal Types',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/unit`,
    title: 'Units (UOM)',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/blog-category`,
    title: 'Blog Categories',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/forum-category`,
    title: 'Forum Categories',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/pack`,
    title: 'Packs',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/dish-group`,
    title: 'Dish Groups',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/side-group`,
    title: 'Sides Groups',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/allergen`,
    title: 'Allergens',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/cutlery`,
    title: 'Cutlery',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/hygiene`,
    title: 'Hygiene products',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/emirate`,
    title: 'Emirates',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/area`,
    title: 'Areas',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/language`,
    title: 'Languages',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/phrases`,
    title: 'Phrases',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/transport`,
    title: 'Transport',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/model/order`,
    title: 'Orders',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/model/delivery-partner`,
    title: 'Delivery Partner',
    icon: Menu,
    roles: ['admin'],
    group: 'delivery'
  },
  {
    path: `/${type}/settings`,
    title: 'System settings',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/mail-events`,
    title: 'Mail Events',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/mail-templates`,
    title: 'Mail Templates',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/spreadsheet`,
    title: 'Spreadsheets',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/static-pages`,
    title: 'Static Pages',
    icon: Menu,
    roles: ['admin'],
    group: 'system'
  },
  {
    path: `/${type}/model/total-rating`,
    title: 'Total Rating',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/dish-rating`,
    title: 'Dishes Rating',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/labels`,
    title: 'Dish Labels',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/heating-instructions`,
    title: 'Heating Instructions',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/portion-size`,
    title: 'Portion Size',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/sides-types`,
    title: 'Sides Type',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/suppliers`,
    title: 'Suppliers',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  }
  ,{
    path: `/${type}/model/medical-conditions`,
    title: 'Medical Conditions',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/mealtype-combinations`,
    title: 'Mealtype Combinations',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/days-range`,
    title: 'Days Range',
    icon: Menu,
    roles: ['admin'],
    group: 'menu'
  },
  {
    path: `/${type}/model/companies`,
    title: 'Companies',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/manage-pd`,
    title: 'Support Staff: Name',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
  {
    path: `/${type}/model/payment-methods`,
    title: 'Payment methods',
    icon: Menu,
    roles: ['admin'],
    group: 'other'
  },
];

const groups = {
  menu: 'Menu builder',
  other: 'Other',
  system: 'System',
  delivery: 'Delivery'
};

export {
  getMenuLinks,
  getCatalogs,
  groups
};
