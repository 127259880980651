import React, { Component,Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { connect } from 'react-redux';
import moment from 'moment';
import RegularCard from 'components/Cards/RegularCard';
import ItemGrid from 'components/Grid/ItemGrid';

class DishLog extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      id,
      mealPlan: {},
      planParams: {},
      note: false,
      message: '',
      dishlogs: [],
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getDishLog(id);
  }

  showNotification(message) {
    if (this.DishLogRef) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  listener = ({ data, type }) => {
    if (this.DishLogRef) {
      switch (type) {
        case 'getDishLogOk':
          this.setState(data);
          break;
        case 'getDishLogErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  componentDidMount() {
   // const { socket, dataArray } = this.props;
    // const types = ['typeList', 'productsFull'].filter(el => !(el in dataArray));
    // types.length && getArrays(socket, types);
  }

  getDishLog = dishId => this.props.socket.emit('menu_builder', { type: 'getDishLog', data: { dishId } });

  render() {
    const { dishlogs,isToggleOn } = this.state;
    const { user_id ,dataArray} = this.props;
    const {products,typeList,slotsList}=dataArray;
    var logs = [];
    //console.log(this.props);
   // console.log(dishlogs);
   if (dishlogs && dishlogs.length>0) {
        dishlogs.map( (el, idx) => {
        logs.push(
          <li>
          <a target="_blank" href="#">{el.action} by {el.modified_by} : {el.user_type} </a>
        
          <a href="javascript:void(0);" className="float-right">{el.created_at}</a>
          <p dangerouslySetInnerHTML={{__html: el.description}} />
        </li>
            )
      });
    }else{ 
      logs.push(
        <li>
        <p>No Log</p>
      </li>
          );
    }

    return (
     <div ref={el => (this.DishLogRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={'Dish Logs'}
              cardSubtitle='View Logs'
              content={
                <div className="progress scrollable-h">
                  <Grid container>
                  <ul className="timeline">
                    {logs}
                  </ul>
                  </Grid>
                </div>
              }
            />
          </ItemGrid>
        </Grid>
       
      </div>   
    );
  }
}

DishLog.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired, 
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(DishLog));
